import "./UserFeedbackForStates.css"
import React, { useState, useEffect } from 'react'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCircleCheck, faCheck } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import ratedStarImage from "../../images/ratingstart.svg"
import emptyStarImage from "../../images/emptyStart.svg"
import DataLoader from "../../images/dataload_gif.gif";
import noDataLoader from "../../images/noDataFull.svg"
import Dropdown from 'react-bootstrap/Dropdown'
import DropdownToggle from "react-bootstrap/esm/DropdownToggle";
import DropdownMenu from "react-bootstrap/esm/DropdownMenu";
import { useSearchParams } from "react-router-dom";
import StateProductWiseSummary from "../StateProductWiseSummary/StateProductWiseSummary";
import { Skeleton } from "antd";
import checkEnv from "../../../utils/checkEnv";
import RatingFeedback from "../../RatingsAndReview/RatingUserFeedbacks/RatingFeedback";
export default function UserFeedbackForState(props) {
    const [noDataLoad, setNoDataLoad] = useState(false)
    const [searchParams, setSearchParams] = useSearchParams()
    const [offset, setOffset] = useState(0)
    const [feedbackLimit, setFeedbackLimit] = useState(20)
    const [nextState, setNextState] = useState(false)
    const [allProductId, setAllProductId] = useState([])
    let brandId = props.brandId
    let token = localStorage.getItem("token")
    let startDate = localStorage.getItem("start_date");
    let endDate = localStorage.getItem("end_date");
    let url=checkEnv()
    const [data, setData] = useState([])
    const [data1, setData1] = useState([])
    const [userFeedbackDetails, setUserFeedbackDetails] = useState([])
    const [recentState, setRecentState] = useState(false)
    const [recentFeedbackState, setRecentFeedbackState] = useState(true)
    const [positiveFeedbackState, setPositiveFeedbackState] = useState(false)
    const [negativeFeedbackState, setNegativeFeedback] = useState(false)
    const [ratingValue, setRatingvalue] = useState('')
    const [createdDate, setCreatedDate] = useState('')
    const [sentiment, setSentiment] = useState('')
    const [singleProductId, setSingleProductId] = useState('')
    const [loaderState, setLoaderState] = useState(false)
    const [text,setText]=useState("")

    const recentClickHandler = (e) => {
        setPositiveFeedbackState(false)
        setNegativeFeedback(false)
        setRecentFeedbackState(true)
        setCreatedDate('desc')
        setRatingvalue('desc')
        setSentiment('desc')
        getUserFeedbackDetails('desc', 'desc', 'desc');
        setLoaderState(true)
    }

    const positiveFeedbackClickHandler = (e) => {
        setPositiveFeedbackState(true)
        setNegativeFeedback(false)
        setRecentFeedbackState(false)
        setCreatedDate()
        setRatingvalue('desc')
        setSentiment('desc')
        getUserFeedbackDetails('desc', 'desc', '');
        setLoaderState(true)
    }
    const negativeFeedbackClickHandler = (e) => {
        setPositiveFeedbackState(false)
        setNegativeFeedback(true)
        setRecentFeedbackState(false)
        setCreatedDate('')
        setRatingvalue('asc')
        setSentiment('asc')
        getUserFeedbackDetails('asc', 'asc', '');
        setLoaderState(true)
    }

    if (recentFeedbackState === true && document.querySelector('#userFeedbackShowRecent') !== null) {
        document.querySelector('#userFeedbackShowRecent').style.border = '1px solid #003087'
        document.querySelector('#userFeedbackShowRecent').style.color = '#003087'
        document.querySelector('#userFeedbackShowRecent').firstElementChild.style.color = '#003087'
    } else if (recentFeedbackState === false && document.querySelector('#userFeedbackShowRecent') !== null) {
        document.querySelector('#userFeedbackShowRecent').style.border = '1px solid #e5e5e5'
        document.querySelector('#userFeedbackShowRecent').style.color = '#5B5C60'
        document.querySelector('#userFeedbackShowRecent').firstElementChild.style.color = '#c4c4c4'
    }
    if (positiveFeedbackState === true && document.querySelector('#userFeedbackPositive') !== null) {
        document.querySelector('#userFeedbackPositive').style.border = '1px solid #003087'
        document.querySelector('#userFeedbackPositive').style.color = '#003087'
        document.querySelector('#userFeedbackPositive').firstElementChild.style.color = '#003087'
    } else if (positiveFeedbackState === false && document.querySelector('#userFeedbackPositive') !== null) {
        document.querySelector('#userFeedbackPositive').style.border = '1px solid #e5e5e5'
        document.querySelector('#userFeedbackPositive').style.color = '#5B5C60'
        document.querySelector('#userFeedbackPositive').firstElementChild.style.color = '#c4c4c4'
    }
    if (negativeFeedbackState === true && document.querySelector('#userFeedbackNegative') !== null) {
        document.querySelector('#userFeedbackNegative').style.border = '1px solid #003087'
        document.querySelector('#userFeedbackNegative').style.color = '#003087'
        document.querySelector('#userFeedbackNegative').firstElementChild.style.color = '#003087'
    } else if (negativeFeedbackState === false && document.querySelector('#userFeedbackNegative') !== null) {
        document.querySelector('#userFeedbackNegative').style.border = '1px solid #e5e5e5'
        document.querySelector('#userFeedbackNegative').style.color = '#5B5C60'
        document.querySelector('#userFeedbackNegative').firstElementChild.style.color = '#c4c4c4'
    }

    const getUserFeedbacks = async () => {
        setData([])
        const response = await axios.get(`${url}/brandsdashboardproxy/v1/brand/${searchParams.get('brand')}/userfeedbacks/?startDate=${startDate}&endDate=${endDate}&categoryIds=${searchParams.get('categoryIds')}&productIds=${searchParams.get('productIds')}&stateName=${searchParams.get(`stateName`)}`, {
            headers: {
                'authorization': `${token}`
            }
        })
            .catch((err) => {
                console.log(err);
            });
        if (singleProductId === ''||singleProductId.length===0) {
            let allIds = []
            response.data.products_with_most_feedback.map(d => allIds.push(d.product_id))
            setSingleProductId(allIds)
        }
        setData(response.data.products_with_most_feedback.map(d => d))
        setData1(response.data.products_with_most_feedback.map(d => d.rating_count))
        setAllProductId(response.data.products_with_most_feedback.map(d => d.product_id))
        
    };

    const getUserFeedbackDetails = async (ratingValue, sentiment, createdDate) => {
        // setUserFeedbackDetails([])
        // if (offset === 0) {
        //     setOffset(offset)
        // }
        // console.log("helloo")
        const response = await axios.get(`${url}/brandsdashboardproxy/v1/brand/${searchParams.get('brand')}/userfeedbacksfiltered/?startDate=${startDate}&endDate=${endDate}&categoryIds=${searchParams.get('categoryIds')}&productIds=${searchParams.get('productIds')}&ratingValue=${ratingValue}&sentiment=${sentiment}&createdDate=${createdDate}&stateName=${searchParams.get(`stateName`)}&productId=${singleProductId}&offset=${offset}&limit=${feedbackLimit}`, {
            headers: {
                'authorization': `${token}`
            }
        })
            .then(res => {
                // setUserFeedbackDetails(res.data.map(d => d))
                setUserFeedbackDetails(res.data)
                // res.data.length === 0 ? setNoDataLoad(true) : setNoDataLoad(false)
                // if (userFeedbackDetails.length === 0) {
                // } else {
                //     res.data.map(d => {
                //         userFeedbackDetails.push(d)
                //     })
                // }
                res.data.length === 0 ? setNoDataLoad(true) : setNoDataLoad(false)
                setLoaderState(false)
                setSingleProductId("")
            })
            .catch((err) => {
                console.log(err);
            });
    };
    const selectedgetUserFeedbackDetails=async (ratingValue='desc', sentiment='desc', createdDate='desc',singleProductId) => {
        // setUserFeedbackDetails([])
        const response = await axios.get(`${url}/brandsdashboardproxy/v1/brand/${searchParams.get('brand')}/userfeedbacksfiltered/?startDate=${startDate}&endDate=${endDate}&categoryIds=${searchParams.get('categoryIds')}&productIds=${searchParams.get('productIds')}&ratingValue=${ratingValue}&sentiment=${sentiment}&createdDate=${createdDate}&productId=${singleProductId}&offset=${offset}&limit=${feedbackLimit}`, {
            headers: {
                'authorization': `${token}`
            }
        }).then(res=>{
            setUserFeedbackDetails(res.data.map(d => d))
            res.data.length === 0 ? setNoDataLoad(true) : setNoDataLoad(false)
            setLoaderState(false)
        })
    }
    useEffect(() => {
        getUserFeedbacks();
    }, [searchParams.get("brand")])

    useEffect(() => {
        if (recentFeedbackState === true) {
            getUserFeedbackDetails('desc', 'desc', 'desc');
        } else {
            getUserFeedbackDetails(ratingValue, sentiment, createdDate)
        }
    }, [searchParams, singleProductId, offset,ratingValue,sentiment,createdDate])

    useEffect(()=>{
        userFeedbackDetails.length===0?setNoDataLoad(true):setNoDataLoad(false)
        data.length===0?setNoDataLoad(true):setNoDataLoad(false)
    },[data,userFeedbackDetails])


    let totalFeedbacks = data1.reduce((partialSum, a) => partialSum + a, 0)
    let totalProducts = data.length

    let ratingColors = ["rgb(255, 31, 0)", "rgb(233, 237, 49)", "rgb(120, 214, 0)"]

    const singleProductSelectClickHandler = (e) => {
        // setSingleProductId(e.target.id)
        selectedgetUserFeedbackDetails("desc", "desc", "desc",e.target.id)
        document.getElementsByClassName('usersProductsHeader')[0].classList.remove('performance-product-selected')
        document.getElementsByClassName("singleSelectedColor")[0].style.display = 'none'
        document.getElementsByClassName("singleSelectedColor")[0].classList.remove('tickColor')
        let array = document.querySelectorAll(".UsersProductsSection")
        let tickArray = document.querySelectorAll(".selectedColor")
        array.forEach((d, index) => {
            d.classList.remove("performance-product-selected")
        })
        tickArray.forEach((d, index) => {
            d.classList.remove("tickColor")
        })
        document.getElementsByClassName(`UsersProductsSection` + ' ' + e.target.id)[0].classList.add('performance-product-selected')
        document.getElementsByClassName(`selectedColor` + ' ' + e.target.id)[0].classList.add('tickColor')

        setLoaderState(true)
        // setTimeout(() => {
        //     setLoaderState(false)
        // }, 1000);
    }

    useEffect(() => {
        if (document.getElementsByClassName(`UsersProductsSection`)[0]) {
            if (document.querySelector(".performance-product-selected") === null) {
                document.getElementsByClassName('usersProductsHeader')[0].classList.add('performance-product-selected')
                document.getElementsByClassName("singleSelectedColor")[0].style.display = 'flex'
                document.getElementsByClassName("singleSelectedColor")[0].classList.add('tickColor')
            } else if (document.querySelectorAll(".performance-product-selected").length > 1) {
                document.getElementsByClassName(`UsersProductsSection`)[0].classList.remove('performance-product-selected')
                document.getElementsByClassName(`selectedColor`)[0].classList.remove('tickColor')
            }
        }
    })

    useEffect(() => {
        if (props.nextState === true && userFeedbackDetails.length !== 0 && data.length !== 0) {
            setNextState(true)
        } else {
            setNextState(false)
        }
    }, [props.nextState && userFeedbackDetails])

    const allproductsClickHandler = (e) => {
        setSingleProductId(e.target.id)
        // selectedgetUserFeedbackDetails("desc", "desc", "desc",e.target.id)
        let array = document.querySelectorAll(".UsersProductsSection")
        array.forEach((d, index) => {
            d.classList.remove("performance-product-selected")
        })
        let tickArray = document.querySelectorAll(".selectedColor")
        tickArray.forEach((d, index) => {
            d.classList.remove("tickColor")
        })
        document.getElementsByClassName('usersProductsHeader')[0].classList.add('performance-product-selected')
        document.getElementsByClassName("singleSelectedColor")[0].style.display = 'flex'
        document.getElementsByClassName("singleSelectedColor")[0].classList.add('tickColor')
        setLoaderState(true)
    }
    

    const loadMoreClickHandler = () => {
        setOffset(offset + 20)
        setLoaderState(true)
    }
    // console.log("data",data)
    // console.log("userfeedback",userFeedbackDetails)
    // console.log("satte",noDataLoad)
    function createText(t){
        setText(t)
        setTimeout(() => {
            setText("")
        }, 1000);
    }

    return (
        <div>
            <div className="userFeedbackSection">
                <div className="userFeedbackHeader">
                    <div className="userFeedback">
                        <b>User Feedbacks</b>
                        <div className="userFeedbackResults">
                            Total {totalProducts} Products 
                            {/* ({totalFeedbacks} results) */}
                        </div>
                    </div>
                    <div>
                        {/* Successfully Blacklisted Feedback */}
                        {text}
                    </div>
                    <div>
                        <div className="userFeedbackShowRecent" id="userFeedbackShowRecent" onClick={recentClickHandler}>
                            <FontAwesomeIcon icon={faCircleCheck} />  Most Relevant
                        </div>
                        {/* <div className="userFeedbackPositive" id="userFeedbackPositive" onClick={positiveFeedbackClickHandler}>
                            <FontAwesomeIcon icon={faCircleCheck} />   Top Positive
                        </div>
                        <div className="userFeedbackNegative" id="userFeedbackNegative" onClick={negativeFeedbackClickHandler}>
                            <FontAwesomeIcon icon={faCircleCheck} />  Top Negative
                        </div> */}
                    </div>
                    {/* <Dropdown>
                        <DropdownToggle className="userFeedbackSortDropDown">
                            <FontAwesomeIcon icon={faArrowUpShortWide} />Sort By
                        </DropdownToggle>
                        <DropdownMenu className="recentSections">
                  
                            <div className="recent" onClick={recentClickHandler}>
                                Most Recent
                            </div>
                            <div onClick={positiveFeedbackClickHandler}>
                                Highest Rated
                            </div>
                            <div onClick={negativeFeedbackClickHandler}>
                                Lowest Rated
                            </div>
                       
                        </DropdownMenu>
                    </Dropdown> */}
                </div>
                {   
                    userFeedbackDetails.length !== 0 && data.length !== 0 ?
                        <div className="userFeedbackMiddleSection">
                            <div className="userFeedbackAllProducts">
                                <div className="usersProductsHeader" id={allProductId} onClick={allproductsClickHandler}>
                                    <div className="productImage" id={allProductId}>
                                        Brand Name
                                    </div>
                                    <div className="allProductsDetailsTop" id={allProductId}>
                                        <div>
                                            <div className="productName" id={allProductId}>
                                                All Products
                                            </div>
                                            {/* <div className="totalFeedbacks" id={allProductId}>
                                                ({totalFeedbacks} Feedbacks)
                                            </div> */}
                                        </div>
                                        <div className='singleSelectedColor' >
                                            <FontAwesomeIcon icon={faCheck} />
                                        </div>
                                    </div>
                                </div>
                                {
                                    data.map((d, index) => {
                                        return <div className={`UsersProductsSection` + ' ' + d.product_id} key={index} id={d.product_id} onClick={singleProductSelectClickHandler}>
                                            <div className="productImage" id={d.product_id}>
                                                <img src={d.image_url} alt="image"></img>
                                            </div>
                                            <div className="allProductsDetails" id={d.product_id}>
                                                <div className="tickSection" id={d.product_id}>
                                                    <div className="productName" id={d.product_id}>
                                                        {d.name}
                                                    </div>
                                                    <div className={`selectedColor` + ' ' + d.product_id} >
                                                        <FontAwesomeIcon icon={faCheck} />
                                                    </div>
                                                </div>
                                                <div className="ratingTotal" id={d.product_id}>
                                                    <img src={ratedStarImage} />
                                                    {d.average_rating !== undefined ? (d.average_rating).toFixed(1) : ""}
                                                    {/* <div className="totalFeedbacks" id={d.product_id}>
                                                        ({d.rating_count} Ratings)
                                                    </div> */}
                                                </div>
                                            </div>
                                        </div>
                                    })
                                }
                            </div>
                            <div className="userFeedbackDetailsSection">
                                {data.legth===0 ?  <img className="singleNoDataImage" src={noDataLoader} alt='image data empty' />
                                :loaderState ? <img className="singleSelectDataLoader" src={DataLoader} /> :
                                    <>

                                        {/* {userFeedbackDetails.map((d, index) => {
                                            let ratingStars = [1, 2, 3, 4, 5];
                                            let userLetter = d.user_name.slice(0, 1)
                                            let color
                                            {
                                                if (d.nps <= 5) {
                                                    color = ratingColors[0]
                                                } else if (d.nps <= 7) {
                                                    color = ratingColors[1]
                                                } else if (7 < d.nps <= 10) {
                                                    color = ratingColors[2]
                                                }
                                            }
                                            return <div className="userFeedbackDetails" key={index}>
                                                <div className="userFeedbackProfile">
                                                    <div>
                                                        <div className="userProfileImage">
                                                            {userLetter}
                                                        </div>
                                                        <div className="userProfileName">
                                                            {d.user_name}
                                                        </div>
                                                    </div>
                                                    
                                                </div>
                                                <div className="userFeedbackRating">
                                                    <div className="userRatings">
                                                        {
                                                            ratingStars.map(data => {
                                                                if (data <= d.rating) {
                                                                    return <img src={ratedStarImage} alt='rated star Image' key={data} />
                                                                } else {
                                                                    return <img src={emptyStarImage} alt='empty star image' key={data} />
                                                                }
                                                            })
                                                        }
                                                    </div>
                                                    <div className="userNPSRatings">
                                                        NPS Rating <span className="ratingColorBox" style={{ background: `${color}` }} ></span>{d.nps}/10
                                                    </div>
                                                </div>
                                                <div className="userComment">
                                                    {d.feedback}
                                                </div>
                                                <div className="userCommentRating">
                                                    <div className="overallExperience">
                                                        Overall Experience {d.overall_experience}/5
                                                    </div>
                                                    <div className="productQuality">
                                                        Product Quality {d.product_quality}/5
                                                    </div>
                                                    <div className="packaging">
                                                        Packaging {d.packaging}/5
                                                    </div>
                                                </div>
                                            </div>
                                        })} */}
                                        <RatingFeedback createText={createText} userFeedbackDetails={userFeedbackDetails}/>
                                        {/* <div className="loadMore" onClick={loadMoreClickHandler}>
                                            <div>
                                                Load More
                                            </div>
                                        </div> */}

                                    </>
                                }
                            </div>
                        </div> : <div className="brandsVsCategorySkeleton">
                            {
                                noDataLoad == true?
                                    <Skeleton
                                        active
                                        title
                                        paragraph={{
                                            rows: 8,
                                        }} />
                                    :
                                    <img className="singleNoDataImage" src={noDataLoader} alt='image data empty' />
                            }
                        </div>
                }
            </div>
            <StateProductWiseSummary brandId={props.brandId} allChecked={props.allChecked}
                allProductsChecked={props.allProductsChecked}
                singleCheck={props.singleCheck} singleProductCheck={props.singleProductCheck} nextState={nextState} />
        </div>
    )
}
