import React, { useState, useEffect } from "react";
import axios from "axios";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-daterangepicker/daterangepicker.css";
import moment from "moment";
import Dropdown from 'react-bootstrap/Dropdown'
import DropdownToggle from "react-bootstrap/esm/DropdownToggle";
import DropdownMenu from "react-bootstrap/esm/DropdownMenu";
import { useSearchParams, useNavigate } from "react-router-dom";
import CompetitiveAnalysisRankGraphs from "./CompetitiveAnalysisRankGraphs/CompetitiveAnalysisRankGraphs";
import CompetitiveAnalysisMultiLine from "./CompetitiveAnalysisMultiLine/CompetitiveAnalysisMultiLine";
import checkEnv from "../../utils/checkEnv";
import rdSortUp from "../images/rdSortUp.svg";
import rdSortDown from "../images/rdSortDown.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarDays } from "@fortawesome/free-regular-svg-icons";


const CompetitiveAnalysisDetails = (props) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const [selectedState, setSelectedState] = useState('')
    const navigate = useNavigate()
    const maxDate = moment().format("YYYY-MM-DD");
    const todaysDate = maxDate;
    const [sortState,setSortState]=useState({
        category:false,
        product:false,
        date:false
      })
    let stateNames = ['ANDHRA PRADESH',
        'ARUNACHAL PRADESH',
        'ASSAM',
        'BIHAR',
        'CHHATTISGARH',
        'GOA',
        'GUJARAT',
        'HARYANA',
        'HIMACHAL PRADESH',
        'JAMMU AND KASHMIR',
        'JHARKHAND',
        'KARNATAKA',
        'KERALA',
        'MADHYA PRADESH',
        'MAHARASHTRA',
        'MANIPUR',
        'MEGHALAYA',
        'MIZORAM',
        'NAGALAND',
        'ODISHA',
        'PUNJAB',
        'RAJASTHAN',
        'SIKKIM',
        'TAMIL NADU',
        'TELANGANA',
        'TRIPURA',
        'UTTARAKHAND',
        'UTTAR PRADESH',
        'WEST BENGAL',
        'ANDAMAN AND NICOBAR ISLANDS',
        'CHANDIGARH',
        'DADRA AND NAGAR HAVELI',
        'DAMAN AND DIU',
        'DELHI',
        'LAKSHADWEEP',
        'PUDUCHERRY']
    let token = localStorage.getItem("token")
    const [categoryList, setCategoryList] = useState([]);
    const [categoryIdList, setCategoryIdList] = useState([])
    const [checkedCategoryState, setCheckedCategoryState] = useState();
    const [categorySearch, setCategorySearch] = useState('')
    const [value,setValue]=useState("Last 1 Month")
    let url=checkEnv()


    if (localStorage.getItem('start_date') === null) {
        localStorage.setItem("start_date", moment().subtract(6, 'days').format('YYYY-MM-DD'))
        localStorage.setItem("end_date", moment().format("YYYY-MM-DD"))
    }

    


   

    
    let forward = ">";

    const getCategories = async () => {
        const response = await axios.get(`${url}/brandsdashboardproxy/v1/brand/${searchParams.get(`brand`)}/category/`, {
            headers: {
                'authorization': `${token}`
            }
        })
            .catch((err) => {
            });
        setCategoryList(response.data.map((dat) => dat));
        setCategoryIdList(response.data.map(d => d.categoryId));
        setCheckedCategoryState(new Array(response.data.length).fill(false))
    };


    useEffect(() => {
        getCategories();
    }, [searchParams.get('brand')]);

    const handleOnChange1 = (e) => {
        if (e.target.checked === false) {
            navigate({
                pathname: `/brandsdashboard/competitoranalysis`,
                search: `?brand=${searchParams.get('brand')}&categoryIds=${[]}&productIds=${[]}&date=${searchParams.get('date')}`
            })
            let checkedAllCategoryState = new Array(categoryList.length).fill(false)
            setCheckedCategoryState(checkedAllCategoryState)
        }
        if (e.target.checked === true) {
            let checkedAllCategoryState = new Array(categoryList.length).fill(true)
            setCheckedCategoryState(checkedAllCategoryState)
            navigate({
                pathname: `/brandsdashboard/competitoranalysis`,
                search: `?brand=${searchParams.get('brand')}&categoryIds=${e.target.value}&productIds=${searchParams.get('productIds')}&date=${searchParams.get('date')}`
            })
        }

        if (e.target.checked === undefined) {
            if (e.target.firstElementChild.checked === true) {
                let checkedAllCategories = new Array(categoryList.length).fill(false)
                setCheckedCategoryState(checkedAllCategories)
                navigate({
                    pathname: `/brandsdashboard/competitoranalysis`,
                    search: `?brand=${searchParams.get('brand')}&categoryIds=${[]}&productIds=${[]}&date=${searchParams.get('date')}`
                })
            }
            if (e.target.firstElementChild.checked === false) {
                let checkedAllCategories = new Array(categoryList.length).fill(true)
                setCheckedCategoryState(checkedAllCategories)
                navigate({
                    pathname: `/brandsdashboard/competitoranalysis`,
                    search: `?brand=${searchParams.get('brand')}&categoryIds=${e.target.firstElementChild.value}&productIds=${searchParams.get('productIds')}&date=${searchParams.get('date')}`
                })
            }
            return e.target.firstElementChild.checked = !e.target.firstElementChild.checked
        }
    };

    const handleCategoryChange = (position) => {
        let categoryIdStates = []
        const updatedCheckedState = checkedCategoryState.map((item, index) =>
            index === position ? !item : item
        );
        setCheckedCategoryState(updatedCheckedState);
        updatedCheckedState.map((d, index) => {
            if (d === true) {
                categoryIdStates.push(categoryIdList[index])
            }
        })
        navigate({
            pathname: `/brandsdashboard/competitoranalysis`,
            search: `?brand=${searchParams.get('brand')}&categoryIds=${categoryIdStates}&productIds=${searchParams.get('productIds')}&date=${searchParams.get('date')}`
        })
    };

    const stateChangeHandler = (e) => {
        setSelectedState(e.target.value)
    }

    const handleSelect = (e) => {
        // console.log(e.target.id);
        setValue(e.target.id)
        if (e.target.id === 'Last 1 Month') {
            localStorage.setItem("my_start_date", moment(todaysDate).subtract(1, 'months').format('YYYY-MM-DD'))
            localStorage.setItem("my_end_date", todaysDate)
            let start_date = moment(todaysDate).subtract(1, 'months').format('YYYY-MM-DD')
            let end_date = todaysDate
            navigate({
                pathname: `/brandsdashboard/competitoranalysis`,
                search: `?brand=${searchParams.get('brand')}&categoryIds=${searchParams.get('categoryIds')}&productIds=${searchParams.get('productIds')}&date=${start_date}to${end_date}`
            })
        }
        if (e.target.id === 'Last 3 Months') {
            console.log("last 3 month is clicked")

            localStorage.setItem("my_start_date", moment(todaysDate).subtract(3, 'months').format('YYYY-MM-DD'))
            localStorage.setItem("my_end_date", todaysDate)
            let start_date = moment(todaysDate).subtract(3, 'months').format('YYYY-MM-DD')
            let end_date = todaysDate
            navigate({
                pathname: `/brandsdashboard/competitoranalysis`,
                search: `?brand=${searchParams.get('brand')}&categoryIds=${searchParams.get('categoryIds')}&productIds=${searchParams.get('productIds')}&date=${start_date}to${end_date}`
            })
        }
        if (e.target.id === 'Last 6 Months') {
            console.log("last 6 months is clicked")
            localStorage.setItem("my_start_date", moment(todaysDate).subtract(6, 'months').format('YYYY-MM-DD'))
            localStorage.setItem("my_end_date", todaysDate)
            let start_date = moment(todaysDate).subtract(6, 'months').format('YYYY-MM-DD')
            let end_date = todaysDate
            navigate({
                pathname: `/brandsdashboard/competitoranalysis`,
                search: `?brand=${searchParams.get('brand')}&categoryIds=${searchParams.get('categoryIds')}&productIds=${searchParams.get('productIds')}&date=${start_date}to${end_date}`
            })
        }
        if (e.target.id === 'Last Year') {
            console.log("last one year is clicked")
            localStorage.setItem("my_start_date", moment(todaysDate).subtract(1, 'years').format('YYYY-MM-DD'))
            localStorage.setItem("my_end_date", todaysDate) 
            let start_date = moment(todaysDate).subtract(1, 'years').format('YYYY-MM-DD')
            let end_date = todaysDate
            navigate({
                pathname: `/brandsdashboard/competitoranalysis`,
                search: `?brand=${searchParams.get('brand')}&categoryIds=${searchParams.get('categoryIds')}&productIds=${searchParams.get('productIds')}&date=${start_date}to${end_date}`
            })
        }
    }
    let selectedCategory=searchParams.get("categoryIds")!==""?searchParams.get("categoryIds").split(","):[]

    let selectedProducts=searchParams.get("productIds")!==""?searchParams.get("productIds").split(","):[]
    const changeImg=(st)=>{
        let copy={...sortState}
        // copy[st]=!copy[st]
        setSortState(copy)
    }
    const getCategory=(num)=>{
        // console.log(num,categoryList)
        let selectedname=""
        categoryList.forEach(e=>{
          if(e.categoryId===parseInt(num)){
              
              selectedname=e.categoryName
          }
        })
        return selectedname
      }
    //   const getProduct=(sku)=>{
    //     let selectedname=""
    //     productListWithIdName.forEach(e=>{
    //       if(e.sku===sku){
    //           selectedname=e.name
    //       }
    //     })
    //     return selectedname
    //   }
    
      const trimText=(str,strLen)=>{
        if(str.length>strLen){
          return str.slice(0,25)+`...`
        }
        return str
      }
   
    return (
        <div className="mainCategoryPage" id="mainCategoryPage">
            <div className="catData" >
                <div className="home" >
                    Home {forward}
                    <p>Competitor</p>
                </div>
                <div className="sample" >
                    <div>
                        <div className="samplingHeading">
                            Competitor Analysis
                        </div>
                    </div>
                    <div ></div>
                    <div className="category">
                        <div className="first" >
                            <Dropdown>
                                <Dropdown.Toggle className="catSection1">
                                    <div className="myflex" onClick={()=>changeImg("category")}>
                                    {selectedCategory.length===1?
                                            <div>{trimText(getCategory(selectedCategory[0]),25)}</div>
                                            :<div>{selectedCategory.length!==categoryList.length && selectedCategory.length!==0 ?`${selectedCategory.length} categories selecetd`:"All Categories Selected"}</div>}                                       
                                             <div>
                                        {sortState.category?<img src={rdSortUp} alt="up"></img>:<img src={rdSortDown} alt="down"></img>}
                                        </div>
                                    </div>
                                </Dropdown.Toggle>
                                <Dropdown.Menu className="sortStateSectionHideMenu" >
                                    <div className="mainCategory1" id="mainCategory1">
                                        <input
                                            className="search"
                                            type="text"
                                            placeholder="Search"
                                            id="mainCategory1"
                                            value={categorySearch}
                                            onChange={(e) => setCategorySearch(e.target.value)}
                                        ></input>
                                        <div className="cList" id="mainCategory1" onClick={handleOnChange1}>
                                            <input
                                                id="allCategoryChecked"
                                                type="checkbox"
                                                checked={searchParams.get('categoryIds') ? searchParams.get('categoryIds').split(',').length === categoryIdList.length : false}
                                                // onChange={handleOnChange1}
                                                value={categoryIdList}
                                            ></input>
                                            <p id="mainCategory1">All</p>
                                        </div>
                                        <div className="totalList1">
                                            {categoryList ? categoryList.filter((d) => d.categoryName.toLowerCase().includes(categorySearch.toLowerCase())).map((d, index) => {
                                                return (
                                                    <div className="categoryList" id={index} key={index} onClick={() => handleCategoryChange(index)} >
                                                        <input
                                                            id={`singleCategory${index}`}
                                                            type="checkbox"
                                                            checked={searchParams.get('categoryIds') ? searchParams.get('categoryIds').split(',').includes(`${d.categoryId}`) : false}
                                                            // onChange={() => handleCategoryChange(index)}
                                                            value={d.categoryId}
                                                        ></input>
                                                        <p>{d.categoryName}</p>
                                                    </div>
                                                );
                                            }) : ""}
                                        </div>
                                    </div>
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                        <select value={selectedState ? selectedState : ""} className="competitorStateSelection" onChange={stateChangeHandler}>
                            <option value="" defaultValue disabled hidden>Select a State</option>
                            {
                                stateNames.map((d, index) => {
                                    return <option>{d}</option>
                                })
                            }
                        </select>
                        <div className="DateRange">
                            <Dropdown >
                                <DropdownToggle id="date" variant="success" >
                                    <div className="myflex">
                                        <div style={{marginRight:"10px"}}>
                                            {value}
                                        </div>
                                        {/* <div>
                                            <FontAwesomeIcon className="calenderIcon" icon={faCalendarDays}></FontAwesomeIcon>
                                        </div> */}
                                    </div>
                                    {/* {moment(monthback).format("LL")} to  {moment(today).format("LL")} */}
                                </DropdownToggle>
                                <DropdownMenu className="newDate" onClick={(e)=>handleSelect(e)}>
                                    <Dropdown.Item id='Last 1 Month' >  Last 1 Month</Dropdown.Item>
                                    <Dropdown.Item id='Last 3 Months'> Last 3 Months</Dropdown.Item>
                                    <Dropdown.Item id='Last 6 Months'>  Last 6 Months</Dropdown.Item>
                                    <Dropdown.Item id='Last Year'> Last Year</Dropdown.Item>
                                </DropdownMenu>
                            </Dropdown>
                        </div>
                    </div>
                </div>
            </div>
            <CompetitiveAnalysisRankGraphs selectedState={selectedState} />
            {/* <CompetitiveAnalysisMultiLine /> */}
        </div>
    );
};

export default CompetitiveAnalysisDetails;