import React, { useEffect, useState } from 'react';
import './style.css';
import { Link, useParams } from 'react-router-dom';
import editQuestionButton from '../../../../images/editQuestionButton.svg';
import checkboxIcon from '../../../../images/rn-checkbox.svg';
import radioIcon from '../../../../images/rn-radio.svg';
import MatrixOption from "../../../../images/MatrixSelectCircle.svg"
import skipFlow from "../../../../images/skipFlow.svg"
import axios from 'axios';
import configData from '../../../../../config/config.json'
import SkipLogic from '../SkipLogic';
import DeleteModal from '../../../ResearchDashboard/DeleteModal';
import { checkSurveyEnv } from '../../../../../utils/checkEnv';


function CondensedQuestion(props) {
    const base_url = configData.base_url;
    const surveyUrl=checkSurveyEnv()
    const [questionObject, setQuestionObject] = useState(props.question);
    const [showSkipFlow, setSkipFlowShow] = useState(false);
    const [nextQuestionsList, setNextQuestionsList] = useState([]);
    let { surveyId } = useParams();
    const [optionObj, setOptionObj] = useState(props.question.option_objects.reduce((acc, curr) => {
        acc[curr.option] = curr.next_question_id;
        return acc;
    }, {}));

   
    const getSurveyDetails = props.getSurveyDetails;

    const questionTypeLabels = {
        'SINGLE_SELECT': 'Single Selection',
        'MULTI_SELECT': 'Multiple Selection',
        'MATRIX_SELECT': 'Matrix Selection',
        'RANGE_SELECTION': 'Range Selection',
        'ORDER_SELECTION': 'Order Selection',
        'TEXT_INPUT': 'Text Input',
        'TERMINAL_QUESTION': 'Terminal Question'
    }

    const renderOptionDesign = () => {
        switch (questionObject.question_type) {
            case 'SINGLE_SELECT':
                return <img src={radioIcon} />
            case 'MULTI_SELECT':
                return <img src={checkboxIcon} />
            default:
                return <></>
        }
    }

    useEffect(() => {
        generateQuestionsList();
    }, [])


    const generateQuestionsList = () => {
        axios.get(surveyUrl + `/smyttenresearch/dependantquestions/${questionObject.id}`)
            .then((res) => {
                let localNextQuestionList = [...res.data.next_questions];
                setNextQuestionsList(localNextQuestionList);
            })
            .catch((err) => {
                console.log(err.message);
            })
    }

    const changeSkipFlowShow = (flag) => {
        setSkipFlowShow(flag);
    }

    const setParentQuestionObject = (obj) => {
        setQuestionObject({ ...obj });
        setOptionObj(obj.option_objects.reduce((acc, curr) => {
            acc[curr.option] = curr.next_question_id;
            return acc;
        }, {}))
    }


    // console.log(questionObject)
    const renderQuestionData = () => {
        let questionType = questionObject.question_type;
        if (questionType == 'MATRIX_SELECT') {
            if(!showSkipFlow){
                    return (
                        <>
                            <table className='condensed-matrixTable'>
                                <thead>
                                    <tr>
                                        <td></td>
                                        {
                                            questionObject.options.choices && questionObject.options.choices.map((choice) => {
                                                return <td key={choice}><div style={{ display: "flex", justifyContent: "center" }}><div className='table-params'>{choice}</div></div></td>
                                            })
                                        }
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        questionObject.options.params && questionObject.options.params.map((param) => {
                                            return (
                                                <tr>
                                                    <td>{param}</td>
                                                    {
                                                        questionObject.options.choices.map((choice) => {
                                                            return <td key={choice}> <img src={MatrixOption} alt='matrixOption' /></td>
                                                        })
                                                    }
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </table>
                            <div style={{ alignSelf: "flex-end", cursor: "pointer" }} onClick={() => { setSkipFlowShow(true) }}><img src={skipFlow} /></div>
                        </>
                    )
            }
            else if (showSkipFlow) {
                return <SkipLogic key={Math.random()} optionObj={optionObj} questionObject={questionObject} changeSkipFlowShow={changeSkipFlowShow} setParentQuestionObject={setParentQuestionObject} />
            }
        }
        else if (questionType == 'TEXT_INPUT') {
            if(!showSkipFlow){
                return (
                    <>
                    <div className='textInputDiv'>
                        <div style={{ borderBottom: "1px solid #928e8e" }}>User will be asked to fill in the Text Box</div>
                    </div>
                    <div style={{ alignSelf: "flex-end", cursor: "pointer" }} onClick={() => { setSkipFlowShow(true) }}><img src={skipFlow} /></div>
                    </>
                )
            }
            else if (showSkipFlow) {
                return <SkipLogic key={Math.random()} optionObj={optionObj} questionObject={questionObject} changeSkipFlowShow={changeSkipFlowShow} setParentQuestionObject={setParentQuestionObject} />
            }
            
        }
        else if (questionType == 'RANGE_SELECTION') {
            if(!showSkipFlow){
                return (
                    <>
                    <div className="slidecontainer">
                        <div className='numRadioSection'>
                            {
                                questionObject.options.map((number, index) => {
                                    return (
                                        <label className='numRadio' key={index}>
                                            <input type={'radio'} value={number} name={'numRadio'} hidden />
                                            {number}
                                        </label>
                                    )
                                })
                            }
                        </div>
                        <input type="range" value={questionObject.options[3]} min={questionObject.options[0]} max={questionObject.options[questionObject.options.length - 1]} className="slider" id="myRange" />
                    </div>
                    <div style={{ alignSelf: "flex-end", cursor: "pointer" }} onClick={() => { setSkipFlowShow(true) }}><img src={skipFlow} /></div>
                    </>
                )
            }
            else if (showSkipFlow) {
                return <SkipLogic key={Math.random()} optionObj={optionObj} questionObject={questionObject} changeSkipFlowShow={changeSkipFlowShow} setParentQuestionObject={setParentQuestionObject} />
            }
            
        }
        else if (questionType == 'SINGLE_SELECT') {
            if (!showSkipFlow) {
                if (questionObject['options'] && questionObject.options.length > 0) {
                    return (
                        <>
                            <div className='smo-options-grid' style={{ marginTop: "2rem" }}>
                                {
                                    (questionObject.options.length > 0)
                                        ?
                                        <>
                                            {questionObject.options.map((option, index) => {
                                                return (
                                                    <div className='smo-indivOption'>
                                                        <div className='alignCenter-row' style={{ width: "100%" }}>
                                                            {renderOptionDesign()}
                                                            &nbsp;&nbsp;
                                                            <div style={{ width: "100%", textAlign: "left" }} key={index} >{option}</div>
                                                        </div>
                                                    </div>
                                                )
                                            })}

                                        </>
                                        :
                                        null
                                }
                            </div>
                            <div style={{ alignSelf: "flex-end", cursor: "pointer" }} onClick={() => { setSkipFlowShow(true) }}><img src={skipFlow} /></div>
                        </>
                    )
                }
            }
            else if (showSkipFlow) {
                return <SkipLogic key={Math.random()} optionObj={optionObj} questionObject={questionObject} changeSkipFlowShow={changeSkipFlowShow} setParentQuestionObject={setParentQuestionObject} />
            }
        }
        else if (questionType == 'MULTI_SELECT') {
            if (!showSkipFlow) {
                if (questionObject['options'] && questionObject.options.length > 0) {
                    return (
                        <>
                            <div className='smo-options-grid' style={{ marginTop: "2rem" }}>
                                {
                                    (questionObject.options.length > 0)
                                        ?
                                        <>
                                            {questionObject.options.map((option, index) => {
                                                return (
                                                    <div className='smo-indivOption'>
                                                        <div className='alignCenter-row' style={{ width: "100%" }}>
                                                            {renderOptionDesign()}
                                                            &nbsp;&nbsp;
                                                            <div style={{ width: "100%", textAlign: "left" }} key={index} >{option}</div>
                                                        </div>
                                                    </div>
                                                )
                                            })}

                                        </>
                                        :
                                        null
                                }
                            </div>
                            <div style={{ alignSelf: "flex-end", cursor: "pointer" }} onClick={() => { setSkipFlowShow(true) }}><img src={skipFlow} /></div>
                        </>
                    )
                }
            }
            else if (showSkipFlow) {
                return <SkipLogic key={Math.random()} optionObj={optionObj} questionObject={questionObject} changeSkipFlowShow={changeSkipFlowShow} setParentQuestionObject={setParentQuestionObject} />
            }
        }

        //____________________________________ 

        else {
            if (!showSkipFlow) {
            if (questionObject['options'] && questionObject.options.length > 0) {
                return (
                    <>
                    <div className='smo-options-grid' style={{ marginTop: "2rem" }}>
                        {
                            (questionObject.options.length > 0)
                                ?
                                <>
                                    {questionObject.options.map((option, index) => {
                                        return (
                                            <div className='smo-indivOption'>
                                                <div className='alignCenter-row' style={{ width: "100%" }}>
                                                    {renderOptionDesign()}
                                                    &nbsp;&nbsp;
                                                    <div style={{ width: "100%", textAlign: "left" }} key={index} >{option}</div>
                                                </div>
                                            </div>
                                        )
                                    })}

                                </>
                                :
                                null
                        }
                    </div>
                    <div style={{ alignSelf: "flex-end", cursor: "pointer" }} onClick={() => { setSkipFlowShow(true) }}><img src={skipFlow} /></div>
                    </>
                )
            }
        }
        else if (showSkipFlow) {
                return <SkipLogic key={Math.random()} optionObj={optionObj} questionObject={questionObject} changeSkipFlowShow={changeSkipFlowShow} setParentQuestionObject={setParentQuestionObject} />
            }
        }

    }

    return (
        <div className='questionBox' id={`condensed-${questionObject.id}`} key={questionObject.id}>
            <div className='cond-inner1'>
                <div className='cond-questionText'>
                    {(props.questionIndex + 1 < 10) ? '0' + (props.questionIndex + 1) : props.questionIndex + 1}&nbsp;&nbsp;&nbsp;
                    <div className='cond-questionText-inner'>
                        {questionObject.question_text}
                        <span className='questionType'>{questionTypeLabels[questionObject.question_type]}</span>
                    </div>
                </div>
                <div>
                    <img onClick={() => props.changeEditMode(questionObject.id)} style={{ cursor: "pointer" }} src={editQuestionButton} />
                    &nbsp;&nbsp;
                    <DeleteModal whatToDelete={'question'} questionId={questionObject.id} getSurveyDetails={getSurveyDetails} />
                </div>
                
            </div>
            <div className='image-container'>
                    {questionObject.media_content && !Array.isArray(questionObject.media_content) && <div>
                        <img className="image" src={questionObject.media_content} alt="selectedImage" />
                    </div>}
            </div>
            {renderQuestionData()}
        </div>
    )
}

export default CondensedQuestion