import axios from 'axios';
import React, { useEffect, useState } from 'react'
import QuestionOptions from './QuestionOptions';
import './style.css';
import configData from '../../../../../config/config.json';
import { checkSurveyEnv } from '../../../../../utils/checkEnv';

function EditableQuestion(props) {

    const base_url = configData.base_url;
    const surveyUrl=checkSurveyEnv()
    const [questionObject, setQuestionObject] = useState(props.question);
    const getSurveyDetails = props.getSurveyDetails;
    const [showPrompt, setShowPrompt] = useState(false)
    
    const saveClickHandler = () => {
        //Check if New Question or Existing Question <--- Make respective API call.
        if (questionObject.question_text && questionObject.question_type) {
            if (questionObject['new_question']) {
                // Make API Call...for new Question
                axios.post(surveyUrl + `/smyttenresearch/question/`, {
                    "survey_section_id": props.sectionId,
                    "previous_question": props.previousQuestionId,
                    "question_text": questionObject.question_text,
                    "question_type": questionObject.question_type,
                    "options": questionObject.options,
                    "media_type": questionObject.media_type,
                    "media_content": questionObject.media_content,
                })
                    .then((res) => {
                        // console.log(res.data)
                        props.setUniqId(res.data.id)
                        localStorage.setItem("uniqId",res.data.id)
                        getSurveyDetails();
                    })
                    .catch((err) => {
                        console.log(err.message);
                        getSurveyDetails();
                    })
            }

            else {
                // Make API Call...for edit Question
                axios.put(surveyUrl + `/smyttenresearch/question/${questionObject.id}`, questionObject)
                    .then(() => {
                        getSurveyDetails();
                    })
                    .catch((err) => {
                        getSurveyDetails();
                    })
            }
        }

        else
            setShowPrompt(true);
        // ------------------ <> </> ----------------

    }

    // useEffect(() => {
    // }, [questionObject])

    const renderPrompt = () => {
        setTimeout(() => {
            setShowPrompt(false);
        }, 1500)
        return <div className='questionBox-prompt'>Please fill in the above fields</div>
    }

    const changeParentQuestionObject = (obj) => {
        setQuestionObject({ ...obj });
    }

    return (
        <div className='questionBox fontSize14' id={`questionBox-${questionObject.id}`} key={questionObject.id}>
            {/* Dynamic Options Section | Also has question text, type and media */}
            <QuestionOptions questionObject={questionObject} changeParentQuestionObject={changeParentQuestionObject} />
            {showPrompt ? renderPrompt() : null}
            <div className='buttonContainer'>
                <button className='questionBox-cancel' onClick={getSurveyDetails}>Cancel</button>
                <button className='saveQuestion' onClick={saveClickHandler}>Save</button>
            </div>
        </div>
    )
}

export default EditableQuestion