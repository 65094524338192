import "./ProductTrial.css"
import React, { useState, useEffect } from 'react';
import axios from "axios";
import ProductGeography from "../ProductGeograhy/ProductGeography"
import checkEnv from "../../../utils/checkEnv";

export default function ProductTrials(props) {
    function nFormatter(num) {
        if (num >= 1000000000) {
            return (num / 1000000000).toFixed(1).replace(/\.0$/, "") + "G";
        }
        if (num >= 1000000) {
            return (num / 1000000).toFixed(1).replace(/\.0$/, "") + "M";
        }
        if (num >= 1000) {
            return (num / 1000).toFixed(1).replace(/\.0$/, "") + "K";
        }
        return num;
    }
    let brandId = props.brandId;
    let token = localStorage.getItem("token")
    let startDate = localStorage.getItem("start_date");
    let endDate = localStorage.getItem("end_date");
    let url=checkEnv()
    const [trailList, setTrailList] = useState([])

    const getTrailAdoptionList = async () => {
        const response = await axios.get(`${url}/brandsdashboardproxy/v1/brand/${brandId}/productfourtwoodlock/?startDate=${startDate}&endDate=${endDate}`, {
            headers: {
                'authorization': `${token}`
            }
        })
            .catch((err) => {
                console.log(err);
            });
        setTrailList(response.data);
        console.log(response.data.trials)
    };
    let trailRate
    let trailUnit
    let trailVolume
    let repeat
    let frequency
    let repeatUnits
    let repeatVolumes
    if (trailList.trials !== undefined && trailList.adoption !== undefined) {
        trailRate = parseInt(trailList.trials.trialRate).toFixed(1)
        trailUnit = parseInt(trailList.trials.trialUnits).toFixed(1)
        trailVolume = trailList.trials.trialVolumes
        repeat = parseInt(trailList.adoption.repeat).toFixed(1)
        frequency = parseInt(trailList.adoption.frequency).toFixed(1)
        repeatUnits = parseInt(trailList.adoption.repeatUnits).toFixed(1)
        repeatVolumes = trailList.adoption.repeatVolumes
    }

    useEffect(() => {
        getTrailAdoptionList();
    }, [])
    return (
        <div>

            <div className="mainTrailContainer">
                <div className="productSection1">
                    <div className="trialSection">
                        <b>
                            TRAILS
                        </b>
                    </div>
                    <div className="productCard">
                        <div className="card">
                            <div className="productPercentage">
                                {trailRate}%
                            </div>
                            <div className="productLabel">
                                Trail Rate
                            </div>
                        </div>
                        <div className="card">
                            <div className="productPercentage">
                                {trailUnit}
                            </div>
                            <div className="productLabel">
                                Trail Units
                            </div>

                        </div>
                        <div className="card">
                            <div className="productPercentage">
                                {nFormatter(trailVolume)}
                            </div>
                            <div className="productLabel">
                                Trail Volumes
                            </div>

                        </div>

                    </div>
                </div>
                <div className="productSection2">
                    <div className="adoptionSection">
                        <b>ADOPTION</b>
                    </div>
                    <div className="productCard">
                        <div className="card">
                            <div className="productPercentage">
                                {trailRate}%
                            </div>
                            <div className="productLabel">
                                Trail Rate
                            </div>

                        </div>
                        <div className="card">
                            <div className="productPercentage">
                                {repeat}%
                            </div>
                            <div className="productLabel">
                                Repeat
                            </div>

                        </div>
                        <div className="card">
                            <div className="productPercentage">
                                {frequency}
                            </div>
                            <div className="productLabel">
                                Frequency
                            </div>

                        </div>
                        <div className="card">
                            <div className="productPercentage">
                                {repeatUnits}
                            </div>
                            <div className="productLabel">
                                Repeat Units
                            </div>

                        </div>
                        <div className="card">
                            <div className="productPercentage">
                                {repeatVolumes}
                            </div>
                            <div className="productLabel">
                                Repeat Volumes
                            </div>

                        </div>

                    </div>
                </div>
            </div>
            <ProductGeography brandId={props.brandId} />
        </div>
    )
}
