import React from 'react'
import "./NodataCard.css"

export default function Nodatacard({text}){
  return (
        <div class="alertbox">
            <p>{text}</p>
        </div>
  )
  
}
