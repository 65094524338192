import React, { useEffect } from "react";
import "./RatingUserFeedback.css";
import { useState } from "react";
import emptyStarImage from "../../images/emptyStart.svg";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import { useSearchParams } from "react-router-dom";
import DeleteBtnModal from "./DeleteBtnModal";
import { local } from "d3";
import checkEnv from "../../../utils/checkEnv";

export default function SingleFeedback({ d, index, createText }) {
  let ratingColors = [
    "rgb(255, 31, 0)",
    "rgb(233, 237, 49)",
    "rgb(120, 214, 0)",
  ];
  const [searchParams, setSearchParams] = useSearchParams();
  let usertype = localStorage.getItem("type");
  //   console.log(d);
  const [new_btn_state, setNewBtnState] = useState(d.is_blacklisted);
  const [modalShow, setModalShow] = React.useState(false);
  const [text, setText] = useState("delete");

  let ratingStars = [1, 2, 3, 4, 5];
  let userLetter = d.user_name.slice(0, 1);
  let id = `delete${index}`;
  let url=checkEnv()
  let color = getColor();
  function getColor() {
    if (d.nps <= 5) {
      return ratingColors[0];
    } else if (d.nps <= 7) {
      return ratingColors[1];
    } else if (7 < d.nps <= 10) {
      return ratingColors[2];
    }
  }
  //   console.log(new_btn_state)
  const options = {
    headers: {
      "Content-Type": "application/json",
      authorization: localStorage.getItem("token"),
    },
  };
  const data = {
    feedback_id: d.id,
    reason: "test",
    brandId: searchParams.get("brand"),
  };

  useEffect(() => {
    if (new_btn_state) {
      setText("deleted");
      document.getElementById(id).style.border = "1px solid #e5e5e5";
      document.getElementById(id).style.color = "#5B5C60";
      document.getElementById(id).firstElementChild.style.color = "#c4c4c4";
    }
  }, [new_btn_state]);

  function apiCall() {
    axios
      .post(
        `${url}/brandsdashboardproxy/v1/blacklistfeedback/`,
        {
          feedback_id: d.id,
          reason: "",
          brandId: searchParams.get("brand"),
        },
        {
          headers: {
            // "Content-Type":"application/json",
            authorization: localStorage.getItem("token"),
            // 'Access-Control-Allow-Origin': '*'
          },
        }
      )
      .then((res) => {
        // console.log(res.data);
        createText(res.data.message);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  const canceClicked = () => {
    setNewBtnState(false);
    setModalShow(false);
    setText("delete");
    document.getElementById(id).style.border = "1px solid #003087";
    document.getElementById(id).style.color = "#003087";
    document.getElementById(id).firstElementChild.style.color = "#003087";
  };

  const buttonHandler = () => {
    if (!new_btn_state && text === "delete") {
      setNewBtnState(true);
      // setModalShow(true)
      setText("deleted");
      document.getElementById(id).style.border = "1px solid #e5e5e5";
      document.getElementById(id).style.color = "#5B5C60";
      document.getElementById(id).firstElementChild.style.color = "#c4c4c4";
      apiCall();
    }
  };

  const onHideHandler = (e) => {
    if (e === "cancel") {
      setModalShow(false);
      setNewBtnState(true);
      canceClicked();
    } else {
      setModalShow(false);
    }
  };

  const deleteHandler = (reason) => {
    console.log(reason);
    apiCall(reason);
  };

  return (
    <div className="userFeedbackDetails" key={index}>
      <div className="userFeedbackProfile">
        <div>
          <div className="userProfileImage">{userLetter}</div>
          <div className="userProfileName">{d.user_name}</div>
        </div>

        {usertype === "ADMIN" && (
          <div className="new_btn" id={id} onClick={() => buttonHandler()}>
            <FontAwesomeIcon icon={faTrash} style={{ marginRight: "5px" }} />
            {text}
          </div>
        )}
      </div>

      <div className="userFeedbackRating">
        <div className="userRatings">
          {ratingStars.map((data) => {
            if (data <= d.rating) {
              return <img src="/images/ratingstart.svg" alt="" />;
            } else {
              return <img src={emptyStarImage} alt="" />;
            }
          })}
        </div>
        {/* <div className="userNPSRatings">
          NPS Rating{" "}
          <span
            className="ratingColorBox"
            style={{ background: `${color}` }}
          ></span>
          {d.nps}/10
        </div> */}
      </div>
      <div className="userComment">{d.feedback}</div>
      <div className="userCommentRating">
        <div className="overallExperience">
          Overall Experience {d.overall_experience}/5
        </div>
        <div className="productQuality">
          Product Quality {d.product_quality}/5
        </div>
        <div className="packaging">Packaging {d.packaging}/5</div>
      </div>
      {/* {modalShow && <DeleteBtnModal show={modalShow} delete={deleteHandler}  onHide={onHideHandler}/>} */}
    </div>
  );
}
