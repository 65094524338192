import "./ProductLevelTop20States.css";
import React, { useState, useEffect } from "react";
import DatamapsIndia from "react-datamaps-india";
import axios from "axios";
import { useSearchParams, useNavigate } from "react-router-dom";
import DataLoader from "../../images/noDataFull.svg";
import ProductLevelUserFeedback from "../ProductLevelUserFeedbacks/ProductLevelUserFeedback";
import { Skeleton } from "antd";
import rdSortUp from "../../images/rdSortUp.svg";
import rdSortDown from "../../images/rdSortDown.svg";
import checkEnv from "../../../utils/checkEnv";

const ProductLevelTop20States = (props) => {
  let noOfSections;
  const [currentSection, setCurrentSection] = useState(0);
  const [noDataLoad, setNoDataLoad] = useState(false);
  const [nextState, setNextState] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  let stateLabelColor;
  let stateColors = [
    "rgb(77, 96, 172)",
    "rgb(103, 136, 193)",
    "rgb(117, 153, 201)",
    "rgb(119, 156, 202)",
    "rgb(128, 167, 207)",
    "rgb(132, 172, 209)",
    "rgb(136, 177, 211)",
    "rgb(137, 178, 212)",
    "rgb(142, 184, 215)",
    "rgb(144, 184, 215)",
  ];
  let token = localStorage.getItem("token");
  let states = {};
  let startDate = localStorage.getItem("start_date");
  let endDate = localStorage.getItem("end_date");
  let url = checkEnv();
  const [data, setData] = useState([]);
  const [statesList, setStatesList] = useState({});
  const [sortState, setSortState] = useState({
    user_sampled: false,
    product_sampled: false,
    nps_rating: false,
  });

  function nFormatter(num) {
    if (num >= 1000000000) {
      return (num / 1000000000).toFixed(1).replace(/\.0$/, "") + "G";
    }
    if (num >= 1000000) {
      return (num / 1000000).toFixed(1).replace(/\.0$/, "") + "M";
    }
    if (num >= 1000) {
      return (num / 1000).toFixed(1).replace(/\.0$/, "") + "K";
    }
    return num;
  }

  const getStateDetails = async () => {
    setData([]);
    const response = await axios
      .get(
        `${url}/brandsdashboardproxy/v1/brand/${searchParams.get(
          `brand`
        )}/usertriedstatewise/?startDate=${startDate}&endDate=${endDate}&categoryIds=${searchParams.get(
          "categoryIds"
        )}&productId=${searchParams.get("productId")}`,
        {
          headers: {
            authorization: `${token}`,
          },
        }
      )
      .catch((err) => {
        console.log(err);
      });
    setData(response.data.map((dat) => dat));
    response.data.length === 0 ? setNoDataLoad(true) : setNoDataLoad(false);
    setStatesList(states);
    setCurrentSection(1);
  };
  useEffect(() => {
    getStateDetails();
  }, [searchParams]);

  function toTitles(s) {
    return s
      ? s.replace(/\w\S*/g, function (t) {
          return t.charAt(0).toUpperCase() + t.substr(1).toLowerCase();
        })
      : "";
  }
  data.map((dat) => {
    states[toTitles(dat.regionName)] = { value: dat.totalSampledUsers };
  });
  const stateClickHandler = (e) => {
    navigate({
      pathname: `/brandsdashboard/performance/:stateDetails`,
      search: `?brand=${searchParams.get("brand")}&stateName=${
        e.target.id
      }&categoryIds=${searchParams.get(
        "categoryIds"
      )}&productIds=${searchParams.get("productIds")}&date=${searchParams.get(
        "date"
      )}`,
    });
  };

  const ascendingUsersSampledHandler = () => {
    data.sort((a, b) => {
      return a.totalSampledUsers - b.totalSampledUsers;
    });
  };
  const descendigUsersSampledHandler = () => {
    data.sort((a, b) => {
      return b.totalSampledUsers - a.totalSampledUsers;
    });
  };
  const ascendingProductsSampledHandler = () => {
    data.sort((a, b) => {
      return a.totalProductsSampled - b.totalProductsSampled;
    });
  };
  const descendingProductsSampledHandler = () => {
    data.sort((a, b) => {
      return b.totalProductsSampled - a.totalProductsSampled;
    });
  };

  const ascendingNpsHandler = () => {
    data.sort((a, b) => {
      return a.npsRating - b.npsRating;
    });
  };

  const descendingNpsHandler = () => {
    data.sort((a, b) => {
      return b.npsRating - a.npsRating;
    });
  };

  useEffect(() => {
    if (props.nextState == true && data.length !== 0) {
      setNextState(true);
    } else {
      setNextState(false);
    }
  }, [props.nextState, data]);

  const changeCurrentSection = (step) => {
    noOfSections = 20 / 5;

    if (step == "previous") {
      if (currentSection - 1 <= 0) setCurrentSection(1);
      else setCurrentSection(currentSection - 1);
    } else if (step == "next") {
      if (currentSection + 1 >= noOfSections) setCurrentSection(noOfSections);
      else setCurrentSection(currentSection + 1);
    }
  };

  const getStepper = () => {
    if (data.length > 0) {
      let startIndex = (currentSection - 1) * 5;
      let endIndex = currentSection * 5;
      noOfSections = Math.ceil(parseFloat(data.length / 5));
      return (
        <div className="ca-table2-stepper">
          <div
            className="ca-table2-stepper-navBtn"
            onClick={() => changeCurrentSection("previous")}
          >
            {"<"}
          </div>
          <div>{`Showing ${startIndex + 1}-${endIndex} of ${20} regions`}</div>
          <div
            className="ca-table2-stepper-navBtn"
            onClick={() => changeCurrentSection("next")}
          >
            {">"}
          </div>
        </div>
      );
    }
  };

  const sortSurveyList = (param, order) => {
    setCurrentSection(1);
    let sortStateCopy = { ...sortState };
    sortStateCopy[param] = !sortStateCopy[param];
    setSortState({ ...sortStateCopy });

    if (param == "user_sampled") {
      if (order == "ascending") {
        ascendingUsersSampledHandler();
      } else {
        descendigUsersSampledHandler();
      }
    }
    if (param == "product_sampled") {
      if (order == "ascending") {
        ascendingProductsSampledHandler();
      } else {
        descendingProductsSampledHandler();
      }
    }
    if (param == "nps_rating") {
      if (order == "ascending") {
        ascendingNpsHandler();
      } else {
        descendingNpsHandler();
      }
    }
  };

  return (
    <>
      <div className="ProductLevelSatesSection">
        <div>
          <div className="stateSummaryHeading">
            <h3>Heat Map</h3>
            <div className="stateSortSection">
              <select>
                <option value="Top20States">Top 20 States</option>
              </select>
            </div>
          </div>
        </div>
        {data.length !== 0 ? (
          <div className="indiaMapLevel">
            <div className="dataMap1">
              <DatamapsIndia
                style={{ width: 90, border: "2px solid white" }}
                regionData={states}
                hoverComponent={({ value }) => {
                  return (
                    <div>
                      <div>
                        <p>{value.name}</p>
                        {value.value ? value.value : 0}
                      </div>
                    </div>
                  );
                }}
                mapLayout={{
                  startColor: "rgb(170, 211, 228)",
                  endColor: "rgb(88, 114, 183)",
                  hoverTitle: "Count",
                  noDataColor: "rgb(170, 211, 228)",
                  borderColor: "gray",
                  hoverBorderColor: "#f5f5f5",
                  hoverColor: "rgb(88, 114, 183)",
                }}
              />
            </div>
            <table className="productLevelstateDetails">
              <thead>
                <tr className="productLevelStateHeaders">
                  {/* <td className="state serialNumber"></td> */}
                  <td className="stateName"></td>
                  <td
                    className="state UserSampled"
                    onClick={() =>
                      document.getElementById("sort-user-sampled").click()
                    }
                  >
                    Total users Sampled
                    {sortState.user_sampled ? (
                      <img
                        id="sort-user-sampled"
                        src={rdSortUp}
                        onClick={() =>
                          sortSurveyList("user_sampled", "ascending")
                        }
                      />
                    ) : (
                      <img
                        id="sort-user-sampled"
                        src={rdSortDown}
                        onClick={() =>
                          sortSurveyList("user_sampled", "descending")
                        }
                      />
                    )}
                  </td>
                  <td
                    className="state ProductSampled"
                    onClick={() =>
                      document.getElementById("sort-product_sampled").click()
                    }
                  >
                    Total Products Sampled
                    {sortState.product_sampled ? (
                      <img
                        id="sort-product_sampled"
                        src={rdSortUp}
                        onClick={() =>
                          sortSurveyList("product_sampled", "ascending")
                        }
                      />
                    ) : (
                      <img
                        id="sort-product_sampled"
                        src={rdSortDown}
                        onClick={() =>
                          sortSurveyList("product_sampled", "descending")
                        }
                      />
                    )}
                  </td>
                  <td
                    className="state NPSRating"
                    onClick={() =>
                      document.getElementById("sort-nps_rating").click()
                    }
                  >
                    Net Promoter Score
                    {sortState.nps_rating ? (
                      <img
                        id="sort-nps_rating"
                        src={rdSortUp}
                        onClick={() =>
                          sortSurveyList("nps_rating", "ascending")
                        }
                      />
                    ) : (
                      <img
                        id="sort-nps_rating"
                        src={rdSortDown}
                        onClick={() =>
                          sortSurveyList("nps_rating", "descending")
                        }
                      />
                    )}
                  </td>
                </tr>
              </thead>
              <div className="productLevelstateValues">
                {data.map((dat, index) => {
                  if (index <= 8) {
                    stateLabelColor = stateColors[index];
                  } else {
                    stateLabelColor = stateColors[9];
                  }
                  let startIndex = (currentSection - 1) * 5;
                  let endIndex = currentSection * 5;
                  if (index <= 19 && index >= startIndex && index < endIndex) {
                    
                    let right = 195 - dat.npsRating;
                    // console.log(right)
                    return (
                      <tr
                        className="stateData"
                        key={index}
                        onClick={stateClickHandler}
                        id={dat.regionName}
                      >
                        {/* <td className="state serialNumber">{index + 1}</td> */}
                        <td className="stateName" id={dat.regionName}>
                          <div
                            className="stateColor"
                            style={{ background: `${stateLabelColor}` }}
                          ></div>
                          <div id={dat.regionName}>{dat.regionName}</div>
                        </td>
                        <td className="state UserSampled" id={dat.regionName}>
                          {nFormatter(dat.totalSampledUsers)}
                        </td>
                        <td
                          className="state ProductSampled"
                          id={dat.regionName}
                        >
                          {nFormatter(dat.totalProductsSampled)}
                        </td>
                        <td className="levelnpsDetails" id={dat.regionName}>
                          <div className="state NPSRating" id={dat.regionName}>
                            {/* {dat.npsRating ? dat.npsRating.toFixed(2) : "0.00"} */}
                            {dat.npsRating &&
                            dat.npsRating >= -100 &&
                            dat.npsRating <= 100
                              ? Math.round(dat.npsRating)
                              : "NA"}
                          </div>
                          {dat.npsRating &&
                          dat.npsRating >= -100 &&
                          dat.npsRating <= 100 ? (
                            <div className="mainColor" id={dat.regionName}>
                              <div
                                className="levelLinearGraph"
                                id={dat.regionName}
                              ></div>
                              <div
                                className="levelNPSMarker"
                                style={{ right: `${-200 + right}px` }}
                                id={dat.regionName}
                              ></div>
                            </div>
                          ) : (
                            <div className="mainColor" id={dat.regionName} style={{background:'white'}}>
                              <div
                                className="levelLinearGraph"
                                id={dat.regionName}
                                style={{background:'white'}}
                              ></div>
                              
                            </div>
                          )}
                        </td>
                      </tr>
                    );
                  }
                })}
              </div>
              <div className="ca-table2-stepper">{getStepper()}</div>
            </table>
          </div>
        ) : (
          <div className="brandsVsCategorySkeleton">
            {noDataLoad !== true ? (
              <Skeleton
                active
                title
                paragraph={{
                  rows: 8,
                }}
              />
            ) : (
              <img
                className="singleNoDataImage"
                src={DataLoader}
                alt="image data empty"
              />
            )}
          </div>
        )}
      </div>
      <ProductLevelUserFeedback brandId={props.brandId} nextState={nextState} />
    </>
  );
};

export default ProductLevelTop20States;
