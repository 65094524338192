import React, { useState, useEffect } from "react";
import "./ProductLevelMetricBoxes.css"
import Graph1 from "./Graph1";
import Graph2 from "./Graph2";
import Graph3 from "./Graph3";
import WeeklyValues from "./WeeklyValues";
import axios from "axios";
import ProductLevelUsersWords from "../ProductLevelUsersWordForProduct/ProductLevelUsersWords";
import DataLoader from "../../images/noDataFull.svg"
import { useSearchParams,useParams } from "react-router-dom";
import MetrixSkeletonLoading from "../../SkeletonLoading/MetrixSkeletonLoading";
import checkEnv from "../../../utils/checkEnv";
import Dropdown from 'react-bootstrap/Dropdown'
import DropdownToggle from "react-bootstrap/esm/DropdownToggle";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarDays } from "@fortawesome/free-regular-svg-icons";
function nFormatter(num) {
  if (num >= 1000000000) {
    return (num / 1000000000).toFixed(1).replace(/\.0$/, "") + "G";
  }
  if (num >= 1000000) {
    return (num / 1000000).toFixed(1).replace(/\.0$/, "") + "M";
  }
  if (num >= 1000) {
    return (num / 1000).toFixed(1).replace(/\.0$/, "") + "K";
  }
  return num;
}

const ProductLevelMetricBoxes = (props) => {
  const [noDataLoad, setNoDataLoad] = useState(false)
  const [nextState, setNextState] = useState(false)
  const [searchParams, setSearchParams] = useSearchParams()
  let token = localStorage.getItem('token')
  let startDate = localStorage.getItem('start_date')
  let endDate = localStorage.getItem('end_date')
  let url=checkEnv()
  
  const [boxData, setBoxData] = useState([]);
  const [boxData1, setBoxData1] = useState([]);
  const [boxData2, setBoxData2] = useState([]);
  const [boxData3, setBoxData3] = useState([]);

  const getAverageNPSRating = async () => {
    setBoxData([])
    const response = await axios.get(`${url}/brandsdashboardproxy/v1/brand/${searchParams.get('brand')}/averagenps/?startDate=${startDate}&endDate=${endDate}&categoryIds=${searchParams.get('categoryIds')}&productId=${searchParams.get('productId')}`, {
      headers: {
        'authorization': `${token}`
      }
    })
      .catch((err) => {
        console.log(err);
      });
    setBoxData(response.data.map((dat) => dat));
  };

  const getAverageProductRating = async () => {
    setBoxData1([])
    const response = await axios.get(`${url}/brandsdashboardproxy/v1/brand/${searchParams.get('brand')}/averageproductrating/?startDate=${startDate}&endDate=${endDate}&categoryIds=${searchParams.get('categoryIds')}&productId=${searchParams.get('productId')}`, {
      headers: {
        'authorization': `${token}`
      }
    })
      .catch((err) => {
        console.log(err);
      });
    setBoxData1(response.data.map((dat) => dat));

  };
  const getPositiveFeedback = async () => {
    setBoxData2([])
    const response = await axios.get(`${url}/brandsdashboardproxy/v1/brand/${searchParams.get('brand')}/positivefeedbackcount/?startDate=${startDate}&endDate=${endDate}&categoryIds=${searchParams.get('categoryIds')}&productId=${searchParams.get('productId')}`, {
      headers: {
        'authorization': `${token}`
      }
    })
      .catch((err) => {
        console.log(err);
      });
    setBoxData2(response.data.map((dat) => dat));
    response.data[0].metric_value === 0 ? setNoDataLoad(true) : setNoDataLoad(false)
  };
  const getNegativeFeedback = async () => {
    setBoxData3([])
    const response = await axios.get(`${url}/brandsdashboardproxy/v1/brand/${searchParams.get('brand')}/negativefeedbackcount/?startDate=${startDate}&endDate=${endDate}&categoryIds=${searchParams.get('categoryIds')}&productId=${searchParams.get('productId')}`, {
      headers: {
        'authorization': `${token}`
      }
    })
      .catch((err) => {
        console.log(err);
      });
    setBoxData3(response.data.map((dat) => dat));
  };

  useEffect(() => {
    getAverageNPSRating();
    getAverageProductRating();
    getPositiveFeedback();
    getNegativeFeedback();
  }, [searchParams]);
  let format = /[-]/;

  useEffect(() => {
    if (boxData.length !== 0 && boxData1.length !== 0 && boxData2.length !== 0 && boxData3.length !== 0) {
      setNextState(true)
    } else {
      setNextState(false)
    }
  }, [boxData, boxData1, boxData2, boxData3])

  return (
    <div className="boxes">
      {/* <div className="selectedProductName">{searchParams.get('productName').replace('-','&')}</div> */}
      <div className="sample borderBottom">
        <div className="selectedProductName">{searchParams.get('productName')}</div>
        <div className="DateRange1">
          <Dropdown>
            <DropdownToggle id="date">
                {/* {moment(startDate).format("LL")} to  {moment(endDate).format("LL")} */}
                <div className="myflex">
                    <div style={{marginRight:"10px"}}>
                      {moment(startDate).format("LL")} to  {moment(endDate).format("LL")}
                    </div>
                    <div>
                      <FontAwesomeIcon className="calenderIcon" icon={faCalendarDays}></FontAwesomeIcon>
                    </div>
                  </div>
            </DropdownToggle>
          </Dropdown>
        </div>
      </div>
      
      {
        noDataLoad !== true ?
          <>
            <div className="productLevelMetricBoxes">
              {boxData.length !== 0 && boxData1.length !== 0 && boxData2.length !== 0 && boxData3.length !== 0 ? boxData.map((data, index) => {
                return (
                  <div className="box" key={index}>
                    <div className="dataCategory">
                      <p> Net Promoter Score  </p>
                      {/* ({data.count}) */}
                    </div>
                    <div className="value">
                      <div className="det">
                        <b>
                          {data.metric_value === 0 ? <span>No Data Found</span> : Math.round(data.metric_value)}
                        </b>
                      </div>
                      <div className="graph" id={index}>
                        <WeeklyValues
                          val={data.weekly_numbers ? data.weekly_numbers.slice(0, 11) : ""}
                          id={index}
                          // colorData={data.percentage_change.toFixed(1).toString()}
                          brandId={props.brandId}
                          startDate={props.startDate}
                          endDate={props.endDate}
                          data={props.boxData}
                        />
                      </div>
                    </div>
                  </div>
                );
              }) : <MetrixSkeletonLoading />}
              {boxData.length !== 0 && boxData1.length !== 0 && boxData2.length !== 0 && boxData3.length !== 0 ? boxData1.map((data, index) => {
                return (
                  <div className="box" key={index}>
                    <div className="dataCategory">
                      <p>Average Product Ratings </p>
                      {/* ({data.count}) */}
                    </div>
                    <div className="value">
                      <div className="det">
                        {data.metric_value === 0 ? <span>No Data Found</span> : (data.metric_value).toFixed(2)}
                      </div>
                      <div className="graph" id={index}>
                        <Graph1
                          val={data.weekly_numbers ? data.weekly_numbers.slice(0, 11) : ""}
                          id={index}
                          // colorData={data.percentage_change.toFixed(1).toString()}
                          brandId={props.brandId}
                          data={props.boxData}
                        />
                      </div>
                    </div>
                  </div>
                );
              }) : <MetrixSkeletonLoading />}
              
            </div>
            <ProductLevelUsersWords brandId={props.brandId} nextState={nextState} />
          </>
          : <img className="fullNoDataImage" src={DataLoader} alt='image data empty' />}
    </div>
  );
};

export default ProductLevelMetricBoxes;


// {boxData.length !== 0 && boxData1.length !== 0 && boxData2.length !== 0 && boxData3.length !== 0 ? boxData2.map((data, index) => {
//   let rs1 = format.test(data.percentage_change.toString());
//   let valueColor
//   if (rs1 === true) {
//     valueColor = "rgb(255, 115, 97)";
//   } else if (data.percentage_change.toFixed(1).toString() === '0.0') {
//     valueColor = 'rgb(255, 207, 97)'
//   } else {
//     valueColor = "rgb(0, 188, 138)";
//   }
//   let plus
//   let rs2 = data.percentage_change !== 0.0;
//   if (rs2 === true && rs1 === false) {
//     plus = '+'
//   } else {
//     plus = ''
//   }
//   return (
//     <div className="box" key={index}>
//       <div className="dataCategory">
//         <p>Positive Feedback</p>
//       </div>
//       <div className="value">
//         <div className="det">
//           {data.metric_value === 0 ? <span>No Data Found</span> : nFormatter(data.metric_value)}
//           {data.metric_value === 0 ? "" :
//             <p className="percentage2" style={{ color: `${valueColor}` }}>
//               {data.percentage_change ? `${plus}${data.percentage_change.toFixed(1).toString()}%` : ""}
//             </p>
//           }

//         </div>
//         <div className="graph" id={index}>
//           <Graph2
//             val={data.weekly_numbers ? data.weekly_numbers.slice(0, 11) : ""}
//             id={index}
//             colorData={data.percentage_change && data.percentage_change !== null ? data.percentage_change.toFixed(1).toString() : ""}
//             brandId={props.brandId}
//             startDate={props.startDate}
//             endDate={props.endDate}
//             data={props.boxData}
//           />
//         </div>
//       </div>
//     </div>
//   );
// }) : <MetrixSkeletonLoading />}
// {boxData.length !== 0 && boxData1.length !== 0 && boxData2.length !== 0 && boxData3.length !== 0 ? boxData3.map((data, index) => {
//   let rs1 = format.test(data.percentage_change.toString());
//   let valueColor
//   if (rs1 === true) {
//     valueColor = "rgb(255, 115, 97)";
//   } else if (data.percentage_change.toFixed(1).toString() === '0.0') {
//     valueColor = 'rgb(255, 207, 97)'
//   } else {
//     valueColor = "rgb(0, 188, 138)";
//   }
//   let plus
//   let rs2 = data.percentage_change !== 0.0;
//   if (rs2 === true && rs1 === false) {
//     plus = '+'
//   } else {
//     plus = ''
//   }
//   return (
//     <div className="box" key={index}>
//       <div className="dataCategory">
//         <p>Negative Feedback</p>
//       </div>
//       <div className="value">
//         <div className="det">
//           {data.metric_value === 0 ? <span>No Data Found</span> : nFormatter(data.metric_value)}
//           {data.metric_value === 0 ? "" :
//             <p className="percentage2" style={{ color: `${valueColor}` }}>
//               {data.percentage_change ? `${plus}${data.percentage_change.toFixed(1).toString()}%` : ""}
//             </p>
//           }
//         </div>
//         <div className="graph">
//           <Graph3
//             val={data.weekly_numbers ? data.weekly_numbers.slice(0, 11) : ""}
//             id={index}
//             colorData={data.percentage_change ? data.percentage_change.toFixed(1).toString() : ""}
//             brandId={props.brandId}
//             startDate={props.startDate}
//             endDate={props.endDate}
//             data={props.boxData}
//           />
//         </div>
//       </div>
//     </div>
//   );
// }) : <MetrixSkeletonLoading />}