import React from 'react'
import ShopCard from './ShopCard'


export default function ShopCardContainer({data}) {
    data=data.slice(0,6)
    const left=()=>{
        let leftarr=[]
        let rightarr=[]
        data.forEach((ele,ind)=>{
            if(ind<=2){
                leftarr.push(<ShopCard data={ele} key={ind} index={ind}/>)
            }else{
                rightarr.push(<ShopCard data={ele} key={ind} index={ind}/>)
            }
            // return 
        })
        return [leftarr,rightarr]
    }
  return (
    <div className='shop-card-container'>
         <div className='left-side'>
            {left()[0]}
        </div>
        <div className='right-side'>
             {left()[1]}
        </div>
    </div>
  )
}
