import React, { useState, useEffect } from 'react';
import ChartDataLabels from "chartjs-plugin-datalabels";
import "./Doughnut.css"
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Bar, Doughnut } from 'react-chartjs-2';
import axios from 'axios';
// import "./RatingsGraphs.css"
import DataLoader from "../../images/noDataFull.svg";
import { useSearchParams } from 'react-router-dom';
import { Skeleton } from "antd"
import DoughnutComponent from './DoughnutComponent';
import checkEnv from '../../../utils/checkEnv';

ChartJS.register(
    ChartDataLabels,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

export function CompetitiveAnalysisMultiGraph(props) {
    const [noDataLoad, setNoDataLoad] = useState(false)
    const [searchParams, setSearchParams] = useSearchParams()
    let token = localStorage.getItem("token")
    const [data1, setData1] = useState([])
    const [data2, setData2] = useState([])
    const [data3, setData3] = useState([])
    const [labels, setLabels] = useState([])
    const [hoverLabels, setHoverLabels] = useState([])

    let startDate = localStorage.getItem("my_start_date");
    let endDate = localStorage.getItem("my_end_date");
    let url=checkEnv()

    // const textlabels = ['your brand', 'competitor']
    // const data1 = [25, 30]
    // const data2 = [25, 45]
    // const data3 = [10, 30]

    useEffect(() => {
        axios.get(`${url}/brandsdashboardproxy/v1/brand/${searchParams.get('brand')}/compititorranking/?startDate=${startDate}&endDate=${endDate}&categoryIds=${searchParams.get('categoryIds')}&productIds=&stateName=${props.selectedState}`, {
            headers: {
                'authorization': `${token}`
            }
        })
            .then(res => {
                // setLabels(res.data.data.map(d => d.label))
                // setData1(res.data.data.map(d => d.values.detractors));
                // setData2(res.data.data.map(d => d.values.passives));
                // setData3(res.data.data.map(d => d.values.promoters));
                setData1(res.data.Your_Brand)
                setData2(res.data.Competitor__Brand)
            })
            .catch(err => {
                console.log(err)
            })
    }, [searchParams, props.selectedState])

    // console.log(data1, data2)
    const options = {
        indexAxis: 'y',
        categoryPercentage: 0.8,
        barPercentage: 0.8,
        plugins: {
            tooltip: {

                callbacks: {
                    labelTextColor: (context) => {
                        return 'black'
                    },
                    title: function (context) {
                        let index = context[0].dataIndex
                        return hoverLabels[index]
                    },
                    label: function (context) {
                        return `${context.dataset.label} ${context.raw.toFixed(2)}%`
                    },
                },
                boxWidth: 10,
                titleColor: 'black',
                backgroundColor: '#F9FBFF',
            },
            title: {
                display: false,
                text: 'User feedback distribution',
                font: {
                    weight: 500,
                    size: 18,
                },
                align: "start",
                padding: {
                    top: 10,
                    bottom: 50,
                },
                color: '#0f1628'
            },
            legend: {
                display: false,
            },
            datalabels: {
                display: false,
                formatter: function (value, context) {
                    return `${value.toFixed(2)}%`;
                },
                anchor: 'end',
                align: "bottom",
                padding: {
                    top: -12,

                },
                color: "white"
            }
        },
        responsive: true,
        scales: {
            x: {
                stacked: true,
                ticks: {
                    stepSize: 20,
                    callback: function (val, index) {
                        return `${val}%`;
                    },
                }
            },
            y: {
                // beginAtZero: true,
                max: 100,
                stacked: true,
                // ticks: {
                //     callback: function (val, index) {
                //         return `${val}%`;
                //     },
                //     // display: false,
                //     stepSize: 20
                // },
                grid: {
                    drawTicks: false,
                }
            },
        },
    };
    // console.log(data1)
    // const data = {
    //     labels: labels.map(text => text),
    //     datasets: [
    //         {
    //             label: 'Detractors (0-6)',
    //             data: data1.map((d, i) => (d / (data1[i] + data2[i] + data3[i])) * 100),
    //             backgroundColor: '#EC654E',
    //             barThickness: 45,
    //             borderWidth: {
    //                 right: 1.5
    //             },
    //             borderColor: "white",
    //         },
    //         {
    //             label: 'Passives (7-8)',
    //             data: data2.map((d, i) => (d / (data1[i] + data2[i] + data3[i])) * 100),
    //             backgroundColor: '#F8C43D',
    //             barThickness: 45,
    //             borderWidth: {
    //                 right: 1.5
    //             },
    //             borderColor: "white",
    //         },
    //         {
    //             label: 'Promoters (9-10)',
    //             data: data3.map((d, i) => (d / (data1[i] + data2[i] + data3[i])) * 100),
    //             backgroundColor: '#7FCD31',
    //             barThickness: 45,
    //             borderWidth: {
    //                 right: 1.5,
    //             },
    //             borderColor: "white",
    //         },
    //     ],
    // };

    const hoverData = ['Promoters (9-10)', 'Passives (7-8)', 'Detractors (0-6)']

    const data = {
        datasets: [
            {
                label: 'Your Brand',
                data: data1,
                backgroundColor: [
                    // 'rgb(238, 80, 79)',
                    // 'rgb(255, 207, 97)',
                    // 'rgb(108, 222, 129)'
                    '#7FCD31',
                    '#F8C43D',
                    '#EC654E',
                ],
                // cutout: "50%",
                borderWidth: 1.5,
                borderColor: "white",
            },
            {
                label: 'Competitor Brand',
                data: data2,
                backgroundColor: [
                    // 'rgb(238, 80, 79)',
                    // 'rgb(255, 207, 97)',
                    // 'rgb(108, 222, 129)'
                    '#bfe698',
                    '#fbda84',
                    '#f39b8c',
                ],
                // cutout: "50%",
                borderWidth: 1.5,
                borderColor: "white",
            },
        
        ],
    };

    const DoughnutData1={
        datasets: [
            {
                label: 'Your Brand',
                data: data1,
                backgroundColor: [
                    // 'rgb(238, 80, 79)',
                    // 'rgb(255, 207, 97)',
                    // 'rgb(108, 222, 129)'
                    '#7FCD31',
                    '#F8C43D',
                    '#EC654E',
                ],
                // cutout: "50%",
                borderWidth: 1.5,
                borderColor: "white",
            },
        ]
    }

    const DoughnutData2={
        datasets: [
            {
                label: 'Your Competitor Brand',
                data: data2,
                backgroundColor: [
                    // 'rgb(238, 80, 79)',
                    // 'rgb(255, 207, 97)',
                    // 'rgb(108, 222, 129)'
                    '#7FCD31',
                    '#F8C43D',
                    '#EC654E',
                ],
                // cutout: "50%",
                borderWidth: 1.5,
                borderColor: "white",
            },
        ]
    }
    function totalSum(total, datapoint) {
        return total + datapoint;
    }
    
    const totalValue1 = data1.reduce(totalSum, 0);
    const totalValue2 = data2.reduce(totalSum, 0);
    // console.log(data1)
    // console.log(totalValue1)

    

    const npsvalue1=(((data1[0]-data1[2])/totalValue1)*100).toFixed(0)
    const npsvalue2=(((data2[0]-data2[2])/totalValue2)*100).toFixed(0)


    return (

        <div className='competitorAnalysisCompetitorGraphs'>
            <div className="topWordsHeader">
                {/* User feedback distribution */}
                Net Promoter Score Distribution
            </div>
            {/* <Doughnut
                data={data}
                
                options={{
                    // layout: {
                    //     padding: 20
                    // },
                    plugins: {
                        datalabels: {
                            display: false,
                            formatter: function (value, context) {
                                const datapoints = context.chart.data.datasets[0].data;
                                function totalSum(total, datapoint) {
                                    return total + datapoint;
                                }
                                const totalValue = datapoints.reduce(totalSum, 0);
                                const percentageValue = ((value / totalValue) * 100).toFixed(0);

                                return `${percentageValue}%`;
                            },
                            color: [
                                
                            ],
                            align: "end",
                            anchor: "end",
                            padding: {
                                left: -10,
                                bottom: -10,
                            },
                            font: {
                                weight: 900,
                                size: 26
                            },
                        },
                        tooltip: {
                            callbacks: {
                                labelTextColor: (context) => {
                                    return 'black'
                                },
                                label: function (context) {
                                    for (let i in hoverData) {
                                        if (context.datasetIndex == 0) {
                                            let index = context.dataIndex
                                            return `Your Brand : ${hoverData[index]}  ${data1[index]}%`;
                                        } else {
                                            let index = context.dataIndex
                                            return `Your Top Competitors : ${hoverData[index]}  ${data2[index]}%`;
                                        }
                                    }
                                },
                            },
                            padding: 10,
                            backgroundColor: "white",
                        },
                    },

                }}
                
            /> */}
            <div className='flexDoughnut'>
                <div className='doughnut1'>
                    <div><DoughnutComponent data={DoughnutData1} data1={data1} data2={data2}/></div>
                    { <div className='text'>
                        Your Brand<br/>
                        NPS: {npsvalue1}
                    </div>}

                </div>
                <div className='doughnut1'>
                    <div><DoughnutComponent data={DoughnutData2} data1={data1} data2={data2}/></div>
                    {<div className='text'>
                        {/* Your Top Competitor Brands */}
                        Your Top Competitor Brands<br/>
                        NPS: {npsvalue2}
                    </div>}
                </div>
            </div>
            <div className='competitorColorLabels'>
                <div className='colorBox promotors'>

                </div>
                <div>
                    {hoverData[0]}
                </div><div className='colorBox passives'>

                </div>
                <div>
                    {hoverData[1]}
                </div>
                <div className='colorBox detractors'>

                </div>
                <div>
                    {hoverData[2]}
                </div>
            </div>
        </div>


    )
}
