import React from "react";
import "./ProductLevelDetails.css";
import Dropdown from 'react-bootstrap/Dropdown'
import DropdownToggle from "react-bootstrap/esm/DropdownToggle";
import moment from "moment";
import ProductLevelMetricBoxes from "../ProductLevelMetricBoxes/ProductLevelMetricBoxes";
import { NavLink,useNavigate, useSearchParams } from "react-router-dom";
const ProductLevelDetails = (props) => {
    const [searchParams,setSearchParams] = useSearchParams()
    let navigate = useNavigate()
    let startDate = localStorage.getItem("start_date")
    let endDate = localStorage.getItem("end_date")

    let forward = ">";

    const goBackClickHandler = ()=>{
        navigate({
            pathname: `/brandsdashboard/ratings`,
            search: `?brand=${searchParams.get('brand')}&categoryIds=${searchParams.get('categoryIds')}&productIds=${searchParams.get('productIds')}&date=${searchParams.get('date')}`
          })
    }

    return (
        <div className="mainCategoryPage">
            <div className="productLevelData">
                <div className="productLevelHome">
                    <span>
                        Home
                    </span>
                    {forward}
                    <span>
                        Sampling Overview
                    </span>
                    {forward}
                    <span className="productLevelPage" onClick={goBackClickHandler}>
                        Ratings And Review
                    </span>
                   
                    {forward}
                    <p>View Feedback</p>
                </div>
                {/* <div className="DateRange1">
                    <Dropdown>
                        <DropdownToggle id="date">
                        {moment(startDate).format("LL")} to  {moment(endDate).format("LL")}
                        </DropdownToggle>
                    </Dropdown>
                </div> */}
            </div>
            
            <ProductLevelMetricBoxes
                brandId={props.brandId}
                startDate={startDate}
                endDate={endDate}
            />
        </div>
    );
};

export default ProductLevelDetails;
