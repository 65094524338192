import React, { useState, useEffect } from "react";
import ProductTrials from "../ProductTrial/ProductTrials";
import "./ProductCollection.css"
import axios from "axios";
import removeTag from "../../images/remove_tag.png"
import checkEnv from "../../../utils/checkEnv";

const ProductCollections = (props) => {
  let brandId = props.brandId
  const [collectionState, setCollectionState] = useState(false)
  const [allProductState, setAllProductState] = useState(false)
  const [collectionsList, setCollectionList] = useState([])
  const [checkedCollectionState, setCheckedCollectioonState] = useState()
  const [isCheckCollections, setisCheckCollections] = useState(false)
  const [isCheckProducts, setIsCHeckProducts] = useState(false)
  const [collectionSearch, setCollectionSearch] = useState('')
  let token = localStorage.getItem("token")
  let startDate = localStorage.getItem("start_date");
  let endDate = localStorage.getItem("end_date");
  let url=checkEnv()
  const collectionClickHandler = () => {
    setCollectionState(!collectionState)
  }
  const allProductsClickHandler = () => {
    setAllProductState(!allProductState)
  }

  const categoryCheckedList = props.categoryCheckedList
  const productCheckedList = props.productCheckedList
  let categoryState = props.categoryState
  let productState = props.productState

  const getCollections = async () => {
    const response = await axios.get(`${url}/brandsdashboardproxy/v1/brand/${brandId}/collections/?startDate=${startDate}&endDate=${endDate}`, {
      headers: {
        'authorization': `${token}`
      }
    })
      .catch((err) => {
        console.log(err);
      });
    setCollectionList(response.data.map((dat) => dat));
    setCheckedCollectioonState(new Array(response.data.length).fill(false))
  };

  useEffect(() => {
    getCollections();
  }, [])

  const selecteAllCollections = () => {
    setisCheckCollections(!isCheckCollections)
  }

  const selectIndividualCollection = (position) => {
    let updateCheckedState = checkedCollectionState.map((item, index) =>
      index === position ? !item : item
    )
    setCheckedCollectioonState(updateCheckedState)
  }

  const selectAllProducts = () => {
    setIsCHeckProducts(!isCheckProducts)
  }

  return (
    <div className="boxes">
      <div className="show">Showing |{categoryState ?
        `All Categories` : <div className="selectedList">
          {
            categoryCheckedList.map(d => {
              return <div className="selectedData">{d}<img src={removeTag} /></div>
            })
          }
        </div>
      } | {
          productState ?
            `All Products` : <div className="selectedList">
              {
                productCheckedList.map(d => {
                  return <div className="selectedData">{d}<img src={removeTag} /></div>
                })
              }
            </div>
        }</div>
      <div className="collectionSection">
        <div>
          <div className="collection" onClick={collectionClickHandler}>
            Collection
          </div>
          {
            collectionState ?
              <div className="collectionData">
                <div className="collectionDataHeader">
                  <input
                    className="search"
                    type="text"
                    placeholder="search"
                    value={collectionSearch}
                    onChange={(e) => setCollectionSearch(e.target.value)}
                  ></input>
                  <div className="collectionList">
                    <input
                      className="allSelection"
                      type="checkbox"
                      checked={isCheckCollections}
                      onChange={selecteAllCollections}
                    ></input>
                    <div>All</div>
                  </div>
                </div>
                <div className="collectionDataSection">
                  {
                    collectionsList.filter(d => d.collectionName.toLowerCase().includes(collectionSearch.toLowerCase())).map((d, index) => {
                      return <div className="collectionDatas" key={index}>
                        <input
                          className="allSelection"
                          type="checkbox"
                          checked={isCheckCollections || checkedCollectionState[index]}
                          onChange={() => selectIndividualCollection(index)}
                        ></input>
                        <div>{d.collectionName}</div>
                      </div>
                    })
                  }
                </div>
              </div>
              : ""
          }
        </div>
        <div>
          <div className="collection" onClick={allProductsClickHandler}>
            Products
          </div>
          {
            allProductState ?
              <div className="allProductsData">
                <div className="collectionDataHeader">
                  <input
                    className="search"
                    type="text"
                    placeholder="search"
                  ></input>
                  <div className="collectionList">
                    <input
                      className="allSelection"
                      type="checkbox"
                      checked={isCheckProducts}
                      onChange={selectAllProducts}
                    ></input>
                    <div>All</div>
                  </div>
                </div>
              </div>
              : ""
          }
        </div>
      </div>
      <ProductTrials brandId={props.brandId} />
    </div>
  );
};

export default ProductCollections;
