import React, { useState } from 'react';
import "./ChangePassword.css";
import Modal from 'react-bootstrap/Modal';
import axios from "axios";
import Lock from "../../images/lock.png"
import goBack from "../../images/goBack.svg"
import resetPassword from "../../images/resetPassword.svg"
import mustHave from "../../images/mustHaveNormal.svg"
import notHave from "../../images/notMustHave.svg"
import notMatched from "../../images/notMatched.svg"
import tick from '../../images/rnAckTick.svg';
import goBackSubmit from "../../images/goBackSubmit.svg"
import checkEnv from '../../../utils/checkEnv';

export default function ChangePassword(props) {
    let url=checkEnv()
    const handleClose = () => props.setShow(false);
    var validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    const token = localStorage.getItem('token')
    const initialValues = {
        oldPassword: '',
        newPassword: '',
        confirmPassword: '',
    }
    const [passwordValues, setPasswordValues] = useState({
        oldPassword: '',
        newPassword: '',
        confirmPassword: '',
    })
    const [errorImage, setErrorImage] = useState(mustHave)
    const [submitPasswordState, setSubmitPassowrdState] = useState(false)

    const submitNewPasswordClickHandler = () => {
        errorFunction()
        // if (passwordValues.oldPassword.length > 0 && passwordValues.newPassword === passwordValues.confirmPassword) {

        // }
        // props.setShow(false)
        // setSubmitPassowrdState(true)
    }

    const passwordChangeHandler = (e) => {
        setErrorImage(mustHave)
        document.querySelectorAll('.changePasswordPart')[0].classList.remove('invalidState')
        document.querySelectorAll('.changePasswordPart')[1].classList.remove('invalidState')
        document.querySelectorAll('.changePasswordPart')[2].classList.remove('invalidState')
        const { name, value } = e.target;
        setPasswordValues({ ...passwordValues, [name]: value })
    }

    const errorFunction = () => {
        if (!passwordValues.newPassword.match(validRegex) || passwordValues.newPassword.length < 8) {
            setErrorImage(notHave)
            document.querySelectorAll('.changePasswordPart')[1].classList.add('invalidState')
            document.querySelectorAll('.changePasswordPart')[2].classList.add('invalidState')
        } else if (passwordValues.newPassword.match(validRegex) && passwordValues.newPassword.length >= 8 && passwordValues.newPassword !== passwordValues.confirmPassword) {
            setErrorImage(notMatched)
            document.querySelectorAll('.changePasswordPart')[1].classList.add('invalidState')
            document.querySelectorAll('.changePasswordPart')[2].classList.add('invalidState')
        }
        else if (passwordValues.newPassword.match(validRegex) && passwordValues.newPassword.length >= 8 && passwordValues.newPassword == passwordValues.confirmPassword) {
            // props.setShow(false)
            axios.post(`${url}/brandsdashboardproxy/v1/passwordchange/`, {
                "old_password": passwordValues.oldPassword,
                "new_password": passwordValues.newPassword,
            }, {
                headers: {
                    'authorization': `${token}`
                },
            })
                .then(res => {
                    // console.log(res.status, 'response status')
                    if (res.status === 200) {
                        props.setShow(false)
                        setErrorImage(mustHave)
                        setSubmitPassowrdState(true)
                        setPasswordValues(initialValues)

                    }
                })
                .catch(err => {
                    console.log(err)
                    if (err.response.status === 401) {
                        setErrorImage(notMatched)
                        document.querySelectorAll('.changePasswordPart')[0].classList.add('invalidState')
                        document.querySelectorAll('.changePasswordPart')[1].classList.remove('invalidState')
                        document.querySelectorAll('.changePasswordPart')[2].classList.remove('invalidState')
                    } else {
                        props.setShow(true)
                        setErrorImage(notHave)
                        document.querySelectorAll('.changePasswordPart')[1].classList.add('invalidState')
                        document.querySelectorAll('.changePasswordPart')[2].classList.add('invalidState')
                    }
                })
        }
    }
    // console.log(passwordValues, 'values ')
    // console.log(submitPasswordState)

    return (
        <div>
            <Modal className='changePasswordModal' size='lg' show={props.show} onHide={handleClose} backdrop="static" centered>
                {/* <form> */}
                <div className='changePasswordTitle'>
                    Change Password
                </div>
                <div className='oldPassword'>
                    <p>
                        Old Password
                    </p>
                    <div className="changePasswordPart" >
                        <input type='password' name='oldPassword' placeholder='Enter Old Password' required value={passwordValues.oldPassword} onChange={passwordChangeHandler} />
                        <img src={Lock} alt="lock" />
                    </div>
                </div>
                <div className='newPasswordSection'>
                    <div className='oldPassword'>
                        <p>
                            New Password
                        </p>
                        <div className="changePasswordPart" >
                            <input type='text' name='newPassword' placeholder='Enter New Password' required value={passwordValues.newPassword} onChange={passwordChangeHandler} />
                            <img src={Lock} alt="lock" />
                        </div>
                    </div>
                    <div className='oldPassword'>
                        <p>
                            Confirm New Password
                        </p>
                        <div className="changePasswordPart" >
                            <input type='password' name='confirmPassword' placeholder='Confirm New Password' required value={passwordValues.confirmPassword} onChange={passwordChangeHandler} />
                            <img src={Lock} alt="lock" />
                        </div>
                    </div>
                </div>
                <img src={errorImage} alt='data' className='changeCondition' />
                <div className='submitAndCancellation'>
                    <img className='changeCancelButton' src={goBack} alt="goBack" onClick={() => props.setShow(false)} />
                    <img className='changeSubmitButton' src={resetPassword} alt="resetPassword" onClick={submitNewPasswordClickHandler} />
                </div>
                {/* </form> */}
            </Modal>
            <Modal className='changePasswordModalSubmit' size='lg' show={submitPasswordState} onHide={() => setSubmitPassowrdState(false)} backdrop="static" centered>
                <div className='changePasswordHead'>
                    Your password has been reset succesfully.
                </div>
                <div className='changeSubmitImage'>
                    <img src={tick} alt='tick' />
                </div>
                <div className='rn-ack-action'><img src={goBackSubmit} onClick={() => setSubmitPassowrdState(false)} /></div>
            </Modal>
        </div>
    )
}
