import React ,{useEffect, useState}from 'react'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useNavigate } from 'react-router';
import Card from '../../new_survey/Card';
import moment from 'moment';
import axios from 'axios';
import "./CreateModal.css"
import checkEnv, { checkSurveyEnv } from '../../../../utils/checkEnv';
import emptyIcon from "../../../images/emptyicon.png"
import fullRect from "../../../images/full.svg"
import halfRect from "../../../images/half.svg"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { useSearchParams } from 'react-router-dom';

export default function CreateModal(props) {
    const navigate=useNavigate()
    let startDate = localStorage.getItem('start_date') ? localStorage.getItem('start_date') : localStorage.setItem("start_date", moment().subtract(6, 'days').format('YYYY-MM-DD'))
    let endDate = localStorage.getItem('end_date') ? localStorage.getItem('end_date') : localStorage.setItem("end_date", moment().format("YYYY-MM-DD"))
    const url=checkEnv()
    const [data,setData]=useState([])
    const [searchParams]=useSearchParams()
    const surveyUrl=checkSurveyEnv()
    // const testing=[
    //     {
    //         "id": "637b8a8aa21a60d7eb8115d2",
    //         "title": "Brand Awareness Survey",
    //         "sub_title": "test template",
    //         "status": "CREATED",
    //         "created_at": "2022-11-20T14:22:13Z",
    //         "number_of_sections": 0,
    //         "number_of_questions": 0
    //     },
    //     {
    //         "id": "637b8a8aa21a60d7eb8115d2",
    //         "title": "Brand Awareness Survey",
    //         "sub_title": "test template",
    //         "status": "CREATED",
    //         "created_at": "2022-11-20T14:22:13Z",
    //         "number_of_sections": 3,
    //         "number_of_questions": 5
    //     },
    //     {
    //         "id": "637b8a8aa21a60d7eb8115d2",
    //         "title": "Brand Awareness Survey",
    //         "sub_title": "Check the current percention about your brand",
    //         "status": "CREATED",
    //         "created_at": "2022-11-20T10:10:01Z",
    //         "number_of_sections": 3,
    //         "number_of_questions": 5
    //     },
    //     {
    //         "id": "637b8a8aa21a60d7eb8115d2",
    //         "title": "Brand Awareness Survey",
    //         "sub_title": "Check the current percention about your brand",
    //         "status": "CREATED",
    //         "created_at": "2022-11-20T10:10:01Z",
    //         "number_of_sections": 3,
    //         "number_of_questions": 5
    //     },
    //     {
    //         "id": "637b8a8aa21a60d7eb8115d2",
    //         "title": "Brand Awareness Survey",
    //         "sub_title": "Check the current percention about your brand",
    //         "status": "CREATED",
    //         "created_at": "2022-11-20T10:10:01Z",
    //         "number_of_sections": 3,
    //         "number_of_questions": 5
    //     },
    //     {
    //         "id": "637b8a8aa21a60d7eb8115d2",
    //         "title": "Brand Awareness Survey",
    //         "sub_title": "Check the current percention about your brand",
    //         "status": "CREATED",
    //         "created_at": "2022-11-20T10:10:01Z",
    //         "number_of_sections": 3,
    //         "number_of_questions": 5
    //     },
        
    // ]
    useEffect(()=>{
        axios.get(`${surveyUrl}/smyttenresearch/surveytemplate/`)
        .then(res=>{
            console.log(res.data)
            setData(res.data)
        })
    },[url])
    const colorArr=["#f1f0ff","#fef4ec","#ddf4ff"]
    const imagecolor=["#7D71FF","#D95900","#21C2FC"]


    const cardClickHandler=(id,s,st)=>{
        localStorage.setItem("cardData",JSON.stringify({
            "title":s,
            "sub_title":st
        }))

        navigate({
            pathname: '/brandsdashboard/research/instantiatesurvey',
            search:`?templateId=${id}&brand=${localStorage.getItem("brand_id")}&categoryIds=${localStorage.getItem('allcategoryId') ? (localStorage.getItem('allcategoryId').split(',')) : []}&productIds=${localStorage.getItem('allProductId') ? localStorage.getItem('allProductId').split(',') : []}&date=${startDate}to${endDate}`
        })
    }


    const createClickHandler=()=>{
        
        navigate({
        pathname: `/brandsdashboard/research/surveys`,
        search: `?brand=${localStorage.getItem("brand_id")}&step=1&categoryIds=${searchParams.get('categoryIds')}&productIds=${searchParams.get('productIds')}&date=${searchParams.get('date')}`
        })
    }





    return (
        <Modal
          {...props}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          className='modal-container'
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
                Create New Survey
            </Modal.Title>
          </Modal.Header>
          <Modal.Body style={{border:"none"}} className="modalBody">
            <div className='cards'>
                {data.length!==0&& data.map((test,index)=>{
                    let color=colorArr[index]
                    let bgcolor=imagecolor[index]
                    if(index>=3){
                        
                        color=colorArr[index%3]
                        bgcolor=imagecolor[index%3]
                    }
                    
                    return <div key={index} onClick={()=>{cardClickHandler(test.id,test.title,test.sub_title)}}><Card bgcolor={bgcolor} color={color} test={test}/></div>
                    })
                }
                <div className='create-new-card-container card-container' onClick={()=>{createClickHandler()}}>
                    <div className='reate-new-card-header'>
                        <div className='reactangle'>
                            <img src={fullRect} alt=""></img>
                            <img src={fullRect} alt=""></img>
                            <img src={fullRect} alt=""></img>
                            <img src={halfRect} alt=""></img>
                        </div>
                        <img src={emptyIcon} alt=""/>
                    </div>
                    <div className='reate-new-card-footer'>
                        <div className='plus'><FontAwesomeIcon icon={faPlus}></FontAwesomeIcon></div>
                        <div><span>Start from Scratch</span></div>
                    </div>
                </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={props.onHide}>Close</Button>
          </Modal.Footer>
        </Modal>
      );
}
