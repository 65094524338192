import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faThLarge,
  faTachometerAlt,
  faTvAlt,
  faChartLine,
  faChartSimple
} from "@fortawesome/free-solid-svg-icons";
import { NavLink, useParams, useLocation, useSearchParams, useNavigate } from "react-router-dom";
import { faResearchgate } from "@fortawesome/free-brands-svg-icons"
import moment from "moment"
import 'animate.css'

const Dashboard = () => {
  const navigate = useNavigate()
  const [countLevel, setCountLevel] = useState()
  const [productCountLevel, setProductCountLevel] = useState()
  const [searchParams, setSearchParams] = useSearchParams()
  let startDate = localStorage.getItem('start_date') ? localStorage.getItem('start_date') : localStorage.setItem("start_date", moment().subtract(6, 'days').format('YYYY-MM-DD'))
  let endDate = localStorage.getItem('end_date') ? localStorage.getItem('end_date') : localStorage.setItem("end_date", moment().format("YYYY-MM-DD"))

  let my_end_date=moment().format("YYYY-MM-DD")
  let my_start_date=moment(my_end_date).subtract(1, 'months').format("YYYY-MM-DD")
  useEffect(()=>{
    localStorage.setItem("my_start_date",my_start_date)
    localStorage.setItem("my_end_date",my_end_date)
  },[])
  
  
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  let customerCountLevel
  // useEffect(() => {
  //   if (window.location.pathname !== "/brandsdashboard/performance/:stateDetails" && window.location.pathname !== "/brandsdashboard/ratings/:productDetails" && window.location.pathname !=="/brandsdashboard/research/surveys" && localStorage.getItem('brand_id')) {
  //     navigate({
  //       pathname: window.location.pathname,
  //       search: `?brand=${localStorage.getItem("brand_id")}&categoryIds=${searchParams.get('categoryIds') ? searchParams.get('categoryIds') : []}&productIds=${searchParams.get('productIds') ? searchParams.get('productIds') : []}&date=${startDate}to${endDate}`
  //     })
  //   }
  // }, [window.reload])

  useEffect(() => {
    if (!localStorage.getItem("brand_id")) {
      localStorage.setItem("brand_id", searchParams.get('brand'))
    }
    if (window.location.pathname === "/brandsdashboard/performance/:stateDetails") {
      setCountLevel(1)
      setProductCountLevel(0)
    } else if (window.location.pathname === "/brandsdashboard/ratings/:productDetails") {
      setProductCountLevel(1)
      setCountLevel(0)
    } else if (window.location.pathname !== "/brandsdashboard/performance/:stateDetails" && window.location.pathname !== "/brandsdashboard/ratings/:productDetails") {
      setCountLevel(0)
      setProductCountLevel(0)
    }
  }, [window.reload, searchParams])

  useEffect(() => {
    if (document.querySelectorAll(".active") && document.querySelectorAll(".active")[0].parentElement.previousSibling && document.querySelectorAll(".active")[0].parentElement.nextSibling) {
      document.querySelectorAll(".active")[0].parentElement.previousSibling.style.borderBottomRightRadius = '16px'
      document.querySelectorAll(".active")[0].parentElement.nextSibling.style.borderTopRightRadius = '16px'
    } else if (document.querySelectorAll(".active") && !document.querySelectorAll(".active")[0].parentElement.previousSibling && document.querySelectorAll(".active")[0].parentElement.nextSibling) {
      document.querySelectorAll(".active")[0].parentElement.nextSibling.style.borderTopRightRadius = '16px'
    } else if (document.querySelectorAll(".active") && document.querySelectorAll(".active")[0].parentElement.previousSibling && !document.querySelectorAll(".active")[0].parentElement.nextSibling) {
      document.querySelectorAll(".active")[0].parentElement.previousSibling.style.borderBottomRightRadius = '16px'
    }
  }, [searchParams])
 
  return (
    <div className="section">
      <div className="menu">
        <div className="overview" id="overviewId">
          <div className="samplingList">
            <div className="anchorParent">
              <NavLink className={countLevel === 1 ? "active1" : "clicks"} to={`/brandsdashboard/performance?brand=${localStorage.getItem("brand_id")}&categoryIds=${searchParams.get('categoryIds') ? searchParams.get('categoryIds') : []}&productIds=${searchParams.get('productIds') ? searchParams.get('productIds') : []}&date=${startDate}to${endDate}`} style={{ textDecoration: "none" }}  >
                <div>
                  <FontAwesomeIcon icon={faThLarge} />
                  <p>Performance</p>
                </div>
              </NavLink>
            </div>
            <div className="anchorParent">
              <NavLink className='clicks' to={`/brandsdashboard/competitoranalysis?brand=${localStorage.getItem("brand_id")}&categoryIds=${searchParams.get('categoryIds') ? searchParams.get('categoryIds') : []}&productIds=${searchParams.get('productIds') ? searchParams.get('productIds') : []}&date=${my_start_date}to${my_end_date}`} style={{ textDecoration: "none" }}>
                <div>
                  <FontAwesomeIcon icon={faChartSimple} />
                  <p>Competitor Analysis</p>
                </div>
              </NavLink>
            </div>
            <div className="anchorParent">
              <NavLink className={customerCountLevel === 1 ? "active3" : "clicks"} to={`/brandsdashboard/consumer?brand=${localStorage.getItem("brand_id")}&categoryIds=${searchParams.get('categoryIds') ? searchParams.get('categoryIds') : []}&productIds=${searchParams.get('productIds') ? searchParams.get('productIds') : []}&date=${startDate}to${endDate}`} style={{ textDecoration: "none" }}>
                <div>
                  <FontAwesomeIcon icon={faTachometerAlt} />
                  <p>Consumer Profile</p>
                </div>
              </NavLink>
            </div>
            <div className="anchorParent">
              <NavLink to={`/brandsdashboard/ratings?brand=${localStorage.getItem("brand_id")}&categoryIds=${searchParams.get('categoryIds') ? searchParams.get('categoryIds') : []}&productIds=${searchParams.get('productIds') ? searchParams.get('productIds') : []}&date=${startDate}to${endDate}`} className={productCountLevel === 1 ? "active2" : "clicks"} style={{ textDecoration: "none" }} >
                <div>
                  <FontAwesomeIcon icon={faChartLine} />
                  <p>Ratings and Review</p>
                </div>
              </NavLink>
            </div>
            <div className="anchorParent">
              <NavLink to={`/brandsdashboard/research?brand=${localStorage.getItem("brand_id")}&categoryIds=${searchParams.get('categoryIds') ? searchParams.get('categoryIds') : []}&productIds=${searchParams.get('productIds') ? searchParams.get('productIds') : []}&date=${startDate}to${endDate}`} className="clicks" style={{ textDecoration: "none" }}  >
                <div>
                  <FontAwesomeIcon icon={faResearchgate} />
                  <p >Research</p>
                </div>
              </NavLink>
            </div>
            <div className="anchorParent">
              <div>
                <p ></p>
              </div>
            </div>
          </div>
        </div>
        <div className="hoverSamplingList">
          <div>
            <NavLink className={countLevel === 1 ? "active1" : "clicks"} to={`/brandsdashboard/performance?brand=${localStorage.getItem("brand_id")}&categoryIds=${searchParams.get('categoryIds') ? searchParams.get('categoryIds') : []}&productIds=${searchParams.get('productIds') ? searchParams.get('productIds') : []}&date=${startDate}to${endDate}`} style={{ textDecoration: "none" }}  >
              <FontAwesomeIcon className="dashboard" icon={faThLarge} />
            </NavLink>
          </div>
          <div>
            <NavLink className='clicks' to={`/brandsdashboard/competitoranalysis?brand=${localStorage.getItem("brand_id")}&categoryIds=${searchParams.get('categoryIds') ? searchParams.get('categoryIds') : []}&productIds=${searchParams.get('productIds') ? searchParams.get('productIds') : []}&date=${my_start_date}to${my_end_date}`} style={{ textDecoration: "none" }}>
              <FontAwesomeIcon className="dashboard" icon={faChartSimple} />
            </NavLink>
          </div>
          <div>
            <NavLink className="clicks" to={`/brandsdashboard/consumer?brand=${localStorage.getItem("brand_id")}&categoryIds=${searchParams.get('categoryIds') ? searchParams.get('categoryIds') : []}&productIds=${searchParams.get('productIds') ? searchParams.get('productIds') : []}&date=${startDate}to${endDate}`} style={{ textDecoration: "none" }}>
              <FontAwesomeIcon className="request" icon={faTachometerAlt} />
            </NavLink>
          </div>
          <div>
            <NavLink to={`/brandsdashboard/ratings?brand=${localStorage.getItem("brand_id")}&categoryIds=${searchParams.get('categoryIds') ? searchParams.get('categoryIds') : []}&productIds=${searchParams.get('productIds') ? searchParams.get('productIds') : []}&date=${startDate}to${endDate}`} className={productCountLevel === 1 ? "active2" : "clicks"} style={{ textDecoration: "none" }} >
              <FontAwesomeIcon className="chart" icon={faChartLine} />
            </NavLink>
          </div>
          <div>
            <NavLink to={`/brandsdashboard/research?brand=${localStorage.getItem("brand_id")}&categoryIds=${searchParams.get('categoryIds') ? searchParams.get('categoryIds') : []}&productIds=${searchParams.get('productIds') ? searchParams.get('productIds') : []}&date=${startDate}to${endDate}`} className="clicks" style={{ textDecoration: "none" }} >
              <FontAwesomeIcon className="research" icon={faResearchgate} />
            </NavLink>
          </div>
        </div>
      </div>
    </div>
  )
};

export default Dashboard;
