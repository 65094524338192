import React, { useState, useEffect } from "react";
import axios from "axios";

import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-daterangepicker/daterangepicker.css";
import moment from "moment";
import ProductCollections from "./ProductCollectionDetails/ProductCollections";
import Dropdown from 'react-bootstrap/Dropdown'
import DropdownToggle from "react-bootstrap/esm/DropdownToggle";
import DropdownMenu from "react-bootstrap/esm/DropdownMenu";
import checkEnv from "../../utils/checkEnv";


const ProductDetails = (props) => {
  let url=checkEnv()
  let brandId = props.brandId
  let productList1 = []
  let categoryState
  let productCheckedState
  let categoryList1 = []
  let token = localStorage.getItem("token")
  const [categoryList, setCategoryList] = useState([]);
  const [productListWithIdName,setProductListWithIdName] = useState([])
  const [singleCheck, setSingleCheck] = useState(false)
  const [categoryIdList, setCategoryIdList] = useState([])
  const [productIdList,setProductIdList] = useState([])
  const [categoryListAfterCheck, setCategoryListAfterCheck] = useState([])
  const [productList, setProductList] = useState([]);
  const [state, setState] = useState(false);
  const [productState, setProductState] = useState(false);
  const [isCheck, setIsCheck] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [checkedCategoryState, setCheckedCategoryState] = useState();
  const [checkedState, setCheckedState] = useState();
  const [categorySearch, setCategorySearch] = useState('')
  const [productSearch, setProductSearch] = useState('')
  if (localStorage.getItem('start_date') === null) {
    let endValue = moment().format("LD").slice(0, 10);
    let startValue = moment().subtract(6, "days").format("LD").slice(0, 10);
    let startMonth = startValue.slice(0, 2);
    let startDate = startValue.slice(3, 5);
    let startYear = startValue.slice(6, 10);
    let endDate = endValue.slice(3, 5);
    let endYear = endValue.slice(6, 10);
    let endMonth = endValue.slice(0, 2);
    localStorage.setItem(
      "start_date",
      `${startYear}-${startMonth}-${startDate}`
    );
    localStorage.setItem("end_date", `${endYear}-${endMonth}-${endDate}`);
  }
  let startDate = localStorage.getItem("start_date");
  let endDate = localStorage.getItem("end_date");
  
  const [rangeState, setRangeState] = useState(false)
  const todayclick = () => {
    let startValue = moment().format("LD").slice(0, 10);
    let endValue = moment().format("LD").slice(0, 10);
    let startMonth = startValue.slice(0, 2);
    let startDate = startValue.slice(3, 5);
    let startYear = startValue.slice(6, 10);
    let endDate = endValue.slice(3, 5);
    let endYear = endValue.slice(6, 10);
    let endMonth = endValue.slice(0, 2);
    localStorage.setItem(
      "start_date",
      `${startYear}-${startMonth}-${startDate}`
    );
    localStorage.setItem("end_date", `${endYear}-${endMonth}-${endDate}`);
    setRangeState(!rangeState)
    window.location.reload()
  }
  const sevenClick = () => {
    let endValue = moment().format("LD").slice(0, 10);
    let startValue = moment().subtract(6, "days").format("LD").slice(0, 10);
    let startMonth = startValue.slice(0, 2);
    let startDate = startValue.slice(3, 5);
    let startYear = startValue.slice(6, 10);
    let endDate = endValue.slice(3, 5);
    let endYear = endValue.slice(6, 10);
    let endMonth = endValue.slice(0, 2);
    localStorage.setItem(
      "start_date",
      `${startYear}-${startMonth}-${startDate}`
    );
    localStorage.setItem("end_date", `${endYear}-${endMonth}-${endDate}`);
    setRangeState(!rangeState)
    window.location.reload()
  }
  const halfMonthClick = () => {
    let endValue = moment().format("LD").slice(0, 10);
    let startValue = moment().subtract(14, "days").format("LD").slice(0, 10);
    let startMonth = startValue.slice(0, 2);
    let startDate = startValue.slice(3, 5);
    let startYear = startValue.slice(6, 10);
    let endDate = endValue.slice(3, 5);
    let endYear = endValue.slice(6, 10);
    let endMonth = endValue.slice(0, 2);
    localStorage.setItem(
      "start_date",
      `${startYear}-${startMonth}-${startDate}`
    );
    localStorage.setItem("end_date", `${endYear}-${endMonth}-${endDate}`);
    setRangeState(!rangeState)
    window.location.reload()
  }
  const monthClick = () => {
    let endValue = moment().format("LD").slice(0, 10);
    let startValue = moment().subtract(30, "days").format("LD").slice(0, 10);
    let startMonth = startValue.slice(0, 2);
    let startDate = startValue.slice(3, 5);
    let startYear = startValue.slice(6, 10);
    let endDate = endValue.slice(3, 5);
    let endYear = endValue.slice(6, 10);
    let endMonth = endValue.slice(0, 2);
    localStorage.setItem(
      "start_date",
      `${startYear}-${startMonth}-${startDate}`
    );
    localStorage.setItem("end_date", `${endYear}-${endMonth}-${endDate}`);
    setRangeState(!rangeState)
    window.location.reload()
  }
  const threeMonthClick = () => {
    let endValue = moment().format("LD").slice(0, 10);
    let startValue = moment().subtract(3, "month").format("LD").slice(0, 10);
    let startMonth = startValue.slice(0, 2);
    let startDate = startValue.slice(3, 5);
    let startYear = startValue.slice(6, 10);
    let endDate = endValue.slice(3, 5);
    let endYear = endValue.slice(6, 10);
    let endMonth = endValue.slice(0, 2);
    localStorage.setItem(
      "start_date",
      `${startYear}-${startMonth}-${startDate}`
    );
    localStorage.setItem("end_date", `${endYear}-${endMonth}-${endDate}`);
    setRangeState(!rangeState)
    window.location.reload()
  }
  const sixtyDaysClick = () => {
    let endValue = moment().format("LD").slice(0, 10);
    let startValue = moment().subtract(6, "month").format("LD").slice(0, 10);
    let startMonth = startValue.slice(0, 2);
    let startDate = startValue.slice(3, 5);
    let startYear = startValue.slice(6, 10);
    let endDate = endValue.slice(3, 5);
    let endYear = endValue.slice(6, 10);
    let endMonth = endValue.slice(0, 2);
    localStorage.setItem(
      "start_date",
      `${startYear}-${startMonth}-${startDate}`
    );
    localStorage.setItem("end_date", `${endYear}-${endMonth}-${endDate}`);
    setRangeState(!rangeState)
    window.location.reload()
  }
  const yearClick = () => {
    let endValue = moment().format("LD").slice(0, 10);
    let startValue = moment().subtract(12, "month").format("LD").slice(0, 10);
    let startMonth = startValue.slice(0, 2);
    let startDate = startValue.slice(3, 5);
    let startYear = startValue.slice(6, 10);
    let endDate = endValue.slice(3, 5);
    let endYear = endValue.slice(6, 10);
    let endMonth = endValue.slice(0, 2);
    localStorage.setItem(
      "start_date",
      `${startYear}-${startMonth}-${startDate}`
    );
    localStorage.setItem("end_date", `${endYear}-${endMonth}-${endDate}`);
    setRangeState(!rangeState)
    window.location.reload()
  }
  if (rangeState === false) {
    if (document.querySelector(".daterangepicker") !== null) {
      document.querySelector(".daterangepicker").style.display = "none"
    }
  } else {
    if (document.querySelector(".daterangepicker") !== null) {
      document.querySelector(".daterangepicker").style.display = "block"
    }
  }
  const changeDateHandler = (e) => {
    setRangeState(!rangeState)
    setState(false)
    setProductState(false)
    console.log("clicked", moment());
    let x = document.getElementsByClassName("applyBtn");
    console.log(x);
    x[0].addEventListener("click", (e) => {
      setRangeState(!rangeState)
      let startValue = document
        .getElementsByClassName("drp-selected")[0]
        .textContent.slice(0, 10);
      let endValue = document
        .getElementsByClassName("drp-selected")[0]
        .textContent.slice(13);
      console.log(startValue.slice(0, 2));
      let startMonth = startValue.slice(0, 2);
      let startDate = startValue.slice(3, 5);
      let startYear = startValue.slice(6);
      let endDate = endValue.slice(3, 5);
      let endYear = endValue.slice(6);
      let endMonth = endValue.slice(0, 2);
      console.log(`${startYear}-${startMonth}-${startDate}`);
      localStorage.setItem(
        "start_date",
        `${startYear}-${startMonth}-${startDate}`
      );
      localStorage.setItem("end_date", `${endYear}-${endMonth}-${endDate}`);
      window.location.reload();
    });
  };
  let forward = ">";
  const clickHandler = (e) => {
    setRangeState(false)
    setState(!state)
    setProductState(false)
    if (localStorage.getItem("allCategoriesChecked") === null) {
      localStorage.setItem("allCategoriesChecked", isChecked)
    }
  };
  const getCategories = async () => {
    const response = await axios.get(`${url}/brandsdashboardproxy/v1/brand/${brandId}/category/`, {
      headers: {
        'authorization': `${token}`
      }
    })
      .catch((err) => {
        console.log(err);
      });
    setCategoryList(response.data.map((dat) => dat));
    setCategoryIdList(response.data.map(d => d.categoryId));
    setCategoryListAfterCheck(response.data.map((dat => dat.categoryName)))
    setCheckedCategoryState(new Array(response.data.length).fill(false))
  };
  const getProducts = async () => {
    const response = await axios.get(`${url}/brandsdashboardproxy/v1/brand/${brandId}/product/`, {
      headers: {
        'authorization': `${token}`
      }
    })
      .catch((err) => {
        console.log(err);
      });
    setProductList(response.data.map((dat) => dat.name));
    setProductListWithIdName(response.data.map(dat=>dat))
    setCheckedState(new Array(response.data.length).fill(false))
    setProductIdList(response.data.map(dat=>dat.sku))
  };
  useEffect(() => {
    getCategories();
    getProducts();
  }, []);
  const productClickHandler = () => {
    setRangeState(false)
    setProductState(!productState)
    setState(false)
    if (localStorage.getItem("allProductsChecked") === null) {
      localStorage.setItem("allProductsChecked", isCheck)
    }
  };
  const handleOnChange1 = (e) => {
    setIsChecked(!isChecked);
    localStorage.setItem("allCategoriesChecked", !isChecked)
    if (e.target.checked === false) {
      localStorage.setItem("clear_singleSelect", e.target.checked)
      localStorage.setItem("allcategoryId", '')
    } else {
      localStorage.setItem("clear_singleSelect", true)
      localStorage.setItem('allcategoryId', e.target.value)
    }
  };
  if (document.getElementById("allCategoryChecked") !== null) {
    if ((handleOnChange1 && isChecked === true) || (localStorage.getItem("allCategoriesChecked") === 'true')) {
      let checkedAllCategoryState = new Array(categoryList.length).fill(true)
      localStorage.setItem("checkedCategoryIndividually", checkedAllCategoryState)
      // localStorage.setItem('allcategoryId', categoryIdList)
    } else if ((handleOnChange1 && isChecked === false) || document.getElementById("allCategoryChecked").checked === false) {
      let checkedAllCategoryState = new Array(categoryList.length).fill(false)
      localStorage.setItem("checkedCategoryIndividually", checkedCategoryState)
    }
  }

  const handleOnChange = (e) => {
    setIsCheck(!isCheck);
    localStorage.setItem("allProductsChecked", !isCheck)
    if (e.target.checked === false){
      localStorage.setItem('allProductId','')
    }else{
      localStorage.setItem('allProductId',e.target.value)
    }
  };
  if ((handleOnChange && isCheck === true) || (localStorage.getItem("allProductsChecked") === 'true')) {
    let checkedAllProducts = new Array(productList.length).fill(true)
    localStorage.setItem("checkedProductIndividually", checkedAllProducts)
  } else if ((handleOnChange && isCheck === false) || (localStorage.getItem("allProductsChecked") === 'false')) {
    localStorage.setItem("checkedProductIndividually", checkedState)
  }

  const handleChange = (position) => {
    const updatedCheckedState = checkedState.map((item, index) =>
      index === position ? !item : item
    );
    setCheckedState(updatedCheckedState);
    setSingleCheck(!singleCheck)
    localStorage.setItem("checkedProductIndividually", updatedCheckedState)
  };
  const handleCategoryChange = (position) => {
    const updatedCheckedState = checkedCategoryState.map((item, index) =>
      index === position ? !item : item
    );
    setCheckedCategoryState(updatedCheckedState);
    setSingleCheck(!singleCheck)
    localStorage.setItem("checkedCategoryIndividually", updatedCheckedState)
  };

  let checkedStates = localStorage.getItem("checkedCategoryIndividually")
  let checkedCategoryStates1
  if (checkedCategoryState !== undefined) {
    if (checkedStates !== null) {
      checkedCategoryStates1 = checkedStates.split(',')
    } else {
      localStorage.setItem("checkedCategoryIndividually", checkedCategoryState)
    }
    for (let index = 0; index < checkedCategoryStates1.length; index++) {
      let list = []
      if ((checkedCategoryStates1[index]) === 'true') {
        // console.log(categoryList[index])
        if (categoryList1.includes(categoryList1[index]) === false) {
          list.push([categoryListAfterCheck[index]])
        }
        categoryList1.push(list)
      }
    }
  }
  if (categoryList1.includes(undefined) || categoryList1.length === 0) {
    categoryState = true
  } else {
    categoryState = false
  }

  let checkedIndividualProduct = localStorage.getItem("checkedProductIndividually")
  let checkedProductIndividually1

  if (checkedState !== undefined) {
    if (checkedIndividualProduct !== null) {
      checkedProductIndividually1 = checkedIndividualProduct.split(',')
    } else {
      localStorage.setItem("checkedProductIndividually", checkedState)
    }
    for (let index = 0; index < checkedProductIndividually1.length; index++) {
      let list = []
      if (checkedProductIndividually1[index] === 'true') {
        console.log(productList[index])
        if (productList1.includes(productList[index]) === false) {
          list.push([productList[index]])
        }
        productList1.push(list)
      }
    }
  }
  if (productList1.includes(undefined) || productList1.length === 0) {
    productCheckedState = true
  } else {
    productCheckedState = false
  }

  let allCategoriesChecked
  if (localStorage.getItem("allCategoriesChecked") !== null) {
    allCategoriesChecked = localStorage.getItem("allCategoriesChecked")
  }

  let allProductsChecked
  if (localStorage.getItem("allProductsChecked") !== null) {
    allProductsChecked = localStorage.getItem("allProductsChecked")
  }


  let handleCount = 0
  const clickedcheck = document.getElementById("allCategoryChecked")

  if (clickedcheck !== null) {
    clickedcheck.addEventListener('click', (e) => {
      if (e.target.checked === false) {
        localStorage.setItem("clear_singleSelect", e.target.checked)
        localStorage.setItem("allcategoryId", '')
      } else {
        localStorage.setItem("clear_singleSelect", true)
        localStorage.setItem('allcategoryId', e.target.value)
      }
    })
  }

  let categoryIdStates = []
  if (checkedCategoryStates1 !== undefined && checkedCategoryStates1 !== null) {
    checkedCategoryStates1.map((d, index) => {
      if (d === 'true') {
        categoryIdStates.push(categoryIdList[index])
      }
    })
  }
  localStorage.setItem('allcategoryId', categoryIdStates)


  if (document.getElementById('allProductsChecked')){
    document.getElementById('allProductsChecked').addEventListener('click',(e)=>{
      if (e.target.checked === true){
        localStorage.setItem('allProductId',e.target.value)
      }else if(e.target.checked === false) {
        localStorage.setItem('allProductId','')
      }
    })
  }
  let productIdStates = []
  if (checkedProductIndividually1 !== undefined && checkedProductIndividually1 !== null) {
    checkedProductIndividually1.map((d, index) => {
      if (d === 'true') {
        productIdStates.push(productIdList[index])
      }
    })
  }
  localStorage.setItem('allProductId', productIdStates)

  const catDivClickHandler = () => {
    if (state === true){
      setState(false)
    }else if (productState === true){
      setProductState(false)
    }else if (rangeState === true){
      setRangeState(false)
    }
  }



  return (
    <div className="mainCategoryPage"  onClick={catDivClickHandler}>
      <div className="catData" >
        <div className="home">
          Home {forward}
          <p>Product</p>
        </div>
        <div className="sample" >
          <div>
            <h2>
              <b>Product</b>
            </h2>
          </div>
          <div ></div>
          <div className="category">
            <div className="first">
              <p className="catSection1" onClick={clickHandler}>
                Category
              </p>
              {state ? (
                <div className="mainCategory1">
                  <input
                    className="search"
                    type="text"
                    placeholder="search"
                    value={categorySearch}
                    onChange={(e) => setCategorySearch(e.target.value)}
                  ></input>
                  <div className="cList">
                    <input
                      id="allCategoryChecked"
                      type="checkbox"
                      checked={Boolean(JSON.parse(allCategoriesChecked))}
                      onChange={handleOnChange1}
                      value={categoryIdList}
                    ></input>
                    <p>All</p>
                  </div>
                  {categoryList.filter((d) => d.categoryName.toLowerCase().includes(categorySearch.toLowerCase())).map((d, index) => {
                    return (
                      <div className="categoryList" id={index} key={index}>
                        <input
                          id={`singleCategory${index}`}
                          type="checkbox"
                          checked={checkedCategoryState[index] || Boolean(JSON.parse(checkedCategoryStates1[index]))}
                          onChange={() => handleCategoryChange(index)}
                          value={d.categoryId}
                        ></input>
                        <p>{d.categoryName}</p>
                      </div>
                    );
                  })}
                </div>
              ) : null}
            </div>
            <div className="second">
              <p className="catSection2" onClick={productClickHandler}>
                Product
              </p>
              {productState ? (
                <div className="mainCategory2">
                  <input
                    className="search"
                    type="text"
                    placeholder="search"
                    value={productSearch}
                    onChange={(e) => setProductSearch(e.target.value)}
                  ></input>
                  <div className="pList">
                    <input
                      id="allProductsChecked"
                      type="checkbox"
                      checked={Boolean(JSON.parse(allProductsChecked))}
                      onChange={handleOnChange}
                      value = {productIdList}
                    ></input>
                    <p>All</p>
                  </div>
                  <div className="totalList">
                    {productListWithIdName.filter((d) => d.name.toLowerCase().includes(productSearch.toLowerCase())).map((d, index) => {
                      return (
                        <div className="productList" id={"product" + index} key={index}>
                          <input
                            type="checkbox"
                            checked={checkedState[index] || Boolean(JSON.parse(checkedProductIndividually1[index]))}
                            onChange={() => handleChange(index)}
                            value={d.sku}
                          ></input>
                          <p>{d.name}</p>
                        </div>
                      );
                    })}
                  </div>
                </div>
              ) : null}
            </div>
            <div className="DateRange">
              <DateRangePicker>
                <button id="date" onClick={changeDateHandler}>
                  {moment(startDate).format("LL")} to  {moment(endDate).format("LL")}
                </button>
              </DateRangePicker>
              {
                rangeState ?
                  <div className="ranges1">
                    <div>
                      <b>
                        Date Presets
                      </b>
                    </div>
                    <div onClick={todayclick}>
                      Today
                    </div>
                    <div onClick={sevenClick}>
                      Last 7 days
                    </div>
                    <div onClick={halfMonthClick}>
                      Last 15 days
                    </div>
                    <div onClick={monthClick}>
                      Last 30 days
                    </div>
                    <div onClick={threeMonthClick}>
                      Last 3 months
                    </div>
                    <div onClick={sixtyDaysClick}>
                      Last 6 months
                    </div>
                    <div onClick={yearClick}>
                      Last year
                    </div>
                  </div>
                  : ""
              }
            </div>
          </div>
        </div>
      </div>
      <ProductCollections brandId={props.brandId}
        startDate={startDate}
        endDate={endDate} categoryState={categoryState}
        productState={productCheckedState} categoryCheckedList={categoryList1}
        productCheckedList={productList1}  allChecked={isChecked}
        allProductsChecked = {isCheck}
        singleCheck={singleCheck}/>
        

    </div>
  );
};

export default ProductDetails;
