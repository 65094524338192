import "./RatingsTopWords.css"
import React, { useEffect, useState } from 'react'
import axios from "axios";
import RatingsAndReviewWordCloud from "./RatingsAndReviewWordCloud/RatingsAndReviewWordCloud";
import stateBackgroundBlurImage from "../../images/planLock.png"
import { useSearchParams } from "react-router-dom";
import RatingUserFeedback from "../RatingUserFeedbacks/RatingUserFeedback";
import DataLoader from "../../images/noDataFull.svg";
import { Skeleton } from "antd";
import checkEnv from "../../../utils/checkEnv";
export default function RatingsWordsOfBrands(props) {
    const [noDataLoad,setNoDataLoad] = useState(false)
    const [searchParams, setSearchParams] = useSearchParams()
    const [nextState, setNextState] = useState(false)
    let brandId = props.brandId
    let token = localStorage.getItem("token")
    const [positiveKeywords, setPositiveKeyWords] = useState([])
    const [negativeKeywords, setNegativeKeyWords] = useState([])
    const [overAllDivision, setOverAllDivision] = useState([])
    let startDate = localStorage.getItem("start_date");
    let endDate = localStorage.getItem("end_date");
    let url=checkEnv()
    let categoryId = localStorage.getItem('allcategoryId') ? (localStorage.getItem('allcategoryId').split(',')) : []
    let productId = localStorage.getItem('allProductId') ? localStorage.getItem('allProductId').split(',') : []

    const getTopKeyWords = async () => {
        setPositiveKeyWords([])
        const response = await axios.get(`${url}/brandsdashboardproxy/v1/brand/${searchParams.get('brand')}/topkeywords/?startDate=${startDate}&endDate=${endDate}&categoryIds=${searchParams.get('categoryIds')}&productIds=${searchParams.get('productIds')}`, {
            headers: {
                'authorization': `${token}`
            }
        })
            .catch((err) => {
                console.log(err);
            });
        setPositiveKeyWords(response.data.top_positive_keywords.map(d => d))
        setNegativeKeyWords(response.data.top_negative_keywords.map(d => d))
        setOverAllDivision(response.data.overall_division)
        response.data.top_positive_keywords.length ===0 ? setNoDataLoad(true) : setNoDataLoad(false)
    };
    useEffect(() => {
        getTopKeyWords();
    }, [searchParams])

    let positiveColors = ["rgb(1, 33, 101)", "rgb(2, 53, 126)", "rgb(3, 99, 169)", "rgb(4, 125, 189)", "rgb(4, 127, 191)"]
    let negativeColors = ["rgb(250, 187, 41)", "rgb(254, 130, 81)", "rgb(254, 123, 88)", "rgb(254, 122, 88)", "rgb(254, 122, 98)"]


    // positiveKeywords.sort((a, b) => {
    //     return b.percentage - a.percentage;
    // });
    // negativeKeywords.sort((a, b) => {
    //     return b.percentage - a.percentage;
    // });

    useEffect(() => {
        if (localStorage.getItem('plan') !== null && localStorage.getItem('plan') === 'Free' && document.getElementsByClassName("topWordsSection") !== undefined && document.getElementsByClassName("userWordsPlanState")) {
            document.getElementsByClassName("topWordsSection")[0].classList.add('plan')
            document.getElementsByClassName("userWordsPlanState")[0].style.display = "flex"
            document.getElementsByClassName("userWordsPlanState")[0].style.border = "2px solid #FFCBC4"
        }
    }, [])

    useEffect(() => {
        if (props.nextState === true && positiveKeywords.length !== 0 && negativeKeywords.length !== 0 && overAllDivision.length !== 0) {
            setNextState(true)
        } else {
            setNextState(false)
        }
    }, [props.nextState, positiveKeywords, negativeKeywords, overAllDivision])

    return (
        <div>
            <div className="topWordsSection">
            <div className="topHeader">
                    <div className="topWordsHeader">
                        Users Sentiment for your Brand
                    </div>
                    <div className="topPositiveHeader">
                        <div className="topKeyWordsHead">
                            Top positive Feedback
                        </div>
                        <div className="topPhrases">
                            Analysis provided by likely promoters
                        </div>
                    </div>
                </div>
                    {/* overAllDivision.length !== 0 && !== 0 && negativeKeywords.length !== 0 */}
                {
                     positiveKeywords.length !==0 ?
                        <>
                            <div className="topWordsMiddle">
                                <div className="topWordsWordCloud">
                                    <RatingsAndReviewWordCloud brandId={props.brandId} allChecked={props.allChecked}
                                        allProductsChecked={props.allProductsChecked}
                                        singleCheck={props.singleCheck} singleProductCheck={props.singleProductCheck} nextState={props.nextState} />
                                </div>
                                <div className="topPositiveWords">

                                    <div className="topKeyWordsSection">
                                        {
                                            positiveKeywords.map((d, index) => {
                                                if (index <= 4) {
                                                    return <div className="positiveKeyWords" key={index}>
                                                        <div className="keyWord">{d.keyword}
                                                        </div>
                                                        <div className="keyWordsPercentage" style={{ color: positiveColors[index] }}>
                                                            {d.percentage !== undefined ? (d.percentage).toFixed(2) : ""}%
                                                        </div>
                                                    </div>
                                                }
                                            })}
                                    </div>
                                </div>
                                {/* <div className="topWordsOverAllDivision">
                                    <div className="overAllDivisions">
                                        <div className="overAllHeading">
                                            Overall Division
                                        </div>
                                    </div>
                                    <div className="overAllDivisions">
                                        <div className="overAllType">
                                            Positive
                                        </div>
                                        <div className="overAllPercentage">
                                            <div className="overAllColorBox1">

                                            </div>
                                            <div className="overAllValue">
                                                {overAllDivision.positive !== undefined ? (overAllDivision.positive).toFixed(2) : ""}%
                                            </div>
                                        </div>
                                    </div>
                                    <div className="overAllDivisions">
                                        <div className="overAllType">
                                            Neutrals
                                        </div>
                                        <div className="overAllPercentage">
                                            <div className="overAllColorBox2">

                                            </div>
                                            <div className="overAllValue">

                                                {overAllDivision.neutral !== undefined ? (overAllDivision.neutral).toFixed(2) : ""}%
                                            </div>
                                        </div>
                                    </div>
                                    <div className="overAllDivisions">
                                        <div className="overAllType">
                                            Negatives
                                        </div>
                                        <div className="overAllPercentage">
                                            <div className="overAllColorBox3">

                                            </div>
                                            <div className="overAllValue">
                                                {overAllDivision.negative !== undefined ? (overAllDivision.negative).toFixed(2) : ""}%
                                            </div>
                                        </div>
                                    </div>
                                </div> */}



                            </div>
                            {/* <div className="topWordsBottom">
                                <div className="topPositiveWords">
                                    <div className="topPositiveHeader">
                                        <div className="topKeyWordsHead">
                                            Top 5 Positive Keywords
                                        </div>
                                        <div className="topPhrases">
                                            Phrases used by your likely promoters
                                        </div>
                                    </div>
                                    <div className="topKeyWordsSection">
                                        {
                                            positiveKeywords.map((d, index) => {
                                                if (index <= 4) {
                                                    return <div className="positiveKeyWords" key={index}>
                                                        <div className="keyWord">{d.keyword}
                                                        </div>
                                                        <div className="keyWordsPercentage" style={{ color: positiveColors[index] }}>
                                                            {d.percentage !== undefined ? (d.percentage).toFixed(2) : ""}%
                                                        </div>
                                                    </div>
                                                }
                                            })}
                                    </div>
                                </div>
                                <div className="topNegativeWords">
                                    <div className="topNegativeheader">
                                        <div className="topKeyWordsHead">
                                            Top 5 Negative Keywords
                                        </div>
                                        <div className="topPhrases">
                                            Phrases used by your likely detractors
                                        </div>
                                    </div>
                                    <div className="topKeyWordsSection">
                                        {
                                            negativeKeywords.map((d, index) => {
                                                if (index <= 4) {
                                                    return <div className="negativeKeyWords" key={index}>
                                                        <div className="keyWord">{d.keyword}
                                                        </div>
                                                        <div className="keyWordsPercentage" style={{ color: negativeColors[index] }}>
                                                            {d.percentage !== undefined ? (d.percentage).toFixed(2) : ""}%
                                                        </div>
                                                    </div>
                                                }
                                            })}
                                    </div>

                                </div>

                            </div> */}

                        </>
                        :
                        <div className="brandsVsCategorySkeleton">
                        {
                                noDataLoad !== true ?
                                    <Skeleton
                                        active
                                        title
                                        paragraph={{
                                            rows: 10,
                                        }} />
                                    :
                                    <img className="singleNoDataImage" src={DataLoader} alt='image data empty' />
                            }
                        </div>}
            </div>
            <div className="userWordsPlanState">
                <img src={stateBackgroundBlurImage} alt="blur image" />
                <div className="notHaveAccess">
                    You don’t have access to this data.
                </div>
                <div className="upgradeMemberShip">
                    Upgrade your membership to unlock
                </div>
                <button className="upgradeButton">
                    Upgrade
                </button>
            </div>

            <RatingUserFeedback brandId={props.brandId} allChecked={props.allChecked}
                allProductsChecked={props.allProductsChecked}
                singleCheck={props.singleCheck} singleProductCheck={props.singleProductCheck} nextState={nextState} />
        </div>
    )
}
