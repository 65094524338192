import React from 'react'
import { Skeleton } from 'antd'
export default function MetrixSkeletonLoading() {
    return (
        <div className="box">
            <div className="dataCategory">
                <Skeleton active paragraph={{rows:1}}/>
            </div>
            <div className="value">
                <div className="det">
                    {/* <Skeleton /> */}
                    {/* <p className="percentage3"><Skeleton /></p> */}
                </div>
                <div className="graph">
                    {/* <Skeleton /> */}
                </div>
            </div>
        </div>
    )
}
