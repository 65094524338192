import React from 'react'
import UsersTriedInSpecificState from './UsersTriedInSpecificState';
import "./StateHeatMaps.css"



export default function StateHeatMaps(props) {
  return (
    <UsersTriedInSpecificState brandId={props.brandId} startDate={props.startDate} endDate={props.endDate} allChecked={props.allChecked}
      allProductsChecked={props.allProductsChecked}
      singleCheck={props.singleCheck} singleProductCheck={props.singleProductCheck} nextState={props.nextState}/>
  )
}
