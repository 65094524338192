import React from 'react'
import StandardTemplate from "../../StandardTemplate.js"
import CreateNew from './CreateNew.js'
import "./CreateNewHeader.css"
export default function CreateNewHeader() {
  return (
    <div>
        <StandardTemplate />
        <div className='component'>
            <CreateNew/>
        </div>
    </div>
  )
}
