import React from 'react';
import maleLogo from "../../images/maleUserPersona.svg"
import femaleLogo from "../../images/femaleUserPersona.svg"

export default function UserCard({data,type}) {
    // console.log(data)
  return (
    <div className='userPersonaCarouselItem'>
        <div className='carouselCardItemContainer' style={{ background:  '#F9FDFF' }}>
            <div className='userPersonaCardLeft'>
                <img alt='' src={maleLogo} width={64} height={64}/>
                <div className='userPersonaGender'>
                    {`Male User`}
                </div>
                <div className='age-container'>
                    <span className='userPersonaAgeHeader'>
                        Age {data?.male?.age_bracket}
                    </span>
                    {/* <span className='userPersonaAgeValue'>
                        
                    </span> */}
                </div>
                <div className='high-affinity-age'>
                    <span>High Affinity Age Group</span>
                </div>
                {/* <div className='quote'>
                    <span>“I love to buy travel accessories.”</span>
                </div> */}
                {/* <div>
                    <span className='userPersonaAgeHeader'>
                        Location
                    </span>
                    <span className='userPersonaAgeValue'>
                        {data?.first?.location}
                    </span>
                </div> */}
            </div>
            <div className='userPersonaCardRight'>
                <div className='userPersonaCategoryIntrestedHeader'>
                    Categories Interested in
                </div>
                <div className='userPersonaCategoryIntrestedData'>
                    { type==="all"?
                        data.male_categories?.length > 0 && data?.male_categories?.slice(0,10).map((d, index) => {
                            return <span key={index} style={{ background: '#ECF8FF',  }}>
                                {d}
                            </span>
                        }):
                        data.male_categories.length > 0 && data?.male_categories.slice(0,10).map((d, index) => {
                            return <span key={index} style={{ background: '#ECF8FF',}}>
                                {d}
                            </span>
                        })
                    }
                </div>
                {/* <div className='userPersonaCategoryIntrestedHeader'>
                    Frequently purchased
                </div>
                <div className='userPersonaCategoryIntrestedData'>
                    {
                        data?.first?.frequently_used.length > 0 && data?.first?.frequently_used.map((d, index) => {
                            return <span key={index} style={{ background: data.first.gender === 'male' ? '#ECF8FF' : '#FFF1FE', color: data.first.gender === 'male' ? '#003087' : '#98348F' }}>
                                {d}
                            </span>
                        })
                    }
                </div> */}
            </div>
        </div>
        {
            data?.female?
            <div className='carouselCardItemContainer' style={{ background:'white' }}>
                <div className='userPersonaCardLeft'>
                    <img
                        alt='' src={femaleLogo} width={64} height={64}
                    />
                    <div className='userPersonaGender'>
                        {`Female User`}
                    </div>
                    <div>
                        <span className='userPersonaAgeHeader'>
                            Age {data?.female?.age_bracket}
                        </span>
                        {/* <span className='userPersonaAgeValue'>
                            
                        </span> */}
                    </div>
                    <div className='high-affinity-age female' style={{backgroundColor:"#FFF1FE"}}>
                        <span>High Affinity Age Group</span>
                    </div>
                    {/* <div className='quote' >
                    <span style={{color:"#98348F"}}>“I love to buy travel accessories.”</span>
                    </div> */}
                    {/* <div>
                        <span className='userPersonaAgeHeader'>
                            Location
                        </span>
                        <span className='userPersonaAgeValue'>
                            {data?.second?.location}
                        </span>
                    </div> */}
                </div>
                <div className='userPersonaCardRight'>
                    <div className='userPersonaCategoryIntrestedHeader'>
                        Categories Interested in
                    </div>
                    <div className='userPersonaCategoryIntrestedData'>
                        {
                            type==="all"?
                            data?.female_categories?.length > 0 && data?.female_categories?.slice(0,10).map((d, index) => {
                                return <span key={index} style={{ background: '#FFF1FE',border:"1px solid #DEB5DB"}}>
                                    {d}
                                </span>
                            }):
                            data?.female_categories?.length > 0 && data?.female_categories?.slice(0,10).map((d, index) => {
                                return <span key={index} style={{ background: '#FFF1FE',border:"1px solid #DEB5DB"}}>
                                    {d}
                                </span>
                            })

                        }
                    </div>
                    {/* <div className='userPersonaCategoryIntrestedHeader'>
                        Frequently purchased
                    </div>
                    <div className='userPersonaCategoryIntrestedData'>
                        {
                            data?.second?.frequently_used.length > 0 && data?.second?.frequently_used.map((d, index) => {
                                return <span key={index} style={{ background: data.second.gender === 'male' ? '#ECF8FF' : '#FFF1FE', color: data.second.gender === 'male' ? '#003087' : '#98348F' }}>
                                    {d}
                                </span>
                            })
                        }
                    </div> */}
                </div>
            </div>
            : ''
        }
    
    </div>
    
  )
}
