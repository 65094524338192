import React, { useState, useEffect } from "react";
import Dashboard from "../../Dashboard";
import UserProfileArrow from "../../../components/images/userProfileArrow.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBars,
  faCircle,
  faCircleInfo,
} from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import { useNavigate } from "react-router";
import { useSearchParams } from "react-router-dom";
import SurveyStepper from ".";
import ChangePassword from "../../UserSignIn/ChangePassword/ChangePassword";
import coin from "../../images/singleCoin.svg";
import checkEnv, { checkSurveyEnv } from "../../../utils/checkEnv";
import Logo from "../../images/Logo.png";
import logoCoin from "../../images/Final_01_1.png";
import ConvertTemplatized1 from "../../modal/ConvertTemplatized1";

let currentId;

export default function ResearchWrapper() {
  const [searchParams, setSearchParams] = useSearchParams();
  const [open, setOpen] = useState(false);
  const [brands, setBrands] = useState([]);
  const [userState, setUserState] = useState(false);
  const [show, setShow] = useState(false);
  let startDate = searchParams.get("date")
    ? searchParams.get("date").split("to")
    : "";
  let token = localStorage.getItem("token");
  let userName = localStorage.getItem("userName");
  const [learnMore, setLearnMore] = useState(false);
  let url = checkEnv();
  let surveyUrl = checkSurveyEnv();
  const navigate = useNavigate();
  let search =
    searchParams.get("brand") !== "null" ? window.location.search : "";
  const getBrands = () => {
    axios
      .get(`${url}/brandsdashboardproxy/v1/brand/`, {
        headers: {
          authorization: `${token}`,
        },
      })
      .then((res) => {
        setBrands(res.data.map((dat) => dat));
      })
      .catch((err) => {
        getnewToken(window.location.pathname, search);
      });
  };
  const getnewToken = (path, search) => {
    axios
      .get(`${url}/brandsdashboardproxy/v1/auth/token`, {
        headers: {
          authorization: `${token}`,
        },
      })
      .then((res) => {
        console.log(res.status);
        localStorage.setItem("token", res.data.token);
      })
      .catch((err) => {
        localStorage.clear();
        localStorage.setItem("previousUrl", path);
        localStorage.setItem("search", search);
        localStorage.setItem("start_date", startDate[0]);
        localStorage.setItem("end_date", startDate[1]);
        navigate("/brandsdashboard");
      });
  };
  useEffect(() => {
    getBrands();
  }, []);
  const clickHandler = () => {
    setOpen(!open);
  };

  if (
    open === true &&
    document.querySelector(".section") !== null &&
    document.querySelector(".surveyStepper")
  ) {
    document.querySelector(".section").style.display = "none";
    document.querySelector(".surveyStepper").style.marginLeft = "0px";
  } else if (
    open === false &&
    document.querySelector(".section") &&
    document.querySelector(".surveyStepper") &&
    window.innerWidth > 1200
  ) {
    document.querySelector(".section").style.display = "block";
    document.querySelector(".surveyStepper").style.marginLeft = "200px";
  } else if (
    open === false &&
    document.querySelector(".section") &&
    document.querySelector(".surveyStepper") &&
    window.innerWidth <= 1200
  ) {
    document.querySelector(".section").style.display = "block";
    document.querySelector(".surveyStepper").style.marginLeft = "50px";
  }
  window.addEventListener("resize", function (event) {
    if (
      document.querySelector(".section") &&
      document.querySelector(".surveyStepper") &&
      window.innerWidth > 1200
    ) {
      document.querySelector(".section").style.display = "block";
      document.querySelector(".surveyStepper").style.marginLeft = "200px";
    } else if (
      document.querySelector(".section") &&
      document.querySelector(".surveyStepper") &&
      window.innerWidth <= 1200
    ) {
      document.querySelector(".section").style.display = "block";
      document.querySelector(".surveyStepper").style.marginLeft = "50px";
    }
  });

  const changeHandler = (e) => {
    currentId = e.target.value;
    localStorage.setItem("brand_id", currentId);
    navigate({
      pathname: window.location.pathname,
      search: `?brand=${e.target.value}&categoryIds=${searchParams.get(
        "categoryIds"
      )}&productIds=${searchParams.get("productIds")}&date=${searchParams.get(
        "date"
      )}`,
    });
  };

  const MakeItem = function (X) {
    return (
      <option key={X.brandId} value={X.brandId}>
        {X.brandName}
      </option>
    );
  };
  const userprofileClickHandler = () => {
    setUserState(!userState);
  };

  const logoutClickHandler = () => {
    navigate("/brandsdashboard");
    localStorage.clear();
  };
  const loginPopUpHideClickHandler = () => {
    if (userState === true) {
      setUserState(false);
    }
  };

  const changePasswordClickHandler = () => {
    setShow(true);
    setUserState(true);
  };

  const [creditBalance, setCreditBalance] = useState("");
  useEffect(() => {
    axios
      .get(
        `${surveyUrl}/smyttenresearch/brandcredits/${searchParams.get(
          "brand"
        )}`,
        {}
      )
      .then((res) => {
        setCreditBalance(res.data.credits);
      });
  }, [searchParams]);

  return (
    <div onClick={loginPopUpHideClickHandler}>
      <div className="header">
        <div className="header-left">
          {/* <FontAwesomeIcon
                        className="hamberger"
                        icon={faBars}
                        onClick={clickHandler}
                    /> */}
          <div className="header-left-left">
            <img className="logo-image" src={Logo} alt="logo" />
          </div>
          <div className="header-left-right">
            <div>
              <img className="logo-image1" src={logoCoin} alt="coin" />
            </div>
            <div className="remaining-balance-container">
              <span className="remaining-text">Remaining Balance:</span>
              <span className="remaining-balance">{creditBalance} Credits</span>
            </div>
          </div>
          <div className="learn-more">
            <FontAwesomeIcon icon={faCircleInfo} className="learn-more-icon" />
            <span
              className="learn-more-text"
              onClick={() => {
                setLearnMore(true);
              }}
            >
              Learn More
            </span>
          </div>
        </div>
        <div className="headerOne">
          {/* <div className="creditBalance">
                Research Credit Balance : {creditBalance} credits <img src={coin} className='coinImageHeader' alt='coin image'/>
                    </div> */}
          <div className="headerCenter">
            <div className="brandSelection">
              <select
                className="selectBrand"
                value={searchParams.get("brand")}
                onChange={(e) => {
                  changeHandler(e);
                }}
              >
                {brands.map(MakeItem)}
              </select>
            </div>
          </div>
          <div>
            <div className="user" onClick={userprofileClickHandler}>
              {/* <FontAwesomeIcon className="userProfile" icon={faCircle} /> */}
              <div className="userLetterProfile">
                {userName ? userName.slice(0, 1) : ""}
              </div>
              <img src={UserProfileArrow} alt="user" />
            </div>
            {userState ? (
              <div className="userLogoutProfile">
                <div onClick={changePasswordClickHandler}>Change Password</div>
                <div onClick={logoutClickHandler}>Logout</div>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
      <Dashboard />
      <SurveyStepper />
      <ChangePassword show={show} setShow={setShow} />
      <ConvertTemplatized1
        show={learnMore}
        onHide={() => setLearnMore(false)}
      />
    </div>
  );
}
