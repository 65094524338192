import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import DataLoader from "./images/noDataHalf.svg"
import DataLoaderFull from "./images/noDataFull.svg"
import { useSearchParams } from "react-router-dom";
import { Skeleton } from 'antd';
import MaleVsFemale from "./MaleVsFemale";
import Maps from "./Maps"
import checkEnv from "../utils/checkEnv";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const BrandVsCategory = (props) => {
  const [noDataLoad,setNoDataLoad] = useState(false)
  const [nextState, setNextState] = useState(false)
  const [stateNextState, setStateNextState] = useState(false)
  const [searchParams, setSearchParams] = useSearchParams()
  let token = localStorage.getItem("token")
  const brandId = props.brandId;
  let startDate = localStorage.getItem('start_date')
  let endDate = localStorage.getItem('end_date')
  let url=checkEnv()
  const [sample, setSample] = useState([]);
  const [labels, setLabels] = useState([])

  let categoryId = localStorage.getItem('allcategoryId') ? (localStorage.getItem('allcategoryId').split(',')) : []
  let productId = localStorage.getItem('allProductId') ? localStorage.getItem('allProductId').split(',') : []

  const getBrandsVsCategory = async () => {
    setSample([])
    const response = await axios.get(`${url}/brandsdashboardproxy/v1/brand/${searchParams.get('brand')}/usertriedage/?startDate=${startDate}&endDate=${endDate}&categoryIds=${searchParams.get('categoryIds')}&productIds=${searchParams.get('productIds')}`, {
      headers: {
        'authorization': `${token}`
      }
    })
      .catch((err) => {
        console.log(err);
      });
    setSample(response.data.map((dat) => dat));
    response.data.length ===0 ? setNoDataLoad(true) : setNoDataLoad(false)
    
    setLabels(response.data.map(dat => (dat.age_group).slice(0, 6)))
  };
  useEffect(() => {
    getBrandsVsCategory();
  }, [searchParams]);

  const data = {
    labels: labels,
    datasets: [
      {
        label: "Brand Average",
        data: sample.map((text) => text.brands_average),
        backgroundColor: "rgb(166, 206, 227)",
      },
    ],
  };
  useEffect(() => {
    if (props.nextState === true && sample.length !== 0) {
      setNextState(true)
    } else {
      setNextState(false)
    }
  }, [props.nextState, sample.length])


  console.log(props.noDataLoad)

  return (
    <>
      <div className="maps-container">
        <div className="maps-header"><span className="maps-header-text">User Demographics</span> <span className="maps-header-subtext">(Tried your product)</span></div>
        <div className='maps'>
        <div className="map1">
          <div className="brandVsCategoryHeading">Age Distribution</div>
          {
            sample.length !== 0 ?
              <div id="d3demo">
                <div className="brandVsCategoryBarGraph">
                  <div className="yAxes">
                    %Contribution
                  </div>
                  <Bar
                    data={data}
                    // width={90}
                    height={250}
                    options={{
                      barPercentage: 1,
                      plugins: {
                        tooltip: {
                          callbacks: {
                            label: function (context) {
                              return `${context.dataset.label} ${context.raw.toFixed(2)}%`
                            }
                          },
                        },
                        title: {
                          display: true,
                          text: "Age Group",
                          position: "bottom",
                          font: {
                            size: 14,
                            weight:400
                          }
                        },
                        legend: {
                          display: false,
                        },
                        datalabels: {
                          display: false,
                        },
                      },
                    }}
                  />
                </div>
                {/* <div className="chartFooter">
                  <div className="label">
                    <div className="labelBoxBrands"></div>
                    <div>Brands Average</div>
                  </div>
                </div> */}
                  {/* <div className="label">
                <div className="labelBoxCategories"></div>
                <div>Category Average</div>
              </div> */}
              </div>

              :
              <div className="brandsVsCategorySkeleton">
              {
                noDataLoad !== true ? 
                <Skeleton
                  active
                  title
                  paragraph={{
                    rows: 8,
                  }} />
                   :
                <img className="singleNoDataImage" src={DataLoader} alt='image data empty' />
              }
              </div>
          }
        </div>
        <MaleVsFemale brandId={props.brandId} startDate={props.startDate} endDate={props.endDate} allChecked={props.allChecked}
          allProductsChecked={props.allProductsChecked}
          singleCheck={props.singleCheck}
          singleProductCheck={props.singleProductCheck} nextState={nextState} setStateNextState={setStateNextState} />
      </div>
      </div>
      <Maps
        brandId={props.brandId}
        startDate={props.startDate}
        endDate={props.endDate}
        allChecked={props.allChecked}
        allProductsChecked={props.allProductsChecked}
        singleCheck={props.singleCheck}
        singleProductCheck={props.singleProductCheck}
        nextState={stateNextState}
      />
    </>
  );
};

export default BrandVsCategory;
