 export default function checkEnv(){
    if(process.env.NODE_ENV==="development"){
      
        return process.env.REACT_APP_API_BRANDS_LOCAL_BASE_URL
        
    }
    else if(process.env.NODE_ENV==="production"){
       
        return process.env.REACT_APP_API_BRANDS_PRODUCTION_BASE_URL
        
    }
}

export function checkSurveyEnv(){
    if(process.env.NODE_ENV==="development"){
      
        return process.env.REACT_APP_API_BRANDS_LOCAL_SURVEY_URL
        
    }
    else if(process.env.NODE_ENV==="production"){
       
        return process.env.REACT_APP_API_BRANDS_PRODUCTION_SURVEY_URL
        
    }
}