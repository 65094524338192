import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import './style.css';
import { useNavigate, useParams } from 'react-router';
import moment from 'moment';
import { Input } from 'antd';
import axios from 'axios';
import configData from '../../../../../config/config.json';
import { checkSurveyEnv } from '../../../../../utils/checkEnv';

export default function TestUserImport() {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    let base_url = configData.base_url;
    let surveyUrl=checkSurveyEnv()
    let { surveyId } = useParams();
    const [successToast, setSuccessToast] = useState(false);
    const [testExceededToast, setTestExceededToast] = useState(false);
    const [errorToast, setErrorToast] = useState(false);
    const [phoneNumber, setPhoneNumber] = useState('');
    const importUser = (e) => {

        e.preventDefault();


        axios.post(surveyUrl+ '/smyttenresearch/testusers/', {
            "survey_id": surveyId,
            "phone_numbers": [phoneNumber]
        })
            .then((res) => {
                setSuccessToast(true);
            })
            .catch((err) => {
                let errorStatus = err.response.status;
                if (errorStatus == 400)
                    setTestExceededToast(true);
                else
                    setErrorToast(true);
            })
            .finally(() => {
                setTimeout(() => {
                    setPhoneNumber('');
                    setSuccessToast(false);
                    setErrorToast(false);
                    setTestExceededToast(false);
                }, 1500)
            })
    }

    return (
        <>
            <div className='previewSubmitDoneSection'>
                <button className='previewDoneButton' onClick={handleShow}>
                    Run Demo Survey
                </button>
            </div>
            <div className='tui'>
                <Modal centered show={show} onHide={handleClose}>
                    <Modal.Header className='tui-modalHeader' closeButton>
                        <Modal.Title className='tui-modalTitle'>Add your Details</Modal.Title>
                    </Modal.Header>

                    <form className='tui-main' onSubmit={importUser}>
                        <div className='tui-subHeading'>Have a look at how your survey would appear to your customers. This would be visible to you only for 2 hours.</div>
                        <label style={{ fontSize: "14px" }}>
                            Phone Number: <Input value={phoneNumber} onChange={(e) => setPhoneNumber(e.target.value)} id='tui-phoneNumber' required />

                        </label>
                  
                        {successToast ? <div className='tui-toast'>Test User Imported</div> : null}
                        {testExceededToast ? <div className='tui-toast tui-errorToast'>Exceeded 5 Tests</div> : null}
                        {errorToast ? <div className='tui-toast tui-errorToast'>An error occurred. Please try again!</div> : null}
                        <input type={'submit'} className='tui-submit' />
                    </form>

                </Modal>
            </div>
        </>

    )

    return (
        <>


        </>
    )
}




// import React, { useState } from 'react'
// import Button from 'react-bootstrap/Button';
// import Modal from 'react-bootstrap/Modal';
// import tick from '../images/rnAckTick.svg';
// import goToDashboard from '../images/goToDashboard.svg';
// import './style.css'
// import { useNavigate, useParams } from 'react-router';
// import moment from 'moment';

// import { useSearchParams } from 'react-router-dom';

// import axios from 'axios';


// export default function ResearchPageSubmitted(props) {
//   const [searchParams,setSearchParams] = useSearchParams()
//   const [show, setShow] = useState(false);
//   const handleClose = () => setShow(false);
//   const handleShow = () => setShow(true);
//   const navigate = useNavigate();
//   let startDate = localStorage.getItem('start_date') ? localStorage.getItem('start_date') : localStorage.setItem("start_date", moment().subtract(6, 'days').format('YYYY-MM-DD'))
//   let endDate = localStorage.getItem('end_date') ? localStorage.getItem('end_date') : localStorage.setItem("end_date", moment().format("YYYY-MM-DD"))
//   let { surveyId } = useParams();

//   const navigateToDashboard = () => {

//     axios.put(`https://ancillary-prod.smytten.com/smyttenresearch/survey/${surveyId}`, {
//       "status": "PENDING_APPROVAL"
//     })
//       .then(() => {
//         navigate({
//       pathname: `/brandsdashboard/research`,
//       search: `?brand=${localStorage.getItem("brand_id")}&categoryIds=${searchParams.get('categoryIds')}&productIds=${searchParams.get('productIds')}&date=${searchParams.get('date')}`
//       })
//       })
//       .catch(() => {
// navigate({
//       pathname: `/brandsdashboard/research`,
//       search: `?brand=${localStorage.getItem("brand_id")}&categoryIds=${searchParams.get('categoryIds')}&productIds=${searchParams.get('productIds')}&date=${searchParams.get('date')}`
//         })
//       })

//   }

//   return (
//     <>
//       {/* <Button variant="primary" >
//         DONE
//       </Button> */}
//       <div className='previewSubmitDoneSection'>
//         <button className='previewDoneButton' onClick={handleShow}>
//           Done
//         </button>
//       </div>

//       <Modal size='lg' show={show} onHide={handleClose}>
//         <div className='rn-ack'>
//           <div className='rn-ack-head'>
//             Your Survey has been Submitted. It will take 48 hours for it to be approved and ready for publication.
//           </div>
//           <div className='rn-ack-head2'>
//             WeLorem ipsum dolor sit amet, consectetur adipiscing elit. Amet nulla augue nascetur.
//           </div>
//           <div className='rn-ack-tick'>
//             <img src={tick} />
//           </div>
//           <div className='rn-ack-action'><img src={goToDashboard} onClick={navigateToDashboard} /></div>
//         </div>
//       </Modal>
//     </>
//   )
// }

