import React, { useState } from 'react'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import './style.css'
import { useNavigate } from 'react-router';
import { Dropdown } from 'react-bootstrap';
import moment from 'moment';
import axios from 'axios';
import configData from '../../../../config/config.json';
import deleteBinIcon from "../../../images/deleteBinIcon.svg";
import { checkSurveyEnv } from '../../../../utils/checkEnv';

export default function DeleteModal(props) {
    const base_url = configData.base_url;
    const surveyId = props.surveyId;
    const questionId = props.questionId;
    const sectionId = props.sectionId;
    const fetchSurveyList = props.fetchSurveyList;
    const getSurveyDetails = props.getSurveyDetails;
    const whatToDelete = props.whatToDelete;

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const navigate = useNavigate();
    let surveyUrl=checkSurveyEnv()

    let startDate = localStorage.getItem('start_date') ? localStorage.getItem('start_date') : localStorage.setItem("start_date", moment().subtract(6, 'days').format('YYYY-MM-DD'))
    let endDate = localStorage.getItem('end_date') ? localStorage.getItem('end_date') : localStorage.setItem("end_date", moment().format("YYYY-MM-DD"))


    const navigateToDashboard = () => {
        navigate({
            pathname: `/brandsdashboard/research`,
            search: `?brand=${localStorage.getItem("brand_id")}&categoryIds=${localStorage.getItem('allcategoryId') ? (localStorage.getItem('allcategoryId').split(',')) : []}&productIds=${localStorage.getItem('allProductId') ? localStorage.getItem('allProductId').split(',') : []}&date=${startDate}to${endDate}`
        })
    }
    const deleteFunction = () => {
        if (whatToDelete == 'survey') {
            axios.delete(surveyUrl + `/smyttenresearch/survey/${surveyId}`)
                .then(() => {
                    fetchSurveyList();
                    handleClose();
                })
                .catch(() => {
                    fetchSurveyList();
                    handleClose();
                })
        }
        else if (whatToDelete == 'question') {
            axios.delete(surveyUrl + `/smyttenresearch/question/${questionId}`)
                .finally(() => {
                    getSurveyDetails();
                    handleClose();
                })
        }
        else if (whatToDelete == 'section') {
            axios.delete(surveyUrl + `/smyttenresearch/surveysection/${sectionId}`)
                .finally(() => {
                    getSurveyDetails();
                    handleClose();
                })
        }
    }

    const getSuffix = () => {
        if (whatToDelete == 'question')
            return 'question?';
        else if (whatToDelete == 'section')
            return 'section?';
        else if (whatToDelete == 'survey')
            return 'survey?';
    }

    return (
        <>
            {
                whatToDelete == 'question'
                    ?
                    <img src={deleteBinIcon} style={{ cursor: "pointer" }} onClick={handleShow} />
                    :
                    <Dropdown.Item className='optionDropdown-menuItem' onClick={handleShow}>
                        Delete
                    </Dropdown.Item>
            }
            <Modal show={show} onHide={handleClose} centered>
                <div className='deleteSurveyModal'>
                    <div className='deleteSurveyModal-prompt'>Are you sure you want to delete this {getSuffix()}</div>
                    <div style={{ width: "60%", display: "flex", justifyContent: "space-between" }}><div className='saveQuestion' style={{ display: "flex", alignItems: "center", cursor: "pointer" }} onClick={deleteFunction}>Yes</div><div className='questionBox-cancel' style={{ display: "flex", alignItems: "center", cursor: "pointer" }} onClick={handleClose}>No</div></div>
                </div>
            </Modal>
        </>
    )
}
