import React, { useEffect, useState } from 'react'
//  styles written in CreateNew.css
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faXmark } from '@fortawesome/free-solid-svg-icons'
import ImageUpload from './media_uploads/ImageUpload'
import CreateNew from './CreateNew'




export default function CreateSurvey({obj,setAnswers}) {
    const [value,setValue]=useState('')
    const [multiple,setMultiple]=useState([])
   
    useEffect(()=>{
        if(obj.type==="MULTIPLE_TEXT"){
            setAnswers(prev=>{
                let copy={...prev}
                copy[obj.name]=[...multiple]
                return copy
            })
        }else{
            setAnswers(prev=>{
                let copy={...prev}
                copy[obj.name]=value
                return copy
            })
        }
        
    },[])

    useEffect(()=>{
        if(obj.type==="MULTIPLE_TEXT"){
            setAnswers(prev=>{
                let copy={...prev}
                copy[obj.name]=[...multiple]
                return copy
            })
        } 
    },[multiple])

    const onsubmitHandler=(e)=>{
        
        e.preventDefault()
        if(obj.type==="MULTIPLE_TEXT"){
            setMultiple([...multiple,value])
            setValue("")
        }
        else{
            setAnswers(prev=>{
                let copy={...prev}
                copy[obj.name]=value
                return copy
            })
        }
    }


    useEffect(()=>{
        if(obj.type==='SINGLE_TEXT'){
            setAnswers(prev=>{
                let copy={...prev}
                copy[obj.name]=value
                return copy
            })
        }
    },[value])

    const onChangeHandler=(e)=>{
        setValue(e.target.value)     
    }


    const deletefromlist=(i)=>{ 
        setMultiple([...multiple.slice(0,i),...multiple.slice(i+1)])
    }

    const renderMultipleOptions=()=>{
        return multiple.map((e,i)=>{
            return <li className='multiple-option' key={i}>
                <span>{e}</span>
                <button onClick={()=>{deletefromlist(i)}}><FontAwesomeIcon icon={faXmark}></FontAwesomeIcon></button>
            </li>
        })
    }

    if(obj.type==="SINGLE_IMAGE"||obj.type==="MULTI_IMAGE"||obj.type==="SINGLE_VIDEO"||obj.type==="MULTI_VIDEO"){
        return (<div className='inline'>
             <p className='body-bottom-header'>{obj.description}</p>
             <ImageUpload setAnswers={setAnswers} name={obj.name} id={obj.id} type={obj.type}/>
        </div>)
    }

  return (
    <div>
        <p className='body-bottom-header'>{obj.description}</p>
        <form onSubmit={(e)=>onsubmitHandler(e)}>
            <input placeholder={obj.dummy_text_examples?obj.dummy_text_examples:""} onChange={(e)=>{onChangeHandler(e)}} value={value} className='projectname'/>
            {/* <ImageUpload/> */}
        </form>
        <p className='notice'>please click enter after entering the value</p>
        {obj.type==="MULTIPLE_TEXT"&&<div className='multiple-options-list'>
            {renderMultipleOptions()}
        </div>}
    </div>
  )
}
