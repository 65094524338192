import { useEffect, useState } from "react";
import { Chart as ChartJs, Tooltip, Title, ArcElement, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";
import axios from "axios";
import "./RatingsGraphs.css"
import DataLoader from "../../images/dataload_gif.gif";
import { useSearchParams } from "react-router-dom";
import { Skeleton } from "antd"

ChartJs.register(ChartDataLabels, Tooltip, Title, ArcElement, Legend);

export const RatingsAndReviewDoughnutGraph = (props) => {
    const [searchParams, setSearchParams] = useSearchParams()
    let token = localStorage.getItem("token")
    // const [data1, setData1] = useState([])
    // const [data2, setData2] = useState([])
    // const [data3, setData3] = useState([])
    const data1 = props.totalDetrators
    const data2 = props.totalPassives
    const data3 = props.totalPromotors
    
    let datas = [data1, data2, data3]
    // console.log(datas)
    function sumArr(arr){
        return arr.reduce((e,a)=>{
            return e+a
        },0)
    }
   
    const promoters=Math.round(datas[2]/sumArr(datas)*100)
    const passives=Math.round(datas[1]/sumArr(datas)*100)
    const ditractors=100-(promoters+passives)
    const datas2=[ditractors,passives,promoters]
   

    function totalSum(total, datapoint) {
        return total + datapoint;
    }
    
    const totalValue = datas.reduce(totalSum, 0);
    // console.log(totalValue)
    const npsvalue=promoters-ditractors.toFixed(0)
    // console.log(datas)
    const hoverData = ['Detractors (0-6)', 'Passives (7-8)', 'Promoters (9-10)',]

    const data = {
        datasets: [
            {
                data: datas2,
                backgroundColor: [
                    // 'rgb(238, 80, 79)',
                    // 'rgb(255, 207, 97)',
                    // 'rgb(108, 222, 129)'
                    '#EC654E',
                    '#F8C43D',
                    '#7FCD31',
                ],
                cutout: "60%",
                borderWidth: 1.5,
                borderColor: "white",
            },
        ],
    };
    return (
        <div
            className="doughnut1"
            style={{
                width: "350px",
                
            }}
        >
            {/* <span>Net Promter Score Analysis</span> */}
            {
                props.nextState === true && data1.length !== 0 && data2.length !== 0 && data3.length !== 0 ?
                    <div className="mar">
                    <div className="doughnut_inside">
                        <Doughnut
                            data={data}
                            width={100}
                            options={{
                                layout: {
                                    padding: 10
                                },
                                plugins: {
                                    title: {
                                        display: true,
                                        text: "Net Promter Score Analysis",
                                        padding: {
                                            bottom: 50,
                                            left:50
                                        },
                                        weight: "bold",
                                        color: "black",
                                        font: {
                                            size: 18,
                                            weight:500
                                        },
                                        align:"start"
                                    },
                                    datalabels: {
                                        display: true,
                                        formatter: function (value, context) {
                                            // console.log(context)
                                            // const datapoints = context.chart.data.datasets[0].data;
                                            const datapoints=context.dataset.data
                                            function totalSum(total, datapoint) {
                                                return total + datapoint;
                                            }
                                            
                                            const totalValue = datapoints.reduce(totalSum, 0);
                                            
                                           
                                            const percentageValue = ((value / totalValue) * 100).toFixed(0);

                                            return `${percentageValue}%`;
                                        },
                                        color: [
                                            '#EC654E',
                                            '#F8C43D',
                                            '#7FCD31',
                                        ],
                                        align: "start",
                                        anchor: "start",
                                        padding: {
                                            left: -10,
                                            bottom: -10,
                                        },
                                        font: {
                                            weight: 900,
                                            size: 26
                                        },

                                        labels: {
                                            padding: { top: 10 },
                                            position: "outside",
                                            title: {
                                                font: {
                                                    weight: "bold",
                                                }
                                            },
                                        }
                                    },
                                    tooltip: {
                                        callbacks: {
                                            labelTextColor: (context) => {
                                                return 'black'
                                            },
                                            label: function (context) {
                                                // console.log(context)
                                                // let array = ["hi", "hello", "good", "not", "well", "cool"];
                                                for (let i in hoverData) {
                                                    let index = context.dataIndex
                                                    return `${hoverData[index]}  ${datas[index]}`;
                                                }
                                            },
                                        },
                                        padding: 10,
                                        backgroundColor: "white",
                                    },
                                },

                            }
                            }
                        />
                    </div>
                    <div className="bottom_part">
                        <div className="legendLabels">
                            <div className="legend">
                                <div className="ratingLabels">
                                    <div className="legendColor1">

                                    </div>
                                    <div>
                                        Detractors(0-6)
                                    </div>
                                </div>
                                <div className="ratingData">
                                    {/* {datas[0]} */}
                                </div>
                            </div>
                            <div className="legend">
                                <div className="ratingLabels">
                                    <div className="legendColor2">
                                    </div>
                                    <div>
                                        Passives(7-8)
                                    </div>
                                </div>
                                <div className="ratingData">
                                    {/* {datas[1]} */}
                                </div>
                            </div>
                            <div className="legend">
                                <div className="ratingLabels">
                                    <div className="legendColor3">

                                    </div>
                                    <div>
                                        Promoters(9-10)
                                    </div>
                                </div>
                                <div className="ratingData">
                                    {/* {datas[2]} */}
                                </div>
                            </div>
                        </div>
                        <div style={{marginTop:"2%"}}>
                            <h6>Net Promoter Score: {npsvalue}</h6>
                        </div>
                    </div>
                    </div>
                    : ""}
        </div>
    );
}
