import React, { useState, useEffect } from "react";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowUpShortWide,
  faArrowDownShortWide,
} from "@fortawesome/free-solid-svg-icons";
import { NavLink, useSearchParams, useNavigate } from "react-router-dom";
import upImage from "./images/up.svg";
import downImage from "./images/down.svg";
import { Skeleton } from "antd";
import DataLoader from "./images/noDataFull.svg";
import Dropdown from "react-bootstrap/Dropdown";
import rdSortUp from "./images/Group_right.svg";
import rdSortDown from "./images/Group_left.svg";
import Export from "./images/export.svg";
import checkEnv from "../utils/checkEnv";

const ProductWiseSummary = (props) => {
  const [noDataLoad, setNoDataLoad] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  let token = localStorage.getItem("token");
  const brandId = props.brandId;
  let startDate = localStorage.getItem("start_date");
  let endDate = localStorage.getItem("end_date");
  let url = checkEnv();
  const [productDetail, setProductDetail] = useState([]);
  const [sortState, setSortState] = useState({
    product_name: false,
    sub_category: false,
    engagement: false,
    samples_tried: false,
    conversion: false,
    add_to_cart: false,
  });

  let categoryId = localStorage.getItem("allcategoryId")
    ? localStorage.getItem("allcategoryId").split(",")
    : [];
  let productId = localStorage.getItem("allProductId")
    ? localStorage.getItem("allProductId").split(",")
    : [];
  const getProductDetails = async () => {
    setProductDetail([]);
    const response = await axios
      .get(
        `${url}/brandsdashboardproxy/v1/brand/${searchParams.get(
          "brand"
        )}/productwisesummary/?startDate=${startDate}&endDate=${endDate}&categoryIds=${searchParams.get(
          "categoryIds"
        )}&productIds=${searchParams.get("productIds")}`,
        {
          headers: {
            authorization: `${token}`,
          },
        }
      )
      .catch((err) => {
        console.log(err);
      });
    setProductDetail(response.data.map((dat) => dat));
    response.data.length === 0 ? setNoDataLoad(true) : setNoDataLoad(false);
  };
  useEffect(() => {
    getProductDetails();
  }, [searchParams]);

  const ascendingProductHandler = () => {
    productDetail.sort((a, b) => {
      if (a.product_name > b.product_name) {
        return 1;
      } else if (b.product_name > a.product_name) {
        return -1;
      } else {
        return 0;
      }
    });
  };
  const descendingProductHandler = () => {
    productDetail.sort((a, b) => {
      if (a.product_name > b.product_name) {
        return -1;
      } else if (b.product_name > a.product_name) {
        return 1;
      } else {
        return 0;
      }
    });
  };
  const ascendingSubCategoryHandler = () => {
    productDetail.sort((a, b) => {
      if (a.sub_category > b.sub_category) {
        return 1;
      } else if (b.sub_category > a.sub_category) {
        return -1;
      } else {
        return 0;
      }
    });
  };
  const descendingSubCategoryHandler = () => {
    productDetail.sort((a, b) => {
      if (a.sub_category > b.sub_category) {
        return -1;
      } else if (b.sub_category > a.sub_category) {
        return 1;
      } else {
        return 0;
      }
    });
  };
  const ascendingEngagementHandler = () => {
    productDetail.sort((a, b) => {
      return a.engagement - b.engagement;
    });
  };
  const descendingEngagementHandler = () => {
    productDetail.sort((a, b) => {
      return b.engagement - a.engagement;
    });
  };
  const ascendingConsiderationHandler = () => {
    productDetail.sort((a, b) => {
      return a.consideration - b.consideration;
    });
  };
  const descendingConsiderationHandler = () => {
    productDetail.sort((a, b) => {
      return b.consideration - a.consideration;
    });
  };
  const ascendingSamplesTriedHandler = () => {
    productDetail.sort((a, b) => {
      return a.samples_tried - b.samples_tried;
    });
  };
  const descendingSamplesTriedHandler = () => {
    productDetail.sort((a, b) => {
      return b.samples_tried - a.samples_tried;
    });
  };
  const ascendingConversionHandler = () => {
    productDetail.sort((a, b) => {
      return a.conversion - b.conversion;
    });
  };
  const descendingConversionHandler = () => {
    productDetail.sort((a, b) => {
      return b.conversion - a.conversion;
    });
  };

  const ascendingAddtoCartHandler = () => {
    productDetail.sort((a, b) => {
      return a.add_to_cart - b.add_to_cart;
    });
  };
  const descendingAddtoCartHandler = () => {
    productDetail.sort((a, b) => {
      return b.add_to_cart - a.add_to_cart;
    });
  };
  const ascendingIntentHandler = () => {
    productDetail.sort((a, b) => {
      if (a.intent > b.intent) {
        return 1;
      } else if (b.intent > a.intent) {
        return -1;
      } else {
        return 0;
      }
    });
  };
  const descendingIntentHandler = () => {
    productDetail.sort((a, b) => {
      if (a.intent > b.intent) {
        return -1;
      } else if (b.intent > a.intent) {
        return 1;
      } else {
        return 0;
      }
    });
  };
  function nFormatter(num) {
    if (num >= 1000000000) {
      return (num / 1000000000).toFixed(1).replace(/\.0$/, "") + "G";
    }
    if (num >= 1000000) {
      return (num / 1000000).toFixed(1).replace(/\.0$/, "") + "M";
    }
    if (num >= 1000) {
      return (num / 1000).toFixed(1).replace(/\.0$/, "") + "K";
    }
    return num;
  }
  const productLevelClickHandler = (e) => {
    const val1 =
      e.target.parentElement.firstElementChild.nextSibling.textContent.replaceAll(
        "&",
        "-"
      );
    navigate({
      pathname: `/brandsdashboard/ratings/:productDetails`,
      search: `?brand=${searchParams.get("brand")}&productId=${
        e.target.id
      }&productName=${val1}&categoryIds=${searchParams.get(
        "categoryIds"
      )}&productIds=${searchParams.get("productIds")}&date=${searchParams.get(
        "date"
      )}`,
    });
  };
  const makeDefaultState = (param, sortstate) => {
    let keys = Object.keys(sortstate).filter((key) => key !== param);
    keys.forEach((key) => {
      sortstate[key] = false;
    });
    return sortstate;
  };

  const sortSurveyList = (param, order) => {
    let sortStateCopy = { ...sortState };
    sortStateCopy = makeDefaultState(param, sortStateCopy);
    sortStateCopy[param] = !sortStateCopy[param];
    setSortState({ ...sortStateCopy });

    if (param == "product_name") {
      if (order == "ascending") {
        ascendingProductHandler();
      } else {
        descendingProductHandler();
      }
    }
    if (param == "sub_category") {
      if (order == "ascending") {
        ascendingSubCategoryHandler();
      } else {
        descendingSubCategoryHandler();
      }
    }
    if (param == "engagement") {
      if (order == "ascending") {
        ascendingEngagementHandler();
      } else {
        descendingEngagementHandler();
      }
    }
    if (param == "conversion") {
      if (order == "ascending") {
        ascendingConversionHandler();
      } else {
        descendingConversionHandler();
      }
    }
    if (param == "samples_tried") {
      if (order == "ascending") {
        ascendingSamplesTriedHandler();
      } else {
        descendingSamplesTriedHandler();
      }
    }
    if (param == "add_to_cart") {
      if (order == "ascending") {
        ascendingAddtoCartHandler();
      } else {
        descendingAddtoCartHandler();
      }
    }
  };

  return (
    <div className="mainTable">
      <div className="tableHeader">
        <div className="productWise">
          <div>
            <span className="maps-header-text">Funnel Distribution</span>&nbsp;
            <span className="maps-header-subtext">(Product Summary)</span>
          </div>
          <div className="export">
            <div>
              <img src={Export} alt=""></img> Export in CSV
            </div>
          </div>
        </div>
        <div className="productSort">
          {/* <div>
            <Dropdown>
              <Dropdown.Toggle className="productSortSectionDrop">
                <div className="productSortSectionDrop"> <FontAwesomeIcon id="iconProductSort" icon={faArrowUpShortWide} />Sort By</div>
              </Dropdown.Toggle>
              <Dropdown.Menu >
                <div className="sortLabel"><FontAwesomeIcon onClick={ascendingProductHandler} className="font" icon={faArrowDownShortWide} /><FontAwesomeIcon onClick={descendingProductHandler} className="font" icon={faArrowUpShortWide} /> Product Name</div>

                <div className="sortLabel"><FontAwesomeIcon onClick={ascendingSubCategoryHandler} className="font" icon={faArrowDownShortWide} /><FontAwesomeIcon onClick={descendingSubCategoryHandler} className="font" icon={faArrowUpShortWide} /> Sub-Category</div>

                <div className="sortLabel"><FontAwesomeIcon onClick={ascendingEngagementHandler} className="font" icon={faArrowDownShortWide} /><FontAwesomeIcon onClick={descendingEngagementHandler} className="font" icon={faArrowUpShortWide} /> Engagement</div>

                <div className="sortLabel"><FontAwesomeIcon onClick={ascendingConsiderationHandler} className="font" icon={faArrowDownShortWide} /><FontAwesomeIcon onClick={descendingConsiderationHandler} className="font" icon={faArrowUpShortWide} /> Consideration</div>

                <div className="sortLabel"><FontAwesomeIcon onClick={ascendingSamplesTriedHandler} className="font" icon={faArrowDownShortWide} /><FontAwesomeIcon onClick={descendingSamplesTriedHandler} className="font" icon={faArrowUpShortWide} /> Samples Tried</div>

                <div className="sortLabel"><FontAwesomeIcon onClick={ascendingConversionHandler} className="font" icon={faArrowDownShortWide} /><FontAwesomeIcon onClick={descendingConversionHandler} className="font" icon={faArrowUpShortWide} /> Conversion %</div>

                <div className="sortLabel"><FontAwesomeIcon onClick={ascendingIntentHandler} className="font" icon={faArrowDownShortWide} /><FontAwesomeIcon onClick={descendingIntentHandler} className="font" icon={faArrowUpShortWide} /> Intent %</div>

              </Dropdown.Menu>
            </Dropdown>
          </div> */}
          {/* <div className="exportProductDetails"><img src={exportImage} alt="export image" /> Export</div> */}
        </div>
      </div>
      {productDetail.length !== 0 ? (
        <div className="productSummaryTables">
          <div className="tableHeadings">
            <div className="pVal productSummarySerial">S.no</div>
            <div
              className="productName1"
              onClick={() =>
                document.getElementById("sort-product_name").click()
              }
            >
              Product Name
              {sortState.product_name ? (
                <img
                  id="sort-product_name"
                  src={rdSortUp}
                  onClick={() => sortSurveyList("product_name", "ascending")}
                />
              ) : (
                <img
                  id="sort-product_name"
                  src={rdSortDown}
                  onClick={() => sortSurveyList("product_name", "descending")}
                />
              )}
            </div>
            <div
              className="pVal"
              onClick={() =>
                document.getElementById("sort-sub-category").click()
              }
            >
              Sub-Category
              {sortState.sub_category ? (
                <img
                  id="sort-sub-category"
                  src={rdSortUp}
                  onClick={() => sortSurveyList("sub_category", "ascending")}
                />
              ) : (
                <img
                  id="sort-sub-category"
                  src={rdSortDown}
                  onClick={() => sortSurveyList("sub_category", "descending")}
                />
              )}
            </div>
            <div
              className="pVal"
              onClick={() => document.getElementById("sort-engagement").click()}
            >
              Engagement
              {sortState.engagement ? (
                <img
                  id="sort-engagement"
                  src={rdSortUp}
                  onClick={() => sortSurveyList("engagement", "ascending")}
                />
              ) : (
                <img
                  id="sort-engagement"
                  src={rdSortDown}
                  onClick={() => sortSurveyList("engagement", "descending")}
                />
              )}
            </div>
            {/* <div className="pVal">Consideration</div> */}
            <div
              className="pVal"
              onClick={() =>
                document.getElementById("sort-add-to-cart").click()
              }
            >
              Add to Cart
              {sortState.add_to_cart ? (
                <img
                  id="sort-add-to-cart"
                  src={rdSortUp}
                  onClick={() => sortSurveyList("add_to_cart", "ascending")}
                />
              ) : (
                <img
                  id="sort-add-to-cart"
                  src={rdSortDown}
                  onClick={() => sortSurveyList("add_to_cart", "descending")}
                />
              )}
            </div>
            <div
              className="pVal"
              onClick={() =>
                document.getElementById("sort-samples-tried").click()
              }
            >
              Samples Tried
              {sortState.samples_tried ? (
                <img
                  id="sort-samples-tried"
                  src={rdSortUp}
                  onClick={() => sortSurveyList("samples_tried", "ascending")}
                />
              ) : (
                <img
                  id="sort-samples-tried"
                  src={rdSortDown}
                  onClick={() => sortSurveyList("samples_tried", "descending")}
                />
              )}
            </div>

            {/* <div className="pVal" onClick={() => document.getElementById('sort-conversion').click()}>Trial Conversion %
                {
                  sortState.conversion
                    ?
                    <img id='sort-conversion' src={rdSortUp} onClick={() => sortSurveyList('conversion', 'ascending')} />
                    :
                    <img id='sort-conversion' src={rdSortDown} onClick={() => sortSurveyList('conversion', 'descending')} />
                }</div> */}
            {/* <div className="pVal">Intent %</div> */}
          </div>
          {productDetail.map((d, index) => {
            let image;
            if (d.intent === "High") {
              image = upImage;
            } else {
              image = downImage;
            }
            return (
              <div className="values" key={index}>
                <div
                  className="tableValues"
                  id={d.product_sku}
                  onClick={productLevelClickHandler}
                >
                  <div className="pVal productSummarySerial" id={d.product_sku}>
                    {index + 1}
                  </div>
                  <div className="productName1" id={d.product_sku}>
                    {d.product_name}
                  </div>
                  <div className="pVal" id={d.product_sku}>
                    {d.sub_category}
                  </div>
                  <div className="pVal" id={d.product_sku}>
                    {nFormatter(d.engagement)}
                  </div>
                  <div className="pVal" id={d.product_sku}>
                    {nFormatter(d.add_to_cart)}
                  </div>
                  <div className="pVal" id={d.product_sku}>
                    {nFormatter(d.samples_tried)}
                  </div>

                  {/* <div className="pVal" id={d.product_sku}>{JSON.parse(d.conversion).toFixed(2)}%</div> */}
                  {/* <img id={d.product_sku} src={image} alt="image" /> */}
                  {/* <div className="pVal" id={d.product_sku}>{d.intent}</div> */}
                </div>
              </div>
            );
          })}
        </div>
      ) : (
        <div className="stateGraphSkeleton">
          {noDataLoad !== true ? (
            <Skeleton
              active
              title
              paragraph={{
                rows: 10,
              }}
            />
          ) : (
            <img
              className="singleNoDataImage"
              src={DataLoader}
              alt="image data empty"
            />
          )}
        </div>
      )}
    </div>
  );
};

export default ProductWiseSummary;
