import React, { useState, useEffect } from 'react'
import ProductGender from '../ProductGender/ProductGender'
import "./ProductGeography.css"
import axios from 'axios';
import checkEnv from '../../../utils/checkEnv';


export default function ProductGeography(props) {

    const statesList = ['Andaman and Nicobar Islands', 'Andhra Pradesh', 'Arunachal Pradesh', 'Assam', 'Bihar', 'Chandigarh', 'Chhattisgarh', 'Dadra and Nagar Haveli', 'Daman and Diu', 'Delhi', 'Goa', 'Gujarat', 'Haryana', 'Himachal Pradesh', 'Jammu', 'Jharkhand', 'Karnataka', 'Kashmir', 'Kerala', 'Ladakh', 'Lakshadweep', 'Madhya Pradesh', 'Maharashtra', 'Manipur', 'Meghalaya', 'Mizoram', 'Nagaland', 'Odisha', 'Puducherry', 'Punjab', 'Rajasthan', 'Sikkim', 'Tamil Nadu', 'Telangana', 'Tripura', 'Uttarakhand', 'Uttar Pradesh', 'West Bengal']
    let brandId = props.brandId
    let token = localStorage.getItem('token')
    let startDate = localStorage.getItem("start_date");
    let endDate = localStorage.getItem("end_date");
    let url=checkEnv()
    const [compareState, setCompareState] = useState(false)
    const [geographyList, setGeographyList] = useState([])
    const [twoStatesComparision, setTwoStatesComparision] = useState(false)
    const [comparedTwoStates, setComparedTwoStates] = useState(true)

    const compareClickHandler = () => {
        setCompareState(!compareState)
    }

    const getProductsGeography = async () => {
        const response = await axios.get(`${url}/brandsdashboardproxy/v1/brand/${brandId}/productfourtwoodlockgeography/?startDate=${startDate}&endDate=${endDate}`, {
            headers: {
                'authorization': `${token}`
            }
        })
            .catch((err) => {
                console.log(err);
            });
        setGeographyList(response.data.map((dat) => dat));
    };

    useEffect(() => {
        getProductsGeography();
    }, [])
    const showResultsClickHandler = () => {
        setTwoStatesComparision(true)
        setCompareState(!compareState)
        setComparedTwoStates(false)
    }
    const showTop5StatesClickHandler = () => {
        setTwoStatesComparision(!twoStatesComparision)
        setComparedTwoStates(!comparedTwoStates)
    }

    return (
        <div>
            <div className='geographySection'>
                <div className='geographyContainer'>
                    <div className='geographyCompareStates'>
                        <div className='geographyHeader'>
                            <b>Geography</b>
                        </div>
                        {twoStatesComparision ? <div className='showTop5States' onClick={showTop5StatesClickHandler}>Show Top 5 States </div>
                            : ""
                        }
                    </div>
                    <div>
                        <div className='productCompare' onClick={compareClickHandler}>
                            Compare States
                        </div>
                        {
                            compareState ?
                                <div className='productStatesCompare'>
                                    <div className='compareSection'>
                                        <select>
                                            {statesList.map((d, index) => {
                                                return <option value={d} key={index}>{d}</option>
                                            })
                                            }
                                        </select>
                                        <div>Compare With</div>
                                        <select>
                                            {statesList.map((d, index) => {
                                                return <option value={d} key={index}>{d}</option>
                                            })
                                            }
                                        </select>
                                        <button onClick={showResultsClickHandler}>
                                            Show Results
                                        </button>
                                    </div>
                                </div>
                                : ""
                        }
                    </div>
                </div>

                <div className='states'>
                    <table className='productTable'>
                    <thead>
                        <tr className='productHeadings'>
                            <th>
                                States
                            </th>
                            <th colSpan="2">
                                Trails
                            </th>
                            <th colSpan="4">
                                Adoption
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        
                        <tr className='productSections'>
                            <td>

                            </td>
                            <td>
                                Trail Rate
                            </td>
                            <td>
                                Trial Units
                            </td>
                            <td>
                                Trial Rate
                            </td>
                            <td>
                                Repeat
                            </td>
                            <td>
                                Frequency
                            </td>
                            <td>
                                Repeat Unit
                            </td>
                        </tr>
                        {
                            comparedTwoStates ?
                                <>
                                    {
                                        geographyList.map((d,index) => {
                                            return <tr className='productDetails' key={index}>
                                                <td className='productStates'>
                                                    {d.state}
                                                </td>
                                                <td>
                                                    {d.data.trials.trialRate}%
                                                </td>
                                                <td>
                                                    {d.data.trials.trialUnits}
                                                </td>
                                                <td>
                                                    {d.data.adoption.trialRate}%
                                                </td>
                                                <td>
                                                    {d.data.adoption.repeat}%
                                                </td>
                                                <td>
                                                    {d.data.adoption.frequency}
                                                </td>
                                                <td>
                                                    {d.data.adoption.repeatUnits}
                                                </td>
                                            </tr>
                                        })
                                    }
                                </>
                                :
                                <>
                                    {
                                        geographyList.map((d, index) => {
                                            if (index <= 1) {
                                                return <tr className='productDetails' key={index}>
                                                    <td className='productStates'>
                                                        {d.state}
                                                    </td>
                                                    <td>
                                                        {d.data.trials.trialRate}%
                                                    </td>
                                                    <td>
                                                        {d.data.trials.trialUnits}
                                                    </td>
                                                    <td>
                                                        {d.data.adoption.trialRate}%
                                                    </td>
                                                    <td>
                                                        {d.data.adoption.repeat}%
                                                    </td>
                                                    <td>
                                                        {d.data.adoption.frequency}
                                                    </td>
                                                    <td>
                                                        {d.data.adoption.repeatUnits}
                                                    </td>
                                                </tr>
                                            } else {
                                                return ""
                                            }
                                        })
                                    }
                                </>
                        }
                    </tbody>
                    </table>
                </div>
            </div>
            <ProductGender brandId={props.brandId}/>
        </div>
    )
}
