import React from "react";
import "./ConvertTemplatized1.css";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

// import coinIcon from "../../../../../images/coin.svg";
import coinIcon from "../images/coin.svg";
// import { checkSurveyEnv } from '../../../../../../utils/checkEnv';
import axios from "axios";
import { useParams } from "react-router";
import { checkSurveyEnv } from "../../utils/checkEnv";


export default function ConvertTemplatized1(props) {
  const surveyUrl = checkSurveyEnv();
  const { surveyId } = useParams();
  const convertToCustom = () => {
    axios
      .put(`${surveyUrl}/smyttenresearch/survey/${surveyId}`, {
        survey_type: "CUSTOM",
      })
      .then((res) => {
        console.log(res.data);
        props.onHide();
      });
  };
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="modal-container"
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <p>Smytten Pulse - Credits </p>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ border: "none" }} className="modalBody">
        <div>
          <p className="body-text">
            Use your Credits in the Research section to get quick customer
            insights using customised templates.
          </p>
          {/* <p>Custom Survey Charges.</p> */}
          <div className="table-container">
            <table className="templatized-table">
              <tr className="table-row">
                <td className="upto">Upto 20 Questions</td>
                <td className="ammount">
                  200
                  <img src={coinIcon} alt="" />
                  per touch points
                </td>
              </tr>
              <tr className="table-row">
                <td className="upto">Upto 30 Questions</td>
                <td className="ammount">
                  250
                  <img src={coinIcon} alt="" />
                  per touch points
                </td>
              </tr>
              <tr className="table-row">
                <td className="upto">Upto 40 Questions</td>
                <td className="ammount">
                  300
                  <img src={coinIcon} alt="" />
                  per touch points
                </td>
              </tr>
            </table>
          </div>
          <div className="footer-container">
            <p className="footer-text">
              Tap the link in the email message to add credit to your account.
            </p>
            <p className="footer-link"> Add top-up credits </p>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className="templatized-footer">
          <Button className="cancel" onClick={props.onHide}>
            Cancel
          </Button>
          <Button className="ok" onClick={() => convertToCustom()}>
            Ok
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}
