import React, { useEffect, useState } from 'react'
import {Pagination, Skeleton} from 'antd';
import "./BasketAnalysis.css"
import { useSearchParams } from 'react-router-dom';
import checkEnv from '../../../utils/checkEnv';
import axios from 'axios';
import BasketCard from "./BasketCard.js";
import leftArr from "../../images/leftarr.svg";
import rightArr from "../../images/rightarr.svg"

export default function BasketAnalysis() {
    const [data,setData]=useState([])
    const [renderdata,setRenderData]=useState([])
    const [searchParams]=useSearchParams()
    const [startIndex,setStartIndex]=useState(0)
    const [nextIndex,setnextIndex]=useState(4);
    const [endIndex,setEndIndex]=useState(null)
    const [page,setPage]=useState(1)
    let token = localStorage.getItem("token")
    
    const url=checkEnv()

    useEffect(()=>{
        axios.get(`${url}/brandsdashboardproxy/v1/brand/${searchParams.get("brand")}/basketanalysis/`,{
            headers: {
                'authorization': `${token}`
            }
        })
        .then(res=>{
            // console.log(res.data)
            setData(res.data)
            setEndIndex(res.data.length)
            if(res.data.length<4){
                setRenderData(res.data.slice(0,res.data.length))
            }else{
                setRenderData(res.data.slice(startIndex,nextIndex))
            }
        })
    },[url,searchParams,token])

    const changeRenderData=(type)=>{
        if(type==="inc"){
            if(startIndex<nextIndex && startIndex+4<endIndex ){
                setRenderData(data.slice(startIndex+4,nextIndex+4))
                setStartIndex(startIndex+4)
                setnextIndex(nextIndex+4)
                setPage(page+1)
            }
        }
        else if(type==='dec'){
           
            if(startIndex>0){
                // setRenderData(data.slice(,))
                setRenderData(data.slice(startIndex-4,nextIndex-4))
                setStartIndex(startIndex-4)
                setnextIndex(nextIndex-4)
                setPage(page-1)
            }
        }
    }

    const paginationFunc=(num)=>{
        if(num===1){
            setRenderData(data.slice(0,4))
            setStartIndex(0)
            setnextIndex(4)
            setPage(1)
        }
        else if(num===2){
            console.log(2)
            setRenderData(data.slice(4,8))
            setStartIndex(4)
            setnextIndex(8)
            setPage(2)
        }
        else if(num===3){
            setRenderData(data.slice(8,12))
            setStartIndex(8)
            setnextIndex(12)
            setPage(3)
        }
        else{
            setRenderData(data.slice(12,16))
            setStartIndex(12)
            setnextIndex(16)
            setPage(4)
        }
    }
   

  return (
    <>

    {
    data.length!==0?<div className='basketContainer'>
       <div className='basketContainer-header'>
        <div><span>Basket Analysis </span><span className='maps-header-subtext'>(Key Categeroies)</span></div>
        <div className='basket-buttons'>
            <div className='round' onClick={()=>changeRenderData("dec")}><img src={leftArr} alt="leftarr"/></div>
                <div className={page===1?"activepage":"page"} onClick={()=>{paginationFunc(1)}}>1</div>
                <div className={page===2?"activepage":"page"} onClick={()=>{paginationFunc(2)}}>2</div>
                <div className={page===3?"activepage":"page"} onClick={()=>{paginationFunc(3)}}>3</div>
                {/* <div className={page===4?"activepage":"page"} onClick={()=>{paginationFunc(4)}}>4</div> */}
                {/* <div>{page}</div> */}
            <div className='round' onClick={()=>changeRenderData("inc")}><img src={rightArr} alt="rightarr"/></div>
        </div>
        </div>
       <div className='basketContainer-body'>
            {/* <BasketCard data={data[0]} key={0} index={0}/> */}
            {renderdata.map((dat,index)=>{
            return <BasketCard data={dat} key={index} index={index}/>
            })}
       </div>
        
        
    </div> :
    <div className='basketContainer'><Skeleton active paragraph={{rows:6,}}/></div>
    }
    </>

  )
}
