import "./RatingsAndReviewWordCloud.css";
import React, { useState, useEffect } from 'react';
import ReactWordcloud from 'react-wordcloud';
import axios from "axios";
import DataLoader from "../../../images/dataload_gif.gif";
import { useSearchParams } from "react-router-dom";
import checkEnv from "../../../../utils/checkEnv";
export default function RatingsAndReviewWordCloud(props) {
  const [searchParams, setSearchParams] = useSearchParams()
  let brandId = localStorage.getItem('brand_id')
  let token = localStorage.getItem("token")
  const [words, setWords] = useState([])
  const [positive, setPositive] = useState([])
  const [negative, setNegative] = useState([])
  const [neutral, setNeutral] = useState([])

  let startDate = localStorage.getItem("start_date");
  let endDate = localStorage.getItem("end_date");
  let url=checkEnv()
  let categoryId = localStorage.getItem('allcategoryId') ? (localStorage.getItem('allcategoryId').split(',')) : []
  let productId = localStorage.getItem('allProductId') ? localStorage.getItem('allProductId').split(',') : []

  const getWordCloud = async () => {
    const response = await axios.get(`${url}/brandsdashboardproxy/v1/brand/${searchParams.get('brand')}/topkeywords/?startDate=${startDate}&endDate=${endDate}&categoryIds=${searchParams.get('categoryIds')}&productIds=${searchParams.get('productIds')}`, {
      headers: {
        'authorization': `${token}`
      }
    })
      .catch((err) => {
        console.log(err);
      });
    if (response !== undefined) {
      setWords(response.data.word_cloud.map(d => d))
    }
  };
  useEffect(() => {
    getWordCloud();
  }, [searchParams])
  // console.log(words) 
  const options = {
    colors: ["rgb(1, 162, 76)", "rgb(1, 33, 101)","rgb(85, 208, 0)", "rgb(1, 33, 101)","rgb(4, 133, 195)","rgb(1, 162, 76)"],
    enableTooltip: false,
    deterministic: true,
    fontFamily: "impact",
    fontSizes: [10,50],
    fontStyle: "normal",
    fontWeight: "normal",
    rotations: 0,
    rotationAngles: [-90,0],
    // scale: 'sqrt',
    spiral: 'archimedean',
    transitionDuration: 0
  };
  const size = [550, 150]

  // words.sort((a, b) => {
  //   return b.value - a.value;
  // });


  const callbacks = {
    getWordColor: (word) => {
      // console.log(word)
      if (word.type === 'POSITIVE'){
        return "rgb(1, 162, 76)"
      }else if (word.type === 'NEUTRAL'){
        return "rgb(1, 33, 101)"
      }else if (word.type === 'NEGATIVE'){
        return "red"
      }
    },
  }
  return (
    <div className="WordCloudSection">
      {
        props.nextState === true && words.length !== 0 ?
          <ReactWordcloud
            options={options} words={words}
            // callbacks={callbacks}
          size={size}
          />
          : <div className="metrixBoxImageLoader">
            <img src={DataLoader} />
          </div>}
    </div>
  )
}