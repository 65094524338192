import { useEffect, useState } from "react";
import { Chart as ChartJs, Tooltip, Title, ArcElement, Legend } from "chart.js";
import { Pie } from "react-chartjs-2";
import axios from "axios";
import { useSearchParams } from "react-router-dom";
import DataLoader from "../../images/noDataHalf.svg"
import { Skeleton } from "antd"
ChartJs.register(Tooltip, Title, ArcElement, Legend);

function UsersPieChart(props) {

    let maleTotalData = props.maleTotalData
    let femaleTotalData = props.femaleTotalData

    // const getMaleVsFemale = async () => {
    //     setMaleTotalData([])
    //     setFemaleTotalData([])
    //     const response = await axios.get(`https://ancillary-stage.smytten.com/brandsdashboardproxy/v1/brand/${searchParams.get('brand')}/usertriedgender/?startDate=${startDate}&endDate=${endDate}&categoryIds=${searchParams.get('categoryIds')}&productIds=${searchParams.get('productIds')}&stateName=${searchParams.get(`stateName`)}`, {
    //         headers: {
    //             'authorization': `${token}`
    //         }
    //     })
    //         .catch((err) => {
    //             console.log(err);
    //         });
    //         setMaleTotalData(response.data.totalMaleUsersMap)
    //         //   console.log(response.data.totalMaleUsersMap)
    //         setFemaleTotalData(response.data.totalFemaleUsersMap)

    // };
    // useEffect(() => {
    //     getMaleVsFemale();
    // }, [searchParams]);

    let maleCount = maleTotalData
    let femaleCount = femaleTotalData

    let femaleData
    let maleData
    let displayData = {}
    const graphData = [
        {
            name: "Female",
            count: femaleTotalData
        },
        {
            name: "Male",
            count: maleTotalData,
        },
    ];
    graphData.map(d => {
        displayData[d.name] = d.count
    })
    femaleData = displayData["Female"]
    maleData = displayData["Male"]
    const [graph, setGraph] = useState({
        labels: [],
        data: [],
    });
    useEffect(() => {
        const labels = [];
        const data = [];
        graphData.map((v) => {
            labels.push(v.name);
            data.push(v.count);
        });
        setGraph({
            labels: labels,
            data: data,
        });
    }, [maleCount, femaleCount]);

    const data = {
        labels: graph.labels,
        datasets: [
            {
                label: "# of Votes",
                data: graph.data,
                backgroundColor: [
                    "rgb(246, 69, 154)",
                    "rgb(56, 80, 180)"
                ],
                borderWidth: "3",
            },
        ],
    };


    return (
        <div className="usersPie">
            <div className="usersPieChartHeading">
                <b>Male Vs Female Users</b>
            </div>
            {
                 maleTotalData && maleTotalData.length !== 0 || femaleTotalData && femaleTotalData.length !== 0 ?
                    <div className="userPieChartDetails">
                        <div className="usersPieChart" >
                            <Pie
                                data={data}
                                width={80}
                                options={{
                                    plugins: {

                                        tooltip: {
                                            callbacks: {
                                                labelTextColor: function (context) {
                                                    return "black"
                                                },
                                                label: function (context) {
                                                    return `${context.label} ${context.formattedValue}`
                                                },
                                            },
                                            backgroundColor: 'white',
                                            padding: 10,
                                        },
                                        legend: {
                                            display: false,
                                        },
                                        datalabels: {
                                            formatter: function (value, context) {
                                                const datapoints = context.chart.data.datasets[0].data;
                                                function totalSum(total, datapoint) {
                                                    return total + datapoint;
                                                }
                                                const totalValue = datapoints.reduce(totalSum, 0);
                                                const percentageValue = ((value / totalValue) * 100).toFixed(0);
                                                return `${percentageValue}%`;
                                            },
                                            color: "white",
                                            anchor: "center",
                                            align: "end",
                                            font: {
                                                weight: "bold",
                                                size: "14px",
                                            }
                                        },
                                    },
                                }}
                            />
                        </div>
                        <div className="chartFooter">
                            <div className="chartFooterLeft">
                                <div className="label">
                                    <div className="labelFemale"></div>
                                    <div>Female</div>
                                </div>
                                <div className="femaleData">

                                    {femaleData}

                                </div>
                                {/* <div className="activeUsers">
                                    Active Users
                                </div> */}
                            </div>
                            <div className="chartFooterRight">
                                <div className="label">
                                    <div className="labelMale"></div>
                                    <div>Male</div>
                                </div>
                                <div className="maleData">

                                    {maleData}

                                </div>
                                {/* <div className="activeUsers">
                                    Active Users
                                </div> */}
                            </div>
                        </div>
                    </div> : <div className="brandsVsCategorySkeleton">
                        {
                            props.noDataLoad !== true ?
                                <Skeleton
                                    active
                                    title
                                    paragraph={{
                                        rows: 8,
                                    }} />
                                :
                                <img className="singleNoDataImage" src={DataLoader} alt='image data empty' />
                        }
                    </div>}
        </div>
    );
}

export default UsersPieChart;
