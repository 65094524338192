import React from 'react'
// import {
//     Chart as ChartJS,
//     CategoryScale,
//     LinearScale,
//     BarElement,
//     Title,
//     Tooltip,
//     Legend,
// } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';

export default function DoughnutComponent({data,data1,data2}) {
    let array=data.datasets[0].data
    let name=data.datasets[0].label
    // console.log("data",data.datasets[0].data)
    // console.log(data)
    
    const hoverData = ['Promoters (9-10)', 'Passives (7-8)', 'Detractors (0-6)']
  return (
    <Doughnut
                data={data}
                
                options={{
                    // layout: {
                    //     padding: 20
                    // },
                    plugins: {
                        datalabels: {
                            display: false,
                            formatter: function (value, context) {
                                const datapoints = context.chart.data.datasets[0].data;
                                function totalSum(total, datapoint) {
                                    return total + datapoint;
                                }
                                const totalValue = datapoints.reduce(totalSum, 0);
                                const percentageValue = ((value / totalValue) * 100).toFixed(0);

                                return `${percentageValue}%`;
                            },
                            color: [
                                
                            ],
                            align: "start",
                            anchor: "start",
                            padding: {
                                left: -10,
                                bottom: -10,
                            },
                            font: {
                                weight: 900,
                                size: 20
                            },
                        },
                        tooltip: {
                            callbacks: {
                                labelTextColor: (context) => {
                                    return 'black'
                                },
                                label: function (context) {
                                    console.log("context",context)
                                    // for (let i in hoverData) {
                                    //     if (context.datasetIndex == 0) {
                                    //         let index = context.dataIndex
                                    //         return `Your Brand : ${hoverData[index]}  ${data1[index]}%`;
                                    //     } else {
                                    //         let index = context.dataIndex
                                    //         return `Your Top Competitors : ${hoverData[index]}  ${data2[index]}%`;
                                    //     }
                                    // }
                                    let index=context.dataIndex
                                    return ``
                                },
                                afterLabel: function (context) {
                                    console.log("context",context)
                                    // for (let i in hoverData) {
                                    //     if (context.datasetIndex == 0) {
                                    //         let index = context.dataIndex
                                    //         return `Your Brand : ${hoverData[index]}  ${data1[index]}%`;
                                    //     } else {
                                    //         let index = context.dataIndex
                                    //         return `Your Top Competitors : ${hoverData[index]}  ${data2[index]}%`;
                                    //     }
                                    // }
                                    let index=context.dataIndex
                                    return `${hoverData[index]} ${array[index]}%`
                                },
                            },
                            padding: 10,
                            backgroundColor: "white",
                        },
                    },

                }}
                
            />
  )
}
