import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBell } from "@fortawesome/free-regular-svg-icons";
import userProfileArrow from "../images/UserProfile.svg";
import {
  faBars,
  faGear,
  faComments,
  faCircle,
  faCircleInfo,
} from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import ProductDetails from "./ProductDetails";
import Dashboard from "../Dashboard";
import { useNavigate } from "react-router";
import Logo from "../images/Logo.png";
import logoCoin from "../images/Final_01_1.png";
import checkEnv, { checkSurveyEnv } from "../../utils/checkEnv";
import { useSearchParams } from "react-router-dom";
import ConvertTemplatized1 from "../modal/ConvertTemplatized1";
let currentId;
const ProductHeader = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  let brand = localStorage.getItem("brand_id");
  let token = localStorage.getItem("token");
  let userName = localStorage.getItem("userName");
  const [learnMore, setLearnMore] = useState(false);

  let url = checkEnv();
  let surveyUrl = checkSurveyEnv();
  const [brands, setBrands] = useState([]);
  const [userState, setUserState] = useState(false);
  const navigate = useNavigate();

  const getBrands = async () => {
    const response = await axios
      .get(`${url}/brandsdashboardproxy/v1/brand/`, {
        headers: {
          authorization: `${token}`,
        },
      })
      .then((res) => {
        setBrands(res.data.map((dat) => dat));
      })
      .catch((err) => {
        console.log("brands", err);
        // if (err.response.status === 401 || err.response.status === 0) {
        getnewToken();
        // }
      });
  };
  const [creditBalance, setCreditBalance] = useState("");
  useEffect(() => {
    axios
      .get(
        `${surveyUrl}/smyttenresearch/brandcredits/${searchParams.get(
          "brand"
        )}`,
        {}
      )
      .then((res) => {
        setCreditBalance(res.data.credits);
      });
  }, [searchParams]);
  const getnewToken = async () => {
    const response = await axios
      .get(`${url}/brandsdashboardproxy/v1/auth/token`, {
        headers: {
          authorization: `${token}`,
        },
      })
      .then((res) => {
        // console.log(res.status)
        localStorage.setItem("token", res.data.token);
      })
      .catch((err) => {
        // console.log('herhbfqrwh', err.response.status)
        // if (err.response.status === 401 || err.response.status === 0) {
        navigate("/brandsdashboard");
        // }
      });
  };
  useEffect(() => {
    getBrands();
  }, []);
  const [open, setOpen] = useState(false);
  const clickHandler = () => {
    setOpen(!open);
  };

  const changeHandler = (e) => {
    currentId = e.target.value;
    localStorage.setItem("brand_id", currentId);
    window.location.reload();
  };
  const MakeItem = function (X) {
    return (
      <option key={X.brandId} value={X.brandId}>
        {X.brandName}
      </option>
    );
  };

  if (open === true && document.querySelector(".section") !== null) {
    localStorage.setItem("dashBoard_bars", true);
    document.querySelector(".section").style.display = "none";
    document.querySelector(".catData").style.marginLeft = "0px";
    document.querySelector(".boxes").style.marginLeft = "0px";
  } else if (
    open === false &&
    document.querySelector(".section") &&
    document.querySelector(".catData") &&
    document.querySelector(".boxes") &&
    window.innerWidth > 1200
  ) {
    localStorage.setItem("dashBoard_bars", false);
    document.querySelector(".section").style.display = "block";
    document.querySelector(".catData").style.marginLeft = "200px";
    document.querySelector(".boxes").style.marginLeft = "200px";
  } else if (
    open === false &&
    document.querySelector(".section") &&
    document.querySelector(".catData") &&
    document.querySelector(".boxes") &&
    window.innerWidth <= 1200
  ) {
    localStorage.setItem("dashBoard_bars", false);
    document.querySelector(".section").style.display = "block";
    document.querySelector(".catData").style.marginLeft = "50px";
    document.querySelector(".boxes").style.marginLeft = "50px";
  }
  window.addEventListener("resize", function (event) {
    if (
      document.querySelector(".section") &&
      document.querySelector(".catData") &&
      document.querySelector(".boxes") &&
      window.innerWidth > 1200
    ) {
      document.querySelector(".section").style.display = "block";
      document.querySelector(".catData").style.marginLeft = "200px";
      document.querySelector(".boxes").style.marginLeft = "200px";
    } else if (
      document.querySelector(".section") &&
      document.querySelector(".catData") &&
      document.querySelector(".boxes") &&
      window.innerWidth <= 1200
    ) {
      document.querySelector(".section").style.display = "block";
      document.querySelector(".catData").style.marginLeft = "50px";
      document.querySelector(".boxes").style.marginLeft = "50px";
    }
  });

  const userprofileClickHandler = () => {
    setUserState(!userState);
  };

  const logoutClickHandler = () => {
    navigate("/brandsdashboard");
    localStorage.removeItem("token");
    localStorage.removeItem("count_level");
    localStorage.removeItem("product_count_level");
  };
  const loginPopUpHideClickHandler = () => {
    if (userState === true) {
      setUserState(false);
    }
  };

  return (
    <div onClick={loginPopUpHideClickHandler}>
      <div className="header">
        <div className="header-left">
          {/* <FontAwesomeIcon
            className="hamberger"
            icon={faBars}
            onClick={clickHandler}
          /> */}
          <div className="header-left-left">
            <img className="logo-image" src={Logo} alt="logo" />
          </div>
          <div className="header-left-right">
            <div>
              <img className="logo-image1" src={logoCoin} alt="coin" />
            </div>
            <div className="remaining-balance-container">
              <span className="remaining-text">Remaining Balance:</span>
              <span className="remaining-balance">{creditBalance} Credits</span>
            </div>
          </div>
          <div className="learn-more">
            <FontAwesomeIcon icon={faCircleInfo} className="learn-more-icon" />
            <span
              className="learn-more-text"
              onClick={() => {
                setLearnMore(true);
              }}
            >
              Learn More
            </span>
          </div>
        </div>
        <div className="headerOne">
          <div className="headerCenter">
            {/* <FontAwesomeIcon className="settings" icon={faGear} />
            <FontAwesomeIcon className="chat" icon={faComments} />
            <FontAwesomeIcon className="notification" icon={faBell} /> */}
            <div>
              <select
                className="selectBrand"
                value={brand}
                onChange={(e) => {
                  changeHandler(e);
                }}
              >
                {brands.map(MakeItem)}
              </select>
            </div>
          </div>
          <div>
            <div className="user" onClick={userprofileClickHandler}>
              <FontAwesomeIcon className="userProfile" icon={faCircle} />
              <div>{userName}</div>
              <img src={userProfileArrow} alt="user" />
            </div>
            {userState ? (
              <div className="userLogoutProfile">
                <div>Change PassWord</div>
                <div onClick={logoutClickHandler}>Logout</div>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
      <Dashboard />
      <div className="data">
        <ProductDetails brandId={brand} />
      </div>
      <ConvertTemplatized1
        show={learnMore}
        onHide={() => setLearnMore(false)}
      />
    </div>
  );
};

export default ProductHeader;
