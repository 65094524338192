import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import React, { useEffect, useState } from 'react';
import "./CreateNew.css";
import { Button } from 'antd';
import { useNavigate } from 'react-router';
import GridLoader from "react-spinners/GridLoader";
import moment from "moment"
import { useSearchParams } from 'react-router-dom';
import checkEnv from '../../../../utils/checkEnv';
import { checkSurveyEnv } from '../../../../utils/checkEnv';
import axios from 'axios';
import CreateSurvey from './CreateSurvey';
import StandardTemplate from './standardtemplate/StandardTemplate';
import Spinner from 'react-bootstrap/Spinner';




export default function CreateNew() {
    const navigate=useNavigate()
    const [searchParams]=useSearchParams()
    const [data,setData]=useState({})
    const [answers,setAnswers]=useState({})
    const [index,setIndex]=useState(0)
    const [loading,setLoading]=useState(false)
    const [landingPage,setLandingPage]=useState(true)
    
    let url=checkEnv()
    let surveyUrl=checkSurveyEnv()
    let startDate = localStorage.getItem('start_date') ? localStorage.getItem('start_date') : localStorage.setItem("start_date", moment().subtract(6, 'days').format('YYYY-MM-DD'))
    let endDate = localStorage.getItem('end_date') ? localStorage.getItem('end_date') : localStorage.setItem("end_date", moment().format("YYYY-MM-DD"))
    
   let templateId=searchParams.get("templateId")
   let cardData=JSON.parse(localStorage.getItem("cardData"))
    
    useEffect(()=>{
        
            axios.get(`${surveyUrl}/smyttenresearch/surveytemplate/${templateId}/placeholder/`)
            .then((res)=>{
                // console.log(res.data)
                convertData(res.data)
            })
        
        
    },[templateId,url,landingPage])

    function sortArr(arr){
        let op={}
        arr.forEach(ele=>{
            op[ele.priority]=ele
        })
        let sortedKeys=Object.keys(op).map(e=>parseInt(e)).sort((a,b)=>a-b)
        return (sortedKeys.map((e)=>{
            return op[e]
        }))
    }
    
    function convertData(arr){
        let obj={}
        arr.forEach((e)=>{
            let key=e.group_id
            
            if(obj[key]){
                obj[key]=sortArr([...obj[key],e])
                
            }else{
                obj[key]=[e]
            }
        })
        setData(obj)
    }
    let keys=Object.keys(data)

    const createElement=()=>{
        let keys=Object.keys(data)
        if(keys.length>index){
            let key=keys[index]
            return data[key].map((e)=>{
                return <CreateSurvey setAnswers={setAnswers} obj={e} key={e.id}/>
            })
        }
        else{
            return null
        }
        
    }
    // JSON.stringify(answers)
    const nextClickHandler=()=>{
        if(landingPage){
            setLandingPage(false)
        }
        
        else{
            let key=keys[index]
            let currentObj=data[key]
            let error=false;
            
            currentObj.forEach(obj=>{
                let type=obj.type
                if(type.includes("MULTI")){
                    if(answers[obj.name].length===0){
                        error=true
                    }
                }
                else{
                    if(answers[obj.name]===""){
                        error=true
                    }
                }
                
            })
            if(!error){
                setIndex(index+1)
                if(index===keys.length-1){
                    console.log(answers)
                    axios.post(`${surveyUrl}/smyttenresearch/survey/`,{
                       
                            "brand_id": localStorage.getItem("brand_id"),
                            "title": cardData.title,
                            "sub_title": cardData.sub_title,
                            "max_resp_count": 250,
                            "survey_template_id": templateId,
                            "placeholders": answers
                    })
                    .then(res=>{
                        console.log(res.data)
                        // setLoading(true)
                        let surveyID=res.data.id
                        navigate({
                            pathname:`/brandsdashboard/research/surveys/${surveyID}`,
                            search:`?brand=${localStorage.getItem("brand_id")}&categoryIds=${localStorage.getItem('allcategoryId') ? (localStorage.getItem('allcategoryId').split(',')) : []}&productIds=${localStorage.getItem('allProductId') ? localStorage.getItem('allProductId').split(',') : []}&date=${startDate}to${endDate}`
                        })
                        
                    })
                    
                    
                }

            }
            else{
                alert("please enter value")
            }
        }
        
    }



    const createBar=()=>{
        if(landingPage){
            return keys.map((e,i)=>{
                return <div key={i} className='bar'></div>
            })
        }
        else{
            return keys.map((e,i)=>{
                if(i<=index){
                    return <div key={i} className='bar baractive'></div>
                }
                return <div key={i} className='bar'></div>
            })
        }
        
    }

   
    const backClickHandler=()=>{
        if(index===0){
           
            setLandingPage(true)
        }
        if(index>0){
            setIndex(index-1)
        }
    }

    const homeClickHandler=()=>{
        navigate({
            pathname:"/brandsdashboard/research",
            search:`?brand=${localStorage.getItem("brand_id")}&categoryIds=${localStorage.getItem('allcategoryId') ? (localStorage.getItem('allcategoryId').split(',')) : []}&productIds=${localStorage.getItem('allProductId') ? localStorage.getItem('allProductId').split(',') : []}&date=${startDate}to${endDate}`
        })
    }

    

  return (
    <div className='create-new-container'>
        {loading===false && <div className='create-new-header'>
            {/* <p className='header-home' onClick={()=>{homeClickHandler()}}>Home</p> */}
            <div></div>
            <div className='cross' onClick={()=>{homeClickHandler()}}><FontAwesomeIcon icon={faXmark}></FontAwesomeIcon></div>
        </div>}
        {keys.length!==0 && keys.length-1>=index? <div className='create-new-body'>
            <div className='body-top'>
                <div key={Math.random()} className='bar baractive'></div>
                {createBar()}
                {/* <div>
                    {keys.length-(index+1)>1? `${keys.length-(index+1)} steps left`:keys.length-(index+1)>0?`${keys.length-(index+1)} step left`: 
                    "last step"}
                </div> */}
            </div>
             <div className='body-bottom-white'>
                
                <div className='survey-box'>
                    {/* {createElement()} */}
                    
                    {landingPage?<StandardTemplate templateId={templateId}/>:createElement()}
                   
                    {/* <StandardTemplate/> */}
                </div>
                
                <div className='buttons'>
                    {!landingPage ?<Button onClick={()=>backClickHandler()} className='cancel'>{index>0?`Back`:`Back`}</Button>:<div></div>}
                    <Button className='next' onClick={()=>nextClickHandler()}>Next</Button>
                </div>
            </div>
            
            
            
        </div>:
        loading?
        <div className='create-new-body1'>
            <h4 className='completed'> your survey template is completed</h4>
        </div>
        :<div className='create-new-body1'>
            {/* <h4 className='completed'>loading...</h4> */}
                <Spinner animation="grow" variant="primary" />
                <Spinner animation="grow" variant="secondary" />
                <Spinner animation="grow" variant="success" />
                <Spinner animation="grow" variant="danger" />
                <Spinner animation="grow" variant="warning" />
                <Spinner animation="grow" variant="info" />
                <Spinner animation="grow" variant="dark" />
            
        </div>
        
        }
    </div>
  )
}
