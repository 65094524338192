import React from 'react'


export default function ShopCard({data,index}) {
   
    const renderSub=()=>{
        let arr=[]
        data.collec_names.forEach((topSub,ind)=>{
            if(arr.length<3){
                if(topSub.length<=15){
                    arr.push (<div key={ind} className='categeryChips'>{topSub}</div>)
                }
            } 
        })

        return arr
    }
    /* <div className='left-side'>
            {card()}
        </div>
        <div className='right-side'>
             {card()}
        </div> */
        /* {card()} */
       /* <div className='shop-card-header'>
            <span className='shop-card-header-text'>{data.categoryName}</span>
            <span className='shop-card-header-asp'>{data.asp}</span>
       </div>
       <div className='shop-card-body'>
        {data.topSubCategories.map((topSub,ind)=>{
            return <div key={ind} className='categeryChips'>{topSub}</div>
        })}
       </div> */
       const range=(num)=>{
            
           return `₹${Math.floor(num/50)*50}- ₹${Math.ceil(num/50)*50}`
       }
  return (
    
        
        <div className='shop-card-inner-container'>
                <div className='shop-card-header'>
                    <div className='shop-card-header-left-side'>
                        <div className='round'>{index+1}</div>
                        <div className='shop-card-header-text'>{data.subcat}</div>
                    </div>
                    <div className='shop-card-header-right-side'>
                        <span className='shop-card-header-right-avg'>Avg. Selling Price</span>
                        {/* <span className='shop-card-header-asp'>{range(Math.abs(data.ASP))}</span> */}
                        <span className='shop-card-header-asp'>{`₹${data.ASP[0]}-₹${data.ASP[1]}`}</span>
                    </div>
                    
                </div>
                <div className='shop-card-body'>
                    {renderSub()}
                </div>
        </div>

   
  )
}
