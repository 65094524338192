import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCalendarAlt,
} from "@fortawesome/free-regular-svg-icons";
import { faCity, faUser } from "@fortawesome/free-solid-svg-icons";
import "./ConsumerMetric.css";
import ConsumerBarGraphs from "./ConsumerBarGraphs";
import ConsumerPieChart from "./ConsumerPieChart";
import consumerLiving from "../images/consumerLiving.svg";
import consumerProductExperienced from "../images/consumerProductExperienced.svg";
import consumerProfilingPeriod from "../images/consumerProfilingPeriod.svg";
import consumerUserProfile from "../images/consumerUserProfile.svg";
import axios from "axios";
import HighAffinityUser from "./HighAffinityUser/HighAffinityUser";
import removeTag from "../images/remove_tag.png"
import { UserShoppingChart } from "./HighAffinityUser/UserShoppingChart";
import moment from "moment";
import { useSearchParams, useNavigate } from "react-router-dom";
import MetrixSkeletonLoading from "../SkeletonLoading/MetrixSkeletonLoading";
import checkEnv from "../../utils/checkEnv";
import ConsumerWordCloud from "./ConsumerWordCloud";
import ScatterChart from "./ScatterChart";
import { Skeleton } from 'antd'
import UserPersona from "./UserPersona/UserPersona";
import MostAndLeastIntrested from "./MostAndLeastIntrested/MostAndLeastIntrested";
import BasketAnalysis from "./basketAnalysis/BasketAnalysis";
import ShopAnalysis from "./shopAnalysis/ShopAnalysis";
import MostTrendheader from "./mostTrend/MostTrendheader";
import UserJourney from "./userjourney/UserJourney";
function nFormatter(num) {
  if (num >= 1000000000) {
    return (num / 1000000000).toFixed(1).replace(/\.0$/, "") + "G";
  }
  if (num >= 1000000) {
    return (num / 1000000).toFixed(1).replace(/\.0$/, "") + "M";
  }
  if (num >= 1000) {
    return (num / 1000).toFixed(1).replace(/\.0$/, "") + "K";
  }
  return num;
}
let categoryListNameIndex = {}
let categoryListIdName = {}
let productListIndex = {}
let productListIdName = {}
const ConsumerMetricBoxes = (props) => {
  const navigate = useNavigate()
  const [searchParams, setSearchParams] = useSearchParams()
  const [nextState, setNextState] = useState(false)
  let token = localStorage.getItem("token")
  let brandId = props.brandId
  let startDate = localStorage.getItem("start_date");
  let endDate = localStorage.getItem("end_date");
  let url = checkEnv()
  const [userProfiled, setUserProfiled] = useState();
  const [livingIn, setLivingIn] = useState();
  const [productExperienced, setProductExperienced] = useState();
  const [categoryList, setCategoryList] = useState([])
  const [productList, setProductList] = useState([])
  const [categoryCheckedList, setCategoryCheckedList] = useState([])
  const [productCheckedList, setProductCheckedList] = useState([])

  let profilingPeriod = moment(endDate).diff(moment(startDate), 'days') + 1

  const getUsersProfiled = async () => {
    setUserProfiled('')
    const response = await axios.get(`${url}/brandsdashboardproxy/v1/brand/${searchParams.get('brand')}/usersprofiled/?startDate=${startDate}&endDate=${endDate}&categoryIds=${searchParams.get('categoryIds')}&productIds=${searchParams.get('productIds')}`, {
      headers: {
        'authorization': `${token}`
      }
    })
      .catch((err) => {
        console.log(err);
      });
    setUserProfiled(response.data.value);
  };
  const getLivingIn = async () => {
    setLivingIn('')
    const response = await axios.get(`${url}/brandsdashboardproxy/v1/brand/${searchParams.get('brand')}/userslivingin/?startDate=${startDate}&endDate=${endDate}&categoryIds=${searchParams.get('categoryIds')}&productIds=${searchParams.get('productIds')}`, {
      headers: {
        'authorization': `${token}`
      }
    })
      .catch((err) => {
        console.log(err);
      });
    setLivingIn(response.data.value);
  };
  const getProductExperienced = async () => {
    setProductExperienced('')
    const response = await axios.get(`${url}/brandsdashboardproxy/v1/brand/${searchParams.get('brand')}/productsexperienced/?startDate=${startDate}&endDate=${endDate}&categoryIds=${searchParams.get('categoryIds')}&productIds=${searchParams.get('productIds')}`, {
      headers: {
        'authorization': `${token}`
      }
    })
      .catch((err) => {
        console.log(err);
      });
    setProductExperienced(response.data.value);
  };
  useEffect(() => {
    getUsersProfiled();
    getLivingIn();
    getProductExperienced();
  }, [searchParams]);
  const getCategories = async () => {
    const response = await axios.get(`${url}/brandsdashboardproxy/v1/brand/${searchParams.get(`brand`)}/category/`, {
      headers: {
        'authorization': `${token}`
      }
    })
      .catch((err) => {
      });
    setCategoryList(response.data)
    response.data.map((d, index) => {
      categoryListNameIndex[d.categoryName] = index
      categoryListIdName[d.categoryName] = d.categoryId
    })
  };
  const getProducts = async () => {
    const response = await axios.get(`${url}/brandsdashboardproxy/v1/brand/${searchParams.get(`brand`)}/product/?categoryIds=${searchParams.get('categoryIds')}`, {
      headers: {
        'authorization': `${token}`
      }
    })
      .catch((err) => {
      });
    if (response === undefined) {
      console.log(`${url}/brandsdashboardproxy/v1/brand/${searchParams.get(`brand`)}/product/?categoryIds=${searchParams.get('categoryIds')}`)
    } else {
      setProductList(response.data);
      response.data.map((d, index) => {
        productListIndex[d.name] = index
        productListIdName[d.name] = d.sku
      })
    }

  };

  useEffect(() => {
    getCategories()
  }, [searchParams.get('brand')])

  useEffect(() => {
    getProducts();
  }, [searchParams.get('categoryIds')])
  function arrayRemove(arr, value) {
    return arr.filter(function (geeks) {
      return geeks !== value;
    });

  }
  let format = /[-]/;


  const removeSelectedClickHandler = (e) => {
    let a = searchParams.get('categoryIds').split(',')
    navigate({
      pathname: `/brandsdashboard/consumer`,
      search: `?brand=${searchParams.get('brand')}&categoryIds=${arrayRemove(a, `${categoryListIdName[e.target.id]}`)}&productIds=${[]}&date=${searchParams.get('date')}`
    })
  }

  // console.log("userprofile",userProfiled)
  // console.log("cities",livingIn)
  // console.log("product",productExperienced)
  const removeSelectedProductClickHandler = (e) => {

    let a = searchParams.get('productIds').split(',')
    navigate({
      pathname: `/brandsdashboard/consumer`,
      search: `?brand=${searchParams.get('brand')}&categoryIds=${searchParams.get('categoryIds')}&productIds=${arrayRemove(a, `${productListIdName[e.target.id]}`)}&date=${searchParams.get('date')}`
    })

  }

  const checkedCatList = () => {
    let b = []
    let a = searchParams.get('categoryIds') ? searchParams.get('categoryIds').split(',') : []
    if (categoryList.length === a.length) {
      categoryList.map((d, index) => {
        if (d.categoryId == a[index]) {
          b.push(d.categoryName)
        }
      })
    } else {
      for (let i = 0; i < a.length; i++) {
        for (let j = 0; j < categoryList.length; j++) {
          if (a[i] == categoryList[j].categoryId) {
            b.push(categoryList[j].categoryName)
          }
        }
      }
    }
    setCategoryCheckedList(b)
  }
  const checkedProductList = () => {
    let b = []
    let a = searchParams.get('productIds') ? searchParams.get('productIds').split(',') : []
    if (productList.length === a.length) {
      productList.map((d, index) => {
        if (d.sku == a[index]) {
          b.push(d.name)
        }
      })
    } else {
      for (let i = 0; i < a.length; i++) {
        for (let j = 0; j < productList.length; j++) {
          if (a[i] == productList[j].sku) {
            b.push(productList[j].name)
          }
        }
      }
    }
    setProductCheckedList(b)
  }

  useEffect(() => {
    checkedCatList()
    checkedProductList()
  }, [searchParams, categoryList])

  useEffect(() => {
    if (userProfiled && livingIn && productExperienced) {
      setNextState(true)
    } else {
      setNextState(false)
    }
  }, [userProfiled, livingIn, productExperienced])
  return (
    <div className="boxes">
      {/* <div className="showAll">Showing | {searchParams.get('categoryIds') === "" ?
        `All Categories` : <div className="selectedList">
          {
            categoryCheckedList.map((d, index) => {
              return <div className="selectedData" key={index}>{d}<img id={d} src={removeTag} alt="removeSelectedCategory" onClick={removeSelectedClickHandler} /></div>
            })
          }
        </div>
      } | {
          searchParams.get('productIds') === "" ?
            `All Products` : <div className="selectedList">
              {
                productCheckedList.map((d, index) => {
                  if (index < productList.length) {

                    return <div className="selectedData" key={index}>{d}<img id={d} src={removeTag} alt="removeSelectedProduct" onClick={removeSelectedProductClickHandler} /></div>
                  }
                })
              }
            </div>
        }</div> */}
      <div className="consumer">
        {
          userProfiled && livingIn && productExperienced ?
            <div className="consumerMetricBox">
              <div className="consumerMetrixLeft">

                <div className="dataCategory">Users Profiled</div>
                <div className="value">
                  <div className="det">
                    <b>{nFormatter(userProfiled)}</b>
                    <p></p>
                  </div>
                  {/* <div className="figure">
                  <FontAwesomeIcon icon={faUser} />
                </div> */}
                </div>
              </div>
              <div className='consumerMetricBoxRightSide'>
                <img
                  src={consumerUserProfile}
                  alt='userProfile'
                />
              </div>
            </div>
            :
            <div className="consumerMetricBoxLoader">
              <Skeleton active paragraph={{ rows: 1 }} />
            </div>
        }
        {
          userProfiled && livingIn && productExperienced ?
            <div className="consumerMetricBox">
              <div className="consumerMetrixLeft">
                <div className="dataCategory">Living in</div>
                <div className="value">
                  <div className="det">
                    {nFormatter(livingIn)}
                    <p>Cities</p>
                  </div>
                  {/* <div className="figure">
                  <FontAwesomeIcon icon={faCity} />
                </div> */}
                </div>
              </div>
              <div className='consumerMetricBoxRightSide'>
                <img
                  src={consumerLiving}
                  alt='userLiving'
                />
              </div>
            </div>
            : <div className="consumerMetricBoxLoader">
              <Skeleton active paragraph={{ rows: 1 }} />
            </div>
        }
        {
          userProfiled && livingIn && productExperienced ?
            <div className="consumerMetricBox">
              <div className="consumerMetrixLeft">
                <div className="dataCategory">Products Experienced</div>
                <div className="value">
                  <div className="det">
                    {productExperienced ? productExperienced.toFixed(2) : ""}
                    <p>PerUser</p>
                  </div>
                  {/* <div className="figure"><img src={image} alt="image" width="32px" /></div> */}
                </div>
              </div>
              <div className='consumerMetricBoxRightSide'>
                <img
                  src={consumerProductExperienced}
                  alt='product experienced'
                />
              </div>
            </div>
            :
            <div className="consumerMetricBoxLoader">
              <Skeleton active paragraph={{ rows: 1 }} />
            </div>
        }
        {
          userProfiled && livingIn && productExperienced ?
          <div className="consumerMetricBox">
          <div className="consumerMetrixLeft">

            <div className="dataCategory">Users Profiled</div>
            <div className="value">
              <div className="det">
                <b>{nFormatter(userProfiled)}</b>
                <p></p>
              </div>
  
            </div>
          </div>
          <div className='consumerMetricBoxRightSide'>
            <img
              src={consumerUserProfile}
              alt='userProfile'
            />
          </div>
        </div>:
        <div className="consumerMetricBoxLoader">
          <Skeleton active paragraph={{ rows: 1 }} />
        </div>
        }
        {/* {
          userProfiled && livingIn && productExperienced ?
          <div className="consumerMetricBox">
          <div className="consumerMetrixLeft">

            <div className="dataCategory">Users Profiled</div>
            <div className="value">
              <div className="det">
                <b>{nFormatter(userProfiled)}</b>
                <p></p>
              </div>

            </div>
          </div>
          <div className='consumerMetricBoxRightSide'>
            <img
              src={consumerUserProfile}
              alt='userProfile'
            />
          </div>
        </div>:
        <div className="consumerMetricBoxLoader">
          <Skeleton active paragraph={{ rows: 1 }} />
        </div>
        } */}
        {/* {
          userProfiled && livingIn && productExperienced ?
            <div className="consumerMetricBox">
              <div className="consumerMetrixLeft">

                <div className="dataCategory">Profiling Period</div>
                <div className="value">
                  <div className="det">
                    <div>
                      {profilingPeriod}
                    </div>
                    <p>Days</p>
                  </div>
                </div>
              </div>
              <div className='consumerMetricBoxRightSide'>
              <img
                  src={consumerProfilingPeriod}
                  alt='profiling period'
                />
              </div>
            </div>
            : <MetrixSkeletonLoading />
        } */}
      </div>
      {/* <ConsumerBarGraphs brandId={props.brandId} allChecked={props.allChecked}
        allProductsChecked={props.allProductsChecked}
        singleCheck={props.singleCheck}
        singleProductCheck={props.singleProductCheck}
        nextState = {nextState}
      /> */}
      <UserPersona/>
      {/* <HighAffinityUser brandId={props.brandId} allChecked={props.allChecked}
        allProductsChecked={props.allProductsChecked}
        singleCheck={props.singleCheck}
        singleProductCheck={props.singleProductCheck} nextState={nextState} /> */}
      {/* <div className="userShoppingDetails">
        <div className="wordcloud">
          <p>word cloud</p>
          <ConsumerWordCloud />
        </div>
        <div className="scatterplot">
          <p>scatter plot</p>
          <ScatterChart />
        </div>
        <UserShoppingChart brandId={props.brandId} allChecked={props.allChecked}
          allProductsChecked={props.allProductsChecked}
          singleCheck={props.singleCheck} singleProductCheck={props.singleProductCheck} nextState = {nextState} />
      </div> */}

      {/* <MostAndLeastIntrested/> */}
      <MostTrendheader/>
      
      {/* <ConsumerPieChart brandId={props.brandId} allChecked={props.allChecked}
        allProductsChecked={props.allProductsChecked}
        singleCheck={props.singleCheck} singleProductCheck={props.singleProductCheck} nextState={nextState} /> */}
      <div className="shopping-analysis">
        <ShopAnalysis/>
      </div>
      <div className="basket-analysis">
          <BasketAnalysis/>
      </div>
      <UserJourney/>
      {/* 
      <div className="flex-items">
        
        
      </div> */}
    </div>
  );
};

export default ConsumerMetricBoxes;
