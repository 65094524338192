import React, { useState, useEffect } from 'react'
import "./UserPersona.css"
import axios from 'axios'
import checkEnv from "../../../utils/checkEnv";
import { useSearchParams } from 'react-router-dom';
import Carousel from 'react-bootstrap/Carousel'
import maleLogo from "../../images/maleUserPersona.svg"
import femaleLogo from "../../images/femaleUserPersona.svg"
import { Skeleton } from 'antd';
import UserCard from './UserCard';

export default function UserPersona() {
    const [userPersonaData, setUserPersonaData] = useState([])
    const [searchParams] = useSearchParams()
    const [selectedCategory,setSelectedCategory]=useState("all")
    const [userPersonaDetails,setUserPersonaDetails]=useState({})

    let token = localStorage.getItem("token")
    let url = checkEnv()


    // brandsdashboardproxy/v1/brand/${searchParams.get('brand')}/usersprofiled/?startDate=${startDate}&endDate=${endDate}&categoryIds=${searchParams.get('categoryIds')}&productIds=${searchParams.get('productIds')}
    const getUserPersona = async () => {
        await axios.get(`${url}/brandsdashboardproxy/v1/brand/${searchParams.get('brand')}/user_persona/`, {
            headers: {
                'authorization': `${token}`
            }
        })
            .then(res => {
                var result = []
                for (let i = 0; i < res.data.length; i += 2) {
                    if (i + 1 < res.data.length) {
                        result.push(
                            {
                                first: res.data[i],
                                second: res.data[i + 1]
                            }
                        )
                    } else {
                        result.push(
                            {
                                first: res.data[i],
                                second: null
                            }
                        )
                    }
                }
                setUserPersonaData(result)
            })
            .catch((err) => {
                console.log(err);
            });
    };



    useEffect(() => {
        getUserPersona()
    }, [searchParams])

    useEffect(()=>{
        axios.get(`${url}/brandsdashboardproxy/v1/brand/${searchParams.get('brand')}/cohorts/`,{
                    headers: {
                        'authorization': `${token}`
                    }})
                    .then(res=>{
                        console.log(res.data)
                        setUserPersonaDetails(res.data)

                    })
    },[searchParams])
    

   const changeCategoryHandler=(value)=>{
        setSelectedCategory(value)
   }

    return (

        <div className='userPersonaContainer'>
            {
               Object.keys(userPersonaDetails).length!==0?
                    <>


                        <div className='userPersonaHeaderContainer'>
                            <div className='userPersonaHeaderText'>
                                User Persona with the Highest Brand Affinity
                            </div>
                            <div className='userPersonaCategeries'>
                                <div onClick={()=>changeCategoryHandler("all")} className={selectedCategory==="all"?'active':'categeryChips'}>All Profiles</div>
                                <div onClick={()=>changeCategoryHandler("metro")} className={selectedCategory==="metro"?'active':'categeryChips'}>Metro Resident</div>
                                <div onClick={()=>changeCategoryHandler("nonMetro")} className={selectedCategory==="nonMetro"?'active':'categeryChips'}>Non-Metro Resident</div>
                            </div>
                        </div>
                        {/* <div className='userPersonaBodyContainer'>
                            <Carousel variant="dark">
                                {
                                    userPersonaData?.length > 0 && userPersonaData.map((data, index) => {
                                        return <Carousel.Item interval={2000} >
                                            <div className='userPersonaCarouselItem'>
                                                <div className='carouselCardItemContainer' style={{ background: data.first.gender === 'male' ? '#F9FDFF' : 'white' }}>
                                                    <div className='userPersonaCardLeft'>
                                                        <img
                                                            src={data.first.gender === 'male' ? maleLogo : femaleLogo}
                                                        />
                                                        <div className='userPersonaGender'>
                                                            {data.first.gender === 'male' ? `Male User` : `Female User`}
                                                        </div>
                                                        <div>
                                                            <span className='userPersonaAgeHeader'>
                                                                Age
                                                            </span>
                                                            <span className='userPersonaAgeValue'>
                                                                {data?.first.age}
                                                            </span>
                                                        </div>
                                                        <div>
                                                            <span className='userPersonaAgeHeader'>
                                                                Location
                                                            </span>
                                                            <span className='userPersonaAgeValue'>
                                                                {data?.first.location}
                                                            </span>
                                                        </div>
                                                    </div>

                                                    <div className='userPersonaCardRight'>
                                                        <div className='userPersonaCategoryIntrestedHeader'>
                                                            Categories Interested in
                                                        </div>
                                                        <div className='userPersonaCategoryIntrestedData'>
                                                            {
                                                                data?.first?.categories_interested_in.length > 0 && data?.first?.categories_interested_in.map((d, index) => {
                                                                    return <span key={index} style={{ background: data.first.gender === 'male' ? '#ECF8FF' : '#FFF1FE', color: data.first.gender === 'male' ? '#003087' : '#98348F' }}>
                                                                        {d}
                                                                    </span>
                                                                })
                                                            }
                                                        </div>
                                                        <div className='userPersonaCategoryIntrestedHeader'>
                                                            Frequently purchased
                                                        </div>
                                                        <div className='userPersonaCategoryIntrestedData'>
                                                            {
                                                                data?.first?.frequently_used.length > 0 && data?.first?.frequently_used.map((d, index) => {
                                                                    return <span key={index} style={{ background: data.first.gender === 'male' ? '#ECF8FF' : '#FFF1FE', color: data.first.gender === 'male' ? '#003087' : '#98348F' }}>
                                                                        {d}
                                                                    </span>
                                                                })
                                                            }
                                                        </div>
                                                    </div>

                                                </div>
                                                {
                                                    data?.second ?
                                                        <div className='carouselCardItemContainer' style={{ background: data?.second?.gender === 'male' ? '#F9FDFF' : 'white' }}>
                                                            <div className='userPersonaCardLeft'>
                                                                <img
                                                                    src={data?.second?.gender === 'male' ? maleLogo : femaleLogo}
                                                                />
                                                                <div className='userPersonaGender'>
                                                                    {data?.second?.gender === 'male' ? `Male User` : `Female User`}
                                                                </div>
                                                                <div>
                                                                    <span className='userPersonaAgeHeader'>
                                                                        Age
                                                                    </span>
                                                                    <span className='userPersonaAgeValue'>
                                                                        {data?.second?.age}
                                                                    </span>
                                                                </div>
                                                                <div>
                                                                    <span className='userPersonaAgeHeader'>
                                                                        Location
                                                                    </span>
                                                                    <span className='userPersonaAgeValue'>
                                                                        {data?.second?.location}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                            <div className='userPersonaCardRight'>
                                                                <div className='userPersonaCategoryIntrestedHeader'>
                                                                    Categories Interested in
                                                                </div>
                                                                <div className='userPersonaCategoryIntrestedData'>
                                                                    {
                                                                        data?.second?.categories_interested_in.length > 0 && data?.second?.categories_interested_in.map((d, index) => {
                                                                            return <span key={index} style={{ background: data.second.gender === 'male' ? '#ECF8FF' : '#FFF1FE', color: data.second.gender === 'male' ? '#003087' : '#98348F' }}>
                                                                                {d}
                                                                            </span>
                                                                        })
                                                                    }
                                                                </div>
                                                                <div className='userPersonaCategoryIntrestedHeader'>
                                                                    Frequently purchased
                                                                </div>
                                                                <div className='userPersonaCategoryIntrestedData'>
                                                                    {
                                                                        data?.second?.frequently_used.length > 0 && data?.second?.frequently_used.map((d, index) => {
                                                                            return <span key={index} style={{ background: data.second.gender === 'male' ? '#ECF8FF' : '#FFF1FE', color: data.second.gender === 'male' ? '#003087' : '#98348F' }}>
                                                                                {d}
                                                                            </span>
                                                                        })
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                        : ''}
                                            </div>
                                        </Carousel.Item>
                                    })
                                }
                            </Carousel>
                        </div> */}


                        {/* <div className='userPersonaBodyContainer'>
                            {userPersonaData?.length > 0 && userPersonaData.map((data,index)=>{
                                return <UserCard data={data} key={index}/>
                            })}
                        </div> */}
                        {/* {selectedCategory==='all'&&<UserCard data={userPersonaData[0]}/>}
                        {selectedCategory==='metro'&&<UserCard data={userPersonaData[1]}/>}
                        {selectedCategory==='nonMetro'&&<UserCard data={userPersonaData[2]}/>} */}
                        {selectedCategory==='all'&&<UserCard data={userPersonaDetails.all} type={"all"}/>}
                        {selectedCategory==='metro'&&<UserCard data={userPersonaDetails.metro} type={"metro"}/>}
                        {selectedCategory==='nonMetro'&&<UserCard data={userPersonaDetails.non_metro} type={"nonmetro"}/>}

                    </>
                    : <Skeleton
                        active
                        title
                        paragraph={{
                            rows: 4,
                        }} />

            }
        </div>
    )
}
