import React, { useState } from 'react';
import "./PreviewQuestionBox.css"
import { useParams } from 'react-router-dom';
import condLogicIcon from '../../../../images/condLogicIcon.svg';
import MultiSelect from "../../../../images/MultiSelect.svg"
import SingleSelect from '../../../../images/SingleSelect.svg';
import MatrixSelect from "../../../../images/MatrixSelect.svg";
import RangeSelect from "../../../../images/RangeSelection.svg";
import OrderSelect from "../../../../images/OrderSelection.svg";
import TextInput from "../../../../images/TextInput.svg";
import MatrixOption from "../../../../images/MatrixSelectCircle.svg"
import MultiselectOption from '../../../../images/previewMultipleIcon.svg';
// import { checkSurveyEnv } from '../../../../../utils/checkEnv';


function PreviewQuestionBox(props) {
    const [questionObject, setQuestionObject] = useState(props.question);
    let { surveyId } = useParams();
    const questionTypeLabels = {
        'SINGLE_SELECT': 'Single Selection',
        'MULTI_SELECT': 'Multiple Selection',
        'MATRIX_SELECT': 'Matrix Selection',
        'RANGE_SELECTION': 'Range Selection',
        'ORDER_SELECTION': 'Order Selection',
        'TEXT_INPUT': 'Text Input',
        'TERMINAL_QUESTION': 'Terminal Question'
    }
    
    const renderOptionDesign = () => {
        switch (questionObject.question_type) {
            case 'SINGLE_SELECT':
                return <img src={MatrixOption} alt='singleSelect' />
            case 'MULTI_SELECT':
                return <img src={MultiselectOption} alt='multiSelect' />
            default:
                return <></>
        }
    }

    const renderQuestionData = () => {
        let questionType = questionObject.question_type;
        if (questionType == 'MATRIX_SELECT') {
            return (
                <>
                    <table className='condensed-matrixTable'>
                        <thead>
                            <tr>
                                <td></td>
                                {
                                    questionObject.options.choices && questionObject.options.choices.map((param) => {
                                        return <td key={param}><div style={{ display: "flex", justifyContent: "center" }}><div className='table-params'>{param}</div></div></td>
                                    })
                                }
                            </tr>
                        </thead>
                        <tbody>
                            {
                                questionObject.options.params && questionObject.options.params.map((param) => {
                                    return (
                                        <tr>
                                            <td>{param}</td>
                                            {
                                                questionObject.options.choices.map((param) => {
                                                    return <td key={param} >
                                                        <img src={MatrixOption} alt='matrixOption' />
                                                    </td>
                                                })
                                            }
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </table>
                </>
            )
        }
        else if (questionType == 'TEXT_INPUT') {
            return (
                <div className='textInputDiv'>
                    <div style={{ borderBottom: "1px solid #928e8e" }}>User will be asked to fill in the Text Box</div>
                </div>
            )
        }
        else if (questionType == 'RANGE_SELECTION') {
            return <div className="slidecontainer">
                <div className='numRadioSection'>
                    {
                        questionObject.options.map((number, index) => {
                            return (
                                <label className='numRadio' key={index}>
                                    <input type={'radio'} value={number} name={'numRadio'} hidden />
                                    {number}
                                </label>
                            )
                        })
                    }
                </div>
                <input type="range" value={questionObject.options[3]} min={questionObject.options[0]} max={questionObject.options[questionObject.options.length - 1]} className="slider" id="myRange" />
            </div>
        }
        else {
            if (questionObject['options'] && questionObject.options.length > 0) {
                return (
                    <div className='smo-options-grid' style={{ marginTop: "2rem" }}>
                        {
                            (questionObject.options.length > 0)
                                ?
                                <>
                                    {questionObject.options.map((option, index) => {
                                        return (
                                            <div className='smo-indivOption'>
                                                <div className='alignCenter-row' style={{ width: "100%" }}>
                                                    {renderOptionDesign()}
                                                    &nbsp;&nbsp;
                                                    <div style={{ width: "100%", textAlign: "left" }} key={index} >{option}</div>
                                                </div>
                                            </div>
                                        )
                                    })}
                                </>
                                :
                                null
                        }
                    </div>
                )
            }
        }
    }

    const questionType = () => {
        switch (questionObject.question_type) {
            case 'SINGLE_SELECT':
                return <img src={SingleSelect} alt='singleSelect' />
            case 'MULTI_SELECT':
                return <img src={MultiSelect} alt='multiSelect' />
            case 'MATRIX_SELECT':
                return <img src={MatrixSelect} alt='matrixSelect' />
            case 'RANGE_SELECTION':
                return <img src={RangeSelect} alt='rangeSelect' />
            case 'ORDER_SELECTION':
                return <img src={OrderSelect} alt='orderSelect' />
            case 'TEXT_INPUT':
                return <img src={TextInput} alt='textInput' />
            default:
                return <></>
        }
    }

    return (
        <div className='questionBox' id={`condensed-${questionObject.id}`} key={questionObject.id}>
            <div className='cond-inner1'>
                <div className='cond-questionText'>
                    {(props.questionIndex + 1 < 10) ? '0' + (props.questionIndex + 1) : props.questionIndex + 1}&nbsp;&nbsp;&nbsp;
                    <div className='cond-questionText-inner'>
                        {questionObject.question_text}
                        <span className='questionType'>{questionType()}{questionTypeLabels[questionObject.question_type]}</span>
                    </div>
                </div>
                <div>
                    <img style={{ cursor: "pointer" }} src={condLogicIcon} />
                </div>
            </div>
            <div className='image-container'>
                    {questionObject.media_content && !Array.isArray(questionObject.media_content) && <div>
                        <img className="image" src={questionObject.media_content} alt="selectedImage" />
                    </div>}
            </div>
            {renderQuestionData()}
        </div>
    )
}

export default PreviewQuestionBox