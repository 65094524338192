import React, { useState } from 'react'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import tick from '../images/rnAckTick.svg';
import goToDashboard from '../images/goToDashboard.svg';
import './style.css'
import { useNavigate, useParams } from 'react-router';
import moment from 'moment';
import { useSearchParams } from 'react-router-dom';
import axios from 'axios';
import previewSubmitError from "../images/previewSubmitError.svg"
import { checkSurveyEnv } from '../../utils/checkEnv';

export default function ResearchPageSubmitted(props) {
  const [successState, setSuccessState] = useState(false)
  const [searchParams, setSearchParams] = useSearchParams()
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  let surveyUrl=checkSurveyEnv()
  const navigate = useNavigate();
  let startDate = localStorage.getItem('start_date') ? localStorage.getItem('start_date') : localStorage.setItem("start_date", moment().subtract(6, 'days').format('YYYY-MM-DD'))
  let endDate = localStorage.getItem('end_date') ? localStorage.getItem('end_date') : localStorage.setItem("end_date", moment().format("YYYY-MM-DD"))
  let { surveyId } = useParams();

  const navigateToDashboard = () => {
    navigate({
      pathname: `/brandsdashboard/research`,
      search: `?brand=${localStorage.getItem("brand_id")}&categoryIds=${searchParams.get('categoryIds')}&productIds=${searchParams.get('productIds')}&date=${searchParams.get('date')}`
    })
  }

  const doneSurveyClickHandler = () => {
    handleShow()
    axios.put(`${surveyUrl}/smyttenresearch/survey/${surveyId}`, {
      "status": "PENDING_APPROVAL"
    })
      .then(res => {
        setSuccessState(true)
      })
      .catch(err => {
        setSuccessState(false)
      })
  }

  return (
    <>
      {/* <Button variant="primary" >
        DONE
      </Button> */}
      <div className='previewSubmitDoneSection'>
        <button className='previewDoneButton' onClick={doneSurveyClickHandler}>
          Done
        </button>
      </div>
      <Modal size='lg' show={show} onHide={handleClose}>
        {
          successState === true ?
            <div className='rn-ack'>
              <div className='rn-ack-head'>
                Your Survey has been Submitted. It will take 48 hours for it to be approved and ready for publication.
              </div>
              <div className='rn-ack-head2'>
                WeLorem ipsum dolor sit amet, consectetur adipiscing elit. Amet nulla augue nascetur.
              </div>
              <div className='rn-ack-tick'>
                <img src={tick} />
              </div>
              <div className='rn-ack-action'><img src={goToDashboard} onClick={navigateToDashboard} /></div>
            </div>
            :
            <div className='rn-ack'>
              <div className='rn-ack-head'>
                You Have Insufficient Credits Contact Administrator for Top Up
              </div>
              <div className='rn-ack-head2'>
                WeLorem ipsum dolor sit amet, consectetur adipiscing elit. Amet nulla augue nascetur.
              </div>
              <div className='rn-ack-tick'>
                <img src={previewSubmitError} />
              </div>
              <div className='rn-ack-action'><img src={goToDashboard} onClick={navigateToDashboard} /></div>
            </div>
        }
      </Modal>
    </>
  )
}
