import React, { useState, useEffect } from "react";
import DatamapsIndia from "react-datamaps-india";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSortDown, faSortUp } from "@fortawesome/free-solid-svg-icons";
import stateBackgroundBlurImage from "./images/planLock.png";
import DataLoader from "./images/noDataFull.svg";
import Dropdown from "react-bootstrap/Dropdown";
import { useSearchParams, useNavigate } from "react-router-dom";
import ProductWiseSummary from "./ProductWiseSummary";
import { Skeleton } from "antd";
import rdSortUp from "./images/Group_right.svg";
import rdSortDown from "./images/Group_left.svg";

import "./StateWiseSummary.css";
import checkEnv from "../utils/checkEnv";

const StateWiseSummary = (props) => {
  const [noDataLoad, setNoDataLoad] = useState(false);
  let noOfSections;
  const [currentSection, setCurrentSection] = useState(0);
  const [nextState, setNextState] = useState(false);
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  let stateLabelColor;
  let stateColors = [
    "rgb(77, 96, 172)",
    "rgb(103, 136, 193)",
    "rgb(117, 153, 201)",
    "rgb(119, 156, 202)",
    "rgb(128, 167, 207)",
    "rgb(132, 172, 209)",
    "rgb(136, 177, 211)",
    "rgb(137, 178, 212)",
    "rgb(142, 184, 215)",
    "rgb(144, 184, 215)",
  ];
  let token = localStorage.getItem("token");
  let states = {};
  const brandId = localStorage.getItem("brand_id");
  let startDate = localStorage.getItem("start_date");
  let endDate = localStorage.getItem("end_date");
  let url = checkEnv();
  const [data, setData] = useState([]);
  const [statesList, setStatesList] = useState({});
  const [sortState, setSortState] = useState({
    user_sampled: false,
    product_sampled: false,
    nps_rating: false,
  });
  const [freeplanState, setFreePlanState] = useState(true);

  let categoryId = localStorage.getItem("allcategoryId")
    ? localStorage.getItem("allcategoryId").split(",")
    : [];
  let productId = localStorage.getItem("allProductId")
    ? localStorage.getItem("allProductId").split(",")
    : [];

  function nFormatter(num) {
    if (num >= 1000000000) {
      return (num / 1000000000).toFixed(1).replace(/\.0$/, "") + "G";
    }
    if (num >= 1000000) {
      return (num / 1000000).toFixed(1).replace(/\.0$/, "") + "M";
    }
    if (num >= 1000) {
      return (num / 1000).toFixed(1).replace(/\.0$/, "") + "K";
    }
    return num;
  }
  const getStateDetails = async () => {
    setData([]);
    const response = await axios
      .get(
        `${url}/brandsdashboardproxy/v1/brand/${searchParams.get(
          "brand"
        )}/usertriedstatewise/?startDate=${startDate}&endDate=${endDate}&categoryIds=${searchParams.get(
          "categoryIds"
        )}&productIds=${searchParams.get("productIds")}`,
        {
          headers: {
            authorization: `${token}`,
          },
        }
      )
      .catch((err) => {
        console.log(err);
      });
    setData(response.data.map((dat) => dat));
    console.log(response.data);
    response.data.length === 0 ? setNoDataLoad(true) : setNoDataLoad(false);
    setCurrentSection(1);
    setStatesList(states);
  };
  useEffect(() => {
    getStateDetails();
  }, [searchParams]);

  function toTitles(s) {
    if (s === "JAMMU AND KASHMIR") {
      return "Jammu & Kashmir";
    }
    if (s === "ARUNACHAL PRADESH") {
      return "Arunanchal Pradesh";
    }
    return s.replace(/\w\S*/g, function (t) {
      return t.charAt(0).toUpperCase() + t.substr(1).toLowerCase();
    });
  }

  data.map((dat) => {
    // console.log(toTitles(dat.regionName))
    states[toTitles(dat.regionName)] = { value: dat.totalSampledUsers };
  });

  const stateClickHandler = (e) => {
    navigate({
      pathname: `/brandsdashboard/performance/:stateDetails`,
      search: `?brand=${searchParams.get("brand")}&stateName=${
        e.target.id
      }&categoryIds=${searchParams.get(
        "categoryIds"
      )}&productIds=${searchParams.get("productIds")}&date=${searchParams.get(
        "date"
      )}`,
    });
  };
  const ascendingStateHandler = () => {
    data.sort((a, b) => {
      if (a.regionName > b.regionName) {
        return 1;
      } else if (b.regionName > a.regionName) {
        return -1;
      } else {
        return 0;
      }
    });
  };
  const descendingStateHandler = () => {
    data.sort((a, b) => {
      if (a.regionName > b.regionName) {
        return -1;
      } else if (b.regionName > a.regionName) {
        return 1;
      } else {
        return 0;
      }
    });
  };
  const ascendingUsersSampledHandler = () => {
    data.sort((a, b) => {
      return a.totalSampledUsers - b.totalSampledUsers;
    });
  };
  const descendigUsersSampledHandler = () => {
    data.sort((a, b) => {
      return b.totalSampledUsers - a.totalSampledUsers;
    });
  };
  const ascendingProductsSampledHandler = () => {
    data.sort((a, b) => {
      return a.totalProductsSampled - b.totalProductsSampled;
    });
  };
  const descendingProductsSampledHandler = () => {
    data.sort((a, b) => {
      return b.totalProductsSampled - a.totalProductsSampled;
    });
  };

  const ascendingNpsHandler = () => {
    data.sort((a, b) => {
      return a.npsRating - b.npsRating;
    });
  };

  const descendingNpsHandler = () => {
    data.sort((a, b) => {
      return b.npsRating - a.npsRating;
    });
  };

  useEffect(() => {
    if (localStorage.getItem("plan") !== null) {
      if (localStorage.getItem("plan") === "free") {
        setFreePlanState(false);
      }
    }
  }, []);

  useEffect(() => {
    if (
      localStorage.getItem("plan") !== null &&
      localStorage.getItem("plan") === "Free" &&
      document.getElementsByClassName("stateDataSection") !== undefined &&
      document.getElementsByClassName("stateWisePlanState") &&
      document.getElementsByClassName("indiaMap1")
    ) {
      document
        .getElementsByClassName("stateDataSection")[0]
        .classList.add("plan");
      document.getElementsByClassName("stateWisePlanState")[0].style.display =
        "flex";
      document.getElementsByClassName("indiaMap1")[0].style.border =
        "2px solid #FFCBC4";
    }
  }, []);

  useEffect(() => {
    if (props.nextState === true && data.length !== 0) {
      setNextState(true);
    } else {
      setNextState(false);
    }
  }, [props.nextState, data]);

  const changeCurrentSection = (step) => {
    noOfSections = Math.ceil(parseFloat(data.length / 5));
    if (step == "previous") {
      if (currentSection - 1 <= 0) setCurrentSection(1);
      else setCurrentSection(currentSection - 1);
    } else if (step == "next") {
      if (currentSection + 1 >= noOfSections) setCurrentSection(noOfSections);
      else setCurrentSection(currentSection + 1);
    }
  };

  const getStepper = () => {
    if (data.length > 0) {
      let startIndex = (currentSection - 1) * 5;
      let endIndex = currentSection * 5;
      noOfSections = Math.ceil(parseFloat(data.length / 5));
      return (
        <div className="ca-table2-stepper">
          <div
            className="ca-table2-stepper-navBtn-active"
            onClick={() => changeCurrentSection("previous")}
          >
            <span
              className={
                startIndex < 5
                  ? "ca-table2-stepper-button-deactive"
                  : "ca-table2-stepper-button"
              }
            >
              {"< Back"}
            </span>
          </div>
          <div>
            Showing{" "}
            <span className="text-highlight">{`${
              startIndex + 1
            }-${endIndex}`}</span>{" "}
            of <span className="text-highlight">{`${data.length}`} </span>{" "}
            Cities
          </div>
          <div
            className="ca-table2-stepper-navBtn-active"
            onClick={() => changeCurrentSection("next")}
          >
            <span className="ca-table2-stepper-button">{"Next >"}</span>
          </div>
        </div>
      );
    }
  };
  const makeDefaultState = (param, sortstate) => {
    let keys = Object.keys(sortstate).filter((key) => key !== param);
    keys.forEach((key) => {
      sortstate[key] = false;
    });
    return sortstate;
  };

  const sortSurveyList = (param, order) => {
    setCurrentSection(1);
    let sortStateCopy = { ...sortState };
    sortStateCopy = makeDefaultState(param, sortStateCopy);
    sortStateCopy[param] = !sortStateCopy[param];
    setSortState({ ...sortStateCopy });

    if (param == "user_sampled") {
      if (order == "ascending") {
        ascendingUsersSampledHandler();
      } else {
        descendigUsersSampledHandler();
      }
    }
    if (param == "product_sampled") {
      if (order == "ascending") {
        ascendingProductsSampledHandler();
      } else {
        descendingProductsSampledHandler();
      }
    }
    if (param == "nps_rating") {
      if (order == "ascending") {
        ascendingNpsHandler();
      } else {
        descendingNpsHandler();
      }
    }
  };
  // console.log("states",states)
  return (
    <>
      <div className="indiaMap1">
        <div className="stateDataSection">
          <div className="stateSummaryHeading">
            <div className="heatMapHeader">
              <span className="maps-header-text">Geographic Distibution </span>
              &nbsp;<span className="maps-header-subtext">(Heat Map)</span>
            </div>
            {/* <div className="stateSortSection">
              <select>
                <option value="All States">All States</option>
              </select>
              <div className="stateSort">
                <Dropdown>
                  <Dropdown.Toggle className="stateSortDropDown" >
                    <div> <FontAwesomeIcon id="iconProductSort" icon={faArrowUpShortWide} />Sort By</div>
                  </Dropdown.Toggle>
                  <Dropdown.Menu className="sortStateSectionHideMenu">
                    <Dropdown.Item>
                      <div className="sortLabel"><FontAwesomeIcon className="font" onClick={ascendingStateHandler} icon={faArrowDownShortWide} /><FontAwesomeIcon className="font" onClick={descendingStateHandler} icon={faArrowUpShortWide} /> State</div>
                    </Dropdown.Item>
                    <Dropdown.Item>
                      <div className="sortLabel"><FontAwesomeIcon className="font" onClick={ascendingUsersSampledHandler} icon={faArrowDownShortWide} /><FontAwesomeIcon className="font" onClick={descendigUsersSampledHandler} icon={faArrowUpShortWide} /> Total users Sampled</div>
                    </Dropdown.Item>
                    <Dropdown.Item>
                      <div className="sortLabel"><FontAwesomeIcon className="font" onClick={ascendingProductsSampledHandler} icon={faArrowDownShortWide} /><FontAwesomeIcon className="font" onClick={descendingProductsSampledHandler} icon={faArrowUpShortWide} /> Total Products Sampled</div>
                    </Dropdown.Item>
                    <Dropdown.Item>
                      <div className="sortLabel"><FontAwesomeIcon className="font" onClick={ascendingNpsHandler} icon={faArrowDownShortWide} /><FontAwesomeIcon className="font" onClick={descendingNpsHandler} icon={faArrowUpShortWide} /> NPS Rating</div>
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div> */}
          </div>
          {data.length !== 0 ? (
            <div className="indiaMap">
              <div className="dataMap">
                <DatamapsIndia
                  style={{ width: 100, border: "2px solid white" }}
                  regionData={states}
                  hoverComponent={({ value }) => {
                    // console.log(value)
                    return (
                      <div>
                        <div>
                          <p>{value.name}</p>
                          {value.value ? value.value : 0}
                        </div>
                      </div>
                    );
                  }}
                  mapLayout={{
                    startColor: "rgb(170, 211, 228)",
                    endColor: "rgb(88, 114, 183)",
                    hoverTitle: "Count",
                    noDataColor: "rgb(170, 211, 228)",
                    borderColor: "gray",
                    hoverBorderColor: "#f5f5f5",
                    hoverColor: "rgb(88, 114, 183)",
                  }}
                />
              </div>
              <div className="stateDetails">
                <div className="stateHeaders">
                  {/* <div className="state serialNumber"></div> */}
                  <div className="state stateName-head">States</div>
                  <div
                    className={
                      sortState.user_sampled
                        ? "state UserSampled sortedCheck"
                        : "state UserSampled"
                    }
                    onClick={() =>
                      document.getElementById("sort-user-sampled").click()
                    }
                  >
                    Total users Sampled
                    {sortState.user_sampled ? (
                      <img
                        id="sort-user-sampled"
                        src={rdSortUp}
                        alt=""
                        onClick={() =>
                          sortSurveyList("user_sampled", "ascending")
                        }
                      />
                    ) : (
                      <img
                        id="sort-user-sampled"
                        src={rdSortDown}
                        alt=""
                        onClick={() =>
                          sortSurveyList("user_sampled", "descending")
                        }
                      />
                    )}
                  </div>
                  <div
                    className={
                      sortState.product_sampled
                        ? "state ProductSampled sortedCheck"
                        : "state ProductSampled"
                    }
                    onClick={() =>
                      document.getElementById("sort-product_sampled").click()
                    }
                  >
                    Total Products Sampled
                    {sortState.product_sampled ? (
                      <img
                        id="sort-product_sampled"
                        src={rdSortUp}
                        alt=""
                        onClick={() =>
                          sortSurveyList("product_sampled", "ascending")
                        }
                      />
                    ) : (
                      <img
                        id="sort-product_sampled"
                        src={rdSortDown}
                        alt=""
                        onClick={() =>
                          sortSurveyList("product_sampled", "descending")
                        }
                      />
                    )}
                  </div>
                  <div
                    className={
                      sortState.nps_rating
                        ? "state NPSRating sortedCheck"
                        : "state NPSRating"
                    }
                    onClick={() =>
                      document.getElementById("sort-nps_rating").click()
                    }
                  >
                    Net Promoter Score
                    {sortState.nps_rating ? (
                      <img
                        id="sort-nps_rating"
                        src={rdSortUp}
                        alt=""
                        onClick={() =>
                          sortSurveyList("nps_rating", "ascending")
                        }
                      />
                    ) : (
                      <img
                        id="sort-nps_rating"
                        src={rdSortDown}
                        alt=""
                        onClick={() =>
                          sortSurveyList("nps_rating", "descending")
                        }
                      />
                    )}
                  </div>
                </div>
                <div className="stateValues">
                  {data.map((dat, index) => {
                    if (index <= 8) {
                      stateLabelColor = stateColors[index];
                    } else {
                      stateLabelColor = stateColors[9];
                    }
                    let startIndex = (currentSection - 1) * 5;
                    let endIndex = currentSection * 5;
                    if (index >= startIndex && index < endIndex) {
                      let right = 195 - dat.npsRating;
                      // console.log(right)
                      // right=95-(-100)
                      return (
                        <tr
                          className="stateData"
                          key={index}
                          onClick={stateClickHandler}
                          id={dat.regionName}
                        >
                          {/* <td className="state serialNumber">{index + 1}</td> */}
                          <td className="stateName" id={dat.regionName}>
                            <div
                              className="stateColor"
                              style={{
                                background: `${stateLabelColor}`,
                                width: "10px",
                                height: "10px",
                              }}
                            ></div>
                            <div className="regionName" id={dat.regionName}>
                              {dat.regionName}
                            </div>
                          </td>
                          <td className="state UserSampled" id={dat.regionName}>
                            {nFormatter(dat.totalSampledUsers)}
                          </td>
                          <td
                            className="state ProductSampled"
                            id={dat.regionName}
                          >
                            {nFormatter(dat.totalProductsSampled)}
                          </td>
                          <td className="levelnpsDetails" id={dat.regionName}>
                            <div
                              className="state NPSRating"
                              id={dat.regionName}
                            >
                              {dat.npsRating &&
                              dat.npsRating >= -100 &&
                              dat.npsRating <= 100
                                ? Math.round(dat.npsRating)
                                : "NA"}
                            </div>
                            {dat.npsRating &&
                            dat.npsRating >= -100 &&
                            dat.npsRating <= 100 ? (
                              <div className="mainColor" id={dat.regionName}>
                                <div
                                  className="levelLinearGraph"
                                  id={dat.regionName}
                                ></div>
                                <div
                                  className="levelNPSMarker "
                                  style={{ right: `${-200 + right}px` }}
                                  id={dat.regionName}
                                >
                                  {/* npsRatingMarker */}
                                </div>
                              </div>
                            ) : (
                              <div
                                className="mainColor"
                                id={dat.regionName}
                                style={{ background: "white" }}
                              >
                                <div
                                  className="levelLinearGraph"
                                  id={dat.regionName}
                                  style={{ background: "white" }}
                                ></div>
                              </div>
                            )}
                          </td>
                        </tr>
                      );
                    }
                  })}
                </div>
                <div className="ca-table2-stepper">{getStepper()}</div>
              </div>
            </div>
          ) : (
            <div className="stateGraphSkeleton">
              {noDataLoad !== true ? (
                <Skeleton
                  active
                  title
                  paragraph={{
                    rows: 10,
                  }}
                />
              ) : (
                <img
                  className="singleNoDataImage"
                  src={DataLoader}
                  alt="image data empty"
                />
              )}
            </div>
          )}
        </div>
        <div className="stateWisePlanState">
          <img src={stateBackgroundBlurImage} alt="blur image" />
          <div className="notHaveAccess">
            You don’t have access to this data.
          </div>
          <div className="upgradeMemberShip">
            Upgrade your membership to unlock
          </div>
          <button className="upgradeButton">Upgrade</button>
        </div>
      </div>
      <ProductWiseSummary
        brandId={props.brandId}
        startDate={props.startDate}
        endDate={props.endDate}
        allChecked={props.allChecked}
        allProductsChecked={props.allProductsChecked}
        singleCheck={props.singleCheck}
        singleProductCheck={props.singleProductCheck}
        nextState={nextState}
      />
    </>
  );
};

export default StateWiseSummary;
