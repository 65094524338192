import React, { useEffect, useState } from 'react'
import "./ResultAnalysisBody.css"
import MultiSelect from "../../images/MultiSelect.svg"
import SingleSelect from '../../images/SingleSelect.svg';
import MatrixSelect from "../../images/MatrixSelect.svg";
import RangeSelect from "../../images/RangeSelection.svg";
import OrderSelect from "../../images/OrderSelection.svg"
import TextInput from "../../images/TextInput.svg";
import RangeSelectGraph from "../../images/rangeSelectionGraph.svg"

export default function ResultAnalysisMatrixSelect(props) {
    const [questionObject, setQuestionObject] = useState(props.data);

    const questionTypeLabels = {
        'SINGLE_SELECT': 'Single Selection',
        'MULTI_SELECT': 'Multiple Selection',
        'MATRIX_SELECT': 'Matrix Selection',
        'RANGE_SELECTION': 'Range Selection',
        'ORDER_SELECTION': 'Order Selection',
        'TEXT_INPUT': 'Text Input',
    }

    const questionType = () => {
        switch (questionObject.question_type) {
            case 'SINGLE_SELECT':
                return <img src={SingleSelect} alt='singleSelect' />
            case 'MULTI_SELECT':
                return <img src={MultiSelect} alt='multiSelect' />
            case 'MATRIX_SELECT':
                return <img src={MatrixSelect} alt='matrixSelect' />
            case 'RANGE_SELECTION':
                return <img src={RangeSelect} alt='rangeSelect' />
            case 'ORDER_SELECTION':
                return <img src={OrderSelect} alt='orderSelect' />
            case 'TEXT_INPUT':
                return <img src={TextInput} alt='textInput' />
            default:
                return <></>
        }
    }

    const progressColors = ['#D3F4DF', '#DEF6D2', '#DDF7FF', '#FEFFDD', '#FFEAD6', '#FFDBD6']

    const colors = (percentage) => {
        if (percentage === 0) {
            return 'white'
        } else if (percentage >= 75 && percentage <= 100) {
            return progressColors[0]
        } else if (percentage >= 60 && percentage < 75) {
            return progressColors[1]
        } else if (percentage >= 45 && percentage < 60) {
            return progressColors[2]
        } else if (percentage >= 30 && percentage < 45) {
            return progressColors[3]
        } else if (percentage > 0 && percentage < 30) {
            return progressColors[4]
        }
    }

    useEffect(() => {
        questionObject.result.map(d => {
            // console.log(d.choices)
        })
    }, [questionObject])
    return (
        <div className='resultSingleSelectSection'>
            <div className='resultTopSection'>
                <div className='resultSingleSelectHeaderSection'>
                    <div className='resultIndex'>
                        {(props.index + 1 < 10) ? '0' + (props.index + 1) : props.index + 1}&nbsp;&nbsp;&nbsp;
                    </div>
                    <div className='resultSingleSelectHeaderFirstSection'>
                        <div className='resultQuestion'>
                            <div className='resultQuestionText'>
                                {questionObject.question_text}&nbsp;&nbsp;&nbsp;
                            </div>
                            <div className='cond-questionText-inner'>
                                <span className='questionType'>{questionType()}{questionTypeLabels[questionObject.question_type]}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='resultSingleSelectHeaderSecondSection'>
                    <div className='resultAnswered'>
                        <span>800</span> &nbsp;Answered
                    </div>&nbsp;&nbsp;&nbsp;&nbsp;
                    <div className='resultSkipped'>
                        <span>200</span>&nbsp; Skipped
                    </div>
                </div>
            </div>
            <div className='resultMatrixOptions'>
                <div className='resultMatrixOptionsHeader'>
                    <div className='resultMatrixOptionsHeaderFirst'>
                        Parameters
                    </div>
                    <div className='resultMatrixOptionsHeaderSecond'>
                        {questionObject.result[0].choices.map((d, index) => {
                            return <div style={{ width: `${100 / questionObject.result[0].choices.length}%` }} key={index}>{d.choice}</div>
                        })}
                    </div>
                </div>
                <div className='resultMatrixOptionMainBody'>
                    {
                        questionObject.result.map((d, index) => {
                            return <div className='resultMatrixOptionsBody' key={index}>
                                <div className='resultMatrixOptionsHeaderFirst'>
                                    {d.param}
                                </div>
                                <div className='resultMatrixOptionsHeaderSecond'>
                                    {
                                        d.choices.map((data, i) => {
                                            return <div className='matrixOptionPercentage' style={{ width: `${100 / questionObject.result[0].choices.length}%`, backgroundColor: `${colors(data.percentage)}` }}><span>{data.percentage.toFixed(2)}%</span><br></br>({data.count})</div>
                                        })
                                    }
                                </div>
                            </div>
                        })
                    }
                </div>
            </div>
        </div>
    )
}

