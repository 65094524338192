import React, { useEffect, useState } from 'react'
import "./ForgotPassword.css"
import forgot1 from "../../images/forgotPassword1.svg"
import forgot2 from "../../images/forgotPassword2.svg"
import forgotLock from "../../images/forgotLock.svg"
import Envelope from "../../images/mail.png"
import { useNavigate } from "react-router";
import passwordSent from "../../images/passwordSent.svg"
import mailSentEnvelope from "../../images/mailSentConfirmation.svg"
import resendPassword from "../../images/resendPassword.svg"
import goToSignInPage from "../../images/goBackToSignIn.svg"
import axios from 'axios'
import checkEnv from '../../../utils/checkEnv'

export default function ForgotPassword() {
    let color = ['#4EB563', '#EE504F', '#C4C4C4']
    var validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    let borderColorEmail
    const [email, SetEmail] = useState('')
    const navigate = useNavigate()
    let url=checkEnv()
    const emailChangeHandler = (event) => {
        SetEmail(event.target.value)
    }

    const sendPasswordClickHandler = (e) => {
        e.preventDefault()
        if (email.match(validRegex)) {
            axios.post(`${url}/brandsdashboardproxy/v1/resetpassword/`, {
                "email": email
            })
                .then(res => {
                    navigate('/brandsdashboard/forgotPassword/confirmation')
                })
        }
    }
    return (
        <div>
            <div className='forgotPasswordSection'>
                {
                    window.location.pathname === '/brandsdashboard/forgotPassword' ?
                        <>
                            <div className='forgotPasswordFirstSection'>
                                <img src={forgot1} alt='forgotPassword' />
                            </div>
                            <div className='forgotPasswordSecondSection'>
                                <img src={forgotLock} alt='forgotLock' />
                                <div className='forgotPasswordHeader'>
                                    Forgot your Password?
                                </div>
                                <div className='forgotPasswordData'>
                                    We’ve got you covered. Let us know your registered email address & we will send you a temporary password to access your account.
                                </div>
                                <form className="forgotEmailSection" onSubmit={sendPasswordClickHandler}>
                                    <div className="emailHeader">
                                        Email ID
                                    </div>
                                    <div className="forgotEmailPart" style={{ border: `1px solid ${borderColorEmail}` }}>
                                        <input id="emailInput" type="email" value={email} onChange={emailChangeHandler} placeholder='Enter your Email' required />
                                        <img src={Envelope} alt="mailEnvelope" />
                                    </div>
                                    <input value='Send New Password' type='button' className='forgotPasswordButton' onClick={sendPasswordClickHandler} />
                                </form>
                                <div className="forgotNotHaveAccount">
                                    Don’t have an account? <span>Register Now</span>
                                </div>
                            </div>
                        </>
                        :
                        <>
                            <div className='forgotPasswordFirstSection'>
                                <img src={forgot2} alt='forgotPassword' />
                            </div>
                            <div className='forgotPasswordSecondSection'>
                                <img src={forgotLock} alt='forgotLock' />
                                <div className='forgotPasswordHeader'>
                                    Check your Email
                                </div>
                                <img src={passwordSent} alt='forgotLock' className='passwordSentImage' />
                                <div className='resendPasswordData'>
                                    We’ve sent your new password on your registered Email ID {email}
                                </div>
                                <div className='mailSentData'>
                                    <img src={mailSentEnvelope} alt="mailEnvelope" />
                                    <div className='mailReviseError'>
                                        Any error? Revise your Email ID
                                    </div>
                                </div>
                                <div className="notReceivedMail">
                                    Haven’t received the mail yet?
                                </div>
                                <div className='resendLoginButtons'>
                                    <img src={resendPassword} className='resendPasswordButton' onClick={sendPasswordClickHandler} />
                                    <img src={goToSignInPage} className='goBackButton' onClick={() => navigate('/brandsdashboard')} />
                                </div>
                            </div>
                        </>
                }
            </div>
        </div>
    )
}
