import React, { useEffect, useState } from "react";
import * as d3 from "d3";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import { max } from "d3";
// import DataLoader from "./images/dataload_gif.gif"

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

function WeeklyValues(props) {
  let bar_values = props.val;
  let string = props.colorData

  let barWidthList = [9, 11, 13, 16.5, 18, 28, 60]
  let barWidth

  if (bar_values.length === 7) {
    barWidth = barWidthList[0]
  } else if (bar_values.length === 6) {
    barWidth = barWidthList[1]
  } else if (bar_values.length === 5) {
    barWidth = barWidthList[2]
  } else if (bar_values.length === 4) {
    barWidth = barWidthList[3]
  } else if (bar_values.length === 3) {
    barWidth = barWidthList[4]
  } else if (bar_values.length === 2) {
    barWidth = barWidthList[5]
  } else if (bar_values.length === 1) {
    barWidth = barWidthList[6]
  }

  let format = /[-]/;
  let rs1 = format.test(string);
  let valueColor
  if (rs1 === true) {
    valueColor = "rgb(255, 115, 97)";
  } else if (string === '0.0') {
    valueColor = 'rgb(255, 207, 97)'
  } else {
    valueColor = "rgb(0, 188, 138)";
  }

  const data = {
    labels: bar_values,
    datasets: [
      {
        data: bar_values.map((text) => text),
        backgroundColor: `${valueColor}`,
        barThickness: `${barWidth}`
      },

    ],
  };

  return (
    <div style={{ width: '80px', height: '30px' }}>
      <Bar
        data={data}
        height={100}
        options={{
          plugins: {
            title: {
              display: false,
            },
            legend: {
              display: false,
            },
            datalabels: {
              display: false,
            },
          },
          responsive: true,
          scales: {
            y: {
              beginAtZero: true,
              max: max(bar_values),
              ticks: {
                display: false
              },
              grid: {
                display: false,
                drawTicks: false,
                drawOnChartArea: false,
                drawBorder: false
              }
            },
            x: {
              ticks: {
                display: false,

              },
              grid: {
                display: false,
                drawTicks: false,
                drawOnChartArea: false,
                drawBorder: false
              }
            }
          }
        }}
      />
    </div>
  )
}

export default WeeklyValues;

