import "./ProductLevelUserFeedback.css";
import React, { useState, useEffect } from 'react'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCircleCheck } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import starImage from "../../images/ratingstart.svg"
import emptyStarImage from "../../images/emptyStart.svg"
import DataLoader from "../../images/dataload_gif.gif"
import noDataLoader from "../../images/noDataFull.svg"
import Dropdown from 'react-bootstrap/Dropdown'
import DropdownToggle from "react-bootstrap/esm/DropdownToggle";
import DropdownMenu from "react-bootstrap/esm/DropdownMenu";
import { useSearchParams } from "react-router-dom";
import { Skeleton } from "antd";
import RatingFeedback from "../../RatingsAndReview/RatingUserFeedbacks/RatingFeedback";
import checkEnv from "../../../utils/checkEnv";

export default function ProductLevelUserFeedback(props) {
    const [noDataLoad, setNoDataLoad] = useState(false)
    const [searchParams, setSearchParams] = useSearchParams()
    const [offset, setOffset] = useState(0)
    const [feedbackLimit, setFeedbackLimit] = useState(20)
    let userProductId = {}
    let token = localStorage.getItem("token")
    let startDate = localStorage.getItem('start_date')
    let endDate = localStorage.getItem('end_date')
    let url=checkEnv()
    const [levelProductId, setLevelProductId] = useState('')
    const [data, setData] = useState([])
    const [data1, setData1] = useState([])

    const [userFeedbackDetails, setUserFeedbackDetails] = useState([])
    const [recentState, setRecentState] = useState(false)
    const [ratingValue, setRatingvalue] = useState('desc')
    const [createdDate, setCreatedDate] = useState('desc')
    const [sentiment, setSentiment] = useState('desc')

    const [recentFeedbackState, setRecentFeedbackState] = useState(true)
    const [positiveFeedbackState, setPositiveFeedbackState] = useState(false)
    const [negativeFeedbackState, setNegativeFeedback] = useState(false)
    const [loaderState, setLoaderState] = useState(false)
    const [text,setText]=useState("")

    const recentClickHandler = (e) => {
        setPositiveFeedbackState(false)
        setNegativeFeedback(false)
        setRecentFeedbackState(true)
        setRecentState(false)
        setCreatedDate('desc')
        setRatingvalue('desc')
        setSentiment('desc')
        setLoaderState(true)
        getUserFeedbackDetails(levelProductId, 'desc', 'desc', 'desc', [], 0);
    }
    const positiveFeedbackClickHandler = (e) => {
        setPositiveFeedbackState(true)
        setNegativeFeedback(false)
        setRecentFeedbackState(false)
        setCreatedDate('')
        setRatingvalue('desc')
        setSentiment('desc')
        setRecentState(false)
        setLoaderState(true)
        // getUserFeedbackDetails(levelProductId, [], 0)
        getUserFeedbackDetails(levelProductId, 'desc', 'desc', '', [], 0);
    }
    const negativeFeedbackClickHandler = (e) => {
        setPositiveFeedbackState(false)
        setNegativeFeedback(true)
        setRecentFeedbackState(false)
        setCreatedDate('')
        setRatingvalue('asc')
        setSentiment('asc')
        setRecentState(false)
        setLoaderState(true)
        getUserFeedbackDetails(levelProductId, 'asc', 'asc', '', [], 0);
    }


    if (recentFeedbackState === true && document.querySelector('#userFeedbackShowRecent') !== null) {
        document.querySelector('#userFeedbackShowRecent').style.border = '1px solid #003087'
        document.querySelector('#userFeedbackShowRecent').style.color = '#003087'
        document.querySelector('#userFeedbackShowRecent').firstElementChild.style.color = '#003087'
    } else if (recentFeedbackState === false && document.querySelector('#userFeedbackShowRecent') !== null) {
        document.querySelector('#userFeedbackShowRecent').style.border = '1px solid #e5e5e5'
        document.querySelector('#userFeedbackShowRecent').style.color = '#5B5C60'
        document.querySelector('#userFeedbackShowRecent').firstElementChild.style.color = '#c4c4c4'
    }
    if (positiveFeedbackState === true && document.querySelector('#userFeedbackPositive') !== null) {
        document.querySelector('#userFeedbackPositive').style.border = '1px solid #003087'
        document.querySelector('#userFeedbackPositive').style.color = '#003087'
        document.querySelector('#userFeedbackPositive').firstElementChild.style.color = '#003087'
    } else if (positiveFeedbackState === false && document.querySelector('#userFeedbackPositive') !== null) {
        document.querySelector('#userFeedbackPositive').style.border = '1px solid #e5e5e5'
        document.querySelector('#userFeedbackPositive').style.color = '#5B5C60'
        document.querySelector('#userFeedbackPositive').firstElementChild.style.color = '#c4c4c4'
    }
    if (negativeFeedbackState === true && document.querySelector('#userFeedbackNegative') !== null) {
        document.querySelector('#userFeedbackNegative').style.border = '1px solid #003087'
        document.querySelector('#userFeedbackNegative').style.color = '#003087'
        document.querySelector('#userFeedbackNegative').firstElementChild.style.color = '#003087'
    } else if (negativeFeedbackState === false && document.querySelector('#userFeedbackNegative') !== null) {
        document.querySelector('#userFeedbackNegative').style.border = '1px solid #e5e5e5'
        document.querySelector('#userFeedbackNegative').style.color = '#5B5C60'
        document.querySelector('#userFeedbackNegative').firstElementChild.style.color = '#c4c4c4'
    }

    data.map(d => {
        userProductId[d.name] = d.product_id
    })


    const getProductId = async () => {
        const response = await axios.get(`${url}/brandsdashboardproxy/v1/brand/${searchParams.get('brand')}/productinfo/${searchParams.get('productId')}/?startDate=${startDate}&endDate=${endDate}`, {
            headers: {
                'authorization': `${token}`
            }
        })
            .then(res => {
                setLevelProductId(res.data[0].product_id)
                console.log(res.data.length,'productId length')
                getUserFeedbackDetails(res.data[0].product_id, ratingValue, sentiment, createdDate)
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const getUserFeedbackDetails =(levelProductId, ratingValue, sentiment, createdDate) => {
        // setUserFeedbackDetails([])
        // if (offset === 0) {
        //     setOffset(offset)
        // }
         axios.get(`${url}/brandsdashboardproxy/v1/brand/${searchParams.get('brand')}/productuserfeedbacks/?startDate=${startDate}&endDate=${endDate}&productId=${levelProductId}&ratingValue=${ratingValue}&sentiment=${sentiment}&createdDate=${createdDate}&offset=${offset}&limit=${feedbackLimit}`, {
            headers: {
                'authorization': `${token}`
            }
        })
            .then(res => {
                setUserFeedbackDetails(res.data.map(d => d))
                res.data.length === 0 ? setNoDataLoad(true) : setNoDataLoad(false)
                // if (userFeedbackDetails.length === 0) {

                // } else {
                //     res.data.map(d => {
                //         userFeedbackDetails.push(d)
                //     })
                // }
                console.log(res.data.length)
                setLoaderState(false)
            })
            .catch((err) => {
                console.log(err);
            });
    };
    useEffect(() => {
        getProductId()
    }, [searchParams])

    // const loadMoreClickHandler = () => {
    //     setOffset(offset + 20)
    //     setLoaderState(true)
    // }
    function createText(t){
        setText(t)
        setTimeout(() => {
            setText("")
        }, 1000);
    }

    let ratingColors = ["rgb(255, 31, 0)", "rgb(233, 237, 49)", "rgb(120, 214, 0)"]
    return (
        <div>
            <div className="userFeedbackSection">
                <div className="userFeedbackHeader">
                    <div className="userFeedback">
                        <b>User Feedbacks</b>
                        <div className="userFeedbackResults">
                            {/* Total {totalProducts} Products ({totalFeedbacks} results) */}
                        </div>
                    </div>
                    <div>

                        <div className="userFeedbackShowRecent" id="userFeedbackShowRecent" onClick={recentClickHandler}>
                            <FontAwesomeIcon icon={faCircleCheck} />  Most Relevant
                        </div>
                        {/* <div className="userFeedbackPositive" id="userFeedbackPositive" onClick={positiveFeedbackClickHandler}>
                            <FontAwesomeIcon icon={faCircleCheck} />   Top Positive
                        </div>
                        <div className="userFeedbackNegative" id="userFeedbackNegative" onClick={negativeFeedbackClickHandler}>
                            <FontAwesomeIcon icon={faCircleCheck} />  Top Negative
                        </div> */}
                    </div>
                    {/* <Dropdown>
                        <DropdownToggle className="userFeedbackSortDropDown">
                            <FontAwesomeIcon icon={faArrowUpShortWide} />Sort By
                        </DropdownToggle>
                        <DropdownMenu className="recentSections">
                         
                            <div className="recent" onClick={recentClickHandler}>
                                Most Recent
                            </div>
                            <div onClick={positiveFeedbackClickHandler}>
                                Highest Rated
                            </div>
                            <div onClick={negativeFeedbackClickHandler}>
                                Lowest Rated
                            </div>
                         
                        </DropdownMenu>
                    </Dropdown> */}
                </div>
                <div className="userFeedbackMiddleSection">
                    <div className="productUserFeedbackDetailsSection">
                        {userFeedbackDetails.length > 0 && levelProductId !== '' ?
                            loaderState ? <img className="productLevelUserLoader" src={DataLoader} /> :
                                <>
                                    {/* {userFeedbackDetails.map((d, index) => {
                                        let ratingStars = [1, 2, 3, 4, 5];
                                        let userLetter = d.user_name.slice(0, 1)
                                        let color
                                        {
                                            if (d.nps <= 5) {
                                                color = ratingColors[0]
                                            } else if (d.nps <= 7) {
                                                color = ratingColors[1]
                                            } else if (7 < d.nps <= 10) {
                                                color = ratingColors[2]
                                            }
                                        }
                                        return <div className="userFeedbackDetails" key={index}>
                                            <div className="userFeedbackProfile">
                                                <div className="userProfileImage">
                                                    {userLetter}
                                                </div>
                                                <div className="userProfileName">
                                                    {d.user_name}
                                                </div>
                                            </div>
                                            <div className="userFeedbackRating">
                                                <div className="userRatings">
                                                    {
                                                        ratingStars.map(data => {
                                                            if (data <= d.rating) {
                                                                return <img src="/images/ratingstart.svg" />
                                                            } else {
                                                                return <img src={emptyStarImage} />
                                                            }
                                                        })
                                                    }
                                                </div>
                                                <div className="userNPSRatings">
                                                    NPS Rating <span className="ratingColorBox" style={{ background: `${color}` }} ></span>{d.nps}/10
                                                </div>
                                            </div>
                                            <div className="userComment">
                                                {d.feedback}
                                            </div>
                                            <div className="userCommentRating">
                                                <div className="overallExperience">
                                                    Overall Experience {d.overall_experience}/5
                                                </div>
                                                <div className="productQuality">
                                                    Product Quality {d.product_quality}/5
                                                </div>
                                                <div className="packaging">
                                                    Packaging {d.packaging}/5
                                                </div>
                                            </div>
                                        </div>
                                    })} */}
                                    <RatingFeedback createText={createText} userFeedbackDetails={userFeedbackDetails}/>
                                    {/* <div className="loadMore" onClick={loadMoreClickHandler}>
                                        <div>
                                            Load More
                                        </div>
                                    </div> */}
                                </>
                            : <div className="brandsVsCategorySkeleton">
                                {
                                    noDataLoad === false ?
                                        <Skeleton
                                            active
                                            title
                                            paragraph={{
                                                rows: 8,
                                            }} />
                                        :
                                        <img className="singleNoDataImage" src={noDataLoader} alt='image data empty' />
                                }
                            </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}
