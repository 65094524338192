import axios from "axios";
import React, { useEffect, useState } from "react";
import { Doughnut } from "react-chartjs-2";
import { useSearchParams } from "react-router-dom";
import checkEnv from "../../../utils/checkEnv";
import "./UserJourney.css";
import { Skeleton } from "antd";

export default function UserJourney() {
  const url = checkEnv();
  const [searchParams] = useSearchParams();
  let token = localStorage.getItem("token");
  let startDate = localStorage.getItem("start_date");
  let endDate = localStorage.getItem("end_date");
  const [apidata, setApiData] = useState([]);
  // const response = await axios.get(`${url}/brandsdashboardproxy/v1/brand/${searchParams.get('brand')}/didntproceedfromcart/?startDate=${startDate}&endDate=${endDate}&categoryIds=${searchParams.get('categoryIds')}&productIds=${searchParams.get('productIds')}`, {
  //     headers: {
  //         'authorization': `${token}`
  //     }
  // })
  //     .catch((err) => {
  //         console.log(err);
  //     });

  useEffect(() => {
    axios
      .get(
        `${url}/brandsdashboardproxy/v1/brand/${searchParams.get(
          "brand"
        )}/didntproceedfromcart/?startDate=${startDate}&endDate=${endDate}&categoryIds=${searchParams.get(
          "categoryIds"
        )}&productIds=${searchParams.get("productIds")}`,
        {
          headers: {
            authorization: `${token}`,
          },
        }
      )
      .then((res) => {
        if (res.data?.not_converted) {
          setApiData(res.data?.not_converted);
        }
      });
  }, [searchParams]);


  const options = {
    layout: {
      padding: 30,
    },
    legend: {
      display: false,
    },
    tooltips: {
      enabled: false,
    },
    plugins: {
      title: {
        display: false,
      },
      legend: {
        display: false,
      },
      tooltips: {
        enabled: false,
      },
    
      datalabels: {
        display: true,
        formatter: function (value, context) {
          return `${value.toFixed(0)}%`;
        },
        color: ["#1F78B4", "#8D92CB", "#7FCD31"],
        align: "end",
        anchor: "end",
        padding: {
          left: -10,
          bottom: -10,
          top: -20,
        },
        font: {
          weight: 900,
          size: 16,
        },
      },
    },
  };

  const revArr=(arr)=>{
    let op=[]
    for(let i=arr.length-1;i>=0;i--){
      op.push(arr[i])
    }
    return op
  }



  const makearr = (arr) => {
    let op = [];
    arr.forEach((e) => {
      let k = Object.values(e);
      op.push(...k);
    });
    return revArr(op);
  };


  const data = {
    labels: ["", "", ""],
    datasets: [
      {
        data: makearr(apidata),
        backgroundColor: ["#1F78B4", "#8D92CB", "#7FCD31"],
      },
    ],
    cutout: "90%",
    borderWidth:1,
  };

  return (
    <>
      {apidata.length !== 0 ? (
        <div className="user-journey-container">
          <div className="user-journey-container-header">
            <span>Funnel Drop Analysis</span>
          </div>
          <div className="user-journey-container-body">
            <div className="user-journey-container-left">
              <div className="user-journey-container-left-boxes ">
                <div className="user-journey-container-left-color first-color"></div>
                <span className="user-journey-container-left-text">
                  Proceeded with the similar product of a competitor brand
                </span>
              </div>
              <div className="user-journey-container-left-boxes">
                <div className="user-journey-container-left-color"></div>
                <span className="user-journey-container-left-text">
                  Proceeded with a similar product
                </span>
              </div>
              <div className="user-journey-container-left-boxes">
                <div className="user-journey-container-left-color second-color"></div>
                <span className="user-journey-container-left-text">
                  Other Reason
                </span>
              </div>
            </div>
            <div className="user-journey-container-right">
              <div style={{ width: "200px", height: "200px" }}>
                <Doughnut data={data} options={options} />
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="user-journey-container">
          <Skeleton active paragraph={{ rows: 6 }} />
        </div>
      )}
    </>
  );
}


