import React, { useEffect, useState } from 'react'
import "./ResultAnalysis.css"
import axios from 'axios'
import { useSearchParams } from 'react-router-dom'
import { useParams } from 'react-router';
import ResultSingleSelect from "../ResultAnalysisBody/ResultSingleSelect"
import ResultAnalysisMatrixSelect from '../ResultAnalysisBody/ResultAnalysisMatrixSelect';
import ResultAnalysisOrder from '../ResultAnalysisBody/ResultAnalysisOrder';
import GridLoader from 'react-spinners/GridLoader';
import moment from 'moment';
import { checkSurveyEnv } from '../../../utils/checkEnv';

export default function ResultAnalysis() {
    const [data, setData] = useState([])
    const [searchParams, setSearchParams] = useSearchParams()
    const { surveyId } = useParams()
    const [surveyDetails, setSurveyDetails] = useState([])
    const surveyUrl=checkSurveyEnv()

    // useEffect(() => {
    //     axios.get(`${surveyUrl}/smyttenresearch/survey/${surveyId}`, {
    //     })
    //         .then(res => {
    //             console.log(res.data)
    //             console.log(res.data.result_response, "rsponsess")
    //             setSurveyDetails(res.data)
    //             setData(res.data.result_response)
    //         })
    // }, [searchParams])

    useEffect(()=>{
        axios.get(`${surveyUrl}//smyttenresearch/survey/${surveyId}/results/`)
        .then(res=>{
            // console.log(res.data)
            setSurveyDetails(res.data)
            setData(res.data.result_response)
        })
    },[searchParams])

    // console.log(surveyDetails.complete_responses, 'details total')

    return (
        <div className='resultAnalysisMainSection'>
            {
                data.length !== 0 && surveyDetails ? <>
                    <div className='resultAnalysisMainSectionHeader'>
                        <div className='resultAnalysisFirstSection'>
                            <div className='resultAnalysisFirstSectionHeader'>
                                {surveyDetails.title ? surveyDetails.title : ''}
                            </div>
                            <p>{surveyDetails.sub_title ? surveyDetails.sub_title : ''}</p>
                        </div>
                        <div className='resultAnalysisSecondSection'>
                            <div className='previewSubmitTime'>
                                <p className='timeTaken'>
                                    03.00
                                </p>
                                <p className='timeHeader'>
                                    Time
                                </p>
                            </div>
                            <div className='previewSubmitSections'>
                                <p className='totalSections'>
                                    {surveyDetails.number_of_sections ? surveyDetails.number_of_sections : ""}
                                </p>
                                <p className='sectionHeader'>
                                    Sections
                                </p>
                            </div>
                            <div className='previewSubmitQuestions'>
                                <p className='totalNumberQuestions'>
                                    {surveyDetails.number_of_questions ? surveyDetails.number_of_questions : ''}
                                </p>
                                <p className='totalNumberHeader'>
                                    Questions
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className='resultAnalysisMiddleSection'>
                        <div className='resultAnalysisCreated'>
                            Created on <span>{moment(surveyDetails.created_at).format('DD MMM’YY')}</span> | Published on <span>{moment(surveyDetails.published_at).format('DD MMM’YY')}</span> | Completed on <span>29 Sep’22</span>
                        </div>
                        <div className='resultAnalysisResponses'>
                            Partial Responses: <span>{surveyDetails.partial_responses ? surveyDetails.partial_responses : '0'}</span> | Complete Responses: <span>{surveyDetails.complete_responses ? surveyDetails.complete_responses : '0'}</span>
                        </div>
                    </div>
                    {
                        data.map((d, index) => {
                            if (d.question_type === 'SINGLE_SELECT' || d.question_type === 'MULTI_SELECT' || d.question_type === 'RANGE_SELECTION') {
                                return <ResultSingleSelect data={d} index={index} key={index + 1} />
                            } else if (d.question_type === 'MATRIX_SELECT') {
                                return <ResultAnalysisMatrixSelect data={d} index={index} key={index + 1} />
                            } else if (d.question_type === 'ORDER_SELECTION') {
                                return <ResultAnalysisOrder data={d} index={index} key={index + 1} />
                            }
                        })
                    }
                </> :
                    <div className='researchDashboardBody-new-loader'>
                        <GridLoader color={'#003087'} />
                    </div>
            }
        </div>
    )
}
