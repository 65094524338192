import React, { useState, useEffect } from 'react';
import ChartDataLabels from "chartjs-plugin-datalabels";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import axios from 'axios';
import "./RatingsGraphs.css"
import { RatingsAndReviewDoughnutGraph } from './RatingsAndReviewDoughnutGraph';
import DataLoader from "../../images/noDataFull.svg";
import { useSearchParams } from 'react-router-dom';
import RatingsWordsOfBrands from '../RatingsTopWordsForBrands/RatingsWordsOfBrands';
import { Skeleton } from "antd"
import checkEnv from '../../../utils/checkEnv';
ChartJS.register(
    ChartDataLabels,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

export function RatingsAndReviewStackedGraph(props) {
    const [noDataLoad, setNoDataLoad] = useState(false)
    const [searchParams, setSearchParams] = useSearchParams()
    const [secondNextState, setSecondNextState] = useState(false)
    let token = localStorage.getItem("token")
    const [totalPassives, setTotalPassives] = useState([])
    const [totalDetrators, setTotalDetrators] = useState([])
    const [totalPromotors, setTotalPromotors] = useState([])
    const [data1, setData1] = useState([])
    const [data2, setData2] = useState([])
    const [data3, setData3] = useState([])
    const [labels, setLabels] = useState([])
    const [hoverLabels, setHoverLabels] = useState([])

    let startDate = localStorage.getItem("start_date");
    let endDate = localStorage.getItem("end_date");
    let url=checkEnv()
    const getRatingsGraph = async () => {
        setData1([])
        const response = await axios.get(`${url}/brandsdashboardproxy/v1/brand/${searchParams.get('brand')}/ratingsoftriedusermultigraph/?startDate=${startDate}&endDate=${endDate}&categoryIds=${searchParams.get('categoryIds')}&productIds=${searchParams.get('productIds')}`, {
            headers: {
                'authorization': `${token}`
            }
        })
            .catch((err) => {
                console.log(err);
            });
            setData1(response.data.data.map(d => d.values.detractors));
            setData2(response.data.data.map(d => d.values.passives));
            setData3(response.data.data.map(d => d.values.promoters))
        setTotalDetrators(response.data.user_rating_users.det);
        setTotalPassives(response.data.user_rating_users.pas);
        setTotalPromotors(response.data.user_rating_users.pro)
        setLabels(response.data.data.map(d => (d.label).slice(0, 25)))
        setHoverLabels(response.data.data.map(d => d.label))
        response.data.data.length === 0 ? setNoDataLoad(true) : setNoDataLoad(false)
    };
    useEffect(() => {
        getRatingsGraph();
    }, [searchParams])


    const colorBox = (index) => {
        <>

            <div>
                <div className='hoverColorBox'>

                </div>
                <div className='hoverPercentage'>
                    {data3[0] / (data1[0] + data2[0] + data3[0]) * 100}
                </div>
                <div className='hoverLevel'>
                    Promoters(9 - 10)
                </div>
            </div>
            <div>
                <div className='hoverColorBox'>

                </div>
                <div className='hoverPercentage'>
                    {data2[0] / (data1[0] + data2[0] + data3[0]) * 100}
                </div>
                <div className='hoverLevel'>
                    Passives (7-8)
                </div>
            </div>
            <div>
                <div className='hoverColorBox'>

                </div>
                <div className='hoverPercentage'>
                    {data1[0] / (data1[0] + data2[0] + data3[0]) * 100}
                </div>
                <div className='hoverLevel'>
                    Detractors (0-6)
                </div>
            </div>
        </>


    }
    let promoters=data3.map((d, i) => (d / (data1[i] + data2[i] + data3[i])) * 100)
    let passives=data2.map((d, i) => (d / (data1[i] + data2[i] + data3[i])) * 100)
    // let ditractors=100-(promoters+passives)
    let ditractors=data1.map((d, i) => (d / (data1[i] + data2[i] + data3[i])) * 100)

    // console.log(promoters)
    
    // console.log("promototers",promoters)
    // console.log("passives",passives)
    // console.log("ditractors",ditractors)

    // let promoterSum=Math.round(sumArr(promoters)/promoters.length)
    // let passiveSum= Math.round(sumArr(passives)/passives.length)
   
    // let ditractorSum=100-(promoterSum+passiveSum)

    // console.log(promoterSum,passiveSum,ditractorSum)

    

    // console.log(ditractors)
    // console.log(data1[0] / (data1[0] + data2[0] + data3[0]) * 100)
    // console.log(data2)

    const options = {
        categoryPercentage:0.8,
        barPercentage:0.8,
        plugins: {
            tooltip: {

                callbacks: {
                    labelTextColor: (context) => {
                        return 'black'
                    },
                    title: function (context) {
                        let index = context[0].dataIndex
                        return hoverLabels[index]
                    },
                    label: function (context) {
                        return `${context.dataset.label} ${context.raw.toFixed(2)}%`
                    },
                },
                boxWidth: 10,
                titleColor: 'black',
                backgroundColor: '#F9FBFF',
            },
            title: {
                display: true,
                text: 'Category Distribution Of NPS',
                font: {
                    weight: 500,
                    size: 18,
                },
                align: "start",
                padding: {
                    top: 10,
                    bottom: 50,
                },
                color: '#0f1628'
            },
            legend: {
                display: false,
            },
            datalabels: {
                display: false,
                formatter: function (value, context) {
                    return `${value.toFixed(2)}%`;
                },
                anchor: 'end',
                align: "bottom",
                padding: {
                    top: -12,

                },
                color: "white"
            }
        },
        responsive: true,
        scales: {
            x: {
                stacked: true,
            },
            y: {
                // beginAtZero: true,
                max: 100,
                stacked: true,
                ticks: {
                    callback: function (val, index) {
                        return `${val}%`;
                    },
                    // display: false,
                    stepSize: 20
                },
                grid: {
                    drawTicks: false,
                }
            },
        },
    };
    // console.log(data1)
    const data = {
        labels: labels.map(text => (text.split(' ', 3))),
        datasets: [
            {
                label: 'Detractors (0-6)',
                data: ditractors,   
                backgroundColor: '#EC654E',
                barThickness: 45,
                borderWidth:{
                    top:1.5
                },
                borderColor: "white",            
            },
            {
                label: 'Passives (7-8)',
                data: passives,
                backgroundColor: '#F8C43D',
                barThickness: 45,
                borderWidth:{
                    top:1.5
                },
                borderColor: "white",           
            },
            {
                label: 'Promoters (9-10)',
                data: promoters,
                backgroundColor: '#7FCD31',
                barThickness: 45,
                // borderWidth:1,
            },
        ],
    };

    useEffect(() => {
        if (props.nextState === true && data1.length !== 0 && data.length !== 0 && data2.length !== 0) {
            setSecondNextState(true)
        } else {
            setSecondNextState(false)
        }
    }, [props.nextState, data1, data, data2])

    return (
        <>
            <div className="ratingsGraphs">
                {
                    data1.length !== 0 && data.length !== 0 && data2.length !== 0 ?
                        <div className='rating'>
                            <RatingsAndReviewDoughnutGraph totalPassives={totalPassives} totalDetrators={totalDetrators} totalPromotors={totalPromotors} nextState={props.nextState} />
                            <div className='multiRatingChart' style={{textAlign:"center"}} >
                                <Bar
                                    height={200}
                                    options={options}
                                    data={data} />
                                <h6>Top Categories</h6>
                            </div>
                            
                        </div>
                        : <div className="brandsVsCategorySkeleton">
                            {
                                noDataLoad !== true ?
                                    <Skeleton
                                        active
                                        title
                                        paragraph={{
                                            rows: 8,
                                        }} />
                                    :
                                    <img className="singleNoDataImage" src={DataLoader} alt='image data empty' />
                            }
                            
                        </div>
                }
            </div>
            <RatingsWordsOfBrands brandId={props.brandId} allChecked={props.allChecked}
                allProductsChecked={props.allProductsChecked}
                singleCheck={props.singleCheck} singleProductCheck={props.singleProductCheck} nextState={secondNextState} />
        </>
    )
}
