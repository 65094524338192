import React from 'react';
import BrandVsCategory from './BrandVsCategory';
import MaleVsFemale from './MaleVsFemale';
import StateWiseSummary from './StateWiseSummary';

const Maps = (props) => {
    return (
                <StateWiseSummary brandId={props.brandId} startDate={props.startDate} endDate={props.endDate} allChecked={props.allChecked}
                    allProductsChecked={props.allProductsChecked}
                    singleCheck={props.singleCheck}
                    singleProductCheck={props.singleProductCheck} nextState={props.nextState}
                />
    );
};

export default Maps;