import React from 'react'
import "./Card.css";
import Icon from "../../images/Final_01_1.png"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendar } from '@fortawesome/free-regular-svg-icons';

export default function Card({test,color,bgcolor}) {
    // console.log(bgcolor)
    // console.log(test)
        const trimString=(str,trimlen)=>{
            if(str.length>trimlen){
                str=str.slice(0,trimlen)+`...`
            }
            return str
        }
  return (
    <div className='card-container' style={{backgroundColor:color}}>
        <div className='card-top'>
            <div className='image'style={{backgroundColor:bgcolor}} ><img  src={Icon} alt=""/></div>
            {test.creditRequired && <p><span className='bold'>{test.creditRequired}</span> {"Credits Required"}</p>}
        </div>
        <div className='card-body'> 
            {test.title&&<p className='card-heading'>{trimString(test.title,25)}</p>}
            <ul className='description'>
                {test.descriptionLineOne&&<li><span>{trimString(test.descriptionLineOne,30)}</span></li>}
                {test.descriptionLineTwo&&<li><span>{trimString(test.descriptionLineTwo,30)}</span></li>}
            </ul>
        </div>
        <div className='card-footer'>
            <div><FontAwesomeIcon icon={faCalendar}/></div>
            {test.footer&&<p><span className='bold'>{test.footer.slice(0,6)}</span> {test.footer.slice(6)}</p>}
        </div>
    </div>
  )
}
