import axios from "axios";
import React, { useEffect, useState, useRef } from "react";
import stateBackgroundBlurImage from "./images/planLock.png"
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import DataLoader from "./images/noDataHalf.svg"
import { Skeleton } from 'antd';
import { useSearchParams } from "react-router-dom";
import checkEnv from "../utils/checkEnv";
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);
const MaleVsFemale = (props) => {
  const [noDataLoad, setNoDataLoad] = useState(false)
  const [searchParams, setSearchParams] = useSearchParams()
  const [maleData, setMaleData] = useState([])
  const [femaleData, setFemaleData] = useState([])

  const [datas, setDatas] = useState([]);
  let token = localStorage.getItem("token")
  const brandId = props.brandId;
  let startDate = localStorage.getItem('start_date')
  let endDate = localStorage.getItem('end_date')
  let url=checkEnv()
  let categoryId = localStorage.getItem('allcategoryId') ? (localStorage.getItem('allcategoryId').split(',')) : []
  let productId = localStorage.getItem('allProductId') ? localStorage.getItem('allProductId').split(',') : []

  const getMaleVsFemale = async () => {
    setDatas([])
    const response = await axios.get(`${url}/brandsdashboardproxy/v1/brand/${searchParams.get('brand')}/usertriedgender/?startDate=${startDate}&endDate=${endDate}&categoryIds=${searchParams.get('categoryIds')}&productIds=${searchParams.get('productIds')}`, {
      headers: {
        'authorization': `${token}`
      }
    })
      .catch((err) => {
        console.log(err);
      });
      setFemaleData(response.data.map(d => d.female))
      setMaleData(response.data.map(d => d.male))
    setDatas(response.data.map((dat) => dat));
    response.data.length === 0 ? setNoDataLoad(true) : setNoDataLoad(false)
  };
  useEffect(() => {
    getMaleVsFemale();
  }, [searchParams]);
  const data1 = {
    labels: datas.map((text) => (text.age_group).slice(0, 6)),
    datasets: [
      {
        label: "Male",
        data: datas.map((text) => text.male),
        backgroundColor: "#a6cee3",
      },
      {
        label: "Female",
        data: datas.map((text) => text.female),
        backgroundColor: "#f6c0f1",
      },

    ],
  };

  useEffect(() => {
    if (localStorage.getItem('plan') !== null && localStorage.getItem('plan') === 'Free' && document.getElementsByClassName("map2") !== undefined && document.getElementsByClassName("stateWisePlanState") && document.getElementsByClassName("map2")) {
      document.getElementsByClassName("map2")[0].classList.add('plan')
      document.getElementsByClassName("genderWisePlanState")[0].style.display = "flex"
      document.getElementsByClassName("map2")[0].style.border = "2px solid #FFCBC4"
    }
  }, [])


  useEffect(() => {
    if (props.nextState === true && datas.length !== 0) {
      props.setStateNextState(true)
    } else {
      props.setStateNextState(false)
    }
  }, [props.nextState, datas])

  return (
    <>
      <div className="map2">
        <div className="maleVsFemaleHeading">Gender Distribution</div>
        {
          datas.length !== 0 ?
            <>
              <div className="chartFooter">
                <div className="label">
                  <div className="labelBoxMale"></div>
                  <div>Male</div>
                  &nbsp;{maleData.reduce((a, b) => a + b).toFixed(2)}%
                </div>
                <div className="label">
                  <div className="labelBoxFemale"></div>
                  <div>Female</div>
                  &nbsp;{femaleData.reduce((a, b) => a + b).toFixed(2)}%
                </div>
              </div>
              <div id="d3Second">
                <div className="yAxes">
                  %Contribution
                </div>
                <Bar
                  data={data1}
                  height={250}
                  options={{
                    barPercentage: 1,
                    plugins: {
                      tooltip: {
                        callbacks: {
                          label: function (context) {
                            return `${context.dataset.label} ${context.raw.toFixed(2)}%`
                          }
                        },
                      },
                      title: {
                        display: true,
                        text: "Age Group",
                        position: "bottom",
                        font: {
                          size: 14,
                          weight: 400,
                        },
                      },
                      legend: {
                        display: false,
                        position: "bottom",
                      },
                      datalabels: {
                        display: false
                      }
                    },
                  }}
                />

              </div>
            </>
            : <div className="brandsVsCategorySkeleton">
              {
                noDataLoad !== true ?
                  <Skeleton
                    active
                    title
                    paragraph={{
                      rows: 8,
                    }} />
                  :
                  <img className="singleNoDataImage" src={DataLoader} alt='image data empty' />
              }
              {/* <img className="singleNoDataImage" src={DataLoader} alt='image data empty'/> */}
            </div>
        }
      </div>
      <div className="genderWisePlanState">
        <img src={stateBackgroundBlurImage} alt="blur image" />
        <div className="notHaveAccess">
          You don’t have access to this data.
        </div>
        <div className="upgradeMemberShip">
          Upgrade your membership to unlock
        </div>
        <button className="upgradeButton">
          Upgrade
        </button>
      </div>
    </>
  );
};

export default MaleVsFemale;
