import React from 'react'
import "./RatingUserFeedback.css";
import SingleFeedback from './SingleFeedback';


export default function RatingFeedback({userFeedbackDetails,createText}) {
    
  return (
    
    <>
        {userFeedbackDetails.map((d, index) => {
            return  <SingleFeedback createText={createText} d={d} index={index}/>
        })}
    </>
  )
}
