import React,{useEffect, useState} from 'react'
import "./ShopAnalysis.css"
import { Skeleton } from 'antd'
import axios from 'axios'
import checkEnv from '../../../utils/checkEnv';
import { useSearchParams } from 'react-router-dom';
import ShopCard from './ShopCard';
import ShopCardContainer from './ShopCardContainer';


export default function ShopAnalysis() {
    const [data,setData]=useState([])
    const [searchParams]=useSearchParams()
    let token = localStorage.getItem("token")
    const url=checkEnv()

    useEffect(()=>{
        axios.get(`${url}/brandsdashboardproxy/v1/brand/${searchParams.get("brand")}/shop_top_categories/`,{
            headers: {
                'authorization': `${token}`
            }
        })
        .then(res=>{
            // console.log(res.data)
            setData(res.data)
        })
    },[url,searchParams])
    return (
      <>
      {
      data.length!==0?<div className='shopContainer'>
        <div className='shopContainer-header'><span>Most Frequently Purchased</span></div>
        <div className='shopContainer-body'>
          {/* {
            data.map((dat,index)=>{
              return <ShopCard data={dat} index={index} key={index}/>
            })
          } */}
          {/* <ShopCard data={data[0]} key={0}/> */}
          <ShopCardContainer data={data}/>
        </div>
          
      </div> :
      <div className='shopContainer'><Skeleton active paragraph={{rows:6,}}/></div>
      }
      </>
  
    )
}
