import "./CompetitiveAnalysisRank.css"
import React, { useState, useEffect } from "react";
import { Skeleton } from 'antd';
import axios from "axios";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import DataLoader from "../../images/dataload_gif.gif"
import { useSearchParams } from "react-router-dom";
import CompetitiveAnalysisNpsGraph from "./CompetitiveAnalysisNpsGraph";
import caUpArrow from '../../images/ca_upArrow.svg';
import caDownArrow from '../../images/ca_downArrow.svg';
import checkEnv from "../../../utils/checkEnv";


ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);

export default function CompetitiveAnalysisRankGraphs(props) {
    const [nextState, setNextState] = useState(false)
    const [searchParams, setSearchParams] = useSearchParams()
    const [minimumCount, setMinimumCount] = useState()
    const [submitState, setSubmitState] = useState(true)
    let token = localStorage.getItem("token")
    let startDate = localStorage.getItem('my_start_date')
    let endDate = localStorage.getItem('my_end_date')
    let url=checkEnv()
    const [index1, setIndex] = useState()
    const [sample, setSample] = useState([]);
    const [labels, setLabels] = useState([]);

    const getPickUpRate = () => {
        setIndex()
        setSample([])
        axios.get(`${url}/brandsdashboardproxy/v1/brand/${searchParams.get('brand')}/pickuprate/?startDate=${startDate}&endDate=${endDate}&categoryIds=${searchParams.get('categoryIds')}&stateName=${props.selectedState}&minimum_sample_count=${minimumCount ? minimumCount : 0}`, {
            headers: {
                'authorization': `${token}`
            }
        })
            .then(res => {
                setSample(res.data.data.map((dat) => dat));
                setLabels(res.data.data.map(dat => dat.brand_name))
                // console.log(res.data.data)
                setIndex(res.data.index_pos)
            })
            .catch((err) => {
                console.log(err);
            });
    };
    const [pickupRateData, setPickupRateData] = useState();
    const [npsRateData, setNPSRateData] = useState();

    const getDetails = () => {
        setPickupRateData()
        axios.get(`${url}/brandsdashboardproxy/v1/brand/${searchParams.get('brand')}/pickuprate/?startDate=${startDate}&endDate=${endDate}&categoryIds=${searchParams.get('categoryIds')}&stateName=${props.selectedState}&minimum_sample_count=${minimumCount ? minimumCount : 0}`, {
            headers: {
                'authorization': `${token}`
            }
        })
            .then(res => {
                setPickupRateData(res.data);
            })
            .catch((err) => {
                console.log(err);
            });
        setNPSRateData()
        axios.get(`${url}/brandsdashboardproxy/v1/brand/${searchParams.get('brand')}/avgnpsratebrandwise/?startDate=${startDate}&endDate=${endDate}&categoryIds=${searchParams.get('categoryIds')}&stateName=${props.selectedState}&minimum_sample_count=${props.minimumCount ? props.minimumCount : 0}`, {
            headers: {
                'authorization': `${token}`
            }
        })
            .then(res => {
                setNPSRateData(res.data);
            })
            .catch((err) => {
                console.log(err);
            });
    }

    useEffect(() => {
        // getPickUpRate();
        getDetails();
    }, [searchParams, props.selectedState]);

    const data = {
        labels: labels,
        datasets: [
            {
                label: "Brand Average",
                data: sample.map((text) => text.Average_Count),
                // backgroundColor: "rgb(166, 206, 227)",
                backgroundColor: color => {
                    let colors = color.index === index1 ? 'green' : "rgb(166, 206, 227)"
                    return colors
                },
                // barThickness: 40,
            },
        ],
    }

    const enterCountSubmitHandler = (e) => {
        // setMinimumCount(e.target.parentElement.value)
        console.log(minimumCount)
        getPickUpRate()
        setSubmitState(!submitState)
    }
    const minimumChangeHandler = (e) => {
        setMinimumCount(e.target.value)
    }

    useEffect(() => {
        if (sample.length !== 0) {
            setNextState(true)
        } else {
            setNextState(false)
        }
    }, [sample, searchParams])

    // console.log(npsRateData.data)

    return (
        <>
            { pickupRateData && npsRateData
                ?
                <div className="ca-rankSection">
                    {
                        pickupRateData && pickupRateData.index_pos > 0 && npsRateData.index_pos >0
                            ?
                            <div className="ca-rankBox">
                                <div style={{ fontWeight: "500", fontSize: "48px", paddingRight: "1rem" }}>#{pickupRateData ? pickupRateData.index_pos : null}</div>
                                <div style={{ color: "#5B5C60" }}>
                                    Ranking on Brand Trial Intent
                                    {
                                        parseInt(pickupRateData.pickup_rate_change) != 0
                                            ?
                                            <div className={pickupRateData.pickup_rate_change > 0 ? 'ca-rankBox-status ca-rankBox-status-positive' : 'ca-rankBox-status ca-rankBox-status-negative'}>
                                                {parseInt(pickupRateData.pickup_rate_change) > 0 ? '+' : null} {parseInt(pickupRateData.pickup_rate_change)} vs Last Date Range&nbsp;
                                                {
                                                    parseInt(pickupRateData.pickup_rate_change) > 0
                                                        ?
                                                        <img src={caUpArrow} />
                                                        :
                                                        <img style={{ transform: "rotate(180deg)" }} src={caDownArrow} />
                                                }
                                            </div>
                                            :
                                            <div className="ca-rankBox-status ca-rankBox-status-neutral">
                                                No change vs Last Date Range
                                            </div>
                                    }

                                </div>
                            </div>
                            :
                            null
                    }
                    {
                        npsRateData && npsRateData.data.length > 0 && pickupRateData.index_pos > 0 && npsRateData.index_pos >0
                            ?
                            <div className="ca-rankBox">
                                <div style={{ fontWeight: "500", fontSize: "48px", paddingRight: "1rem" }}>#{npsRateData ? npsRateData.index_pos : null}</div>
                                <div style={{ color: "#5B5C60" }}>
                                    Ranking on Product Feedbacks
                                    <div className={parseFloat(npsRateData.data[npsRateData.index_pos].Average_Count).toFixed(1) > 0 ? 'ca-rankBox-status ca-rankBox-status-positive' : 'ca-rankBox-status ca-rankBox-status-negative'}>
                                        {parseFloat(npsRateData.data[npsRateData.index_pos].Average_Count).toFixed(1)?`+`:"-"}{parseFloat(npsRateData.data[npsRateData.index_pos].Average_Count).toFixed(1)} vs Last Date Range&nbsp;
                                        {
                                            parseFloat(npsRateData.data[npsRateData.index_pos].Average_Count).toFixed(1) > 0
                                                ?
                                                <img src={caUpArrow} />
                                                :
                                                <img style={{ transform: "rotate(180deg)" }} src={caDownArrow} />
                                        }
                                    </div>
                                </div>
                            </div>
                            :
                            <div className="ca-rankBox-status ca-rankBox-status-neutral">
                                                No change vs Last Date Range
                            </div>
                    }
                </div >
                :
                <div className='ca-rankSection'>
                    <Skeleton
                        active
                        title
                        paragraph={{
                            rows: 2,
                        }} />
                </div>
            }
            <CompetitiveAnalysisNpsGraph selectedState={props.selectedState} minimumCount={minimumCount} submitState={submitState} nextState={nextState} />
        </>
    )
    return (
        <div>
            {
                index1 !== undefined ?
                    <div className="rankedGraphs">
                        <div className="rankedGraphsHeader">
                            <div className="rankedGraphHeading">Brand Rank By Pickup Rate</div>
                        </div>
                        {
                            index1 !== -1 ?
                                <Bar
                                    data={data}
                                    height={100}
                                    options={{
                                        barPercentage: 1,
                                        plugins: {
                                            tooltip: {
                                                callbacks: {
                                                    label: function (context) {
                                                        return `${context.dataset.label} ${context.raw}`
                                                    }
                                                },
                                            },
                                            title: {
                                                display: false,
                                                text: "Age Group",
                                                position: "bottom",
                                                font: {
                                                    size: 14,
                                                }
                                            },
                                            legend: {
                                                display: false,
                                            },
                                            datalabels: {
                                                display: false,
                                            },
                                        },
                                        // scales: {
                                        //     x: {
                                        //         ticks: {
                                        //             // callback: function (value, index, ticks) {
                                        //             //     if (index === 2){
                                        //             //     console.log(value)
                                        //             //     return ticks;
                                        //             //     }
                                        //             // },
                                        //             maxTicksLimit: labels.length/5
                                        //         },
                                        //     }
                                        // }
                                    }}
                                /> : <div className="brandNotPresent">
                                    <div>
                                        Brand not present in top 25 competitors
                                    </div>
                                </div>}
                        {/* <div className="chartFooter">
                            <div className="label">
                                <div className="labelBoxBrands"></div>
                                <div>Brands Average</div>
                            </div>
                            <div className="label">
                                <div className="labelBoxBrands" style={{backgroundColor:`green`}}></div>
                                <div>Your Brand Average</div>
                            </div>
                        </div> */}
                    </div>
                    :
                    <div className="competitiveGraphSkeleton">
                        <Skeleton
                            active
                            title
                            paragraph={{
                                rows: 7,
                            }} />
                    </div>

            }
            <CompetitiveAnalysisNpsGraph selectedState={props.selectedState} minimumCount={minimumCount} submitState={submitState} nextState={nextState} />
        </div>
    )
}
