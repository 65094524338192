import React, { useState, useEffect } from 'react'
import "./MostTrendheader.css"
import { useSearchParams } from 'react-router-dom'
import TrendingCard from './TrendingCard'
import checkEnv from '../../../utils/checkEnv'
import axios from 'axios';
import { Skeleton } from 'antd';
import { render } from '@testing-library/react'

export default function MostTrendheader() {
    const [searchParams] = useSearchParams()
    // const [mostAndLeastIntrestedData, setMostAndLeastIntrestedData] = useState([])
    const [data, setData] = useState([])
    let token = localStorage.getItem("token")
    let startDate = localStorage.getItem("start_date");
    let endDate = localStorage.getItem("end_date");
    let url = checkEnv()


    const getUserPersona = async () => {
        await axios.get(`${url}/brandsdashboardproxy/v1/brand/${searchParams.get('brand')}/producttrendsusers/?startDate=${startDate}&endDate=${endDate}&categoryIds=${searchParams.get('categoryIds')}&productIds=${searchParams.get('productIds')}`, {
            headers: {
                'authorization': `${token}`
            }
        })
            .then(res => {
                console.log(res.data.slice(0, 15));
                setData(res.data.slice(0, 15))
            })
            .catch((err) => {
                console.log(err);
            });
    };

    useEffect(() => {
        getUserPersona()
    }, [searchParams])

    const trimStr = (str, strlen) => {
        if (strlen < str.length) {
            return `${str.slice(0, strlen)}...`
        } else {
            return str
        }
    }
    const renderCards = () => {
        let green = []
        let yellow = []
        let orange = []
        data.forEach((e, ind) => {
            if (ind < 5) {
                green.push(<TrendingCard name={trimStr(e.name, 38)} index={ind + 1} color={'green'} />)
            }
            else if (ind < 10) {
                yellow.push(<TrendingCard name={trimStr(e.name, 38)} index={ind + 1} color={'yellow'} />)
            }
            else {
                orange.push(<TrendingCard name={trimStr(e.name, 38)} index={ind + 1} color={'orange'} />)
            }
        })
        return [green, yellow, orange]
    }



    return (
        <>

            {data.length !== 0 ? <div className='most-trending-container'>
                <div className='heading-text-container'>
                    <span className='heading-text'>Top Trends Followed</span>
                    <span className='maps-header-subtext margintop-2' style={{fontSize:"12px"}}>Based on the last 12 weeks data analysis</span>
                </div>
                <div className='most-trending-container-body'>
                    <div>
                        {/* <TrendingCard  index={1} color={'green'}/>
                    <TrendingCard index={2} color={'green'}/>
                    <TrendingCard index={3} color={'green'}/>
                    <TrendingCard index={4} color={'green'}/>
                    <TrendingCard index={5} color={'green'}/> */}
                        {renderCards()[0]}
                    </div>
                    <div>
                        {/* <TrendingCard index={6} color={'yellow'}/>
                    <TrendingCard index={7} color={'yellow'}/>
                    <TrendingCard index={8} color={'yellow'}/>
                    <TrendingCard index={9} color={'yellow'}/>
                    <TrendingCard index={10} color={'yellow'}/> */}
                        {renderCards()[1]}
                    </div>
                    <div>
                        {/* <TrendingCard index={11} color={'orange'}/>
                    <TrendingCard index={12} color={'orange'}/>
                    <TrendingCard index={13} color={'orange'}/>
                    <TrendingCard index={14} color={'orange'}/>
                    <TrendingCard index={15} color={'orange'}/> */}
                        {renderCards()[2]}
                    </div>

                </div>

            </div> :
                <div className='most-trending-container'>
                    <Skeleton active paragraph={{ rows: 6, }} />
                </div>}
        </>
    )
}
