import { CloseOutlined, PlusOutlined } from '@ant-design/icons';
import Input from 'antd/lib/input/Input';
import axios from 'axios';
import React, { useEffect, useState } from 'react'
import './style.css'
import uploadMediaIcon from '../../../../../images/uploadMediaIcon.svg'
import { Button } from 'antd';
import checkboxIcon from '../../../../../images/rn-checkbox.svg';
import radioIcon from '../../../../../images/rn-radio.svg';
import configData from '../../../../../../config/config.json';
import checkEnv, { checkSurveyEnv } from '../../../../../../utils/checkEnv';

function QuestionOptions(props) {
    const base_url = configData.base_url;
    const surveyUrl=checkSurveyEnv()
    const url=checkEnv()
    const [questionObject, setQuestionObject] = useState(props.questionObject);
    const changeParentQuestionObject = props.changeParentQuestionObject;
    const [fileUploaded, setFileUploaded] = useState(questionObject.media_content ? true : false);
    const [fileUploading, setFileUploading] = useState(false);
    const [option,setOption]=useState(questionObject.options_order?questionObject.options_order:"")
    const [optionsOrder,setOPtionsOrder]=useState([ "ORDERED"])
    
    const questionTypeLabels = {
        'SINGLE_SELECT': 'Single Selection',
        'MULTI_SELECT': 'Multiple Selection',
        'MATRIX_SELECT': 'Matrix Selection',
        'RANGE_SELECTION': 'Range Selection',
        'ORDER_SELECTION': 'Order Selection',
        'TEXT_INPUT': 'Text Input',
        'TERMINAL_QUESTION': 'Terminal Question',
        "ORDERED":"Ordered", 
        "REVERSE_ORDERED":"Reverse Ordered",
        "RANDOM_ORDERED":"Random Ordered"
    };

    useEffect(() => {
        changeParentQuestionObject({ ...questionObject });
    }, [questionObject])

    useEffect(()=>{
        if(questionObject.question_type == 'SINGLE_SELECT'||questionObject.question_type == 'MULTI_SELECT' ||questionObject.question_type  == 'MATRIX_SELECT'){
            setOPtionsOrder([ "ORDERED", "REVERSE_ORDERED", "RANDOM_ORDERED"])
        }
        else{
            setOPtionsOrder([ "ORDERED"])

        }
    },[questionObject.question_type])

    const questionTypeChangeHandler = (e) => {
        let newOptions;

        //AND of 2 OR statements -> In order to retain the Options list, since all 3 below types have an option array.
        if ((questionObject.question_type == 'SINGLE_SELECT' || questionObject.question_type == 'MULTI_SELECT' || questionObject.question_type == 'ORDER_SELECTION') && (e.target.value == 'SINGLE_SELECT' || e.target.value == 'MULTI_SELECT' || e.target.value == 'ORDER_SELECTION')) {
            //Do nothing
            newOptions = [...questionObject.options];
        }
        else if (e.target.value == 'MATRIX_SELECT') {

            newOptions = { params: [], choices: [] }
        }
        else if (e.target.value == 'RANGE_SELECTION') {
            newOptions = [];
        }
        else if (e.target.value == 'TEXT_INPUT') {
            newOptions = null;
        }
        else
            newOptions = [];
        setQuestionObject({ ...questionObject, options: newOptions, question_type: e.target.value })
    }


    const questionTypeChangeHandler1=(e)=>{
        setOption(e.target.value)
        setQuestionObject(({...questionObject,options_order:e.target.value}))

    }
    // console.log(questionObject)
    const changeMatrixOptions = (e, index) => {
        let copy = { ...questionObject };
        copy.options[e.target.name][index] = e.target.value;
        setQuestionObject({ ...copy });
    }

    const addMatrixOption = (e, field) => {
        e.preventDefault();
        let copy = { ...questionObject };
        if (e.target.elements[0].value.length > 0) {
            copy.options[field].push(e.target.elements[0].value);
        }
        //Reset Fields
        e.target.elements[0].value = '';
        //Update Question Object for Re-render
        setQuestionObject({ ...copy });
    }

    const existingSmoOptionsChangeHandler = (e, index) => {
        let copy = { ...questionObject };
        copy.options[index] = e.target.value;
        setQuestionObject({ ...copy });
    }

    const smoOptionFormSubmitHandler = (e) => {
        e.preventDefault();
        //Update Options Array
        let copy = { ...questionObject };
        copy.options.push(e.target.elements[0].value);
        //Reset Fields
        e.target.elements[0].value = '';
        e.target.reset();
        //Update Question Object for Re-render
        setQuestionObject({ ...copy });
    }

    const rangeOptionHandler = (e) => {
        let arr = [];
        if (e.target.value) {
            let startingRange = 1;
            let endingRange = parseInt(e.target.value);
            if (endingRange - startingRange > 9)
                endingRange = 10;

            for (let i = startingRange; i <= endingRange; i++) {
                arr.push(i.toString());
            }
        }
        setQuestionObject({
            ...questionObject,
            options: [...arr],
        })
    }

    const renderOptionDesign = () => {
        switch (questionObject.question_type) {
            case 'SINGLE_SELECT':
                return <img src={radioIcon} />
            case 'MULTI_SELECT':
                return <img src={checkboxIcon} />
            default:
                return <></>
        }
    }

    const terminalQuestionValueHandler = (e) => {
        let copy = { ...questionObject };
        copy.options = [];
        if (e.target.value)
            copy.options.push(e.target.value);
        setQuestionObject({ ...copy });
    }

    const renderOptions = () => {
        const userType = localStorage.getItem('type');
        switch (questionObject.question_type) {
            case 'MATRIX_SELECT':
                return (
                    <div>
                        <div>
                            <div className='matrixOptionsLabels'>
                                <p className='matrixOption-label'>Rows (Parameters):</p>
                                <p className='matrixOption-label' style={{ marginLeft: "2rem" }}>Columns (Choices):</p>
                            </div>
                            <div className='matrixOptionsBox'>
                                <div className='matrixOptionsBox-params'>
                                    {
                                        questionObject.options.params.length > 0
                                            ?
                                            questionObject.options.params.map((param, index) => {
                                                return (
                                                    <>
                                                        <div className='smo-indivOption'>
                                                            <Input style={{ width: "100%" }} type='text' key={index} name='params' value={param} onChange={(e) => changeMatrixOptions(e, index)} />
                                                            <div style={{ display: "flex", alignItems: "center", cursor: "pointer" }} key={index} onClick={() => {
                                                                let copy = { ...questionObject };
                                                                copy.options.params.splice(index, 1);
                                                                setQuestionObject({ ...copy });
                                                            }}>
                                                                <CloseOutlined />
                                                            </div>
                                                        </div>
                                                    </>
                                                )
                                            })
                                            :
                                            null
                                    }
                                    <form className='smoNewOptionsForm' onSubmit={(e) => addMatrixOption(e, "params")}>
                                        <input type='text' name='params' placeholder='New Parameter' />
                                        <label className='alignCenter-row' style={{ cursor: "pointer" }}><PlusOutlined /><input className='minusPlus' type='submit' hidden /></label>
                                    </form>
                                </div>
                                <div className='matrixOptionsBox-choices'>
                                    {
                                        questionObject.options.choices.length > 0
                                            ?
                                            questionObject.options.choices.map((choice, index) => {
                                                return (

                                                    <div className='smo-indivOption'>

                                                        <Input style={{ width: "100%" }} type='text' key={index} name='choices' value={choice} onChange={(e) => changeMatrixOptions(e, index)} />
                                                        <div style={{ display: "flex", alignItems: "center", cursor: "pointer" }} key={index} onClick={() => {
                                                            let copy = { ...questionObject };
                                                            copy.options.choices.splice(index, 1);
                                                            setQuestionObject({ ...copy });
                                                        }}>
                                                            <CloseOutlined />
                                                        </div>
                                                    </div>
                                                )
                                            })
                                            :
                                            null
                                    }
                                    <form className='smoNewOptionsForm' onSubmit={(e) => addMatrixOption(e, "choices")}>
                                        <input type='text' name='choices' placeholder='New Choice' />
                                        <label className='alignCenter-row' style={{ cursor: "pointer" }}><PlusOutlined /><input className='minusPlus' type='submit' hidden /></label>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            case 'RANGE_SELECTION':
                return (
                    <div className='rangeOption'>
                        <div>
                            <label className='questionBox-label'>Starting Value:<br />
                                <input type='number' disabled value={1} />
                            </label>
                            <label className='questionBox-label'>Ending Value:<br />
                                <input type='number' value={questionObject.options.length > 0 ? questionObject.options[questionObject.options.length - 1] : null} onChange={rangeOptionHandler} />
                            </label>
                        </div>
                        <label className='infoText'>Enter an Ending Value. The range limit is 10.</label>

                    </div>
                )
            case 'SINGLE_SELECT':
            case 'MULTI_SELECT':
            case 'ORDER_SELECTION':
                return (
                    <div className='smo-options-grid'>
                        {
                            (questionObject.options.length > 0)
                                ?
                                <>
                                    {questionObject.options.map((option, index) => {
                                        return (
                                            <div className='smo-indivOption'>
                                                <div className='alignCenter-row' style={{ width: "100%" }}>
                                                    {renderOptionDesign()}
                                                    &nbsp;&nbsp;
                                                    <Input style={{ width: "100%" }} type='text' value={option} key={index} onChange={(e) => existingSmoOptionsChangeHandler(e, index)} />
                                                </div>
                                                {/* Remove Option Button */}
                                                <div style={{ display: "flex", alignItems: "center", cursor: "pointer" }} key={index} onClick={() => {
                                                    let copy = { ...questionObject };
                                                    copy.options.splice(index, 1);
                                                    setQuestionObject({ ...copy });
                                                }}>
                                                    <CloseOutlined />
                                                </div>
                                            </div>
                                        )
                                    })}

                                </>
                                :
                                null
                        }
                        <form className='smoNewOptionsForm' style={{ width: "100%" }} id={`optionsForm-${questionObject.id}`} onSubmit={smoOptionFormSubmitHandler}>
                            <input type='text' id={`newOption-${questionObject.id}`} required placeholder={'Add Option'} />
                            <label className='alignCenter-row' style={{ cursor: "pointer" }}><PlusOutlined /><input className='minusPlus' type='submit' hidden /></label>
                        </form>
                    </div>
                )
            case 'TEXT_INPUT':
                return (
                    <div className='textInputDiv'>
                        <div style={{ borderBottom: "1px solid #928e8e" }}>User will be asked to fill in the Text Box</div>
                    </div>
                )
            case 'TERMINAL_QUESTION':
                if (userType == 'ADMIN')
                    return (
                        <div className='smoNewOptionsForm' style={{ width: "30%" }} id={`optionsForm-${questionObject.id}`}>
                            <Input type='text' id={`newOption-${questionObject.id}`} autoFocus defaultValue={questionObject.options ? questionObject.options[0] : null} required placeholder={'Enter Here..'} onChange={terminalQuestionValueHandler} />
                        </div>
                    )
        }
    }


    const mediaUploadHandler = (e) => {
        setFileUploading(true);
        const files = e.target.files
        const formData = new FormData();
        formData.append('file', files[0]);
        axios.post(surveyUrl + `/smyttenresearch/uploadfile/`,
            formData
        )
            .then((res) => {
                setQuestionObject({
                    ...questionObject,
                    "media_type": "IMAGE",
                    "media_content": res.data.url
                })
                setFileUploaded(true);
                setFileUploading(false);
            })
            .catch((err) => {
                setFileUploaded(false);
                setFileUploading(false);
            })
        e.target.value = '';
    }

    const removeMedia = (e) => {
        e.stopPropagation();
        setQuestionObject({
            ...questionObject,
            "media_type": null,
            "media_content": null
        })
        setFileUploaded(false);
        setFileUploading(false);
    }
   
    return (
        <>
            <div className='questionBox-inner'>
                <div className='questionBox-inner-left'>
                    <Input className='questionText' placeholder='Type your Question here...' defaultValue={questionObject.question_text} onChange={(e) => {
                        setQuestionObject({
                            ...questionObject,
                            question_text: e.target.value,
                        })
                    }} />
                    <div className='image-container'>
                        {questionObject.media_content && !Array.isArray(questionObject.media_content) && <div>
                                <img className="image" src={questionObject.media_content} alt="selectedImage" />
                            </div>}
                        <Button loading={fileUploading} className='questionMediaBox' onClick={() => document.getElementById(`media-${questionObject.id}`).click()}>

                            <img src={uploadMediaIcon} />
                            <div className='questionMediaBox-text'>{fileUploaded ? <>&nbsp;Media Uploaded &nbsp;<span style={{ color: "black" }} onClick={removeMedia}>X</span></> : 'Upload Media'}</div>
                            <input id={`media-${questionObject.id}`} hidden type='file' accept='image/*, video/*' onChange={mediaUploadHandler} />
                        </Button>
                    </div>
                </div>
                <div>
                    <select className='optionsDropdown' defaultValue={questionObject.question_type} onChange={questionTypeChangeHandler}>
                        <option value={undefined} hidden>Select Question Type</option>
                        {
                            ['SINGLE_SELECT', 'MULTI_SELECT', 'MATRIX_SELECT', 'RANGE_SELECTION', 'ORDER_SELECTION', 'TEXT_INPUT', 'TERMINAL_QUESTION'].map((questionType) => {
                                return (
                                    <option value={questionType} key={questionType}>{questionTypeLabels[questionType]}</option>
                                )
                            })
                        }
                    </select>
                    {<select className='optionsDropdown' value={option} onChange={questionTypeChangeHandler1}>
                        <option value={undefined} hidden>Select Option Type</option>
                        {
                            optionsOrder.map((questionType) => {
                                return (
                                    <option value={questionType} key={questionType}>{questionTypeLabels[questionType]}</option>
                                )
                            })
                        }
                    </select>}
                </div>
            </div>
            {/* Question Type Dropdown */}
            {/* ________________________ */}

            {/* Call function which will return the editable options based on question type. */}
            {renderOptions()}
        </>
    )
}

export default QuestionOptions