import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router';
import './style.css';
import bsContinueIcon from '../../../../images/bs_continueIcon.svg'
import configData from '../../../../../config/config.json'
import moment from "moment"
import { useSearchParams } from 'react-router-dom';
import { checkSurveyEnv } from '../../../../../utils/checkEnv';

function BasicSetup({ changeStep }) {
    const [searchParams, setSearchParams] = useSearchParams()
    const base_url = configData.base_url;
    const navigate = useNavigate();
    const [surveyDetails, setSurveyDetails] = useState({});
    const selectedBrand = localStorage.getItem('brand_id');
    let startDate = localStorage.getItem('start_date') ? localStorage.getItem('start_date') : localStorage.setItem("start_date", moment().subtract(6, 'days').format('YYYY-MM-DD'))
    let endDate = localStorage.getItem('end_date') ? localStorage.getItem('end_date') : localStorage.setItem("end_date", moment().format("YYYY-MM-DD"))
    const [paramValue, setParamValue] = useState();
    let { surveyId } = useParams();
    let surveyUrl=checkSurveyEnv()
    
    const [responseCountError, setResponseCountError] = useState(false);

    useEffect(() => {
        if (surveyDetails['id']) {
            changeStep(2);
            navigate({
                pathname: `/brandsdashboard/research/surveys/${surveyDetails.id}`,
                search: `?brand=${localStorage.getItem("brand_id")}&categoryIds=${localStorage.getItem('allcategoryId') ? (localStorage.getItem('allcategoryId').split(',')) : []}&productIds=${localStorage.getItem('allProductId') ? localStorage.getItem('allProductId').split(',') : []}&date=${startDate}to${endDate}`
            })
        }
    }, [surveyDetails])

    useEffect(() => {
        if (surveyId)
            getSurveyDetails();
    }, [])

    const getSurveyDetails = () => {
        axios.get(surveyUrl + `/smyttenresearch/survey/${surveyId}`)
            .then((res) => {
                setSurveyDetails(res.data);
            })
            .catch((err) => {
                console.log(err);
            })
    }
    const paramChangeHandler = (e) => {
        setParamValue(e.target.value)
    }

    const createSurvey = (e) => {
        e.preventDefault();
        let formValues = {};
        Object.values(e.target).forEach((input) => {
            if (input.name && input.value)
                formValues[input.name] = input.value;
        })

        if (formValues.max_resp_count < 250) {
            setResponseCountError(true);
        }
        else {
            axios.post(surveyUrl + `/smyttenresearch/survey/?brand_id=${searchParams.get('brand')}`, {
                "title": formValues.title,
                "sub_title": formValues.sub_title,
                "max_questions": formValues.max_questions,
                "max_resp_count": formValues.max_resp_count
            })
                .then((res) => {
                    changeStep(2);
                    navigate({
                        pathname: `/brandsdashboard/research/surveys/${res.data.id}`,
                        search: `?brand=${localStorage.getItem("brand_id")}&categoryIds=${localStorage.getItem('allcategoryId') ? (localStorage.getItem('allcategoryId').split(',')) : []}&productIds=${localStorage.getItem('allProductId') ? localStorage.getItem('allProductId').split(',') : []}&date=${startDate}to${endDate}`
                    })
                })
                .catch((err) => {
                    console.log(err.message);
                })
        }
    }


    const editSurvey = (e) => {
        e.preventDefault();
        let formValues = {};
        Object.values(e.target).forEach((input) => {
            if (input.name && input.value)
                formValues[input.name] = input.value;
        })
        axios.post(surveyUrl + `/smyttenresearch/survey/?brand_id=${selectedBrand}`, {
            "title": formValues.title,
            "sub_title": formValues.sub_title,
            "max_questions": formValues.max_questions,
            "max_resp_count": formValues.max_resp_count
        })
            .then((res) => {
                changeStep(2);
                navigate({
                    pathname: `/brandsdashboard/research/surveys/${res.data.id}`,
                    search: `?brand=${localStorage.getItem("brand_id")}&categoryIds=${localStorage.getItem('allcategoryId') ? (localStorage.getItem('allcategoryId').split(',')) : []}&productIds=${localStorage.getItem('allProductId') ? localStorage.getItem('allProductId').split(',') : []}&date=${startDate}to${endDate}`
                })
            })
            .catch((err) => {
                console.log(err.message);
            })
    }

    return (
        <>
            <div className='bs-main'>
                <form onSubmit={createSurvey}>
                    <div className='bs-title'>Survey Information</div>
                    <div className='bs-inputRow'>
                        <label className='bs-label'>Survey Name<br /><input className='bs-input' name='title' type='text' required /></label>
                        <label className='bs-label'>Survey Subtitle<br /><input className='bs-input' name='sub_title' type='text' required /></label>
                        <label className='bs-label'>Number Of Questions<br /><input className='bs-input' name='max_questions' type='number' min={1} max={60} required placeholder='Max Limit: 60' /></label>
                    </div>
                    {/* <span style={{ color: "#EE504F" }}>*</span> */}
                    &nbsp;
                    <div>
                        <label className='bs-label'>No. Of Responses<br /><input className='bs-input' name='max_resp_count' type='number' onChange={() => setResponseCountError(false)} required /></label>
                        {
                            responseCountError
                                ?
                                <div className='questionBox-prompt' style={{ marginLeft: '8px', marginTop: '8px', fontSize: '12px', padding: '2px 8px' }}>Min 250 responses required for statistical significance.</div>
                                :
                                null
                        }
                    </div>
                    <div className='bs-actionButtonArea'>
                        <div className='bs-actionButton bs-cancel'>CANCEL</div>
                        <button className='bs-actionButton bs-continue'>CONTINUE <img src={bsContinueIcon} /></button>
                    </div>
                </form>
            </div >
        </>
    )
}

export default BasicSetup


// else
    //     return (
    //         <>
    //             <div className='bs-main'>
    //                 <form onSubmit={editSurvey}>
    //                     <div className='bs-title'>Survey Information</div>
    //                     <div className='bs-inputRow'>
    //                         <label className='bs-label'>Survey Name<span style={{ color: "#EE504F" }}>*</span><br /><input className='bs-input' name='title' type='text' defaultValue={surveyDetails.title} required /></label>
    //                         <label className='bs-label'>Survey Subtitle<br /><input className='bs-input' name='sub_title' type='text' defaultValue={surveyDetails.sub_title} /></label>
    //                         <label className='bs-label'>Number Of Questions<span style={{ color: "#EE504F" }}>*</span><br /><input className='bs-input' name='max_questions' type='number' min={1} max={60} required placeholder='Max Limit: 60' defaultValue={surveyDetails.max_questions ? surveyDetails.max_questions : null} /></label>
    //                     </div>
    //                     <div className='bs-actionButtonArea'>
    //                         <button className='bs-actionButton bs-continue'>UPDATE <img src={bsContinueIcon} /></button>
    //                     </div>
    //                 </form>
    //             </div>
    //         </>
    //     )