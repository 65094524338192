import React, { useState, useEffect } from "react";
import Graph1 from "./Graph1";
import Graph2 from "./Graph2";
import Graph3 from "./Graph3";
import WeeklyValues from "./WeeklyValues";
import CancelBtn from "../../images/cancel_btn.svg";
import axios from "axios";
import "./RatingMetricBoxes.css"
import { RatingsAndReviewStackedGraph } from "../RatingsAndReviewGraphs/RatingsAndReviewStackedGraph";
import removeTag from "../../images/remove_tag.png";
import DataLoader from "../../images/noDataFull.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { useSearchParams, useNavigate } from "react-router-dom";
import MetrixSkeletonLoading from "../../SkeletonLoading/MetrixSkeletonLoading"
import Nodatacard from "../../NodataCard";
import checkEnv from "../../../utils/checkEnv";


function nFormatter(num) {
  if (num >= 1000000000) {
    return (num / 1000000000).toFixed(1).replace(/\.0$/, "") + "G";
  }
  if (num >= 1000000) {
    return (num / 1000000).toFixed(1).replace(/\.0$/, "") + "M";
  }
  if (num >= 1000) {
    return (num / 1000).toFixed(1).replace(/\.0$/, "") + "K";
  }
  return num;
}
let categoryListNameIndex = {}
let categoryListIdName = {}
let productListIndex = {}
let productListIdName = {}
const RatingMetricBoxes = (props) => {
  const [noDataLoad, setNoDataLoad] = useState(false)
  const navigate = useNavigate()
  const [nextState, setNextState] = useState(false)
  const [searchParams, setSearchParams] = useSearchParams()
  let token = localStorage.getItem("token")
  let startDate = localStorage.getItem("start_date");
  let endDate = localStorage.getItem("end_date");
  let url=checkEnv()

  const [boxData, setBoxData] = useState([]);
  const [boxData1, setBoxData1] = useState([]);
  const [boxData2, setBoxData2] = useState([]);
  const [boxData3, setBoxData3] = useState([]);
  const [categoryList, setCategoryList] = useState([])
  const [productList, setProductList] = useState([])
  const [categoryCheckedList, setCategoryCheckedList] = useState([])
  const [productCheckedList, setProductCheckedList] = useState([])
  const getAverageNPSRating = async () => {
    setBoxData([])
    const response = await axios.get(`${url}/brandsdashboardproxy/v1/brand/${searchParams.get('brand')}/averagenps/?startDate=${startDate}&endDate=${endDate}&categoryIds=${searchParams.get('categoryIds')}&productIds=${searchParams.get('productIds')}`, {
      headers: {
        'authorization': `${token}`
      }
    })
      .catch((err) => {
        console.log(err);
      });
    setBoxData(response.data.map((dat) => dat));
  };
  const getAverageProductRating = async () => {
    setBoxData1([])
    const response = await axios.get(`${url}/brandsdashboardproxy/v1/brand/${searchParams.get('brand')}/averageproductrating/?startDate=${startDate}&endDate=${endDate}&categoryIds=${searchParams.get('categoryIds')}&productIds=${searchParams.get('productIds')}`, {
      headers: {
        'authorization': `${token}`
      }
    })
      .catch((err) => {
        console.log(err);
      });
    setBoxData1(response.data.map((dat) => dat));
  };
  const getPositiveFeedback = async () => {
    setBoxData2([])
    const response = await axios.get(`${url}/brandsdashboardproxy/v1/brand/${searchParams.get('brand')}/positivefeedbackcount/?startDate=${startDate}&endDate=${endDate}&categoryIds=${searchParams.get('categoryIds')}&productIds=${searchParams.get('productIds')}`, {
      headers: {
        'authorization': `${token}`
      }
    })
      .catch((err) => {
        console.log(err);
      });
    setBoxData2(response.data.map((dat) => dat));
    response.data[0].metric_value === 0 ? setNoDataLoad(true)
      : setNoDataLoad(false)
  };
  const getNegativeFeedback = async () => {
    setBoxData3([])
    const response = await axios.get(`${url}/brandsdashboardproxy/v1/brand/${searchParams.get('brand')}/negativefeedbackcount/?startDate=${startDate}&endDate=${endDate}&categoryIds=${searchParams.get('categoryIds')}&productIds=${searchParams.get('productIds')}`, {
      headers: {
        'authorization': `${token}`
      }
    })
      .catch((err) => {
        console.log(err);
      });
    setBoxData3(response.data.map((dat) => dat));
  };

  useEffect(() => {
    getAverageNPSRating();
    getAverageProductRating();
    getPositiveFeedback();
    getNegativeFeedback();
  }, [searchParams]);
  const getCategories = async () => {
    const response = await axios.get(`${url}/brandsdashboardproxy/v1/brand/${searchParams.get(`brand`)}/category/`, {
      headers: {
        'authorization': `${token}`
      }
    })
      .catch((err) => {
      });
      if(response===undefined){
        console.log(response)
      }else{
        response.data.map((d, index) => {
          categoryListNameIndex[d.categoryName] = index
          categoryListIdName[d.categoryName] = d.categoryId
        })
        setCategoryList(response.data)
      }
    
  };
  const getProducts = async () => {
    const response = await axios.get(`${url}/brandsdashboardproxy/v1/brand/${searchParams.get(`brand`)}/product/?startDate=${startDate}&endDate=${endDate}&categoryIds=${searchParams.get('categoryIds')}`, {
      headers: {
        'authorization': `${token}`
      }
    })
      .catch((err) => {
      });
    setProductList(response.data);
    response.data.map((d, index) => {
      productListIndex[d.name] = index
      productListIdName[d.name] = d.sku
    })
  };

  useEffect(() => {
    getCategories()
  }, [searchParams])

  useEffect(() => {
    getProducts();
  }, [searchParams.get('categoryIds')])
  function arrayRemove(arr, value) {
    return arr.filter(function (geeks) {
      return geeks !== value;
    });

  }
  let format = /[-]/;

  const removeSelectedClickHandler = (e) => {
    let a = searchParams.get('categoryIds').split(',')
    navigate({
      pathname: `/brandsdashboard/ratings`,
      search: `?brand=${searchParams.get('brand')}&categoryIds=${arrayRemove(a, `${categoryListIdName[e.target.id]}`)}&productIds=${[]}&date=${searchParams.get('date')}`
    })
  }


  const removeSelectedProductClickHandler = (e) => {

    let a = searchParams.get('productIds').split(',')
    navigate({
      pathname: `/brandsdashboard/ratings`,
      search: `?brand=${searchParams.get('brand')}&categoryIds=${searchParams.get('categoryIds')}&productIds=${arrayRemove(a, `${productListIdName[e.target.id]}`)}&date=${searchParams.get('date')}`
    })
  }

  const checkedCatList = () => {
    let b = []
    let a = searchParams.get('categoryIds') ? searchParams.get('categoryIds').split(',') : []
    if (categoryList.length === a.length) {
      categoryList.map((d, index) => {
        if (d.categoryId == a[index]) {
          b.push(d.categoryName)
        }
      })
    } else {
      for (let i = 0; i < a.length; i++) {
        for (let j = 0; j < categoryList.length; j++) {
          if (a[i] == categoryList[j].categoryId) {
            b.push(categoryList[j].categoryName)
          }
        }
      }
    }
    setCategoryCheckedList(b)
  }
  const checkedProductList = () => {
    let b = []
    let a = searchParams.get('productIds') ? searchParams.get('productIds').split(',') : []
    if (productList.length === a.length) {
      productList.map((d, index) => {
        if (d.sku == a[index]) {
          b.push(d.name)
        }
      })
    } else {
      for (let i = 0; i < a.length; i++) {
        for (let j = 0; j < productList.length; j++) {
          if (a[i] == productList[j].sku) {
            b.push(productList[j].name)
          }
        }
      }
    }
    setProductCheckedList(b)
  }

  useEffect(() => {
    checkedCatList()
    checkedProductList()
  }, [searchParams, categoryList])

  useEffect(() => {
    if (boxData.length !== 0 && boxData1.length !== 0 && boxData2.length !== 0 && boxData3.length !== 0) {
      setNextState(true)
    } else {
      setNextState(false)
    }
  }, [boxData, boxData1, boxData2, boxData3])
  const removeItems=(type)=>{
    if(type==="products"){
      navigate({
        pathname: `/brandsdashboard/ratings`,
        search: `?brand=${searchParams.get('brand')}&categoryIds=${searchParams.get('categoryIds')}&productIds=${[]}&date=${searchParams.get('date')}`
      })
    }else{
      navigate({
        pathname: `/brandsdashboard/ratings`,
        search: `?brand=${searchParams.get('brand')}&categoryIds=${[]}&productIds=${searchParams.get('productIds')}&date=${searchParams.get('date')}`
      })
    }
    
  }

  return (
    <div className="boxes">
      <div className="showAll">Showing |{searchParams.get('categoryIds') === "" ? ` All Categories` : 
      // <div className="selectedList">
      //   {
      //     categoryCheckedList.map(d => {
      //       return <div className="selectedData">{d}<img id={d} src={removeTag} alt="removeSelectedCategory" onClick={removeSelectedClickHandler} /></div>
      //     })
      //   }
        
      // </div>
      <>{categoryCheckedList.length===1?
        <div className="selectedList">
          {
            categoryCheckedList.map((d, index) => {
              return <div className="selectedData" key={index}>{d}<img id={d} src={CancelBtn} alt="removeSelectedCategory" onClick={removeSelectedClickHandler} /></div>
            })
          }
        </div>
        :<div className="selected_list_items">
          {`${categoryCheckedList.length} categories selected`}
          <img onClick={()=>removeItems("categery")} alt="" src={CancelBtn}/>
        </div>}
      </>
      } | {
          searchParams.get('productIds') === "" ?
            `All Products` : 
            // <div className="selectedList">
            //  <div className="selected_list_items">
            //   {`${productCheckedList.length} products selected`}
            //   <img onClick={()=>removeItems("products")} alt="" src={CancelBtn}/>
            // </div> 
            // </div>
            <>
              {productCheckedList.length===1?
              <div className="selectedList">
                {
                  productCheckedList.map((d, index) => {
                    return <div className="selectedData" key={index}>{d}<img id={d} src={CancelBtn} alt="removeSelectedProduct" onClick={removeSelectedProductClickHandler} /></div>
                  })
                }
              </div>

              :<div className="selected_list_items">
                {`${productCheckedList.length} products selected`}
                <img onClick={()=>removeItems("products")} alt="" src={CancelBtn}/>
              </div> }
            </>

        }</div>
      {
        noDataLoad !== true ?
          <>
            <div className="ratingMetricBoxes">
              {boxData.length !== 0 && boxData1.length !== 0 && boxData2.length !== 0 && boxData3.length !== 0 ?
                boxData.map((data, index) => {
                  return (
                    <div className="box" key={index}>
                      <div className="dataCategory">
                        <p>Net Promoter Score for your brand </p>
                        {/* ({data.count}) */}
                      </div>
                      <div className="value">
                        <div className="det">

                          {/* {data.metric_value ? (data.metric_value).toFixed(2).toString() : ""} */}
                          {data.metric_value === 0 ? <span>No Data Found</span> : data.metric_value ? Math.round((data.metric_value)).toString() : ""}

                          <p className="percentage">
                            {/* {data.count!== undefined ? data.count.toFixed(1).toString() : ""}% */}
                          </p>
                        </div>
                        <div className="graph" id={index}>
                          <WeeklyValues
                            val={data.weekly_numbers ? data.weekly_numbers.slice(0, 11) : ""}
                            id={index}
                            colorData={data.count !== undefined ? data.count.toFixed(1) : ""}
                            brandId={props.brandId}
                            startDate={props.startDate}
                            endDate={props.endDate}
                            data={props.boxData}
                            allChecked={props.allChecked}
                            allProductsChecked={props.allProductsChecked}
                            singleCheck={props.singleCheck}
                            singleProductCheck={props.singleProductCheck}
                          />
                        </div>
                      </div>
                    </div>
                  );
                }) : <MetrixSkeletonLoading />
              }
              {boxData.length !== 0 && boxData1.length !== 0 && boxData2.length !== 0 && boxData3.length !== 0 ?
                boxData1.map((data, index) => {
                  return (
                    <div className="box" key={index}>
                      <div className="dataCategory">
                        <p>Average Product Ratings</p>
                        {/* ({data.count}) */}
                      </div>
                      <div className="value">
                        <div className="det">
                          {/* {data.metric_value ? (data.metric_value).toFixed(2) : ""} */}
                          {/* <p className="percentage3">{data.count.toFixed(1)}%</p> */}
                          {data.metric_value === 0 ? <span>No Data Found</span> : data.metric_value ? (data.metric_value).toFixed(2) : ""}

                        </div>
                        <div className="graph" id={index}>
                          <Graph1
                            val={data.weekly_numbers ? data.weekly_numbers.slice(0, 11) : ""}
                            id={index}
                            colorData={data.count !== undefined ? data.count.toFixed(1).toString() : ""}
                            brandId={props.brandId}
                            data={props.boxData}
                            allChecked={props.allChecked}
                            allProductsChecked={props.allProductsChecked}
                            singleCheck={props.singleCheck}
                            singleProductCheck={props.singleProductCheck}
                          />
                        </div>
                      </div>
                    </div>
                  );
                }) : <MetrixSkeletonLoading />
              }
             
            </div>
            <RatingsAndReviewStackedGraph brandId={props.brandId} allChecked={props.allChecked}
              allProductsChecked={props.allProductsChecked}
              singleCheck={props.singleCheck} singleProductCheck={props.singleProductCheck} nextState={nextState} />
          </>
          : 
          // <img className="fullNoDataImage" src={DataLoader} alt='image data empty' />
          <div className="fullNoDataImage margintop">
            {/* <h4>! Sorry, we don't have sufficient enough data to show this widget</h4> */}
            <Nodatacard text="Insufficient data to perform Ratings and Review analysis!"/>
          </div>
          
          }

    </div>
  );
};

export default RatingMetricBoxes;

// {boxData.length !== 0 && boxData1.length !== 0 && boxData2.length !== 0 && boxData3.length !== 0 ?
//   boxData2.map((data, index) => {
//     let rs1 = format.test(data.percentage_change.toString());
//     let valueColor
//     if (rs1 === true) {
//       valueColor = "rgb(255, 115, 97)";
//     } else if (data.percentage_change.toFixed(1).toString() === '0.0') {
//       valueColor = 'rgb(255, 207, 97)'
//     } else {
//       valueColor = "rgb(0, 188, 138)";
//     }
//     let plus
//     let rs2 = data.percentage_change !== 0.0;
//     if (rs2 === true && rs1 === false) {
//       plus = '+'
//     } else {
//       plus = ''
//     }
//     return (
//       <div className="box" key={index}>
//         <div className="dataCategory">
//           <p>Positive Feedback</p>
//         </div>
//         <div className="value">
//           <div className="det">

//           {data.metric_value === 0 ? <span>No Data Found</span> : nFormatter(data.metric_value)}
//             {data.metric_value === 0 ? "" : <p className="percentage1" style={{ color: `${valueColor}` }}>
//               {plus}{data.percentage_change.toFixed(1).toString()}%
//             </p>}
//           </div>
//           <div className="graph" id={index}>
//             <Graph2
//               val={data.weekly_numbers ? data.weekly_numbers.slice(0, 11) : ""}
//               id={index}
//               colorData={data.percentage_change !== undefined ? data.percentage_change.toFixed(1).toString() : ""}
//               brandId={props.brandId}
//               startDate={props.startDate}
//               endDate={props.endDate}
//               data={props.boxData}
//               allChecked={props.allChecked}
//               allProductsChecked={props.allProductsChecked}
//               singleCheck={props.singleCheck}
//               singleProductCheck={props.singleProductCheck}
//             />
//           </div>
//         </div>
//       </div>
//     );
//   }) : <MetrixSkeletonLoading />

// }
// {boxData.length !== 0 && boxData1.length !== 0 && boxData2.length !== 0 && boxData3.length !== 0 ?
//   boxData3.map((data, index) => {

//     let rs1 = format.test(data.percentage_change.toString());
//     let valueColor
//     if (rs1 === true) {
//       valueColor = "rgb(255, 115, 97)";
//     } else if (data.percentage_change.toFixed(1).toString() === '0.0') {
//       valueColor = 'rgb(255, 207, 97)'
//     } else {
//       valueColor = "rgb(0, 188, 138)";
//     }
//     let plus
//     let rs2 = data.percentage_change !== 0.0;
//     if (rs2 === true && rs1 === false) {
//       plus = '+'
//     } else {
//       plus = ''
//     }

 
//     return (
//       <div className="box" key={index}>
//         <div className="dataCategory">
//           <p>Negative Feedback</p>
//         </div>
//         <div className="value">
//           <div className="det">
//             {data.metric_value === 0 ? <span>No Data Found</span> : nFormatter(data.metric_value)}
//             {data.metric_value === 0 ? "" : <p className="percentage1" style={{ color: `${valueColor}` }}>
//               {plus}{data.percentage_change.toFixed(1).toString()}%
//             </p>}
//           </div>
//           <div className="graph">
//             <Graph3
//               val={data.weekly_numbers ? data.weekly_numbers.slice(0, 11) : ""}
//               id={index}
//               colorData={data.percentage_change !== undefined ? data.percentage_change.toFixed(1).toString() : ""}
//               brandId={props.brandId}
//               startDate={props.startDate}
//               endDate={props.endDate}
//               data={props.boxData}
//               allChecked={props.allChecked}
//               allProductsChecked={props.allProductsChecked}
//               singleCheck={props.singleCheck}
//               singleProductCheck={props.singleProductCheck}
//             />
//           </div>
//         </div>
//       </div>
//     );
//   }) : <MetrixSkeletonLoading />
// }
