import React, { useEffect, useState } from 'react'
import "./AudienceTargeting.css"
import './NumberToaster.css'
import axios from 'axios'
import { useParams } from 'react-router';
import { useSearchParams } from 'react-router-dom'
import Dropdown from 'react-bootstrap/Dropdown';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSortDown, faCircleXmark, faArrowRight, faCircleExclamation } from "@fortawesome/free-solid-svg-icons";

// import duplicateImage from "../../../images/duplicate.svg"
// import centerLine from "../../../images/Line 120.svg"
import errroImage from '../../../../images/bx_error-circle.svg'
import { checkSurveyEnv } from '../../../../../utils/checkEnv';
let typeObject = {}
let filterObject = {}
let valueObject = {}
export default function AudienceTargeting({ changeStep }) {
    const [searchParams, setSearchParams] = useSearchParams()
    const [mobileNumber, setMobileNumber] = useState([])
    const [errorObject, setErrorObject] = useState([])
    const [errorState, setErrorState] = useState(false)
    const [checkWord, setCheckWord] = useState('')
    const [checkType,setCheckType]=useState('')
    const getKeyByValue = (obj, value) => Object.keys(obj).find(key => obj[key] === value)
    let { surveyId } = useParams()
    const [apiData, setApiData] = useState({
        type: [],
        filter: {},
        values: {},
    })
    const [audienceData, setAudienceData] = useState([])
    const [data, setData] = useState([])
    const userType = localStorage.getItem('type');
    let surveyUrl=checkSurveyEnv()

    useEffect(()=>{
        axios.get(`${surveyUrl}/smyttenresearch/setaudiencetargeting/123`)
        .then(res=>{
            console.log(res.data)
            setCheckType(res.data.message)
        })
    },[])
    

    const getAudienceData = () => {
        axios.get(`${surveyUrl}/smyttenresearch/survey/${surveyId}`, {
        })
            .then((res) => {
                setAudienceData(res.data.audience_targeting)
                if (res.data.audience_targeting.length === 0) {
                    // setData([{
                    //     id: 0,
                    //     type: '',
                    //     filter: '',
                    //     values: '',
                    // }])
                }
            })
            .catch(err => console.log(err))
    }

    useEffect(() => {
       
        getAudienceData()
     
    }, [searchParams, changeStep])

    useEffect(() => {
        axios.get(`${surveyUrl}/smyttenresearch/audiencetargeting/?brand_id=${searchParams.get('brand')}`, {
        })
            .then(res => {
                res.data.map(d => typeObject[d.display] = d.key)
                apiData.type = res.data.map(d => d)
                setApiData({ ...apiData })
                res.data.map(data => {
                    axios.get(`${surveyUrl}/smyttenresearch/audiencetargeting/?brand_id=${searchParams.get('brand')}&targetingType=${data.key}`, {
                    })
                        .then(res => {
                            res.data.map(d => filterObject[d.display] = d.key)
                            apiData.filter[data.key] = res.data.map(d => d)
                            setApiData({ ...apiData })
                            res.data.map(d => {
                                let obj = {}
                                axios.get(`${surveyUrl}/smyttenresearch/audiencetargeting/?brand_id=${searchParams.get('brand')}&targetingType=${data.key}&filters=${d.key}`, {
                                })
                                    .then(res => {
                                        res.data.map(d => valueObject[d.display] = d.key)
                                        apiData.values[d.key] = res.data.map(d => d)
                                        setApiData({ ...apiData })
                                    })
                                    .catch(err => console.log(err))
                            })
                        })
                        .catch(err => console.log(err))
                })
            })
            .catch(err => console.log(err))
    }, [changeStep, surveyId])

    const typeChangeHandler = (e, id) => {
        setData([...data.map(item => {
            if (item.id !== id) return item;
            else return { ...data.find(item => item.id === id), type: e, filter: apiData.filter[typeObject[e]][0].display, values: apiData.values[filterObject[apiData.filter[typeObject[e]]]] ? apiData.values[filterObject[apiData.filter[typeObject[e]][0].display]][0].display : "" }
        })]);
    }
    const typeOptionsChangeHandler = (e, id) => {
        setData([...data.map(item => {
            if (item.id !== id) return item;
            else return { ...data.find(item => item.id === id), filter: e, values: apiData.values[filterObject[e]][0] ? apiData.values[filterObject[e]][0].display : "" }
        })]);
    }

    const typeValuesChangeHandler = (e, id) => {
        setData([...data.map(item => {
            if (item.id !== id) return item;
            else return { ...data.find(item => item.id === id), values: e }
        })]);
    }


    useEffect(() => {
        let testArray = []
        if (audienceData.length !== data.length && audienceData[0]) {
            if (Object.keys(audienceData[0]).length === 0) {
                // setData([{
                //     id: 0,
                //     type: '',
                //     filter: '',
                //     values: '',
                // }])
            } else if (Object.keys(audienceData[0]).length > 0) {
                audienceData.map((d, index) => {
                    if (d.targetingType !== undefined && getKeyByValue(filterObject, d.filter) !== undefined && (d.value === '' || d.value)) {
                        testArray.push({
                            id: index,
                            type: getKeyByValue(typeObject, d.targetingType),
                            filter: getKeyByValue(filterObject, d.filter),
                            values: getKeyByValue(valueObject, d.value),
                        })
                    }
                })
                setData(testArray)
            }
        }
    }, [audienceData, apiData])

    const phoneNumberChangeHandler = (e) => {
        let a = e.target.value.split(',')
        setMobileNumber(a)
    }


    const phoneNumberSubmitClickHandler = (e) => {
        e.preventDefault();
        // if (/^\d+$/.test(mobileNumber)) {
        // let phoneNumber = mobileNumber.split(',')
        axios.post(`${surveyUrl}/smyttenresearch/usereligiblesurveyscontroller/`, {
            "phone_numbers": mobileNumber,
            "survey_id": `${surveyId}`,
        })
            .then(res => {
                if (mobileNumber.length > 0) {
                    e.target.parentElement.previousSibling.value = ''
                    var x = document.getElementById("snackbar");
                    x.className = "show";
                    setTimeout(() => {
                        x.className = x.className.replace("show", "");
                    }, 1000);
                }
            })
            .catch(err => console.log(err))
    }



    const saveClickHandler = (e) => {
        let list = document.querySelectorAll('.value')
        let test = []
        let audienceTargeting = []
        data.map((d, index) => {
            audienceTargeting.push({
                "targetingType": typeObject[d.type],
                "filter": filterObject[d.filter],
                "value": valueObject[d.values],
            })
        })

        for (let i = 0; i < list.length; i++) {
            if (list[i].textContent === '') {
                let a = list[i].id - 1
                setErrorState(true)
                document.getElementById(`main` + ' ' + a).style.border = `1px solid #EE504F`
                setErrorObject(errorObject.map(item => {
                    let key = Object.keys(item)[0];
                    if (key == a) {
                        item[key] = true;
                        return item;
                    }
                    return item

                }));
            } else {
                test.push("i")
            }
        }
        if (test.length === list.length) {
            axios.put(`${surveyUrl}/smyttenresearch/survey/${surveyId}`, {
                "audience_targeting": audienceTargeting,
            })
                .then(res => {
                    // console.log(res) 
                    changeStep(3)
                })
        }
    }

    useEffect(() => {
        let a = []
        data.map((d, index) => {
            let b = {}
            b[index] = false
            a.push(b)
            document.getElementById(`main` + ' ' + index).style.border = `none`
        })
        setErrorObject(a)
    }, [data])


    // document.addEventListener("keydown", (e) => {
    //     // setCheckWord(e.key.toLowerCase() >= 'a', e.key.toLowerCase() <= 'z');
    //     if (e.key.toLowerCase() >= 'a' && e.key.toLowerCase() <= 'z' && e.key.length == 1) {
    //         setCheckWord((checkWord) => checkWord + e.key)
    //     }
    //     // console.log(e.key.length)

    // })


    // console.log(checkWord, 'checkword')
    return (
        <>
            <div className='newAudienceTargetingSection'>
                <div className='newAudienceTargetingSectionHeader'>
                    Choose your Audience
                </div>
                {
                    data.length !== 0 ?
                        <table>
                            <thead>
                                <div className='thHead'>
                                    <th className='audienceSerialNumber'>
                                        S.No
                                    </th>
                                    <th className='audienceType'>
                                        Parameter
                                    </th>
                                    <th className='audienceFilter'>
                                        Filter
                                    </th>
                                    <th className='audienceValues'>
                                        SubFilter
                                    </th>
                                    <th colSpan='2'>

                                    </th>
                                </div>
                            </thead>
                            <tbody>
                                {
                                    data.map((item, index1) => {
                                        const index = item.id
                                        return <>
                                            <div className='trHead' id={`main ${index1}`} style={{ background: `${((index1 + 1) % 2) === 0 ? `#f5f6fa` : `white`}` }}>
                                                <td className='audienceSerialNumber'>
                                                    <div className='typeSelectToggle'>
                                                        {index1 + 1}
                                                    </div>
                                                </td>
                                                <td className='audienceType'>
                                                    <div className='typeSelectToggle'>
                                                        <div className='value' id={index1 + 1}>
                                                            {item.type ? item.type : ''}
                                                        </div>
                                                        <div>
                                                            <Dropdown onSelect={(e) => typeChangeHandler(e, index)}>
                                                                <Dropdown.Toggle variant="success" id="dropdown-basic" >
                                                                    <FontAwesomeIcon icon={faSortDown} />
                                                                </Dropdown.Toggle>
                                                                <Dropdown.Menu className='audienceTargetingMenu'>
                                                                    {
                                                                        apiData.type ? apiData.type.map((d, index) => {
                                                                            return <Dropdown.Item eventKey={d.display} key={index} >{d.display}</Dropdown.Item>
                                                                        }) : ""
                                                                    }
                                                                </Dropdown.Menu>
                                                            </Dropdown>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td className='audienceFilter'>
                                                    <div className='typeSelectToggle'>
                                                        <div className='value' id={index1 + 1}>
                                                            {item.filter ? item.filter : ''}
                                                        </div>
                                                        <div>
                                                            <Dropdown onSelect={(e) => typeOptionsChangeHandler(e, index)}>
                                                                <Dropdown.Toggle variant="success" id="dropdown-basic" >
                                                                    <FontAwesomeIcon icon={faSortDown} />
                                                                </Dropdown.Toggle>
                                                                <Dropdown.Menu className='audienceTargetingMenu secondMenu'>
                                                                    {
                                                                        apiData.filter[typeObject[item.type]] ? apiData.filter[typeObject[item.type]].map((d, index) => {
                                                                            return <Dropdown.Item eventKey={d.display} key={index}>{d.display}</Dropdown.Item>
                                                                        }) : ""
                                                                    }
                                                                </Dropdown.Menu>
                                                            </Dropdown>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td className='audienceValues'>
                                                    <div className='typeSelectToggle'>
                                                        <div className='value' id={index1 + 1}>
                                                            {item.values ? item.values.length <= 20 ? item.values : `${item.values.slice(0, 20)}...` : ''}
                                                        </div>
                                                        <div>

                                                            <Dropdown onSelect={(e) => typeValuesChangeHandler(e, index)}>
                                                                <Dropdown.Toggle variant="success" id="dropdown-basic" >
                                                                    <FontAwesomeIcon icon={faSortDown} />
                                                                </Dropdown.Toggle>
                                                                {/* <input type="text" id="#ab" onKeyPress={handleAnswerChange}> */}

                                                                <Dropdown.Menu className='audienceTargetingMenu thirdMenu' >
                                                                    {
                                                                        apiData.values[filterObject[item.filter]] ? apiData.values[filterObject[item.filter]].map((d, index) => {
                                                                            return <Dropdown.Item eventKey={d.display} key={index}>{d.display}</Dropdown.Item>
                                                                        }) : ""
                                                                    }
                                                                </Dropdown.Menu>
                                                                {/* </input> */}
                                                            </Dropdown>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td className='audienceRemoveButton'>
                                                    {/* <div className='duplicateSelectToggle'>
                                                <img src={duplicateImage} alt='duplicateImage' />
                                                <p className='duplicateTypeSelect'>Duplicate</p>
                                            </div>
                                            <img className='centerLine' src={centerLine} alt="centerLine" /> */}
                                                    <div className='removeSelectToggle' >
                                                        <FontAwesomeIcon icon={faCircleXmark} onClick={() => setData(data.filter(item => item.id !== index))} />
                                                        <p className='deleteTypeSelect' onClick={() => setData(data.filter(item => item.id !== index))}>
                                                            Delete
                                                        </p>
                                                    </div>
                                                </td>
                                            </div>
                                            {
                                                errorObject[index1] && errorObject[index1][index1] === true ?
                                                    <div className='errorMessage'>
                                                        <img src={errroImage} alt='error' /> Please Select the filter and sub-filter for this row
                                                    </div> : ""
                                            }
                                        </>
                                    })
                                }
                            </tbody>
                        </table>
                        : ""
                }
                {
                    userType == 'ADMIN' ?
                        <>
                            <div className='addNewAuidenceParameter'>
                                <button className='addParameterButton' onClick={() => setData([...data, {
                                    id: data.length > 0 ? data[data.length - 1].id + 1 : data.length,
                                    type: '',
                                    filter: '',
                                    values: '',
                                }])}>
                                    + Add New Parameter
                                </button>
                            </div>
                            <div className='newAudienceTargetingSecondSectionHeader'>
                                Import Audience Manually
                            </div>
                            <div className='newAudienceTargetingSubHeadline'>
                                Sub headline text will come here
                            </div>
                            <div className='newAudienceTargetingAddNumberHeader'>
                               <div> Enter Phone Numbers separated by commas</div>
                                <div>
                                    {checkType}
                                </div>
                            </div>
                           
                            <textarea type='tel' onChange={phoneNumberChangeHandler} />
                            <div className='importNumbers'>
                                <button className='importNumbersButton' onClick={phoneNumberSubmitClickHandler}>
                                    IMPORT
                                </button>
                            </div>
                        </>
                        : ""
                }
                <div className='audienceTargetingFooter'>
                    <button className='cancelButton'>
                        CANCEL
                    </button>
                    {checkType!=="Weak" && <button className='continueButton' onClick={saveClickHandler} >
                        CONTINUE <FontAwesomeIcon icon={faArrowRight} />
                    </button>}
                </div>
                <div id="snackbar">Added mobile number successfully...</div>
            </div>

        </>
    )
}