import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import { max } from "d3";
// import DataLoader from "./images/dataload_gif.gif"

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const Graph1 = (props) => {
  let bar_values = props.val;

  
  let barWidthList = [9,11,13,16.5,18,28,60,6]
  let barWidth
  
  if (bar_values.length === 7){
    barWidth = barWidthList[0]
  }else if (bar_values.length === 6){
    barWidth = barWidthList[1]
  }else if (bar_values.length === 5){
    barWidth = barWidthList[2]
  }else if (bar_values.length === 4){
    barWidth = barWidthList[3]
  }else if (bar_values.length === 3){
    barWidth = barWidthList[4]
  }else if (bar_values.length === 2){
    barWidth = barWidthList[5]
  }else if (bar_values.length === 1){
    barWidth = barWidthList[6]
  }else if (bar_values.length > 6){
    barWidth = barWidthList[7]
}
  let string = props.colorData;

  let format = /[-]/;
  let rs1 = format.test(string);
  let valueColor
  if (rs1 === true) {
    valueColor = "rgb(255, 115, 97)";
  } else if (string === '0.0') {
    valueColor = 'rgb(255, 207, 97)'
  } else {
    valueColor = "rgb(0, 188, 138)";
  }

  const data = {
    labels:bar_values,
    datasets: [
      {
        data: bar_values.map((text) => text),
        backgroundColor: `${valueColor}`,
        barThickness: `${barWidth}`
      },

    ],
  };

  return (
    <div className="productLevelMiniGraphs" >
      <Bar
        data={data}
        height={100}
        options={{
          // barPercentage: 1,
          plugins: {
            title: {
              display: false,
            },
            legend: {
              display: false,
            },
            datalabels: {
              display: false,
            },
          },
          responsive: true,
          scales: {
            y: {
              beginAtZero: true,
              max: max(bar_values),
              ticks:{
                display:false
              },
              grid:{
                display:false,
                drawTicks:false,
                drawOnChartArea:false,
                drawBorder:false
              }
            },
            x:{
              ticks:{
                display:false
              },
              grid:{
                display:false,
                drawTicks:false,
                drawOnChartArea:false,
                drawBorder:false
              }
            }
          }
        }}
      />
    </div>
  )
};

export default Graph1;
