import React, { useState } from 'react';
import './style.css';
import { Dropdown } from 'react-bootstrap';
import rdSurveyOptions from '../../../../images/rd-surveyOptions.svg';
import configData from '../../../../../config/config.json'
import axios from 'axios';
import { Input } from 'antd';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import DeleteModal from '../../../ResearchDashboard/DeleteModal';
import { checkSurveyEnv } from '../../../../../utils/checkEnv';

function SectionBox(props) {
    const base_url = configData.base_url;
    const section = props.sectionObject;
    const index = props.index;
    const sectionIndex = props.sectionIndex;
    const changeSectionIndex = props.changeSectionIndex;
    const colorsArray = ['#4F54CC', '#985BD4', '#C1666B', '#4357AD', '#48A9A6'];
    const getSurveyDetails = props.getSurveyDetails;
    let surveyUrl=checkSurveyEnv()

    const deleteSection = () => {
        axios.delete(surveyUrl + `/smyttenresearch/surveysection/${section.id}`)
            .then(() => {
                getSurveyDetails();
            })
            .catch(() => getSurveyDetails())
    }
    const updateSection = (e) => {
        e.preventDefault();
        const updatedSectionName = e.target.getElementsByTagName('input')[0].value;
        axios.put(surveyUrl + `/smyttenresearch/surveysection/${section.id}`, {
            title: updatedSectionName
        })
            .then(() => {
                getSurveyDetails();
            })
            .catch(() => {
                getSurveyDetails();
            })
            .finally(() => setEditMode(false))
    }
    const [editModeOn, setEditMode] = useState(false);


    return (
        <div className='sc-section' key={`section${index} `} id={(sectionIndex == index) ? 'sc-active' : null} onClick={(e) => { changeSectionIndex(index) }}>
            <div style={{ display: "flex", alignItems: "center" }}>
                <div className='sc-sectionNumber' style={{ backgroundColor: colorsArray[index % (colorsArray.length)] }}>S{index + 1}</div>

                {
                    editModeOn
                        ?
                        <form className='rn-sectionCreator' onSubmit={updateSection}>
                            <Input autoFocus defaultValue={section.title} bordered={false} suffix={<><div style={{ cursor: "pointer" }} onClick={() => document.getElementById('updateSectionButton').click()}><CheckOutlined /></div><div style={{ cursor: "pointer" }} onClick={() => setEditMode(false)}><CloseOutlined /></div></>} className='newSectionInput' type={'text'} placeholder='Type here..' required />
                            <input id='updateSectionButton' type='submit' hidden />
                        </form>
                        :
                        section.title
                }
            </div>
            {
                !editModeOn
                    ?
                    <Dropdown className='optionDropdown'>
                        <Dropdown.Toggle>
                            <img src={rdSurveyOptions} />
                        </Dropdown.Toggle>
                        <Dropdown.Menu className='optionDropdown-menu'>
                            <Dropdown.Item className='optionDropdown-menuItem' onClick={() => setEditMode(true)}>
                                Edit
                            </Dropdown.Item>
                            {/* <Dropdown.Item className='optionDropdown-menuItem' onClick={deleteSection}>
                                Delete
                            </Dropdown.Item> */}
                            <DeleteModal sectionId={section.id} whatToDelete={'section'} getSurveyDetails={getSurveyDetails} />
                        </Dropdown.Menu>
                    </Dropdown>
                    :
                    null
            }
        </div>
    )
}

export default SectionBox