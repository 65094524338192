import React, { useEffect, useState } from "react";
import { DateRangePicker, DateRange } from "@matharumanpreet00/react-daterange-picker";
import moment from "moment";
import "./DateRange.css"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { faClose } from "@fortawesome/free-solid-svg-icons";
import { useSearchParams, useNavigate } from "react-router-dom";


function DateRangeExport(props) {
    const [searchParams, setSearchParams] = useSearchParams()
    const navigate = useNavigate()
    const [open, setOpen] = useState(true);
    const [startDate, setStartDate] = useState()
    const [endDate, setEndDate] = useState()
    const initialDateRange = {
        "startDate": localStorage.getItem("start_date"),
        "endDate": moment().format("YYYY-MM-DD")
    }

    // const changeOpen = () => {
    //     setOpen(!open);
    // }
    

    const [dateRange, setDateRange] = useState({ ...initialDateRange });
    const [selectedRange, setSelectedRange] = useState({});

    useEffect(() => {
        // console.log(selectedRange);
    }, [selectedRange])

    const maxDate = moment().format("YYYY-MM-DD");
    // console.log(maxDate)
    const todaysDate = maxDate;

    const preDefinedRanges = [
        {
            label: "Today",
            startDate: todaysDate,
            endDate: todaysDate
        },
        {
            label: "Last 7 Days",
            startDate: moment(todaysDate).subtract(6, 'days').format('YYYY-MM-DD'),
            endDate: todaysDate
        },
        {
            label: "Last 15 Days",
            startDate: moment(todaysDate).subtract(14, 'days').format('YYYY-MM-DD'),
            endDate: todaysDate
        },
        {
            label: "Last 30 Days",
            startDate: moment(todaysDate).subtract(29, 'days').format('YYYY-MM-DD'),
            endDate: todaysDate
        },
        {
            label: "Last 3 Months",
            startDate: moment(todaysDate).subtract(3, 'months').format('YYYY-MM-DD'),
            endDate: todaysDate
        },
        {
            label: "Last 6 Months",
            startDate: moment(todaysDate).subtract(6, 'months').format('YYYY-MM-DD'),
            endDate: todaysDate
        },
        {
            label: "Last Year",
            startDate: moment(todaysDate).subtract(1, 'years').format('YYYY-MM-DD'),
            endDate: todaysDate
        }
    ]
    const changeSelectedRange = (e) => {
        setStartDate(moment(new Date(e['startDate'])).format("YYYY-MM-DD"))
        setEndDate(moment(new Date(e['endDate'])).format("YYYY-MM-DD"))
        setSelectedRange({
            'startDate': startDate,
            'endDate': endDate
        });
        // const differenceBetweenDateRanges = moment(endDate).diff(moment(startDate), 'days');
        // // console.log(differenceBetweenDateRanges + ' days');
        // localStorage.setItem("start_date", startDate)
        // localStorage.setItem("end_date", endDate)
        // setOpen(false)
        // document.getElementById("select").style.display = "none"
        // window.location.reload()
    }

    useEffect(() => {
        console.log(searchParams.get('stateName'))
    }, [searchParams])

    const submitDateClickHandler = () => {
        if (startDate !== undefined && endDate !== undefined) {
            localStorage.setItem("start_date", startDate)
            localStorage.setItem("end_date", endDate)
            if (!searchParams.get('stateName')) {
                navigate({
                    pathname: window.location.pathname,
                    search: `?brand=${searchParams.get('brand')}&categoryIds=${searchParams.get('categoryIds')}&productIds=${searchParams.get('productIds')}&date=${startDate}to${endDate}`
                })
            } else if (searchParams.get('stateName')) {
                navigate({
                    pathname: window.location.pathname,
                    search: `?brand=${searchParams.get('brand')}&stateName=${searchParams.get('stateName')}&categoryIds=${searchParams.get('categoryIds')}&productIds=${searchParams.get('productIds')}&date=${startDate}to${endDate}`
                })
            }
        }
        setOpen(false)
        document.getElementById("select").style.display = "none"
        window.location.reload()
    }
    //console.log(startDate, 'to', endDate)

    const cancelStateClickHandler = () => {
        setOpen(false)
        // document.getElementById("select").style.display = "none"
        window.location.reload()
    }

    useEffect(() => {
        if (document.querySelectorAll('[role="presentation"]')) {
            document.querySelectorAll('[role="presentation"]')[2].style.display = "none"
            document.querySelectorAll('[role="presentation"]')[3].style.display = "none"
            document.querySelectorAll('[role="presentation"]')[6].style.display = "none"
            document.querySelectorAll('[role="presentation"]')[7].style.display = "none"
        }
    }, [window.reload])

    return (
        <div className='dateRange'>
            <DateRangePicker open={open} onChange={(e) => changeSelectedRange(e)}
                definedRanges={preDefinedRanges}
                initialDateRange={initialDateRange}
                maxDate={maxDate}
            />
            {
                open ?
                    <div className="buttonSection" id="select">
                        <FontAwesomeIcon icon={faCheck} className='checkIcon' onClick={submitDateClickHandler} />
                        <FontAwesomeIcon icon={faClose} className='cancelIcon' onClick={cancelStateClickHandler} />
                    </div> : ""
            }
        </div>
    )
}

export default DateRangeExport