import "./RatingsAllProductsFeedback.css";
import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowUpShortWide,
  faArrowDownShortWide,
} from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import starImage from "../../images/ratingstart.svg";
import emptyStarImage from "../../images/emptyStart.svg";
import { useSearchParams, useNavigate } from "react-router-dom";
import DataLoader from "../../images/noDataFull.svg";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownToggle from "react-bootstrap/esm/DropdownToggle";
import DropdownMenu from "react-bootstrap/esm/DropdownMenu";
import { Skeleton } from "antd";
import rdSortUp from "../../images/Group_right.svg";
import rdSortDown from "../../images/Group_left.svg";
import checkEnv from "../../../utils/checkEnv";

function nFormatter(num) {
  if (num >= 1000000000) {
    return (num / 1000000000).toFixed(1).replace(/\.0$/, "") + "G";
  }
  if (num >= 1000000) {
    return (num / 1000000).toFixed(1).replace(/\.0$/, "") + "M";
  }
  if (num >= 1000) {
    return (num / 1000).toFixed(1).replace(/\.0$/, "") + "K";
  }
  return num;
}

export default function RatingsAllProductsFeedback(props) {
  const [noDataLoad, setNoDataLoad] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  let token = localStorage.getItem("token");
  let startDate = localStorage.getItem("start_date");
  let endDate = localStorage.getItem("end_date");
  let url = checkEnv();
  const [data, setData] = useState([]);
  const [sortState, setSortState] = useState({
    product_name: false,
    users_tried: false,
    feedbacks_recieved: false,
    nps_rating: false,
    star_rating: false,
  });

  const getAllProdcutsFeedbacks = async () => {
    setData([]);
    const response = await axios
      .get(
        `${url}/brandsdashboardproxy/v1/brand/${searchParams.get(
          "brand"
        )}/allproductfeedbacklist/?startDate=${startDate}&endDate=${endDate}&categoryIds=${searchParams.get(
          "categoryIds"
        )}&productIds=${searchParams.get("productIds")}`,
        {
          headers: {
            authorization: `${token}`,
          },
        }
      )
      .catch((err) => {
        console.log(err);
      });
    setData(response.data.map((d) => d));
    response.data.length === 0 ? setNoDataLoad(true) : setNoDataLoad(false);
  };

  useEffect(() => {
    getAllProdcutsFeedbacks();
  }, [searchParams]);
  const detailFeedbackClickHandler = (e) => {
    const val1 =
      e.target.parentElement.firstElementChild.nextSibling.textContent.replaceAll(
        "&",
        "-"
      );
    navigate({
      pathname: `/brandsdashboard/ratings/:productDetails`,
      search: `?brand=${searchParams.get("brand")}&productId=${
        e.target.id
      }&productName=${val1}&categoryIds=${searchParams.get(
        "categoryIds"
      )}&productIds=${searchParams.get("productIds")}&date=${searchParams.get(
        "date"
      )}`,
    });
  };

  const ascendingProductHandler = () => {
    data.sort((a, b) => {
      if (a.name > b.name) {
        return 1;
      } else if (b.name > a.name) {
        return -1;
      } else {
        return 0;
      }
    });
  };
  const descendingProductHandler = () => {
    data.sort((a, b) => {
      if (a.name > b.name) {
        return -1;
      } else if (b.name > a.name) {
        return 1;
      } else {
        return 0;
      }
    });
  };
  const ascendingUsersTriedHandler = () => {
    data.sort((a, b) => {
      return a.users_tried - b.users_tried;
    });
  };

  const descendingUsersTriedHandler = () => {
    data.sort((a, b) => {
      return b.users_tried - a.users_tried;
    });
  };
  const ascendingFeedbacksRecievedHandler = () => {
    data.sort((a, b) => {
      return a.feedback_received - b.feedback_received;
    });
  };
  const descendingFeedbacksRecievedHandler = () => {
    data.sort((a, b) => {
      return b.feedback_received - a.feedback_received;
    });
  };
  const ascendingNpsRatingHandler = () => {
    data.sort((a, b) => {
      return a.nps_rating - b.nps_rating;
    });
  };
  const descendingNpsRatingHandler = () => {
    data.sort((a, b) => {
      return b.nps_rating - a.nps_rating;
    });
  };
  const ascendingStarRatingHandler = () => {
    data.sort((a, b) => {
      return a.star_rating - b.star_rating;
    });
  };
  const descendingStarRatingHandler = () => {
    data.sort((a, b) => {
      return b.star_rating - a.star_rating;
    });
  };

  const sortSurveyList = (param, order) => {
    let sortStateCopy = { ...sortState };
    sortStateCopy[param] = !sortStateCopy[param];
    setSortState({ ...sortStateCopy });
    if (param == "product_name") {
      if (order == "ascending") {
        ascendingProductHandler();
      } else {
        descendingProductHandler();
      }
    }
    if (param == "users_tried") {
      if (order == "ascending") {
        ascendingUsersTriedHandler();
      } else {
        descendingUsersTriedHandler();
      }
    }
    if (param == "star_rating") {
      if (order == "ascending") {
        ascendingStarRatingHandler();
      } else {
        descendingStarRatingHandler();
      }
    }
    if (param == "feedbacks_recieved") {
      if (order == "ascending") {
        ascendingFeedbacksRecievedHandler();
      } else {
        descendingFeedbacksRecievedHandler();
      }
    }
    if (param == "nps_rating") {
      if (order == "ascending") {
        ascendingNpsRatingHandler();
      } else {
        descendingNpsRatingHandler();
      }
    }
  };

  return (
    <div>
      <div className="ratingAllFeedbackSection">
        <div className="ratingAllProductsfeedbackHeader">
          <div className="headerLeft">Feedback Summary</div>
          <div className="headerRIght">
            {/* <Dropdown>
                            <DropdownToggle className="ratingSort">
                                <FontAwesomeIcon icon={faArrowUpShortWide} />Sort By
                            </DropdownToggle>
                            <DropdownMenu className="sortProductLevelSection">
                                <div className="sortLabel"><FontAwesomeIcon onClick={ascendingProductHandler} className="font" icon={faArrowDownShortWide} /><FontAwesomeIcon onClick={descendingProductHandler} className="font" icon={faArrowUpShortWide} /> Product Name</div>
                                <div className="sortLabel"><FontAwesomeIcon onClick={ascendingUsersTriedHandler} className="font" icon={faArrowDownShortWide} /><FontAwesomeIcon onClick={descendingUsersTriedHandler} className="font" icon={faArrowUpShortWide} />Total Users Tried</div>
                                <div className="sortLabel"><FontAwesomeIcon onClick={ascendingFeedbacksRecievedHandler} className="font" icon={faArrowDownShortWide} /><FontAwesomeIcon onClick={descendingFeedbacksRecievedHandler} className="font" icon={faArrowUpShortWide} />    Feedbacks received</div>
                                <div className="sortLabel"><FontAwesomeIcon onClick={ascendingNpsRatingHandler} className="font" icon={faArrowDownShortWide} /><FontAwesomeIcon onClick={descendingNpsRatingHandler} className="font" icon={faArrowUpShortWide} />   NPS rating</div>
                                <div className="sortLabel"><FontAwesomeIcon onClick={ascendingStarRatingHandler} className="font" icon={faArrowDownShortWide} /><FontAwesomeIcon onClick={descendingStarRatingHandler} className="font" icon={faArrowUpShortWide} />       Star Rating</div>
                            </DropdownMenu>
                        </Dropdown> */}
            {/* <div className="ratingExport">
                            <img src={exportImage} alt="exportFiles" />
                            Export
                        </div> */}
          </div>
        </div>
        {data.length !== 0 ? (
          <table>
            <thead>
              <tr>
                <td className="ratingNumber">S.no</td>
                <td
                  className="ratingsProductName"
                  onClick={() =>
                    document.getElementById("sort-product_name").click()
                  }
                >
                  Product Name
                  {sortState.product_name ? (
                    <img
                      id="sort-product_name"
                      src={rdSortUp}
                      onClick={() =>
                        sortSurveyList("product_name", "ascending")
                      }
                    />
                  ) : (
                    <img
                      id="sort-product_name"
                      src={rdSortDown}
                      onClick={() =>
                        sortSurveyList("product_name", "descending")
                      }
                    />
                  )}
                </td>
                <td
                  onClick={() =>
                    document.getElementById("sort-users_tried").click()
                  }
                >
                  Total Users Tried
                  {sortState.users_tried ? (
                    <img
                      id="sort-users_tried"
                      src={rdSortUp}
                      onClick={() => sortSurveyList("users_tried", "ascending")}
                    />
                  ) : (
                    <img
                      id="sort-users_tried"
                      src={rdSortDown}
                      onClick={() =>
                        sortSurveyList("users_tried", "descending")
                      }
                    />
                  )}
                </td>
                {/* <td onClick={() => document.getElementById('sort-feedbacks_recieved').click()}>
                                    Feedbacks received
                                    {
                                        sortState.feedbacks_recieved
                                            ?
                                            <img id='sort-feedbacks_recieved' src={rdSortUp} onClick={() => sortSurveyList('feedbacks_recieved', 'ascending')} />
                                            :
                                            <img id='sort-feedbacks_recieved' src={rdSortDown} onClick={() => sortSurveyList('feedbacks_recieved', 'descending')} />
                                    }
                                </td> */}
                <td
                  onClick={() =>
                    document.getElementById("sort-nps_rating").click()
                  }
                >
                  NPS Rating
                  {sortState.nps_rating ? (
                    <img
                      id="sort-nps_rating"
                      src={rdSortUp}
                      onClick={() => sortSurveyList("nps_rating", "ascending")}
                    />
                  ) : (
                    <img
                      id="sort-nps_rating"
                      src={rdSortDown}
                      onClick={() => sortSurveyList("nps_rating", "descending")}
                    />
                  )}
                </td>
                <td
                  onClick={() =>
                    document.getElementById("sort-star_rating").click()
                  }
                >
                  Feedback Rating
                  {sortState.star_rating ? (
                    <img
                      id="sort-star_rating"
                      src={rdSortUp}
                      onClick={() => sortSurveyList("star_rating", "ascending")}
                    />
                  ) : (
                    <img
                      id="sort-star_rating"
                      src={rdSortDown}
                      onClick={() =>
                        sortSurveyList("star_rating", "descending")
                      }
                    />
                  )}
                </td>
                <td>More Details</td>
              </tr>
            </thead>
            <tbody>
              {data.map((d, index) => {
                let ratingStars = [1, 2, 3, 4, 5];
                return (
                  <>
                    <tr>
                      <td className="ratingNumber">{index + 1}</td>
                      <td className="ratingsProductName">{d.name}</td>
                      <td>{nFormatter(d.users_tried)}</td>
                      {/* <td>
                                                {nFormatter(d.feedback_received)}
                                            </td> */}
                      <td>{d.nps_rating ? Math.round(d.nps_rating) : "--"}</td>
                      <td>
                        {ratingStars.map((data, index) => {
                          if (
                            data <= d.star_rating ? d.star_rating.toFixed() : ""
                          ) {
                            return <img src={starImage} key={index} />;
                          } else {
                            return <img src={emptyStarImage} key={index} />;
                          }
                        })}
                      </td>
                      <td
                        onClick={detailFeedbackClickHandler}
                        id={d.product_sku}
                        className="productDetailFeedback"
                      >
                        View Details
                      </td>
                    </tr>
                  </>
                );
              })}
            </tbody>
          </table>
        ) : (
          <div className="brandsVsCategorySkeleton">
            {" "}
            {noDataLoad !== true ? (
              <Skeleton
                active
                title
                paragraph={{
                  rows: 8,
                }}
              />
            ) : (
              <img
                className="singleNoDataImage"
                src={DataLoader}
                alt="image data empty"
              />
            )}
          </div>
        )}
      </div>
    </div>
  );
}
