import React from 'react'

export default function TrendingCard({index,color,name}) {
    const class_name=()=>{
        if(color==='green'){
            return 'trending-card-container-green'
        }
        else if(color==='yellow'){
            return 'trending-card-container-yellow'
        }
        else{
            return 'trending-card-container-orange'
        }
    }
  return (
    <div className={class_name()}>
        <span className='trending-index'>#{index}</span><span className='trending-category-name'>{name}</span>
    </div>
  )
}
