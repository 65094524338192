import "./UserShoppingChart.css"
import { useEffect, useState } from "react";
import { Chart as ChartJs, Tooltip, Title, ArcElement, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";
import axios from "axios";
import ChartDataLabels from "chartjs-plugin-datalabels";
import DataLoader from "../../images/dataload_gif.gif";
import stateBackgroundBlurImage from "../../images/planLock.png"
import {sum} from "d3"
import { useSearchParams } from "react-router-dom";
import {Skeleton} from "antd"
import checkEnv from "../../../utils/checkEnv";
ChartJs.register(ChartDataLabels, Tooltip, Title, ArcElement, Legend);

export const UserShoppingChart = (props) => {
  const [searchParams,setSearchParams] = useSearchParams()
  const [freePlanState, setFreePlanState] = useState(true)
  let brandId = localStorage.getItem('brand_id')
  let startDate = localStorage.getItem('start_date')
  let endDate = localStorage.getItem('end_date')
  let url=checkEnv()
  let token = localStorage.getItem('token')

  const [dataDetails, setDataDetails] = useState([])
  const [hoverData, setHoverData] = useState([])
  const [hoverPercentage,setHoverPercentage] = useState([])
  const productDetailsColorLabes = [
    "#96bf00",
    "#0697cc",
    "#e64437",
    "#f7a110",
    "#375e6e",
    "#0f1628",
    ,]
  const [data, setData] = useState({
    datasets: [
      {
        data: [16, 16, 16, 16, 16, 16],
        backgroundColor: [
          "#96bf00",
          "#0697cc",
          "#e64437",
          "#f7a110",
          "#375e6e",
          "#0f1628",
        ],
        cutout: "40%",
        borderWidth: "",
      },
      {
        data: [1],
        backgroundColor: ["gray"],
        borderWidth: "",
        datalabels: {
          display: false,
        },
        label: {
          display: false,
        }
      }
    ],
  });

  let categoryId = localStorage.getItem('allcategoryId') ? (localStorage.getItem('allcategoryId').split(',')) : []
  let productId = localStorage.getItem('allProductId') ? localStorage.getItem('allProductId').split(',') : []
  const getHighUsersShopppingFor = async () => {
    setDataDetails([])
    const response = await axios.get(`${url}/brandsdashboardproxy/v1/brand/${searchParams.get('brand')}/userstriedalsoshoppingfor/?startDate=${startDate}&endDate=${endDate}&categoryIds=${searchParams.get('categoryIds')}&productIds=${searchParams.get('productIds')}`, {
      headers: {
        'authorization': `${token}`
      }
    })
      .then((res) => {
        setDataDetails(res.data.map(d => d))
        setHoverData(res.data.map(d => d.subcategory))
        setHoverPercentage(res.data.map(d=>d.percentage))
      })
      .catch(err => console.log(err))
  };
  // useEffect(() => {
  //   getHighUsersShopppingFor()
  // }, [searchParams])

  useEffect(() => {
    if (localStorage.getItem('plan') !== null && localStorage.getItem('plan') === 'Free' && document.getElementsByClassName("userTriedForShoppingSection") !== undefined  && document.getElementsByClassName("doughnutPlanState")) {
      document.getElementsByClassName("userTriedForShoppingSection")[0].classList.add('plan')
      document.getElementsByClassName("doughnutPlanState")[0].style.display = "flex"
      document.getElementsByClassName("doughnutPlanState")[0].style.position = "absolute"
    }
  }, [])

  // console.log()
  return (
    <>
      <div className="userTriedForShoppingSection">
        <div className="userTriedForShoppingHeading">
          <b>Users Who Tried Your Product Are Shopping For</b>
        </div>
        {
          props.nextState === true && dataDetails.length !== 0 ?
            <div className="doughnutSection">
              <div className="doughnutOutLabelsLeft">
                {
                  dataDetails.map((d, index) => {
                    if (index >= 3 && index <= 5) {
                      return <div className="productOutLabel">
                        <div className="productPercentageShopping">
                          {d.percentage ? ((d.percentage/sum(hoverPercentage))*100).toFixed(2) : ""}%
                        </div>
                        <div className="productNameDetails">
                          <div className="productNames">
                            {
                              d.subcategory
                            }
                          </div>
                          <div className="productColorLabel" style={{ backgroundColor: `${productDetailsColorLabes[index]}` }}>

                          </div>
                        </div>
                        <div className="productMostPopular" style={{ borderTop: `1px solid ${productDetailsColorLabes[index]}` }}>
                          Most Popular
                        </div>
                        <div className="popularProducts">
                          {d.keywords.map(data => data + ', ')}
                        </div>
                      </div>
                    }
                  })
                }
              </div>
              <div
                className="doughnut"
                style={{
                  width: "200px",
                  height: "200px",
                  marginLeft: "auto",
                  marginRight: "auto",
                }}
              >
                <Doughnut
                  data={data}
                  width={150}
                  options={{
                    maintainAspectRatio: false,
                    plugins: {
                      tooltip: {
                        callbacks: {
                          label: function (context) {
                            // console.log(context)
                            // let array = ["hi", "hello", "good", "not", "well", "cool"];
                            for (let i in hoverData) {
                              let index = context.dataIndex
                              return `${hoverPercentage[index].toFixed(2)}`;
                            }
                          },
                          title:function (context){
                          let index = context[0].dataIndex
                          return hoverData[index]
                          },
                        },
                        padding: 10,
                      },
                      datalabels: {
                        display: true,
                        formatter: (value, context) => {
                          let datalabel = ["01", "02", "03", "04", "05", "06"]
                          // console.log(context.dataIndex)
                          for (let i in datalabel) {
                            let index = context.dataIndex
                            return `${datalabel[index]}`;
                          }
                        },
                        color: "white",
                      },
                    },
                  }
                  }
                />
              </div>
              <div className="doughnutOutLabelsRight">
                {
                  dataDetails.map((d, index) => {
                    if (index < 3) {
                      return <div className="productOutLabel">
                        <div className="productPercentageShopping">
                          {d.percentage ? ((d.percentage/sum(hoverPercentage))*100).toFixed(2) : ""}%
                        </div>
                        <div className="productNameDetailsRight">
                          <div className="productColorLabel" style={{ background: `${productDetailsColorLabes[index]}` }}>

                          </div>
                          <div className="productNames">
                            {
                              d.subcategory
                            }
                          </div>
                        </div>
                        <div className="productMostPopular" style={{ borderTop: ` 1px solid ${productDetailsColorLabes[index]}` }}>
                          Most Popular
                        </div>
                        <div className="popularProducts">
                          {d.keywords.map(data => data + ', ')}
                        </div>
                      </div>
                    }
                  })
                }
              </div>
            </div>
            : <div className="brandsVsCategorySkeleton"> <Skeleton
            active
            title
            paragraph={{
              rows: 8,
            }} />
            </div>
        }
      </div>
      <div className="doughnutPlanState">
        <img src={stateBackgroundBlurImage} alt="blur image" />
        <div className="notHaveAccess">
          You don’t have access to this data.
        </div>
        <div className="upgradeMemberShip">
          Upgrade your membership to unlock
        </div>
        <button className="upgradeButton">
          Upgrade
        </button>
      </div>
    </>
  );
}
