import "./RatingsAndReviewWordCloud.css";
import React, { useState, useEffect } from 'react';
import ReactWordcloud from 'react-wordcloud';
import axios from "axios";
import { useSearchParams } from "react-router-dom";
import DataLoader from "../../../images/dataload_gif.gif"
import checkEnv from "../../../../utils/checkEnv";
export default function RatingsAndReviewWordCloud(props) {
  const [searchParams, setSearchParams] = useSearchParams()
  let brandId = localStorage.getItem('brand_id')
  let token = localStorage.getItem("token")
  const [words, setWords] = useState([])
  let startDate = localStorage.getItem("start_date");
  let endDate = localStorage.getItem("end_date");
  let url=checkEnv()
  let categoryId = localStorage.getItem('allcategoryId') ? (localStorage.getItem('allcategoryId').split(',')) : []
  let productId = localStorage.getItem('allProductId') ? localStorage.getItem('allProductId').split(',') : []

  const getWordCloud = async () => {
    const response = await axios.get(`${url}/brandsdashboardproxy/v1/brand/${searchParams.get('brand')}/topkeywords/?startDate=${startDate}&endDate=${endDate}&categoryIds=${searchParams.get('categoryIds')}&productIds=${searchParams.get('productIds')}&stateName=${searchParams.get(`stateName`)}`, {
      headers: {
        'authorization': `${token}`
      }
    })
      .catch((err) => {
        console.log(err);
      });
    setWords(response.data.word_cloud.map(d => d))
  };
  useEffect(() => {
    getWordCloud();
  }, [searchParams])
  const options = {
    colors: ["rgb(1, 162, 76)", "rgb(1, 33, 101)","rgb(85, 208, 0)", "rgb(1, 33, 101)","rgb(4, 133, 195)","rgb(1, 162, 76)"],
    enableTooltip: false,
    deterministic: true,
    fontFamily: "impact",
    fontSizes: [10, 50],
    fontStyle: "normal",
    fontWeight: "normal",
    padding: 0.5,
    rotations: 0,
    rotationAngles: [180, 0],
    // scale: "sqrt",
    spiral: "archimedean",
    // transitionDuration: 10
  };
  const size = [600, 150]
  return (
    <div className="WordCloudSection">
      {
        words.length !== 0 ?
          <ReactWordcloud
            options={options} words={words}
            size={size}
          />
          : <div className="metrixBoxImageLoader">
            <img src={DataLoader} />
          </div>}
    </div>
  )
}
