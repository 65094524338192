import axios from "axios";
import React, { useEffect, useState, useRef } from "react";
import DataLoader from "../../images/noDataHalf.svg"
import Chart from "chart.js/auto";
import UsersPieChart from './UsersPieChart'
import { Skeleton } from "antd";
import StateTop10Areas from '../StateTop10Areas/StateTop10Areas';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import { useSearchParams } from "react-router-dom";
import checkEnv from "../../../utils/checkEnv";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);
const UsersTriedInSpecificState = (props) => {
  const [noDataLoad,setNoDataLoad] = useState(false)
  const [nextState, setNextState] = useState(false)
  const [searchParams, setSearchParams] = useSearchParams()
  let statName = localStorage.getItem("state_name")
  let token = localStorage.getItem("token")
  const [datas, setDatas] = useState([]);
  const [hoverMaleSkuLabel, setHoverMaleSkuLabel] = useState([])
  const [hoverFemaleSkuLabel, setHoverFemaleSkuLabel] = useState([])
  const [maleTotalData, setMaleTotalData] = useState([])
  const [femaleTotalData, setFemaleTotalData] = useState([])
  let startDate = localStorage.getItem("start_date");
  let endDate = localStorage.getItem("end_date");
  let url=checkEnv()

  const getMaleVsFemale = async () => {
    setDatas([])
    const response = await axios.get(`${url}/brandsdashboardproxy/v1/brand/${searchParams.get('brand')}/usertriedgender/?startDate=${startDate}&endDate=${endDate}&categoryIds=${searchParams.get('categoryIds')}&productIds=${searchParams.get('productIds')}&stateName=${searchParams.get(`stateName`)}`, {
      headers: {
        'authorization': `${token}`
      }
    })
      .catch((err) => {
        console.log(err);
      });
    setDatas(response.data.data.map((dat) => dat));
    setHoverMaleSkuLabel(response.data.data.map(dat => dat.male_avg_sku))
    setHoverFemaleSkuLabel(response.data.data.map(dat => dat.female_avg_sku))
    setMaleTotalData(response.data.totalMaleUsersMap)
    //   console.log(response.data.totalMaleUsersMap)
    setFemaleTotalData(response.data.totalFemaleUsersMap)
    response.data.data.length === 0 ? setNoDataLoad(true) : setNoDataLoad(false)
  };
  useEffect(() => {
    getMaleVsFemale();
  }, [searchParams]);

  const data1 = {
    labels: datas.map((text) => text.age),
    datasets: [
      {
        data: datas.map((text) => text.male_avg),
        backgroundColor: "rgb(56, 80, 180)",
      },
      {
        data: datas.map((text) => text.female_avg),
        backgroundColor: "rgb(246, 69, 154)",
      },
    ],
  };

  useEffect(() => {
    if (props.nextState === true && datas.length !== 0) {
      setNextState(true)
    } else {
      setNextState(false)
    }
  }, [props.nextState, datas])
  return (
    <>
      <div className='stateHeatMaps'>
        <div className="usersMaleVsFemaleMap">
          <div className="usersMaleVsFemaleHeading"><b>
            Users Tried Your Product In {statName}
          </b>
          </div>
          {
            props.nextState === true && datas.length !== 0 ?
              <div className="stateLevelBarGraph">
                <div className="stateLevelYAxes">
                  <p>No.of</p><p>Users</p>
                </div>
                <Bar
                  data={data1}
                  height={200}
                  options={{
                    plugins: {
                      tooltip: {
                        callbacks: {
                          beforeTitle: function (context) {
                            let gender = ['Male', 'Female']
                            let index = context[0].datasetIndex
                            // console.log(context)
                            // return `${gender[index]} Average ${context[0].raw}`
                          },
                          // title: function (context) {
                          //   let index = context[0].dataIndex
                          //   // console.log(context)
                          //   if (context[0].datasetIndex === 0) {
                          //     return `SKUs  ${hoverMaleSkuLabel[index]}`
                          //   } else if (context[0].datasetIndex === 1) {
                          //     return `SKUs  ${hoverFemaleSkuLabel[index]}`
                          //   }
                          // },
                          // afterTitle: function (context) {
                          //   let index = context[0].dataIndex
                          //   if (context[0].datasetIndex === 0) {
                          //     return `SKUs/Users  ${(hoverMaleSkuLabel[index] / context[0].raw).toFixed(2)}`
                          //   } else if (context[0].datasetIndex === 1) {
                          //     return `SKUs/Users  ${(hoverFemaleSkuLabel[index] / context[0].raw).toFixed(2)}`
                          //   }
                          // }
                        }
                      },
                      title: {
                        display: true,
                        text: "Age Group",
                        position: "bottom",
                      },
                      legend: {
                        display: false,
                        position: "bottom",
                      },
                      datalabels: {
                        display: false
                      }
                    },
                  }}
                />
              </div>
              : <div className="brandsVsCategorySkeleton">
              {
                noDataLoad !== true ? 
                <Skeleton
                  active
                  title
                  paragraph={{
                    rows: 8,
                  }} />
                   :
                <img className="singleNoDataImage" src={DataLoader} alt='image data empty' />
              }
              </div>}
        </div>
        <UsersPieChart brandId={props.brandId} startDate={props.startDate} endDate={props.endDate} allChecked={props.allChecked}
          allProductsChecked={props.allProductsChecked}
          singleCheck={props.singleCheck} singleProductCheck={props.singleProductCheck} maleTotalData={maleTotalData} femaleTotalData={femaleTotalData} nextState={props.nextState} noDataLoad = {noDataLoad} />
      </div>
      <StateTop10Areas brandId={props.brandId} allChecked={props.allChecked}
        allProductsChecked={props.allProductsChecked}
        singleCheck={props.singleCheck} singleProductCheck={props.singleProductCheck} nextState={nextState} />
    </>
  );
};

export default UsersTriedInSpecificState;
