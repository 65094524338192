import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBell } from "@fortawesome/free-regular-svg-icons";
import UserProfileArrow from "../images/userProfileArrow.svg"
import {
  faBars,
  faGear,

  faComments,
  faCircle,
  faCircleInfo,
} from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import ProductLevelDetails from "./ProductLevelBrandDetails/ProductLevelDetails"
import Dashboard from "../Dashboard";
import { useNavigate, useSearchParams } from "react-router-dom"
import ChangePassword from "../UserSignIn/ChangePassword/ChangePassword";
import coin from "../images/singleCoin.svg"
import checkEnv, { checkSurveyEnv } from "../../utils/checkEnv";
import Logo from "../images/Logo.png"
import logoCoin from "../images/Final_01_1.png"
import ConvertTemplatized1 from "../modal/ConvertTemplatized1"
let currentId;

const ProductLevelHeader = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  let brand = localStorage.getItem("brand_id")
  let userName = localStorage.getItem('userName')
  let startDate = searchParams.get('date').split('to')
  let token = localStorage.getItem("token")
  const [learnMore, setLearnMore] = useState(false);

  let surveyUrl=checkSurveyEnv()
  let url=checkEnv()
  
  const [brands, setBrands] = useState([]);
  const [userState, setUserState] = useState(false)
  const [show, setShow] = useState(false)
  const navigate = useNavigate()
  let search = searchParams.get('brand') !== 'null' ? window.location.search : '';
  const getBrands = () => {
    axios.get(`${url}/brandsdashboardproxy/v1/brand/`, {
      headers: {
        'authorization': `${token}`
      }
    })
      .then(res => {
        setBrands(res.data.map((dat) => dat));
      })
      .catch((err) => {
        console.log('brands', err);
        getnewToken(window.location.pathname, search)
      })
  };
  const getnewToken = (path, search) => {
    axios.get(`${url}/brandsdashboardproxy/v1/auth/token`, {
      headers: {
        'authorization': `${token}`
      }
    })
      .then(res => {
        localStorage.setItem('token', res.data.token)
      })
      .catch((err) => {
        localStorage.clear();
        localStorage.setItem('previousUrl', path)
        localStorage.setItem('search', search)
        localStorage.setItem('start_date', startDate[0])
        localStorage.setItem('end_date', startDate[1])
        navigate("/brandsdashboard")
      })
  }
  useEffect(() => {
    getBrands();
  }, []);
  const [open, setOpen] = useState(false);
  const clickHandler = () => {
    setOpen(!open);
  };

  const changeHandler = (e) => {
    currentId = e.target.value;
    localStorage.setItem("brand_id", currentId)
    navigate({
      pathname: `/brandsdashboard/ratings/:productDetails`,
      search: `?brand=${e.target.value}&productId=${searchParams.get('productId')}&productName=${searchParams.get('productName')}&categoryIds=${searchParams.get('categoryIds')}&productIds=${searchParams.get('productIds')}&date=${searchParams.get('date')}`
    })
  };

  const MakeItem = function (X) {
    return (
      <option key={X.brandId} value={X.brandId}>
        {X.brandName}
      </option>
    );
  };

  if (open === true && document.querySelector('.section') !== null) {

    document.querySelector('.section').style.display = 'none'
    document.querySelector('.productLevelData').style.marginLeft = '0px'
    document.querySelector('.boxes').style.marginLeft = '0px'
  } else if (open === false && document.querySelector('.section') !== null && document.querySelector('.boxes') && document.querySelector('.productLevelData') && window.innerWidth > 1200) {

    document.querySelector('.section').style.display = 'block'
    document.querySelector('.productLevelData').style.marginLeft = '200px'
    document.querySelector('.boxes').style.marginLeft = '200px'
  } else if (open === false && document.querySelector('.section') !== null && document.querySelector('.boxes') && document.querySelector('.productLevelData') && window.innerWidth <= 1200) {

    document.querySelector('.section').style.display = 'block'
    document.querySelector('.productLevelData').style.marginLeft = '50px'
    document.querySelector('.boxes').style.marginLeft = '50px'
  }
  window.addEventListener('resize', function (event) {
    if (document.querySelector('.section') !== null && document.querySelector('.boxes') && document.querySelector('.productLevelData') && window.innerWidth > 1200) {

      document.querySelector('.section').style.display = 'block'
      document.querySelector('.productLevelData').style.marginLeft = '200px'
      document.querySelector('.boxes').style.marginLeft = '200px'
    } else if (document.querySelector('.section') !== null && document.querySelector('.boxes') && document.querySelector('.productLevelData') && window.innerWidth <= 1200) {

      document.querySelector('.section').style.display = 'block'
      document.querySelector('.productLevelData').style.marginLeft = '50px'
      document.querySelector('.boxes').style.marginLeft = '50px'
    }
  });
  const userprofileClickHandler = () => {
    setUserState(!userState)
  }

  const logoutClickHandler = () => {
    localStorage.clear();
    navigate("/brandsdashboard")
  }
  const loginPopUpHideClickHandler = () => {
    if (userState === true) {
      setUserState(false)
    }
  }
  const changePasswordClickHandler = () => {
    setShow(true)
    setUserState(true)
  }

  const [creditBalance,setCreditBalance] = useState('')
  useEffect(()=>{
    axios.get(`${surveyUrl}/smyttenresearch/brandcredits/${searchParams.get('brand')}`,{

    })
    .then(res=>{
      setCreditBalance(res.data.credits)
    })
  },[searchParams])

  return (
    <div onClick={loginPopUpHideClickHandler}>
      <div className="header">
      <div className="header-left">
          {/* <FontAwesomeIcon
            className="hamberger"
            icon={faBars}
            onClick={clickHandler}
          /> */}
          <div className="header-left-left">
            <img className="logo-image" src={Logo} alt="logo"/>
          </div>
          <div className="header-left-right">
            <div>
                <img className="logo-image1" src={logoCoin} alt="coin"/>
            </div>
            <div className="remaining-balance-container">
              <span className="remaining-text">Remaining Balance:</span>
              <span className="remaining-balance">{creditBalance} Credits</span>
            </div>
          </div>
          <div className="learn-more">
            <FontAwesomeIcon icon={faCircleInfo} className="learn-more-icon" />
            <span className="learn-more-text" onClick={()=>{setLearnMore(true)}}>Learn More</span>
          </div>
        </div>
        <div className="headerOne">
        {/* <div className="creditBalance">
        Research Credit Balance : {creditBalance} credits <img src={coin} className='coinImageHeader' alt='coin image'/>
          </div> */}
          <div className="headerCenter">
            {/* <FontAwesomeIcon className="settings" icon={faGear} />
            <FontAwesomeIcon className="chat" icon={faComments} />
            <FontAwesomeIcon className="notification" icon={faBell} /> */}
            <div>
              <select
                className="selectBrand"
                value={searchParams.get('brand')}
                onChange={(e) => {
                  changeHandler(e);
                }}
              >
                {brands.map(MakeItem)}
              </select>
            </div>
          </div>
          <div>
            <div className="user" onClick={userprofileClickHandler}>
              {/* <FontAwesomeIcon className="userProfile" icon={faCircle} /> */}
              <div className="userLetterProfile">{userName.slice(0, 1)}</div>
              <img
                src={UserProfileArrow}
                alt="user"
              />
            </div>
            {
              userState ? <div className="userLogoutProfile">
                <div onClick={changePasswordClickHandler}>Change Password</div>
                <div onClick={logoutClickHandler}>Logout</div>
              </div> : ""
            }
          </div>
        </div>
      </div>
      <Dashboard />
      <div className="data"> <ProductLevelDetails brandId={brand} /></div>
      <ChangePassword show={show} setShow={setShow} />
      <ConvertTemplatized1 show={learnMore} onHide={()=>setLearnMore(false)}/>

    </div>
  );
};

export default ProductLevelHeader;
