import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import "./style.css";
import configData from "../../../../../config/config.json";
import axios from "axios";
import { FileAddFilled } from "@ant-design/icons";
import EditableQuestion from "../EditableQuestion";
import CondensedQuestion from "../CondensedQuestion";
import addQuestionIcon from "../../../../images/addQuestionIcon.svg";
import coinIcon from "../../../../images/coin.svg";
import { Button, Skeleton } from "antd";
import Loader from "react-spinners/BarLoader";
import { GridLoader } from "react-spinners";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import checkEnv, { checkSurveyEnv } from "../../../../../utils/checkEnv";
import QuestionnaireModal from "./modal/QuestionnaireModal";
import ConvertTemplatized from "./modal/ConvertTemplatized";

function SectionQuestionnaire(props) {
  const base_url = configData.base_url;
  let url = checkEnv();
  let surveyUrl = checkSurveyEnv();
  const sectionIndex = props.sectionIndex;
  const { surveyId } = useParams();
  const [surveyDetails, setSurveyDetails] = useState();
  const [editModeOn, setEditModeOn] = useState({});
  const [addPreviousQuestion, setAddPreviousQuestion] = useState({});
  const [firstLastQuestionToggler, setFirstLastQuestionToggler] = useState({});
  const [questionList, setQuestionList] = useState([]);
  const [modalShow, setModalShow] = React.useState(false);
  const [convertModalShow, setConvertModalShow] = useState(false);
  const [uniqId,setUniqId]=useState(localStorage.getItem('uniqId')?localStorage.getItem('uniqId'):null)
 

  let newQuestionEditTemplate = {
    id: Math.random(),
    question_text: "",
    question_type: "",
    next_question_id: "",
    options: [],
    new_question: true,
    media_type: null,
    media_content: null,
  };
  
  const getSurveyDetails = () => {
    axios
      .get(surveyUrl + `/smyttenresearch/survey/${surveyId}`)
      .then((res) => {
        
        setSurveyDetails(res.data);
        generateStates(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // useEffect(() => {
  //     getSurveyDetails();
  // }, [])
  
 

  useEffect(() => {
    getSurveyDetails();
  }, [sectionIndex,convertModalShow]);

  const generateStates = (data) => {
    // console.log(data)
    let localEditModeOn = {};
    let localAddPreviousQuestion = {};
    let localFirstLastQuestionToggler = {};
    let localQuestionList = [];

    // looping through all questions in every section
    data.sections.forEach((section) => {
      localFirstLastQuestionToggler[section.id] = false;
      if (section.questions.length > 0) {
        section.questions.forEach((question) => {
          localEditModeOn[question.id] = false;
          localAddPreviousQuestion[question.id] = false;
          localQuestionList.push(question);
        });
      } else localFirstLastQuestionToggler[section.id] = true;
    });
    // _____

    // set the states

   
    
    setEditModeOn({ ...localEditModeOn });
    setAddPreviousQuestion({ ...localAddPreviousQuestion });
    setFirstLastQuestionToggler({ ...localFirstLastQuestionToggler });
    setQuestionList([...localQuestionList]);
  };


   



  const changeEditMode = (questionId) => {
    // console.log(questionId)
    if (surveyDetails.survey_type === "TEMPLETIZED") {
        // setConvertModalShow(true);
        if(questionId===uniqId){
            let copy = { ...editModeOn };
            copy[questionId] = !copy[questionId];
            setEditModeOn({ ...copy });
        }else{
            setConvertModalShow(true);
        }
        
        
    }
    else{
        let copy = { ...editModeOn };
        copy[questionId] = !copy[questionId];
        setEditModeOn({ ...copy });
    }
    
  };
  const changeAddPreviousQuestion = (questionId) => {
    console.log("questionid",questionId)
    if (surveyDetails.survey_type === "TEMPLETIZED") {
      if (surveyDetails.total_questions - surveyDetails.total_template_questions <1) {
        
        let copy = { ...addPreviousQuestion };
        copy[questionId] = !copy[questionId];
        console.log(copy)
        setAddPreviousQuestion({ ...copy });
      } else {
        setModalShow(true);
      }
    } else {
      let copy = { ...addPreviousQuestion };
      copy[questionId] = !copy[questionId];
      setAddPreviousQuestion({ ...copy });
    }
  };

  const changeFirstLastQuestionToggler = (sectionId) => {
    console.log("sectionid",sectionId)
    if (surveyDetails.survey_type === "TEMPLETIZED") {
      if (
        surveyDetails.total_questions - surveyDetails.total_template_questions <1
      ) {
        let copy = { ...firstLastQuestionToggler };
        copy[sectionId] = !copy[sectionId];
        setFirstLastQuestionToggler({ ...copy });
      } else {
        setModalShow(true);
      }
    } else {
      let copy = { ...firstLastQuestionToggler };
      copy[sectionId] = !copy[sectionId];
      setFirstLastQuestionToggler({ ...copy });
    }
  };

  const generatePreviousQuestionId = (questionIndex, sectionIndex) => {
    let previousQuestionId;
    if (questionIndex == 0) previousQuestionId = null;
    else
      previousQuestionId =
        surveyDetails.sections[sectionIndex].questions[questionIndex - 1].id;
    return previousQuestionId;
  };

  // Render Conditionally Below

  if (surveyDetails)
    if (sectionIndex || sectionIndex == 0)
      return (
        <>
          <div className="sectionQuestionnaire">
            {surveyDetails
              ? surveyDetails.sections.map((section, index) => {
                  if (index == sectionIndex)
                    return (
                      <>
                        <div
                          className="section"
                          id={section.id}
                          key={section.id}
                        >
                          {section.questions.length > 0 ? (
                            <div className="section-body">
                              {surveyDetails.survey_type === "TEMPLETIZED" ? (
                                <div className="credit-estimate">
                                  Templetized Survey:{" "}
                                  {surveyDetails.credit_required}
                                  <img
                                    className="sb-coinIcon"
                                    src={coinIcon}
                                    alt="coinimage"
                                  />
                                </div>
                              ) : (
                                <div className="credit-estimate">
                                  Estimated Credits Required:{" "}
                                  {surveyDetails.credit_required}
                                  <img
                                    className="sb-coinIcon"
                                    src={coinIcon}
                                    alt="coinimage"
                                  />{" "}
                                  (Untill{" "}
                                  {surveyDetails.question_count
                                    ? surveyDetails.question_count
                                    : 20}{" "}
                                  Questions){" "}
                                </div>
                              )}
                              {surveyDetails.survey_type === "TEMPLETIZED" && (
                                <div className="convert-templetized-button">
                                  <Button
                                    className="button"
                                    onClick={() => {
                                      setConvertModalShow(true);
                                    }}
                                  >
                                    Convert to Custom Survey
                                  </Button>
                                </div>
                              )}

                              {section.questions.map(
                                (question, questionIndex) => {
                                  if (editModeOn[question.id])
                                    return (
                                      <EditableQuestion
                                        setUniqId={setUniqId}
                                        question={question}
                                        changeEditMode={changeEditMode}
                                        getSurveyDetails={getSurveyDetails}
                                        key={`editable-${question.id}`}
                                      />
                                    );
                                  else
                                    return (
                                      <>
                                        {!addPreviousQuestion[question.id] ? (
                                          <>
                                            <div
                                                
                                              className="addQuestion-div"
                                              onClick={() =>
                                                changeAddPreviousQuestion(
                                                  question.id
                                                )
                                              }
                                            >
                                              <img src={addQuestionIcon} />
                                            </div>
                                          </>
                                        ) : (
                                          <EditableQuestion
                                            question={newQuestionEditTemplate}
                                            setUniqId={setUniqId}
                                            sectionId={section.id}
                                            previousQuestionId={generatePreviousQuestionId(
                                              questionIndex,
                                              sectionIndex
                                            )}
                                            getSurveyDetails={getSurveyDetails}
                                            key={`editable-${question.id}`}
                                          />
                                        )}

                                        <CondensedQuestion
                                          questionIndex={questionIndex}
                                          question={question}
                                          questionList={questionList}
                                          changeEditMode={changeEditMode}
                                          key={`condensed-${question.id}`}
                                          getSurveyDetails={getSurveyDetails}
                                        />
                                      </>
                                    );
                                }
                              )}

                              {!firstLastQuestionToggler[section.id] ? (
                                <div
                                  className="addQuestion-div"
                                  onClick={() =>
                                    changeFirstLastQuestionToggler(section.id)
                                  }
                                >
                                  <img src={addQuestionIcon} />
                                </div>
                              ) : (
                                <EditableQuestion
                                  question={newQuestionEditTemplate}
                                  setUniqId={setUniqId}
                                  sectionId={section.id}
                                  previousQuestionId={
                                    section.questions[
                                      section.questions.length - 1
                                    ].id
                                  }
                                  getSurveyDetails={getSurveyDetails}
                                  key={Math.random()}
                                />
                              )}
                            </div>
                          ) : !firstLastQuestionToggler[section.id] ? (
                            <div
                              className="addQuestion-div"
                              onClick={() =>
                                changeFirstLastQuestionToggler(section.id)
                              }
                            >
                              <FileAddFilled />
                            </div>
                          ) : (
                            <EditableQuestion
                              question={newQuestionEditTemplate}
                              setUniqId={setUniqId}
                              sectionId={section.id}
                              previousQuestionId={null}
                              getSurveyDetails={getSurveyDetails}
                              key={Math.random()}
                            />
                          )}
                          <QuestionnaireModal
                            show={modalShow}
                            onHide={() => setModalShow(false)}
                          />
                          <ConvertTemplatized
                            show={convertModalShow}
                            onHide={() => setConvertModalShow(false)}
                          />
                        </div>
                      </>
                    );
                })
              : null}
          </div>
          <div className="questioneerFooterContinueButton">
            <button
              className="questioneerContinueButton"
              onClick={() => props.changeStep(4)}
            >
              Continue <FontAwesomeIcon icon={faArrowRight} />
            </button>
          </div>
        </>
      );
    else return <div>Select a Section</div>;
  else
    return (
      <div className="sq-skeleton">
        {/* <Skeleton
                active
                title
                paragraph={{
                    rows: 7,
                }} /> */}
        <GridLoader />
      </div>
    );
}

export default SectionQuestionnaire;
