import React from 'react'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

export default function QuestionnaireModal(props) {
  return (
    <Modal
    {...props}
    size="lg"
    aria-labelledby="contained-modal-title-vcenter"
    centered
    className='modal-container'
  >
    <Modal.Header closeButton>
      <Modal.Title id="contained-modal-title-vcenter">
          Change Limit Exceeded
      </Modal.Title>
    </Modal.Header>
    <Modal.Body style={{border:"none"}} className="modalBody">
      <li>You can add maximum of 1 question to a templetized survey.</li>
      <li>Convert this to a custom survey for additional edits</li>
    </Modal.Body>
    <Modal.Footer>
      <Button onClick={props.onHide}>Ok</Button>
    </Modal.Footer>
  </Modal>
  )
}
