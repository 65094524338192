import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState, useEffect } from "react";
import "./ImageUpload.css";
import configData from "../../../../../config/config.json";
import { faFolderPlus, faXmark } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import { Button } from "antd";
import { checkSurveyEnv } from "../../../../../utils/checkEnv";

export default function ImageUpload({ type, name, setAnswers,id }) {
  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedVideo, setSelectedVideo] = useState(null);
  const [multiselect, setMultiselect] = useState([]);
  const [multiselectVideo, setMultiselectVideo] = useState([]);
  const base_url = configData.base_url;
  const surveyUrl=checkSurveyEnv()
  
    const Id=`imageinput${id}`
  const createurl = (file) => {
    return new Promise((resolve, reject) => {
      const formData = new FormData();
      formData.append("file", file);
      axios
        .post(surveyUrl + `/smyttenresearch/uploadfile/`, formData)
        .then((res) => {
        // console.log(res.data)
          resolve(res.data.url);
          
        })
        .catch((err) => {
          reject(err);
          console.log(err);
        });
    });
  };
  

  useEffect(() => {
    if (type === "MULTIPLE_VIDEO") {
      setAnswers((prev) => {
        let copy = { ...prev };
        copy[name] = [...multiselectVideo];
        return copy;
      });
    } else if (type === "SINGLE_VIDEO") {
      setAnswers((prev) => {
        let copy = { ...prev };
        copy[name] = selectedVideo;
        return copy;
      });
    } else if (type === "MULTIPLE_IMAGE") {
      setAnswers((prev) => {
        let copy = { ...prev };
        copy[name] = [...multiselect];
        return copy;
      });
    } else {
      setAnswers((prev) => {
        let copy = { ...prev };
        copy[name] = selectedImage;
        return copy;
      });
    }
  }, [selectedImage, selectedVideo, multiselect, multiselectVideo]);

  const imageHandler = (e) => {
    e.preventDefault()
    if (type === "SINGLE_VIDEO") {
        createurl(e.target.files[0])
        .then((res) => {
          
            setSelectedVideo(res);
        })
        .catch(() => {
          console.log("error");
        });

    //   setSelectedVideo(e.target.files[0]);

    } else if (type === "MULTI_VIDEO") {
      let files = Object.values(e.target.files);

      files.forEach((file) => {
        createurl(file)
        .then(res=>{
            console.log(res)
            setMultiselectVideo([...multiselect, res]);   
        })
        .catch(err=>console.log(err))
      });

    //   setMultiselectVideo([...multiselectVideo, ...arr]);


    } 
    else if (type === "MULTI_IMAGE") {
      let files = Object.values(e.target.files);
       files.map((file) => {
        createurl(file)
        .then(res=>{
            console.log(res)
            setMultiselect([...multiselect, res]);   
        })
        .catch(err=>console.log(err))
      });
   
    } else {
      createurl(e.target.files[0])
        .then((res) => {
          
          setSelectedImage(res);
        })
        .catch(() => {
          console.log("error");
        });
      
    }
  };
  const deleteClickHandler = (i) => {
    if (type === "SINGLE_VIDEO") {
      setSelectedVideo(null);
    } else if (type === "MULTI_VIDEO") {
      setMultiselectVideo([
        ...multiselectVideo.slice(0, i),
        ...multiselectVideo.slice(i + 1),
      ]);
    } else if (type === "MULTI_IMAGE") {
      setMultiselect([...multiselect.slice(0, i), ...multiselect.slice(i + 1)]);
    } else {
      setSelectedImage(null);
    }
  };

  const renderVideos = () => {
    return multiselectVideo.map((e, i) => {
      return (
        <div key={i} className="image-delete">
          <img className="image" src={e} alt="selectedVideo" />
          <FontAwesomeIcon
            onClick={() => {
              deleteClickHandler(i);
            }}
            icon={faXmark}
          ></FontAwesomeIcon>
        </div>
      );
    });
  };

  const renderImages = () => {
    return multiselect.map((e, i) => {
      return (
        <div key={i} className="image-delete">
          <img className="image" src={e} alt="selectedImage" />
          <FontAwesomeIcon
            onClick={() => {
              deleteClickHandler(i);
            }}
            icon={faXmark}
          ></FontAwesomeIcon>
        </div>
      );
    });
  };

  if (type === "SINGLE_VIDEO") {
    return (
      <div className="uploadImage-container">
        {selectedVideo !== null && (
          <div className="image-container">
            <div className="image-delete">
              <img
                className="image"
                src={URL.createObjectURL(selectedVideo)}
                alt="selectedVideo"
              />
              <FontAwesomeIcon
                onClick={() => {
                  deleteClickHandler();
                }}
                icon={faXmark}
              ></FontAwesomeIcon>
            </div>
          </div>
        )}
        <form>
          <label htmlFor={Id}>
            {/* <FontAwesomeIcon icon={faFolderPlus}></FontAwesomeIcon> */}
            <p className="upload-media">Upload Media</p>
          </label>
          <input
            accept="video/*"
            className="imageinput"  
            onChange={(e) => {
              imageHandler(e);
            }}
            id={Id}
            type="file"
          ></input>
        </form>

        
      </div>
    );
  }
  if (type === "MULTI_VIDEO") {
    return (
      <div className="uploadImage-container">
        {multiselectVideo.length !== 0 && (
          <div className="image-container">{renderVideos()}</div>
        )}
        <form>
          <label htmlFor={Id}>
            {/* <FontAwesomeIcon icon={faFolderPlus}></FontAwesomeIcon> */}
            <p className="upload-media">Upload Media</p>
          </label>
          <input
            accept="video/*"
            multiple
            className="imageinput"
            onChange={(e) => {
              imageHandler(e);
            }}
            id={Id}
            type="file"
          ></input>
        </form>
        
      </div>
    );
  }

  if (type === "MULTI_IMAGE") {
    return (
      <div className="uploadImage-container">
        {multiselect.length !== 0 && (
          <div className="image-container">{renderImages()}</div>
        )}
        <form>
          <label htmlFor={Id}>
            {/* <FontAwesomeIcon icon={faFolderPlus}></FontAwesomeIcon> */}
            <p className="upload-media">Upload Media</p>
          </label>
          <input
            accept="image/*"
            multiple
            onChange={(e) => {
              imageHandler(e);
            }}
            className="imageinput"
            id={Id}
            type="file"
          ></input>
        </form>
        
      </div>
    );
  }
  return (
    <div className="uploadImage-container">
        {selectedImage !== null && (
        <div className="image-container">
          <div className="image-delete">
            <img className="image" src={selectedImage} alt="selectedImage" />
            <FontAwesomeIcon
              onClick={() => {
                deleteClickHandler();
              }}
              icon={faXmark}
            ></FontAwesomeIcon>
          </div>
        </div>
      )}
      <form>
        <label htmlFor={Id}>
          {/* <FontAwesomeIcon icon={faFolderPlus}></FontAwesomeIcon> */}
          {/* <Button className="upload-media">Upload Media</Button> */}
          {selectedImage!==null?<p className="upload-media">Replace Media</p>:<p className="upload-media">Upload Media</p>}
        </label>
        <input
          accept="image/*"
          onChange={(e) => {
            imageHandler(e);
          }}
          className="imageinput"
          id={Id}
          type="file"
        ></input>
      </form>
      
    </div>
  );
}
