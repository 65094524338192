import React, { useState, useEffect } from "react";
import Graph1 from "./Graph1";
import Graph2 from "./Graph2";
import Graph3 from "./Graph3";
import WeeklyValues from "./WeeklyValues";
import axios from "axios";
import StateHeatMaps from "../StateHeatMaps/StateHeatMaps";
import removeTag from "../../images/remove_tag.png"
import { useSearchParams, useNavigate } from "react-router-dom"
import DataLoader from "../../images/noDataFull.svg"
import MetrixSkeletonLoading from "../../SkeletonLoading/MetrixSkeletonLoading";
import checkEnv from "../../../utils/checkEnv";
import CancelBtn from "../../images/cancel_btn.svg"
function nFormatter(num) {
  if (num >= 1000000000) {
    return (num / 1000000000).toFixed(1).replace(/\.0$/, "") + "G";
  }
  if (num >= 1000000) {
    return (num / 1000000).toFixed(1).replace(/\.0$/, "") + "M";
  }
  if (num >= 1000) {
    return (num / 1000).toFixed(1).replace(/\.0$/, "") + "K";
  }
  return num;
}
let categoryListNameIndex = {}
let categoryListIdName = {}
let productListIndex = {}
let productListIdName = {}
const StateMetricBoxes = (props) => {
  const [noDataLoad, setNoDataLoad] = useState(false)
  const [nextState, setNextState] = useState(false)
  const navigate = useNavigate()
  const [searchParams, setSearchParams] = useSearchParams()
  let token = localStorage.getItem("token");
  const [boxData, setBoxData] = useState([]);
  const [boxData1, setBoxData1] = useState([]);
  const [boxData2, setBoxData2] = useState([]);
  const [boxData3, setBoxData3] = useState([]);
  const [boxData4,setBoxData4] = useState([])
  const [categoryList, setCategoryList] = useState([])
  const [productList, setProductList] = useState([])
  const [categoryCheckedList, setCategoryCheckedList] = useState([])
  const [productCheckedList, setProductCheckedList] = useState([])
  let startDate = localStorage.getItem("start_date");
  let endDate = localStorage.getItem("end_date");
  let url=checkEnv()
  const getSKUActivelySampled = async () => {
    setBoxData([])
    const response = await axios.get(`${url}/brandsdashboardproxy/v1/brand/${searchParams.get('brand')}/skusampled/?startDate=${startDate}&endDate=${endDate}&categoryIds=${searchParams.get('categoryIds')}&productIds=${searchParams.get('productIds')}&stateName=${searchParams.get(`stateName`)}`, {
      headers: {
        'authorization': `${token}`
      }
    })
      .catch((err) => {
        console.log(err);
      });
    setBoxData(response.data.map((dat) => dat));
  }

  const getUsersEngaged = async () => {
    setBoxData1([])
    const response = await axios.get(`${url}/brandsdashboardproxy/v1/brand/${searchParams.get('brand')}/usersengaged/?startDate=${startDate}&endDate=${endDate}&categoryIds=${searchParams.get('categoryIds')}&productIds=${searchParams.get('productIds')}&stateName=${searchParams.get(`stateName`)}`, {
      headers: {
        'authorization': `${token}`
      }
    })
      .catch((err) => {
        console.log(err);
      });
    setBoxData1(response.data.map((dat) => dat));
  }

  const getUsersTried = async () => {
    setBoxData2([])
    const response = await axios.get(`${url}/brandsdashboardproxy/v1/brand/${searchParams.get('brand')}/usertried/?startDate=${startDate}&endDate=${endDate}&categoryIds=${searchParams.get('categoryIds')}&productIds=${searchParams.get('productIds')}&stateName=${searchParams.get(`stateName`)}`, {
      headers: {
        'authorization': `${token}`
      }
    })
      .catch((err) => {
        console.log(err);
      });
    setBoxData2(response.data.map((dat) => dat));
    response.data[0].metric_value === 0 ? setNoDataLoad(true) : setNoDataLoad(false)
  }

  const getPincodeServed = async () => {
    setBoxData3([])
    const response = await axios.get(`${url}/brandsdashboardproxy/v1/brand/${searchParams.get('brand')}/pincodesserviced/?startDate=${startDate}&endDate=${endDate}&categoryIds=${searchParams.get('categoryIds')}&productIds=${searchParams.get('productIds')}&stateName=${searchParams.get(`stateName`)}`, {
      headers: {
        'authorization': `${token}`
      }
    })
      .catch((err) => {
        console.log(err);
      });
    setBoxData3(response.data.map((dat) => dat));
  };
  useEffect(() => {
    getSKUActivelySampled();
    getUsersTried();
    getPincodeServed();
    getUsersEngaged()
    getSamplesTried();
  }, [searchParams])

  const getCategories = async () => {
    const response = await axios.get(`${url}/brandsdashboardproxy/v1/brand/${searchParams.get(`brand`)}/category/`, {
      headers: {
        'authorization': `${token}`
      }
    })
      .catch((err) => {
      });
    response.data.map((d, index) => {
      categoryListNameIndex[d.categoryName] = index
      categoryListIdName[d.categoryName] = d.categoryId
    })
    setCategoryList(response.data)
  };
  const getProducts = async () => {
    const response = await axios.get(`${url}/brandsdashboardproxy/v1/brand/${searchParams.get(`brand`)}/product/?startDate=${startDate}&endDate=${endDate}&categoryIds=${searchParams.get('categoryIds')}`, {
      headers: {
        'authorization': `${token}`
      }
    })
      .catch((err) => {
      });
    setProductList(response.data);
    response.data.map((d, index) => {
      productListIndex[d.name] = index
      productListIdName[d.name] = d.sku
    })
  };
  const getSamplesTried = async () => {
    setBoxData4([])
    const response = await axios.get(`${url}/brandsdashboardproxy/v1/brand/${searchParams.get(`brand`)}/samplestried/?startDate=${startDate}&endDate=${endDate}&categoryIds=${searchParams.get(`categoryIds`)}&productIds=${searchParams.get(`productIds`)}&stateName=${searchParams.get(`stateName`)}`, {
      headers: {
        'authorization': `${token}`
      }
    })
      .catch((err) => {
        // console.log(err);
      });
    setBoxData4(response.data.map((dat) => dat));
  }

  useEffect(() => {
    getCategories()
  }, [searchParams])

  useEffect(() => {
    getProducts();
  }, [searchParams.get('categoryIds')])
  function arrayRemove(arr, value) {
    return arr.filter(function (geeks) {
      return geeks !== value;
    });

  }
  let format = /[-]/;

  const removeSelectedClickHandler = (e) => {
    let a = searchParams.get('categoryIds').split(',')
    navigate({
      pathname: `/brandsdashboard/performance/:stateDetails`,
      search: `?brand=${searchParams.get('brand')}&stateName=${searchParams.get('stateName')}&categoryIds=${arrayRemove(a, `${categoryListIdName[e.target.id]}`)}&productIds=${[]}&date=${searchParams.get('date')}`
    })
  }

  const removeSelectedProductClickHandler = (e) => {
    let a = searchParams.get('productIds').split(',')
    navigate({
      pathname: `/brandsdashboard/performance/:stateDetails`,
      search: `?brand=${searchParams.get('brand')}&stateName=${searchParams.get('stateName')}&categoryIds=${searchParams.get('categoryIds')}&productIds=${arrayRemove(a, `${productListIdName[e.target.id]}`)}&date=${searchParams.get('date')}`
    })
  }

  const checkedCatList = () => {
    let b = []
    let a = searchParams.get('categoryIds') ? searchParams.get('categoryIds').split(',') : []
    if (categoryList.length === a.length) {
      categoryList.map((d, index) => {
        if (d.categoryId == a[index]) {
          b.push(d.categoryName)
        }
      })
    } else {
      for (let i = 0; i < a.length; i++) {
        for (let j = 0; j < categoryList.length; j++) {
          if (a[i] == categoryList[j].categoryId) {
            b.push(categoryList[j].categoryName)
          }
        }
      }
    }
    setCategoryCheckedList(b)
  }
  const checkedProductList = () => {
    let b = []
    let a = searchParams.get('productIds') ? searchParams.get('productIds').split(',') : []
    if (productList.length === a.length) {
      productList.map((d, index) => {
        if (d.sku == a[index]) {
          b.push(d.name)
        }
      })
    } else {
      for (let i = 0; i < a.length; i++) {
        for (let j = 0; j < productList.length; j++) {
          if (a[i] == productList[j].sku) {
            b.push(productList[j].name)
          }
        }
      }
    }
    setProductCheckedList(b)
  }

  useEffect(() => {
    checkedCatList()
    checkedProductList()
  }, [searchParams, categoryList])

  useEffect(() => {
    if (boxData.length !== 0 && boxData1.length !== 0 && boxData2.length !== 0 && boxData3.length !== 0) {
      setNextState(true)
    } else {
      setNextState(false)
    }
  }, [boxData, boxData1, boxData2, boxData3])
  const removeItems=(type)=>{
    if(type==="products"){
      // navigate({
      //   pathname: `/brandsdashboard/performance`,
      //   search: `?brand=${searchParams.get('brand')}&categoryIds=${searchParams.get('categoryIds')}&productIds=${[]}&date=${searchParams.get('date')}`
      // })
      navigate({
        pathname: `/brandsdashboard/performance/:stateDetails`,
        search: `?brand=${searchParams.get('brand')}&stateName=${searchParams.get('stateName')}&categoryIds=${searchParams.get('categoryIds')}&productIds=${[]}&date=${searchParams.get('date')}`
      })
    }else{
      // navigate({
      //   pathname: `/brandsdashboard/performance`,
      //   search: `?brand=${searchParams.get('brand')}&categoryIds=${[]}&productIds=${searchParams.get('productIds')}&date=${searchParams.get('date')}`
      // })
      navigate({
        pathname: `/brandsdashboard/performance/:stateDetails`,
        search: `?brand=${searchParams.get('brand')}&stateName=${searchParams.get('stateName')}&categoryIds=${[]}&productIds=${searchParams.get('productIds')}&date=${searchParams.get('date')}`
      })
    }
    
  }

  return (
    <div className="boxes">
      <div className="showAll">Showing | {searchParams.get('categoryIds') === "" ?
        `All Categories` : 
        // <div className="selectedList">
        //   {
        //     categoryCheckedList.map((d, index) => {
        //       return <div className="selectedData" key={index}>{d}<img id={d} src={removeTag} alt="removeSelectedCategory" onClick={removeSelectedClickHandler} /></div>
        //     })
        //   }
          
        // </div>
        <>{categoryCheckedList.length===1?
          <div className="selectedList">
            {
              categoryCheckedList.map((d, index) => {
                return <div className="selectedData" key={index}>{d}<img id={d} src={CancelBtn} alt="removeSelectedCategory" onClick={removeSelectedClickHandler} /></div>
              })
            }
          </div>
          :<div className="selected_list_items">
            {`${categoryCheckedList.length} categories selected`}
            <img onClick={()=>removeItems("categery")} alt="" src={CancelBtn}/>
          </div>}
        </>
        
      } | {
          searchParams.get('productIds') === "" ?
            `All Products` : 
            // <div className="selectedList">
            //   {
            //     productCheckedList.map((d, index) => {
            //       if (index < productList.length) {

            //         return <div className="selectedData" key={index}>{d}<img id={d} src={removeTag} alt="removeSelectedProduct" onClick={removeSelectedProductClickHandler} /></div>
            //       }
            //     })
            //   }
              
            // </div>
            <>
              {productCheckedList.length===1?
              <div className="selectedList">
                {
                  productCheckedList.map((d, index) => {
                    return <div className="selectedData" key={index}>{d}<img id={d} src={CancelBtn} alt="removeSelectedProduct" onClick={removeSelectedProductClickHandler} /></div>
                  })
                }
              </div>

              :<div className="selected_list_items">
                {`${productCheckedList.length} products selected`}
                <img onClick={()=>removeItems("products")} alt="" src={CancelBtn}/>
              </div> }
            </>

        }</div>
      {
        noDataLoad !== true ?
          <>
            <div className="metricBoxes">
              {boxData.length !== 0 && boxData1.length !== 0 && boxData2.length !== 0 && boxData3.length !== 0 ? boxData.map((data, index) => {
                let rs1 = format.test(data.percentage_change.toString());
                let valueColor
                if (rs1 === true) {
                  valueColor = "rgb(255, 115, 97)";
                } else if (data.percentage_change.toString() === '0.0') {
                  valueColor = 'rgb(255, 207, 97)'
                } else {
                  valueColor = "rgb(0, 188, 138)";
                }
                let plus
                let rs2 = data.percentage_change !== 0.0;
                if (rs2 === true && rs1 === false) {
                  plus = '+'
                } else {
                  plus = ''
                }
                return (
                  <div className="box" key={index}>
                    <div className="dataCategory">
                      <p>SKUs Actively Sampled</p>
                    </div>
                    <div className="value">
                      <div className="det">
                        {data.metric_value === 0 ? <span>No Data Found</span> : nFormatter(data.metric_value)}
                        {/* {data.metric_value === 0 ? "" :
                          <p className="percentage2" style={{ color: `${valueColor}` }}>
                            {data.percentage_change ? `${plus}${data.percentage_change.toFixed(1).toString()}%` : ""}
                          </p>
                        } */}
                      </div>
                      <div className="graph" id={index}>
                        <WeeklyValues
                          val={data.weekly_numbers.slice(0, 7)}
                          id={index}
                          colorData={data.percentage_change.toFixed(1).toString()}
                          brandId={props.brandId}
                          startDate={props.startDate}
                          endDate={props.endDate}
                          data={props.boxData}
                        />
                      </div>
                    </div>
                  </div>
                );
              }) : <MetrixSkeletonLoading />}
              {boxData.length !== 0 && boxData1.length !== 0 && boxData2.length !== 0 && boxData3.length !== 0 ?
                boxData1.map((data, index) => {
                  let rs1 = format.test(data.percentage_change.toString());
                  let valueColor
                  if (rs1 === true) {
                    valueColor = "rgb(255, 115, 97)";
                  } else if (data.percentage_change.toString() === '0.0') {
                    valueColor = 'rgb(255, 207, 97)'
                  } else {
                    valueColor = "rgb(0, 188, 138)";
                  }
                  let plus
                  let rs2 = data.percentage_change !== 0.0;
                  if (rs2 === true && rs1 === false) {
                    plus = '+'
                  } else {
                    plus = ''
                  }
                  return (
                    <div className="box" key={index}>
                      <div className="dataCategory">
                        <p>Users Engaged</p>
                      </div>
                      <div className="value">
                        <div className="det">
                          {data.metric_value === 0 ? <span>No Data Found</span> : nFormatter(data.metric_value)}
                          {/* {data.metric_value === 0 ? "" :
                            <p className="percentage2" style={{ color: `${valueColor}` }}>
                              {data.percentage_change ? `${plus}${data.percentage_change.toFixed(1).toString()}%` : ""}
                            </p>
                          } */}
                        </div>
                        <div className="graph" id={index}>
                          <Graph1
                            val={data.weekly_numbers.slice(0, 7)}
                            id={index}
                            colorData={data.percentage_change.toFixed(1).toString()}
                            brandId={props.brandId}
                            data={props.boxData}
                          />
                        </div>
                      </div>{" "}
                    </div>
                  )
                }) : <MetrixSkeletonLoading />
              }
              {boxData.length !== 0 && boxData1.length !== 0 && boxData2.length !== 0 && boxData3.length !== 0 ? boxData2.map((data, index) => {
                let rs1 = format.test(data.percentage_change.toString());
                let valueColor
                if (rs1 === true) {
                  valueColor = "rgb(255, 115, 97)";
                } else if (data.percentage_change.toString() === '0.0') {
                  valueColor = 'rgb(255, 207, 97)'
                } else {
                  valueColor = "rgb(0, 188, 138)";
                }
                let plus
                let rs2 = data.percentage_change !== 0.0;
                if (rs2 === true && rs1 === false) {
                  plus = '+'
                } else {
                  plus = ''
                }
                return (
                  <div className="box" key={index}>
                    <div className="dataCategory">
                      <p>Users Tried</p>
                    </div>
                    <div className="value">
                      <div className="det">
                        {data.metric_value === 0 ? <span>No Data Found</span> : nFormatter(data.metric_value)}
                        {/* {data.metric_value === 0 ? "" :
                          <p className="percentage2" style={{ color: `${valueColor}` }}>
                            {data.percentage_change ? `${plus}${data.percentage_change.toFixed(1).toString()}%` : ""}
                          </p>
                        } */}
                      </div>
                      <div className="graph" id={index}>
                        <Graph2
                          val={data.weekly_numbers.slice(0, 7)}
                          id={index}
                          colorData={data.percentage_change.toFixed(1).toString()}
                          brandId={props.brandId}
                          startDate={props.startDate}
                          endDate={props.endDate}
                          data={props.boxData}
                        />
                      </div>
                    </div>
                  </div>
                );
              }) : <MetrixSkeletonLoading />}
              {boxData.length !== 0 && boxData1.length !== 0 && boxData2.length !== 0 && boxData3.length !== 0 && boxData4.length !==0 ? boxData4.map((data, index) => {
            let rs1 = format.test(data.percentage_change.toString());
            let valueColor
            if (rs1 === true) {
              valueColor = "rgb(255, 115, 97)";
            } else if (data.percentage_change.toFixed(1).toString() === '0.0') {
              valueColor = 'rgb(255, 207, 97)'
            } else {
              valueColor = "rgb(0, 188, 138)";
            }
            let plus
            let rs2 = data.percentage_change !== 0.0;
            if (rs2 === true && rs1 === false) {
              plus = '+'
            } else {
              plus = ''
            }
            return <div className="box" key={index}>
              <div className="dataCategory">
                <p>Samples Tried</p>
              </div>
              <div className="value">
                <div className="det">
                  {data.metric_value === 0 ? <span>No Data Found</span> : nFormatter(data.metric_value)}
                  {/* {data.metric_value === 0 ? "" : <p className="percentage1" style={{ color: `${valueColor}` }}>
                    {plus}{data.percentage_change.toFixed(1).toString()}%
                  </p>} */}
                </div>
                <div className="graph" id={index}>
                  <Graph2
                    val={data.weekly_numbers.slice(0, 7)}
                    id={index}
                    colorData={data.percentage_change.toFixed(1).toString()}
                    brandId={props.brandId}
                    startDate={props.startDate}
                    endDate={props.endDate}
                    allChecked={props.allChecked}
                    allProductsChecked={props.allProductsChecked}
                    singleCheck={props.singleCheck}
                    singleProductCheck={props.singleProductCheck}
                  // data={boxData2}
                  />
                </div>
              </div>
            </div>
          }) : <MetrixSkeletonLoading />
          }
              {boxData.length !== 0 && boxData1.length !== 0 && boxData2.length !== 0 && boxData3.length !== 0 ? boxData3.map((data, index) => {
                let rs1 = format.test(data.percentage_change.toString());
                let valueColor
                if (rs1 === true) {
                  valueColor = "rgb(255, 115, 97)";
                } else if (data.percentage_change.toString() === '0.0') {
                  valueColor = 'rgb(255, 207, 97)'
                } else {
                  valueColor = "rgb(0, 188, 138)";
                }
                let plus
                let rs2 = data.percentage_change !== 0.0;
                if (rs2 === true && rs1 === false) {
                  plus = '+'
                } else {
                  plus = ''
                }
                return (
                  <div className="box" key={index}>
                    <div className="dataCategory">
                      <p>Pincodes Serviced</p>
                    </div>
                    <div className="value">
                      <div className="det">
                      {data.metric_value === 0 ? <span>No Data Found</span> : nFormatter(data.metric_value)}
                        {/* {data.metric_value === 0 ? "" :
                          <p className="percentage2" style={{ color: `${valueColor}` }}>
                            {data.percentage_change ? `${plus}${data.percentage_change.toFixed(1).toString()}%` : ""}
                          </p>
                        } */}
                      </div>
                      <div className="graph">
                        <Graph3
                          val={data.weekly_numbers.slice(0, 7)}
                          id={index}
                          colorData={data.percentage_change.toFixed(1).toString()}
                          brandId={props.brandId}
                          startDate={props.startDate}
                          endDate={props.endDate}
                          data={props.boxData}
                        />
                      </div>
                    </div>
                  </div>
                );
              }) : <MetrixSkeletonLoading />}
            </div>
            <StateHeatMaps
              brandId={props.brandId}
              nextState={nextState}
            />
          </>
          : <img className="fullNoDataImage" src={DataLoader} alt='image data empty' />}
    </div>
  );
};

export default StateMetricBoxes;
