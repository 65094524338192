import "./ProductGender.css"
import React, { useState, useEffect } from 'react';
import axios from "axios";
import checkEnv from "../../../utils/checkEnv";

export default function ProductGender(props) {
    const genderHeadings = ['Trial Rate', 'Trial Units', 'Trial Rate', 'Repeat', 'Frequency', 'Repeat Unit']
    let brandId = props.brandId;
    let token = localStorage.getItem('authorization')
    let url=checkEnv()
    const [genderList, setGenderList] = useState([])


    const getGenderList = async () => {
        const response = await axios.get(`${url}/brandsdashboardproxy/v1/brand/${brandId}/productfourtwoodlockgender/`, {
            headers: {
                'authorization': `${token}`
            }
        })
            .catch((err) => {
                console.log(err);
            });
        setGenderList(response.data.map(d => d));
    };

    useEffect(() => {
        getGenderList();
    }, [])
    const data1 = [
        {
            name: "Male",
            trial_rate: 0.5579,
            trial_units: 1.67,
            repeat: 1.56,
            frequency: 1.06,
            repeat_unit: 1.88,

        },
    ];
    const data2 = [

        {
            name: "Female",
            trial_rate: 1.21,
            trial_units: 1.66,
            repeat: 2.18,
            frequency: 1.06,
            repeat_unit: 1.7,

        },

    ]
    const data3 = [

        {
            name: "All",
            trial_rate: 0.8766,
            trial_units: 1.75,
            repeat: 2.06,
            frequency: 1.07,
            repeat_unit: 1.88,
        },
    ]
    return (
        <div>
            <div className="genderSection">
                <div className="genderHeading">
                    Gender
                </div>
                <table className="genderTable">
                    <tr className="productGenderHeadings">
                        <th colSpan="2">
                            Age
                        </th>
                        <th colSpan="2">
                            Trials
                        </th>
                        <th colSpan="4">
                            Adoption
                        </th>
                    </tr>
                    <tr className="productGenderSections">
                        <td>
                            Age Group
                        </td>
                        <td>
                            Gender
                        </td>
                        {
                            genderHeadings.map((d,index) => {
                                return  <td key={index}>
                                        {d}
                                    </td>
                                
                            })
                        }

                    </tr>
                    {
                        genderList.map((d, index) => {

                            return <tbody key={index}>
                                <tr className="ageWiseDetails">
                                    <td className="ageGroup" rowSpan="3">
                                        {d.ageGroup} YEARS
                                    </td>
                                    <td>
                                        Male
                                    </td>
                                    <td>
                                        {d.data.male.trials.trialRate}%
                                    </td>
                                    <td>
                                        {d.data.male.trials.trialUnits}
                                    </td>
                                    <td>
                                        {d.data.male.adoption.trialRate}%
                                    </td>
                                    <td>
                                        {d.data.male.adoption.repeat}%
                                    </td>
                                    <td>
                                        {d.data.male.adoption.frequency}
                                    </td>
                                    <td>
                                        {d.data.male.adoption.repeatUnits}
                                    </td>
                                </tr>
                                <tr className="ageWiseDetails1">
                                    <td>
                                        Female
                                    </td>
                                    <td>
                                        {d.data.female.trials.trialRate}%
                                    </td>
                                    <td>
                                        {d.data.female.trials.trialUnits}
                                    </td>
                                    <td>
                                        {d.data.female.adoption.trialRate}%
                                    </td>
                                    <td>
                                        {d.data.female.adoption.repeat}%
                                    </td>
                                    <td>
                                        {d.data.female.adoption.frequency}
                                    </td>
                                    <td>
                                        {d.data.female.adoption.repeatUnits}
                                    </td>
                                </tr>
                                <tr className="ageWiseDetails1">
                                    <td>
                                        All
                                    </td>
                                    <td>
                                        {d.data.all.trials.trialRate}%
                                    </td>
                                    <td>
                                        {d.data.all.trials.trialUnits}
                                    </td>
                                    <td>
                                        {d.data.all.adoption.trialRate}%
                                    </td>
                                    <td>
                                        {d.data.all.adoption.repeat}%
                                    </td>
                                    <td>
                                        {d.data.all.adoption.frequency}
                                    </td>
                                    <td>
                                        {d.data.all.adoption.repeatUnits}
                                    </td>

                                </tr>
                                </tbody>
                        })
                    }
                </table>
            </div>
        </div>
    )
}
