import "./UserSignIn.css";
import React, { useState, useEffect } from 'react';
import SmyttenLogo from "../images/image 17.png"
import Lock from "../images/lock.png"
import Envelope from "../images/mail.png"
import UserResearch from "../images/userCarousel.png"
import Carousel from 'react-bootstrap/Carousel'
// import { useNavigate,useSearchParams } from "react-router";
import { useSearchParams, useNavigate } from "react-router-dom";
import InvalidEntry from "../images/invalid.png"
import ValidEntry from "../images/valid.png"
import axios from "axios";
import Loader from "../images/signIn_gif.gif"
import moment from 'moment';
import checkEnv from "../../utils/checkEnv";
export default function UserSignIn() {
    const [searchParams, setSearchParams] = useSearchParams();
    const navigate = useNavigate();
    var validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    const [email, SetEmail] = useState('')
    const [password, setPassword] = useState('')
    const [invalidState, setInvalidState] = useState(false)
    const [validEntryState, setValidEntryState] = useState(false)
    const [welcomeState, setWelcomeState] = useState(true)
    const [userFullName, setUserFullName] = useState('')
    const [singleEmailCheck, setSingleEmailCheck] = useState()
    const [singlePasswordCheck, setSinglePasswordCheck] = useState()
    let url=checkEnv()
    let color = ['#4EB563', '#EE504F', '#C4C4C4']
    let borderColorPassword
    let borderColorEmail

    const emailChangeHandler = (event) => {
        SetEmail(event.target.value)
        if (!(event.target.value).match(validRegex)) {
            setSingleEmailCheck(true)
            setValidEntryState(false)
            setInvalidState(false)
        } else if ((event.target.value).match(validRegex)) {
            setSingleEmailCheck(false)
            setValidEntryState(false)
            setInvalidState(false)
        }
    }
    const passwordChangeHandler = (event) => {
        setPassword(event.target.value)
        if (event.target.value.length >= 1) {
            setSinglePasswordCheck(true)
            setValidEntryState(false)
            setInvalidState(false)
        } else if (event.target.value.length < 1) {
            setSinglePasswordCheck(false)
            setValidEntryState(false)
            setInvalidState(false)
        }
    }
    const signInClickHandler = (event) => {
        
        event.preventDefault()
        localStorage.clear()
        getUserToken()
    }
    useEffect(() => {
        if (document.getElementById("emailInput")) {
            if (email.match(validRegex) && password.length >= 1) {
                setValidEntryState(true)
                setInvalidState(false)
            }
        }
    }, [email, password])
    let categoryId = localStorage.getItem('allcategoryId') ? (localStorage.getItem('allcategoryId').split(',')) : []
    let productId = localStorage.getItem('allProductId') ? localStorage.getItem('allProductId').split(',') : []
    // let startDate = localStorage.getItem('start_date') && localStorage.getItem('start_date') !== 'undefined' ? localStorage.getItem('start_date') : localStorage.setItem("start_date", moment().subtract(29, 'days').format('YYYY-MM-DD'))
    // let endDate = localStorage.getItem('end_date') && localStorage.getItem('end_date') !== 'undefined' ? localStorage.getItem('end_date') : localStorage.setItem("end_date", moment().format("YYYY-MM-DD"))
    localStorage.setItem("start_date", moment().subtract(29, 'days').format('YYYY-MM-DD'))
    localStorage.setItem("end_date", moment().format("YYYY-MM-DD"))
    let startDate = localStorage.getItem("start_date")
    let endDate =  localStorage.getItem("end_date")
    const getUserToken = () => {
        axios.post(
            `${url}/brandsdashboardproxy/v1/auth/`, {
            'email': `${email}`,
            'password': `${password}`
        })
            .then(response => {
                // console.log(response)
                localStorage.setItem('userName', response.data.username)
                localStorage.setItem('plan', response.data.plan)
                localStorage.setItem('type', response.data.type)
                getBrands(response.data.token)
                setUserFullName(response.data.full_name)
                setInvalidState(false)
                setWelcomeState(false)
            })
            .catch((err) => {
                setInvalidState(true)
                setValidEntryState(false)
            });
    };

    const getBrands = (token) => {
        axios.get(`${url}/brandsdashboardproxy/v1/brand/`, {
            headers: {
                'authorization': `${token}`
            }
        })
            .then(res => {
                localStorage.setItem('token', token)
                // console.log("brands",res)
                setTimeout(() => {
                    if (!localStorage.getItem('previousUrl')) {
                        localStorage.setItem("brand_id", res.data[0].brandId)
                        navigate({
                            pathname: `/brandsdashboard/performance`,
                            search: `?brand=${res.data[0].brandId}&categoryIds=${categoryId}&productIds=${productId}&date=${startDate}to${endDate}`
                        })
                    } else if (localStorage.getItem('previousUrl') && localStorage.getItem('previousUrl') === '/brandsdashboard') {
                        console.log(localStorage.getItem('previousUrl'))
                        navigate({
                            pathname: `/brandsdashboard/performance`,
                            search: `?brand=${res.data[0].brandId}&categoryIds=${categoryId}&productIds=${productId}&date=${startDate}to${endDate}`
                        })
                    } else {
                        navigate({
                            pathname: localStorage.getItem('previousUrl'),
                            search: localStorage.getItem('search') !== '' ? localStorage.getItem('search') : `?brand=${res.data[0].brandId}&categoryIds=${categoryId}&productIds=${productId}&date=${startDate}to${endDate}`
                        })
                    }
                }, 3000)
            })
            .catch((err) => {
                navigate("/brandsdashboard/")
            })
    }

    if (invalidState === false && validEntryState === true && document.querySelector('.emailPart') && document.querySelector('.passwordPart')) {
        borderColorPassword = color[0]
        borderColorEmail = color[0]
        document.querySelector('.emailPart').classList.remove('invalidState')
        document.querySelector('.passwordPart').classList.remove('invalidState')
    } else if (invalidState === true && validEntryState === false && document.querySelector('.emailPart') && document.querySelector('.passwordPart')) {
        borderColorPassword = color[1]
        borderColorEmail = color[1]
        document.querySelector('.emailPart').classList.add('invalidState')
        document.querySelector('.passwordPart').classList.add('invalidState')
    } else if (invalidState === false && validEntryState === false && singleEmailCheck === true && document.querySelector('.emailPart') && document.querySelector('.passwordPart')) {
        borderColorEmail = color[1]
        document.querySelector('.emailPart').classList.add('invalidState')
        document.querySelector('.passwordPart').classList.remove('invalidState')
    } else if (invalidState === false && validEntryState === false && singleEmailCheck === false && document.querySelector('.emailPart')) {
        borderColorEmail = color[0]
        document.querySelector('.emailPart').classList.remove('invalidState')
    } else if (invalidState === false && validEntryState === false && singlePasswordCheck === true && document.querySelector('.passwordPart')) {
        borderColorPassword = color[0]
        document.querySelector('.passwordPart').classList.remove('invalidState')
    } else if (invalidState === false && validEntryState === false && singlePasswordCheck === false && document.querySelector('.passwordPart')) {
        borderColorPassword = color[1]
        document.querySelector('.emailPart').classList.remove('invalidState')
        document.querySelector('.passwordPart').classList.add('invalidState')
    }

    useEffect(() => {
        if (localStorage.getItem('token')) {
            getBrands(localStorage.getItem('token'))
        }
    }, [])

    const forgotPasswordClickHandler = () => {
        navigate('/brandsdashboard/forgotPassword')
    }

    return (
        <div className="userSignInSection">
            <div className="signInPart">

                {
                    welcomeState ? <>
                        <div className="signDescription">
                            <div className="signInHeader">
                                Sign-In to Smytten
                            </div>
                            <div className="signInDescription">
                                Use your registered email address to access cutting edge insights
                            </div>
                        </div>
                        <form onSubmit={signInClickHandler}>
                            <div className="userEmailAndPasswordSection">
                                <div className="emailSection">
                                    <div className="emailHeader">
                                        Email ID
                                    </div>
                                    <div className="emailPart" style={{ border: `1px solid ${borderColorEmail}` }}>
                                        <input id="emailInput" type="email" value={email} onChange={emailChangeHandler} />
                                        <img src={Envelope} alt="mailEnvelope" />
                                    </div>
                                    {
                                        invalidState ? <div className="invalidEntry">
                                            <img src={InvalidEntry} alt="wrongImage" />
                                            Invalid Entry
                                        </div> : ""
                                    }
                                    {
                                        validEntryState ? <div className="validEntry">
                                            <img src={ValidEntry} alt="ValidImage" />
                                            Looks Good!
                                        </div> : ""
                                    }
                                </div>
                                <div className="passwordSection">
                                    <div className="passwordHeader">
                                        <p>Password</p>
                                        <p className="forgotPassword" onClick={forgotPasswordClickHandler}>Forgot Password?</p>
                                    </div>
                                    <div className="passwordPart" style={{ border: `1px solid ${borderColorPassword}` }}>
                                        <input type="password" value={password} onChange={passwordChangeHandler} />
                                        <img src={Lock} alt="lock" />
                                    </div>
                                </div>
                            </div>
                            {/* <div className="checkBoxSection">
                                <input type="checkbox" /> Lorem ipsum dolor sit amet, consectetur adipiscing elit. Leo rhoncus,
                            </div> */}
                            <input type="submit" value="Sign In" className="signInButton" />
                        </form>
                        {/* <div className="notHaveAccount">
                            Don’t have an account? <span>Register Now</span>
                        </div> */}
                    </> : <div className="welcomeUser">
                        <div className="welcomeUserHeader">Welcome Back,<br></br>{userFullName}!</div>
                        <div className="welcomeWaitDescription">   Give us a few moments while we populate your data.. </div>
                        <img src={Loader} alt="loader" />
                    </div>
                }
            </div>
            <div className="UserResearchPart">
                <div className="signInResearchCarousel">
                    <div className="userLogo">
                        <img src={SmyttenLogo} alt="smyttenLogo" />
                    </div>
                    <Carousel variant="dark">
                        <Carousel.Item className="slideCarousel">
                            <img
                                className="d-block w-100"
                                src={UserResearch}
                                alt="First slide"
                            />
                            <Carousel.Caption>
                                <h5> User Research</h5>
                                <p>Understand your users better & what motivates them with our fast & quick research solutions</p>
                            </Carousel.Caption>
                        </Carousel.Item>
                        <Carousel.Item className="slideCarousel">
                            <img
                                className="d-block w-100"
                                src={UserResearch}
                                alt="Second slide"
                            />
                            <Carousel.Caption>
                                <h5> User Research</h5>
                                <p>Understand your users better & what motivates them with our fast & quick research solutions</p>
                            </Carousel.Caption>
                        </Carousel.Item>
                        <Carousel.Item className="slideCarousel">
                            <img
                                className="d-block w-100"
                                src={UserResearch}
                                alt="Third slide"
                            />
                            <Carousel.Caption>
                                <h5> User Research</h5>
                                <p>Understand your users better & what motivates them with our fast & quick research solutions</p>
                            </Carousel.Caption>
                        </Carousel.Item>
                        <Carousel.Item className="slideCarousel">
                            <img
                                className="d-block w-100"
                                src={UserResearch}
                                alt="Third slide"
                            />
                            <Carousel.Caption>
                                <h5> User Research</h5>
                                <p>Understand your users better & what motivates them with our fast & quick research solutions</p>
                            </Carousel.Caption>
                        </Carousel.Item>
                    </Carousel>
                </div>
            </div>
        </div>
    )
}
