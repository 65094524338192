import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import './style.css';
import AudienceTargeting from './SubComponents/AudienceTargeting/AudienceTargeting';
import BasicSetup from './SubComponents/BasicSetup';
import SurveyQuestionnaireSetup from './SubComponents/SurveyQuestionnaireSetup';
import greenCheck from '../../images/greenCheck.svg';
import configData from '../../../config/config.json'
import PreviewSubmit from './SubComponents/PreviewSubmit/PreviewSubmit';
import { useSearchParams } from 'react-router-dom';
import { checkSurveyEnv } from '../../../utils/checkEnv';

function SurveyStepper() {
    let { surveyId } = useParams();
    const [step, setStep] = useState(1);
    const [surveyDetails, setSurveyDetails] = useState();
    const navigate = useNavigate();
    const base_url = configData.base_url;
    const [searchParams, setSearchParams] = useSearchParams();
    // let { stepFromParams } = useParams();
    let surveyUrl=checkSurveyEnv()
    // console.log("url",surveyUrl)
    const getSurveyDetails = () => {
        axios.get(surveyUrl + `/smyttenresearch/survey/${surveyId}`)
            .then((res) => {
                setSurveyDetails(res.data);
            })
            .catch((err) => {
                console.log(err);
            })
    }

    useEffect(() => {
        getSurveyDetails();
    }, [])

    useEffect(() => {
        // If surveyId is present, skip basic setup
        checkWhichStepToRender();
    }, [surveyDetails])

    const checkWhichStepToRender = () => {
        if (surveyDetails)
            setStep(2);
    }

    const changeStep = (temp) => {
        // if (temp > 1 && !surveyId)
        //     setStep(1);
        // else
        setStep(temp);
    }

    const changeStepperStep = (temp) => {
        if (temp > 1 && !surveyId)
            setStep(1);
        else
            setStep(temp);
    }

    const renderStep = () => {
        switch (step) {
            case 1:
                return (
                    <>
                        <BasicSetup changeStep={changeStep} />
                    </>
                )
            case 2:
                return (
                    <>
                        <AudienceTargeting changeStep={changeStep} />
                    </>
                )
            case 3:
                return (
                    <SurveyQuestionnaireSetup changeStep={changeStep} />
                )
            case 4:
                return (
                    <div>
                        <PreviewSubmit />
                    </div>
                )
        }
    }

    useEffect(() => {
        if (document.querySelectorAll('.borderColor')) {
            let a = document.querySelectorAll('.borderColor')
            for (let i = 0; i < a.length; i++) {
                a[i].style.borderBottom = 'none'
            }
            a[step - 1].style.borderBottom = '3px solid #0F1628'
        }
    }, [step])
    return (
        <div className='surveyStepper'>
            <div className='stepperBar'>
                <div className='stepperBarInner'>
                    <div className={step > 1 ? 'stepDivColored borderColor' : 'stepDiv borderColor'} onClick={() => changeStepperStep(1)}>
                        {step > 1 ? <div className='greenCheck'><img src={greenCheck} /></div> : <div className='stepNumber'>1</div>}
                        Basic Setup
                    </div>
                    <div className={step > 2 ? 'stepDivColored borderColor' : 'stepDiv borderColor'} onClick={() => changeStepperStep(2)}>
                        {step > 2 ? <div className='greenCheck'><img src={greenCheck} /></div> : <div className='stepNumber'>2</div>}
                        Audience Targeting
                    </div>
                    <div className={step > 3 ? 'stepDivColored borderColor' : 'stepDiv borderColor'} onClick={() => changeStepperStep(3)}>
                        {step > 3 ? <div className='greenCheck'><img src={greenCheck} /></div> : <div className='stepNumber'>3</div>}
                        Questionnaire
                    </div>
                    <div className='stepDiv borderColor' onClick={() => changeStepperStep(4)}>
                        <div className='stepNumber'>4</div>
                        Preview
                    </div>
                </div>
            </div>
            <div className='stepperBody'>
                {renderStep()}
            </div>
        </div>
    )
}

export default SurveyStepper