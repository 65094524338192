import React, { useEffect, useState } from "react";
import "./style.css";
import createNewSurveyButton from "../../images/createNewSurveyButton.svg";
import { useNavigate } from "react-router";
import Loader from "react-spinners/BarLoader";
import axios from "axios";
import completedStatusIcon from "../../images/completedStatusIcon.svg";
import rejectedStatusIcon from "../../images/rejectedStatusIcon.svg";
import activeStatusIcon from "../../images/activeStatusIcon.svg";
import pendingApprovalStatusIcon from "../../images/pendingApprovalStatusIcon.svg";
import approvedStatusIcon from "../../images/approvedStatusIcon.svg";
import rdSurveyOptions from "../../images/rd-surveyOptions.svg";
import rdSortUp from "../../images/rdSortUp.svg";
import rdSortDown from "../../images/rdSortDown.svg";
import draftEdit from "../../images/draftEdit.svg";
import searchIcon from "../../images/search_icon.svg";
import { useSearchParams } from "react-router-dom";
import configData from "../../../config/config.json";
import moment from "moment";
import { Dropdown } from "react-bootstrap";
import { Button, Input, Menu, Select } from "antd";
import { AppstoreOutlined } from "@ant-design/icons";
import DeleteModal from "./DeleteModal";
import pausedStatusIcon from "../../images/pausedStatusIcon.svg";
import coin from "../../images/singleCoin.svg";
import checkEnv, { checkSurveyEnv } from "../../../utils/checkEnv";
import CreateModal from "./createmodal/CreateModal";

function ResearchDashboardNew() {
  const base_url = configData.base_url;
  const [modalShow, setModalShow] = useState(false);
  let url = checkEnv();
  const surveyUrl = checkSurveyEnv();
  const [searchParams, setSearchParams] = useSearchParams();
  const [surveyList, setSurveyList] = useState([]);
  const [surveyListCopy, setSurveyListCopy] = useState([]);
  const navigate = useNavigate();
  const [sortState, setSortState] = useState({
    title: false,
    created_at: false,
    published_at: false,
    number_of_sections: false,
    number_of_questions: false,
    credits_utilised: false,
  });
  let startDate = localStorage.getItem("start_date")
    ? localStorage.getItem("start_date")
    : localStorage.setItem(
        "start_date",
        moment().subtract(6, "days").format("YYYY-MM-DD")
      );
  let endDate = localStorage.getItem("end_date")
    ? localStorage.getItem("end_date")
    : localStorage.setItem("end_date", moment().format("YYYY-MM-DD"));

  const navigateToDashboard = () => {
    navigate({
      pathname: `/brandsdashboard/research`,
      search: `?brand=${localStorage.getItem("brand_id")}&categoryIds=${
        localStorage.getItem("allcategoryId")
          ? localStorage.getItem("allcategoryId").split(",")
          : []
      }&productIds=${
        localStorage.getItem("allProductId")
          ? localStorage.getItem("allProductId").split(",")
          : []
      }&date=${startDate}to${endDate}`,
    });
  };


  const fetchSurveyList = () => {
    axios
      .get(
        surveyUrl +
          `/smyttenresearch/survey/?brand_id=${searchParams.get("brand")}`
      )
      .then((res) => {
        if (res.data.length == 0){ 
            navigate({
            pathname:"/brandsdashboard/research",
            search: `?brand=${localStorage.getItem("brand_id")}&categoryIds=${
                localStorage.getItem("allcategoryId")
                  ? localStorage.getItem("allcategoryId").split(",")
                  : []
              }&productIds=${
                localStorage.getItem("allProductId")
                  ? localStorage.getItem("allProductId").split(",")
                  : []
              }&date=${startDate}to${endDate}`,
        });}
        setSurveyList(res.data);
        setSurveyListCopy(res.data);
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  useEffect(() => {
    fetchSurveyList();
  }, [searchParams]);

  const dashSVG = () => {
    return (
      <div style={{ marginTop: "0.5rem" }}>
        <svg
          width="30"
          height="2"
          viewBox="0 0 30 2"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <line
            y1="1.25"
            x2="30"
            y2="1.25"
            stroke="#979797"
            strokeWidth="1.5"
          />
        </svg>
      </div>
    );
  };

  const renderSurveyStatus = (status) => {
    switch (status) {
      case "CREATED":
        return (
          <div className="draftStatusIcon">
            <img src={draftEdit} style={{ marginRight: "0.2rem" }} />
            Draft
          </div>
        );
      case "PENDING_APPROVAL":
        return <img src={pendingApprovalStatusIcon} />;
      case "APPROVED":
        return <img src={approvedStatusIcon} />;
      case "REJECTED":
        return <img src={rejectedStatusIcon} />;
      case "ACTIVE":
        return <img src={activeStatusIcon} />;
      case "PAUSED":
        return <img src={pausedStatusIcon} />;
      default:
        return <img src={pendingApprovalStatusIcon} />;
    }
  };

  const searchSurveys = (e) => {
    const searchValue = e.target.value;
    let copy = [...surveyListCopy];
    let searchResult = [];

    if (searchValue == "") setSurveyList([...surveyListCopy]);
    else {
      copy.forEach((survey) => {
        if (
          survey.title &&
          survey.title.toLowerCase().includes(searchValue.toLowerCase())
        )
          searchResult.push(survey);
      });
      if (searchResult.length > 0) setSurveyList(searchResult);
    }
  };

  const sortSurveyList = (param, order) => {
    let sortStateCopy = { ...sortState };
    sortStateCopy[param] = !sortStateCopy[param];
    setSortState({ ...sortStateCopy });

    let copy = [...surveyList];

    copy.sort((ob1, ob2) => {
      if (
        param == "number_of_sections" ||
        param == "number_of_questions" ||
        param == "title" ||
        param == "credits_utilised"
      ) {
        if (!ob1[param]) {
          if (order == "ascending") return 0;
          else return 1;
        } else if (!ob2[param]) {
          if (order == "ascending") return 0;
          else return -1;
        } else if (ob1[param] > ob2[param]) {
          if (order == "ascending") return -1;
          else return 1;
        }
        if (ob1[param] < ob2[param]) {
          if (order == "ascending") return 1;
          else return -1;
        } else if (ob1[param] == ob2[param]) return 0;
      } else {
        let time1 = moment(ob1[param]);
        let time2 = moment(ob2[param]);

        if (time1.isAfter(time2)) {
          if (order == "ascending") return -1;
          else return 1;
        } else if (time1.isBefore(time2)) {
          if (order == "ascending") return 1;
          else return -1;
        } else if (time1.isSame(time2)) {
          return 0;
        } else if (time1.format("DD-MM-YYYY") == "Invalid date") {
          if (order == "ascending") return 0;
          else return 1;
        } else if (time2.format("DD-MM-YYYY") == "Invalid date") {
          if (order == "ascending") return 0;
          else return -1;
        }
      }
    });

    setSurveyList([...copy]);
  };

    const changeSurveyStatus = (localSurveyId, surveyStatus) => {
        axios.put(surveyUrl + `/smyttenresearch/survey/${localSurveyId}`, {
            status: surveyStatus
        })
            .then(() => {
                fetchSurveyList();
            })
            .catch(() => {
                fetchSurveyList();
            })
    }


  const renderActionButton = (surveyObject) => {
    const surveyStatus = surveyObject.status;
    const localSurveyId = surveyObject.id;
    const userType = localStorage.getItem("type");
    if (surveyStatus == "PENDING_APPROVAL") {
      if (userType == "ADMIN")
        return (
          <div
            className="rd-actionBtn"
            onClick={() => changeSurveyStatus(localSurveyId, "APPROVED")}
          >
            {"Approve"}
          </div>
        );
    } else if (surveyStatus == "APPROVED") {
      if (userType == "ADMIN")
        return (
          <div
            className="rd-actionBtn"
            onClick={() => changeSurveyStatus(localSurveyId, "ACTIVE")}
          >
            {"Launch"}
          </div>
        );
    } else if (surveyStatus == "ACTIVE") {
      if (userType == "BRAND" || userType == "ADMIN")
        return (
          <div
            className="rd-actionBtn"
            onClick={() => changeSurveyStatus(localSurveyId, "PAUSED")}
          >
            {"Pause"}
          </div>
        );
    } else if (surveyStatus == "PAUSED") {
      if (userType == "ADMIN" || userType == "BRAND")
        return (
          <div
            className="rd-actionBtn"
            onClick={() => changeSurveyStatus(localSurveyId, "ACTIVE")}
          >
            {"Resume"}
          </div>
        );
    } else {
      return null;
    }
  };

  const renderSurveyOptions = (surveyObject) => {
    const surveyStatus = surveyObject.status;
    const userType = localStorage.getItem("type");
    if (
      userType == "ADMIN" &&
      (surveyStatus === "PENDING_APPROVAL" || surveyStatus === "DRAFT")
    ) {
      return (
        <>
          <Dropdown.Item
            className="optionDropdown-menuItem"
            onClick={() => {
              navigate(`/brandsdashboard/research/surveys/${surveyObject.id}`);
            }}
          >
            Edit
          </Dropdown.Item>
          <Dropdown.Item
            className="optionDropdown-menuItem"
            onClick={() => {
              navigate(
                `/brandsdashboard/research/resultAnalysis/${surveyObject.id}`
              );
            }}
          >
            View Results
          </Dropdown.Item>
          <DeleteModal
            surveyId={surveyObject.id}
            fetchSurveyList={fetchSurveyList}
            whatToDelete={"survey"}
          />
        </>
      );
    } else {
      if (surveyStatus == "ACTIVE")
        return (
          <Dropdown.Item
            className="optionDropdown-menuItem"
            onClick={() => {
              navigate(
                `/brandsdashboard/research/resultAnalysis/${surveyObject.id}`
              );
            }}
          >
            View Results
          </Dropdown.Item>
        );
      else if (surveyStatus == "CREATED") {
        return (
          <>
            <Dropdown.Item
              className="optionDropdown-menuItem"
              onClick={() => {
                navigate(
                  `/brandsdashboard/research/surveys/${surveyObject.id}`
                );
              }}
            >
              Edit
            </Dropdown.Item>
            <DeleteModal
              surveyId={surveyObject.id}
              fetchSurveyList={fetchSurveyList}
              whatToDelete={"survey"}
            />
          </>
        );
      }
      else{
        return (<>
          <DeleteModal
                surveyId={surveyObject.id}
                fetchSurveyList={fetchSurveyList}
                whatToDelete={"survey"}
          />
        </>)
      }
    }
  };

  if (surveyList.length > 0)
    return (
      <div className="researchDashboard-main">
        <div className="researchDashboard-head">
          <div className="researchDashboard-head-left">
            <div>Research Dashboard</div>
            <div
              style={{
                fontWeight: "500",
                fontSize: "16px",
                lineHeight: "18px",
                marginTop: "0.3rem",
              }}
            >
              {surveyListCopy.length} Surveys
            </div>
          </div>
          <div className="researchDashboard-head-right">
            {/* <div className='button-template-survey-section' onClick={()=>{
                            navigate({
                                pathname:`/brandsdashboard/research/`,
                                search:`?brand=${localStorage.getItem("brand_id")}&step=1&categoryIds=${searchParams.get('categoryIds')}&productIds=${searchParams.get('productIds')}&date=${searchParams.get('date')}`
                            })
                        }}><Button className='button-template-survey' >Template Survey</Button></div> */}
            <img
              style={{ cursor: "pointer", width: "230px", height: "auto" }}
              alt="TemplateSurveyImage"
              src={createNewSurveyButton}
              onClick={() => {
                setModalShow(true);
              }}
            />
            <div>
              <CreateModal
                show={modalShow}
                onHide={() => setModalShow(false)}
              />
            </div>
          </div>
        </div>
        <div className="researchDashboard-body">
          <div className="rd-searchNFilter">
            <div className="rd-searchBar">
              <img src={searchIcon} />
              <Input
                bordered={false}
                placeholder="Search Surveys"
                onChange={searchSurveys}
              />
            </div>
            {/* <div className='rd-filters'> Filter</div> */}
          </div>
          <div className="rd-table-head">
            <div></div>
            <div
              className="sortBox"
              onClick={() => document.getElementById("sort-surveyName").click()}
            >
              <div style={{ marginRight: "0.3rem", userSelect: "none" }}>
                Survey Name
              </div>
              <div className="sortBox-icons">
                {sortState.title ? (
                  <img
                    id="sort-surveyName"
                    src={rdSortUp}
                    onClick={() => sortSurveyList("title", "ascending")}
                  />
                ) : (
                  <img
                    id="sort-surveyName"
                    src={rdSortDown}
                    onClick={() => sortSurveyList("title", "descending")}
                  />
                )}
              </div>
            </div>
            <div className="rd-flexJustifyCenter">
              <div
                className="sortBox"
                onClick={() =>
                  document.getElementById("sort-createdDate").click()
                }
              >
                <div style={{ marginRight: "0.3rem", userSelect: "none" }}>
                  Created
                </div>
                <div className="sortBox-icons">
                  {sortState.created_at ? (
                    <img
                      id="sort-createdDate"
                      src={rdSortUp}
                      onClick={() => sortSurveyList("created_at", "ascending")}
                    />
                  ) : (
                    <img
                      id="sort-createdDate"
                      src={rdSortDown}
                      onClick={() => sortSurveyList("created_at", "descending")}
                    />
                  )}
                </div>
              </div>
            </div>
            <div className="rd-flexJustifyCenter">
              <div
                className="sortBox"
                onClick={() =>
                  document.getElementById("sort-publishedDate").click()
                }
              >
                <div style={{ marginRight: "0.3rem", userSelect: "none" }}>
                  Published
                </div>
                <div className="sortBox-icons">
                  {sortState.published_at ? (
                    <img
                      id="sort-publishedDate"
                      src={rdSortUp}
                      onClick={() =>
                        sortSurveyList("published_at", "ascending")
                      }
                    />
                  ) : (
                    <img
                      id="sort-publishedDate"
                      src={rdSortDown}
                      onClick={() =>
                        sortSurveyList("published_at", "descending")
                      }
                    />
                  )}
                </div>
              </div>
            </div>
            <div className="rd-flexJustifyCenter">
              <div
                className="sortBox"
                onClick={() => document.getElementById("sort-sections").click()}
              >
                <div style={{ marginRight: "0.3rem", userSelect: "none" }}>
                  Sections
                </div>
                <div className="sortBox-icons">
                  {sortState.number_of_sections ? (
                    <img
                      id="sort-sections"
                      src={rdSortUp}
                      onClick={() =>
                        sortSurveyList("number_of_sections", "ascending")
                      }
                    />
                  ) : (
                    <img
                      id="sort-sections"
                      src={rdSortDown}
                      onClick={() =>
                        sortSurveyList("number_of_sections", "descending")
                      }
                    />
                  )}
                </div>
              </div>
            </div>
            <div className="rd-flexJustifyCenter">
              <div
                className="sortBox"
                onClick={() =>
                  document.getElementById("sort-questions").click()
                }
              >
                <div style={{ marginRight: "0.3rem", userSelect: "none" }}>
                  Questions
                </div>
                <div className="sortBox-icons">
                  {sortState.number_of_questions ? (
                    <img
                      id="sort-questions"
                      src={rdSortUp}
                      onClick={() =>
                        sortSurveyList("number_of_questions", "ascending")
                      }
                    />
                  ) : (
                    <img
                      id="sort-questions"
                      src={rdSortDown}
                      onClick={() =>
                        sortSurveyList("number_of_questions", "descending")
                      }
                    />
                  )}
                </div>
              </div>
            </div>
            <div className="rd-flexJustifyCenter">
              <div
                className="sortBox"
                onClick={() => document.getElementById("sort-credits").click()}
              >
                <div style={{ marginRight: "0.3rem", userSelect: "none" }}>
                  Credits Used
                </div>
                <div className="sortBox-icons">
                  {sortState.credits_utilised ? (
                    <img
                      id="sort-credits"
                      src={rdSortUp}
                      onClick={() =>
                        sortSurveyList("credits_utilised", "ascending")
                      }
                    />
                  ) : (
                    <img
                      id="sort-credits"
                      src={rdSortDown}
                      onClick={() =>
                        sortSurveyList("credits_utilised", "descending")
                      }
                    />
                  )}
                </div>
              </div>
            </div>
            <div></div>
          </div>
          <div className="rd-table">
            {surveyList.map((survey, index) => {
              const userType = localStorage.getItem("type");
              return (
                <div className="rd-table-row-wrapper">
                  <div className="rd-table-row" key={index}>
                    <div
                      style={{
                        fontWeight: "400",
                        fontSize: "20px",
                        alignSelf: "flex-start",
                        lineHeight: "1.2",
                      }}
                    >
                      {index + 1}.
                    </div>

                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-start",
                        marginBottom: "1rem",
                      }}
                    >
                      <div className="rd-surveyTitle">{survey.title}</div>
                      <div className="rd-surveySubtitle">
                        {survey.sub_title
                          ? survey.sub_title
                          : "Lorem Ipsum is Subtitle"}
                      </div>
                    </div>

                    <div className="rd-table-dates">
                      {survey.created_at
                        ? moment(survey.created_at).format("DD/MM/YYYY")
                        : dashSVG()}
                    </div>
                    <div className="rd-table-dates">
                      {survey.published_at
                        ? moment(survey.published_at).format("DD/MM/YYYY")
                        : dashSVG()}
                    </div>
                    <div className="rd-table-values">
                      {survey.number_of_sections
                        ? survey.number_of_sections
                        : dashSVG()}
                    </div>
                    <div className="rd-table-values">
                      {survey.number_of_questions
                        ? survey.number_of_questions
                        : dashSVG()}
                    </div>
                    <div className="rd-table-values">
                      {survey.credits_utilised ||
                      survey.credits_utilised == 0 ? (
                        <>
                          {survey.credits_utilised}{" "}
                          <img
                            src={coin}
                            className="coinImageHeader"
                            alt="coin image"
                          />
                        </>
                      ) : (
                        dashSVG()
                      )}{" "}
                    </div>
                    {(survey.status != "PENDING_APPROVAL" &&
                      survey.status != "APPROVED" &&
                      survey.status != "INITIATED") ||
                    userType == "ADMIN" ? (
                      <Dropdown className="optionDropdown">
                        <Dropdown.Toggle>
                          <img src={rdSurveyOptions} />
                        </Dropdown.Toggle>
                        <Dropdown.Menu className="optionDropdown-menu">
                          {renderSurveyOptions(survey)}
                        </Dropdown.Menu>
                      </Dropdown>
                    ) : null}
                  </div>
                  <div className="rd-table-row-statusSection">
                    <div className="rd-surveyStatus">
                      {renderSurveyStatus(survey.status)}
                    </div>
                    {renderActionButton(survey)}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    );
  else return <Loader />;
}

export default ResearchDashboardNew;
