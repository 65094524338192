import "./CompetitiveAnalysisRank.css"
import React, { useState, useEffect } from "react";
import axios from "axios";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import DataLoader from "../../images/dataload_gif.gif"
import { useSearchParams } from "react-router-dom";
import { Skeleton } from 'antd';
import CompetitorAnalysisTable from "../CompetitorAnalysisTable/CompetitorAnalysisTable";
import checkEnv from "../../../utils/checkEnv";


ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);

export default function CompetitiveAnalysisNpsGraph(props) {
    const [searchParams, setSearchParams] = useSearchParams()
    const [yLabels, setYLabels] = useState([])
    let token = localStorage.getItem("token")
    let startDate = localStorage.getItem('my_start_date')
    let endDate = localStorage.getItem('my_end_date')
    let url=checkEnv()
    const [index1, setIndex] = useState()
    const [sample, setSample] = useState([]);
    const [labels, setLabels] = useState([]);
    const [nextState, setNextState] = useState(false)

    const getPickUpRate = () => {
        setIndex()
        axios.get(`${url}/brandsdashboardproxy/v1/brand/${searchParams.get('brand')}/avgnpsratebrandwise/?startDate=${startDate}&endDate=${endDate}&categoryIds=${searchParams.get('categoryIds')}&stateName=${props.selectedState}&minimum_sample_count=${props.minimumCount ? props.minimumCount : 0}`, {
            headers: {
                'authorization': `${token}`
            }
        })
            .then(res => {
                setSample(res.data.data.map((dat) => dat));
                setLabels(res.data.data.map(dat => dat.brand_name))
                setYLabels(res.data.data.map(dat => dat.Average_Count))
                // console.log(res.data.data)
                setIndex(res.data.index_pos)
            })
            .catch((err) => {
                console.log(err);
            });
    };

    useEffect(() => {
        getPickUpRate()
    }, [searchParams, props.selectedState, props.submitState]);
    const data = {
        labels: labels,
        datasets: [
            {
                label: "Brand Average",
                data: sample.map((text) => text.Average_Count),
                // backgroundColor: "rgb(166, 206, 227)",
                backgroundColor: color => {
                    let colors = color.index === index1 ? 'green' : "rgb(166, 206, 227)"
                    return colors
                },
                // barThickness: 40,
            },
        ],
    }

    useEffect(() => {
        if (props.nextState === true && index1 !== undefined) {
            setNextState(true)
        } else {
            setNextState(false)
        }
    }, [props.nextState, index1])


    return (
        <>
            <CompetitorAnalysisTable selectedState={props.selectedState} nextState={nextState} />
        </>
    )

    return (
        <div>
            {

                props.nextState === true && index1 !== undefined ?
                    <div className="rankedGraphs1">
                        <div className="rankedGraphHeading">Brand Rank By NPS Rate</div>
                        {index1 !== -1 ?
                            <Bar
                                data={data}
                                height={100}
                                options={{
                                    barPercentage: 1,
                                    plugins: {
                                        tooltip: {
                                            callbacks: {
                                                label: function (context) {
                                                    return `${context.dataset.label} ${context.raw}`
                                                }
                                            },
                                        },
                                        title: {
                                            display: false,
                                            text: "Age Group",
                                            position: "bottom",
                                            font: {
                                                size: 14,
                                            }
                                        },
                                        legend: {
                                            display: false,
                                        },
                                        datalabels: {
                                            display: false,
                                        },
                                    },
                                    scales: {
                                        y: {
                                            min: yLabels.length !== 0 ? yLabels[yLabels.length - 1].toFixed(0) - 1 : 0,
                                            ticks: {
                                                stepSize: 1
                                            }
                                        }
                                    }
                                    // scales: {
                                    //     x: {
                                    //         ticks: {
                                    //             // callback: function (value, index, ticks) {
                                    //             //     if (index === 2){
                                    //             //     console.log(value)
                                    //             //     return ticks;
                                    //             //     }
                                    //             // },
                                    //             maxTicksLimit: labels.length/5
                                    //         },
                                    //     }
                                    // }
                                }}
                            /> : <div className="brandNotPresent">
                                <div>
                                    Brand not present in top 25 competitors
                                </div>
                            </div>}
                        {/* <div className="chartFooter">
                            <div className="label">
                                <div className="labelBoxBrands"></div>
                                <div>Brands Average</div>
                            </div>
                            <div className="label">
                                <div className="labelBoxBrands" style={{ backgroundColor: `green` }}></div>
                                <div>Your Brand Average</div>
                            </div>
                        </div> */}
                    </div>
                    :
                    <div className="competitiveGraphSkeleton">
                        <Skeleton title
                            paragraph={{
                                rows: 7,
                            }} />
                    </div>
            }
            <CompetitorAnalysisTable selectedState={props.selectedState} nextState={nextState} />
        </div>
    )
}

