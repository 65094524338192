import React, { useEffect, useState } from 'react'
import axios from "axios";
import { useSearchParams } from "react-router-dom";
import "./CompetitiveAnalysisTopWords.css"
import { Skeleton } from "antd";
import CompetitveAnalysisWordCloud from './CompetitiveAnalysisWordCloud';
import CompetitiveAnalysisMultiLine from '../CompetitiveAnalysisMultiLine/CompetitiveAnalysisMultiLine';
import CompetitiveAnalysisStateLevelComparision from '../CompetitiveAnalysisStateLevelComparision/CompetitiveAnalysisStateLevelComparision';
import PickupRateTable from '../CompetitiveAnalysisStateLevelComparision/PickupRateTable';
import noDataLoader from "../../images/noDataFull.svg"
import { CompetitiveAnalysisMultiGraph } from './CompetitiveAnalysisMultiGraph';
import Nodatacard from '../../NodataCard';
import checkEnv from '../../../utils/checkEnv';
export default function CompetitiveAnalysisTopWords(props) {
    const [noDataLoad, setNoDataLoad] = useState(false)
    const [nextState, setNextState] = useState(false)
    const [searchParams, setSearchParams] = useSearchParams()
    let token = localStorage.getItem("token")
    const [positiveKeywords, setPositiveKeyWords] = useState([])
    const [words, setWords] = useState([])
    let startDate = localStorage.getItem("my_start_date");
    let endDate = localStorage.getItem("my_end_date");
    let url=checkEnv()
    console.log(props)

    const getTopKeyWords = async () => {
        setWords([])
        const response = await axios.get(`${url}/brandsdashboardproxy/v1/brand/${searchParams.get('brand')}/topkeywordsforbrand/?startDate=${startDate}&endDate=${endDate}&categoryIds=${searchParams.get('categoryIds')}&productIds=&stateName=${props.selectedState}`, {
            headers: {
                'authorization': `${token}`
            }
        })
            .catch((err) => {
                console.log(err);
            });
        response.data.word_cloud.length === 0 ? setNoDataLoad(true) : setNoDataLoad(false)
        setPositiveKeyWords(response.data.top_positive_keywords.map(d => d))
        setWords(response.data.word_cloud.map(d => d))
    };
    useEffect(() => {
        getTopKeyWords();
        // setNoDataLoad(false)
    }, [searchParams, props.selectedState])

    let positiveColors = ["rgb(1, 33, 101)", "rgb(2, 53, 126)", "rgb(3, 99, 169)", "rgb(4, 125, 189)", "rgb(4, 127, 191)"]
    let negativeColors = ["rgb(250, 187, 41)", "rgb(254, 130, 81)", "rgb(254, 123, 88)", "rgb(254, 122, 88)", "rgb(254, 122, 98)"]

    positiveKeywords.sort((a, b) => {
        return b.percentage - a.percentage;
    });
    // console.log("words",words)
    // console.log("length",props.length)
    


    return (
        <>
            <div className="competitorTopWordsSection">
                {/* <div className="topHeadings" style={{ borderBottom: "1px solid #E8EDF6" }}>
                    <div className="topWordsHeader">
                        What users are saying about your Top Competitors
                    </div>
                    <div style={{ width: "fit-content", display: "flex" }}>
                        <div style={{ display: "flex", alignItems: "center", color: "#5B5C60" }}>
                            <div className='ca-legend-green' />
                            &nbsp;
                            Your Brand
                        </div>
                        &nbsp; &nbsp; &nbsp; &nbsp;
                        <div style={{ display: "flex", alignItems: "center", color: "#5B5C60" }} >
                            <div className='ca-legend-red' />
                            &nbsp;
                            Other Brands
                        </div>
                    </div>
                </div> */}

                {
                    words.length !== 0 && positiveKeywords.length !== 0 ?
                        <>
                            <div className="topWordsMiddle">
                                <div className="topWordsWordCloud">
                                    <div className="topWordsHeader">
                                            {/* What users are saying about your Top Competitors */}
                                            Users Sentiment for your Top Competitors
                                    </div>
                                    <CompetitveAnalysisWordCloud words={words} nextState={props.nextState} />
                                </div>

                                {/* <div className="topPositiveWords">
                                    <div className="topKeyWordsSection">
                                        {
                                            positiveKeywords.map((d, index) => {
                                                if (index <= 4) {
                                                    return <div className="positiveKeyWords" key={index}>
                                                        <div className="keyWord">{d.keyword}
                                                        </div>
                                                        <div className="keyWordsPercentage" style={{ color: positiveColors[index] }}>
                                                            {d.percentage !== undefined ? (d.percentage).toFixed(2) : ""}%
                                                        </div>
                                                    </div>
                                                }
                                            })}
                                    </div>
                                </div> */}
                                {/* <PickupRateTable selectedState={props.selectedState} /> */}
                                <div className='doughtnutArea'>
                                    <CompetitiveAnalysisMultiGraph selectedState={props.selectedState} />
                                </div>
                            </div>

                        </>
                        // :
                        // props.length>0 ?<Nodatacard text="Insufficient data to perform sentiment analysis!"/>
                        :
                        noDataLoad ?
                         <Nodatacard text="Insufficient data to perform competitor analysis!"/>
                        
                        :
                            <div className="brandsVsCategorySkeleton"> <Skeleton
                                active
                                title
                                paragraph={{
                                    rows: 8,
                                }} />
                            </div>
                }



            </div>
            {/* <CompetitiveAnalysisMultiLine /> */}
            {/* <CompetitiveAnalysisStateLevelComparision /> */}
        </>
    )
}
