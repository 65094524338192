import React, { useEffect, useState } from 'react'
import "./SurveyHomepage.css"
import Card from './Card'
import { Button } from 'antd'
import Vector from "../../images/Vector.png"
import { useNavigate } from 'react-router'
import moment from 'moment';
import axios from 'axios'
import checkEnv, { checkSurveyEnv } from '../../../utils/checkEnv'

export default function SurveyHomepage() {
    let startDate = localStorage.getItem('start_date') ? localStorage.getItem('start_date') : localStorage.setItem("start_date", moment().subtract(6, 'days').format('YYYY-MM-DD'))
    let endDate = localStorage.getItem('end_date') ? localStorage.getItem('end_date') : localStorage.setItem("end_date", moment().format("YYYY-MM-DD"))
    const navigate=useNavigate()
    const [data,setData]=useState([])
    const url=checkEnv()
    const surveyUrl=checkSurveyEnv()


    useEffect(()=>{
        axios.get(`${surveyUrl}/smyttenresearch/surveytemplate/`)
        .then(res=>{
            console.log(res.data)
            setData(res.data)
        })
    },[url])
    

    const onClickHandler=()=>{
        navigate({
            pathname: '/brandsdashboard/research/surveys',
            search: `?brand=${localStorage.getItem("brand_id")}&categoryIds=${localStorage.getItem('allcategoryId') ? (localStorage.getItem('allcategoryId').split(',')) : []}&productIds=${localStorage.getItem('allProductId') ? localStorage.getItem('allProductId').split(',') : []}&date=${startDate}to${endDate}`
        })
    }
    const cardClickHandler=(id,s,st)=>{
        localStorage.setItem("cardData",JSON.stringify({
            "title":s,
            "sub_title":st
        }))

        navigate({
            pathname: '/brandsdashboard/research/instantiatesurvey',
            search:`?templateId=${id}&brand=${localStorage.getItem("brand_id")}&categoryIds=${localStorage.getItem('allcategoryId') ? (localStorage.getItem('allcategoryId').split(',')) : []}&productIds=${localStorage.getItem('allProductId') ? localStorage.getItem('allProductId').split(',') : []}&date=${startDate}to${endDate}`
        })
    }
    const colorArr=["#f1f0ff","#fef4ec","#ddf4ff"]
    const imagecolor=["#7D71FF","#D95900","#21C2FC"]
  return (
    <div className='survey-main-container'>
     
        {/* <div className='image'>
            <img src={surveyImage} alt="survey-image" />
        </div> */}
        <div className='cards-container'>
            <div className='heading'>
                <h1 className='text'>Easy-Setup Research</h1>
                <p className='sub-title'>Get quick insights within <span className='bold'>72 hours* </span></p>
            </div>
            <div className='cards'>
                {data.length!==0&& data.map((test,index)=>{
                    let color=colorArr[index]
                    let bgcolor=imagecolor[index]
                    if(index>=3){
                        
                        color=colorArr[index%3]
                        bgcolor=imagecolor[index%3]
                    }
                    
                    return <div key={index} onClick={()=>{cardClickHandler(test.id,test.title,test.sub_title)}}><Card bgcolor={bgcolor} color={color} test={test}/></div>
                    })
                }
            </div>
            <div className='cards-footer'>
                 <div className='card-footer-left'>
                    <p className='text'>Customised Research</p>
                    <p className='sub-title'>Get deep insights as per your business needs</p>
                 </div>
                 <div className='card-footer-right'>
                    <Button className='button' onClick={()=>{onClickHandler()}}><img src={Vector} alt="vector"></img>Setup CUSTOM SURVEY</Button>
                 </div>
            </div>
        </div>
        
    </div>
  )
}
