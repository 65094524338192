import React, { useEffect, useState } from 'react';
import './style.css';
import axios from 'axios';
import configData from '../../../../../config/config.json'
import skipFlow from "../../../../images/skipFlow.svg"
import skipBranchIcon from "../../../../images/skipBranchIcon.svg"
import checkboxIcon from '../../../../images/rn-checkbox.svg';
import radioIcon from '../../../../images/rn-radio.svg';
import { Input, InputNumber, Select } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import { checkSurveyEnv } from '../../../../../utils/checkEnv';
import AlertModal from '../SectionQuestionnaire/alert/AlertModal';


function SkipLogic(props) {

    const base_url = configData.base_url;
    const surveyUrl=checkSurveyEnv()
    const [questionObject, setQuestionObject] = useState(props.questionObject);
    const changeSkipFlowShow = props.changeSkipFlowShow;
    const [nextQuestionsList, setNextQuestionsList] = useState([]);
    const [optionObj, setOptionObj] = useState(props.optionObj);
    const setParentQuestionObject = props.setParentQuestionObject;
    const [target,setTarget]=useState(questionObject.options_select_percentage?questionObject.options_select_percentage:{})
    const [show, setShow] = useState(false);

    const [test,setTest] = useState('')

    console.log(optionObj)
    //for target values
    useEffect(()=>{
        if(questionObject.question_type==="MULTI_SELECT"){
            if(!questionObject.options_select_percentage){
                questionObject.options.forEach((e,i)=>{
                    target[e]=100/questionObject.options.length
                })
            }
        }
    },[questionObject])

    useEffect(() => {
        generateQuestionsList();
    }, [])

    const generateQuestionsList = () => {
        axios.get(surveyUrl+ `/smyttenresearch/dependantquestions/${questionObject.id}`)
            .then((res) => {
                let localNextQuestionList = [...res.data.next_questions];
                setNextQuestionsList(localNextQuestionList);
            })
            .catch((err) => {
                console.log(err.message);
            })
    }
    
    const renderOptionDesign = () => {
        switch (questionObject.question_type) {
            case 'SINGLE_SELECT':
                return <img src={radioIcon} />
            case 'MULTI_SELECT':
                return <img src={checkboxIcon} />
            default:
                return <></>
        }
    }

    const changeSkipFlow = (val, option) => {
        console.log(val)
        let existingExpIndex;
        let copy = { ...questionObject };
        let existingExpFound = false;
        

        if (copy.conditional_expressions.length > 0)
            copy.conditional_expressions.forEach((exp, expIndex) => {
                if (exp.expression.value[0] == option) {
                    existingExpIndex = expIndex;
                    existingExpFound = true;
                }
            })

        if (existingExpFound)
            copy.conditional_expressions.splice(existingExpIndex, 1);

        if (val) {
            let obj = {
                "type":questionObject.question_type==="MATRIX_SELECT"||questionObject.question_type==="TEXT_INPUT" ? "DEFAULT_TRUE":"SIMPLE_CONDITION",
                "next_question_id": val,
                "expression": {
                    "question_id": questionObject.id,
                    "comparison": "IS_EXACTLY",
                    "value": [
                        option
                    ]
                }
            }
            if(questionObject.question_type==="MATRIX_SELECT"||questionObject.question_type==="TEXT_INPUT"){
                // console.log("text input",copy.conditional_expressions)
                copy.conditional_expressions=[obj]
            }else{
                copy.conditional_expressions.push(obj);
            }
        }

        if (!val) {
            let copy = { ...optionObj };
            delete copy[option];
            setOptionObj({ ...copy });
        }

        setQuestionObject({ ...copy });
        let copyOptionObj={...optionObj}
        copyOptionObj[option]=val
        setOptionObj(copyOptionObj)
    }

    const sum=(arr)=>{
       return (arr.reduce((e,a)=>{
            return e+a
        },0))
    }

    const saveSkipFlow = () => {
        // console.log(questionObject)
        if(questionObject.question_type==="MULTI_SELECT"){
            if(sum(Object.values(questionObject.options_select_percentage))!==100){
                // alert("sum of values is not equal to 100")
                setShow(true)
                return
            }   
        }
        
        axios.put(surveyUrl + `/smyttenresearch/question/${questionObject.id}`, questionObject)
            .then((res) => {

                setParentQuestionObject(res.data);
                // console.log(res.data)
                changeSkipFlowShow(false);
            })
            .catch((err) => {
                changeSkipFlowShow(false);
            })
    }
    const setTargetVaues=(i,e)=>{
        
        let obj={...target}
        obj[i]=parseInt(e.target.value)
        
        setTarget(obj)
        
        
    }
    
    console.log(questionObject)
    // console.log(optionObj)
    useEffect(()=>{
        setQuestionObject({...questionObject,options_select_percentage:  target})
    },[target])


    if(questionObject.question_type==="MATRIX_SELECT"||questionObject.question_type==="TEXT_INPUT"){
        return (
                <>
               
                            <div className='skip-row'>
                                        
                                        <div className='smo-indivOption' style={{border:"none"}}>
                                        <div>Select Next Question</div>
                                        </div>
                                        <div><img src={skipBranchIcon} /></div>
                                        <div className='skip-row-navigate matrix_select'>
                                            <div className='skip-row-navigate-question'>
                                                <Select bordered={false} style={{ width: "50%" }} value={test !== '' ? test : optionObj["next"]} onChange={(val) => {
                                                    setTest(val)
                                                    changeSkipFlow(val, "next")}}>
                                                    {nextQuestionsList.map((nextQuestion) => {
                                                        return <option value={nextQuestion.id}>{nextQuestion.question_text}</option>
                                                    })}
                                                </Select>

                                            </div>
                                        </div>
                            </div>
                            <div className='buttonContainer'>
                                <button className='questionBox-cancel' onClick={() => changeSkipFlowShow(false)} >Cancel</button>
                                <button className='saveQuestion' onClick={() => saveSkipFlow()}  >Save</button>
                            </div>
                </>
        )
    }

    return (
        <>
            <div className='smo-options-col' style={{ marginTop: "2rem" }}>
                {
                    (questionObject.options.length > 0)
                        ?
                        <>
                            {questionObject.options.map((option, index) => {

                                return (
                                    <div className='skip-row'>
                                        <div className='smo-indivOption'>
                                            <div className='alignCenter-row' style={{ width: "100%" }}>
                                                {renderOptionDesign()}
                                                &nbsp;&nbsp;
                                                <div style={{ width: "100%", textAlign: "left" }} key={index} >{option}</div>
                                            </div>
                                        </div>
                                        <div><img src={skipBranchIcon} /></div>
                                        <div className='skip-row-navigate'>
                                            <div className='skip-row-navigate-question'>
                                                <Select bordered={false} style={{ width: "100%" }} value={optionObj[option]} onChange={(val) => changeSkipFlow(val, option)}>
                                                    {nextQuestionsList.map((nextQuestion) => {
                                                        return <option value={nextQuestion.id}>{nextQuestion.question_text}</option>
                                                    })}
                                                </Select>

                                            </div>

                                           { questionObject.question_type==="MULTI_SELECT"&&<div className='target'>
                                                {/* <Input type='number'   value={target[option]} onChange={(e)=>{setTargetVaues(option,e)}} /> */}
                                                {/* <p>%</p> */}
                                                <input type='number' max={100} min={1}   value={target[option]} onChange={(e)=>{setTargetVaues(option,e)}} />
                                            </div>}

                                            <div className='skip-row-navigate-cancel' onClick={() => {
                                                changeSkipFlow(null, option)
                                            }}>
                                                <CloseOutlined />
                                            </div>
                                        </div>
                                    </div>
                                )
                            })}
                        </>
                        :
                        null
                }
            </div>
            <div className='buttonContainer'>
                <button className='questionBox-cancel' onClick={() => changeSkipFlowShow(false)} >Cancel</button>
                <button className='saveQuestion' onClick={() => saveSkipFlow()}  >Save</button>
            </div>
            {show && <AlertModal setShow={setShow} show={show}/>}
            {/* <AlertModal/> */}
        </>
    )
}

export default SkipLogic