import axios from 'axios';
import React from 'react'
import './style.css';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { Input } from 'antd';
import configData from '../../../../../config/config.json';
import checkEnv, { checkSurveyEnv } from '../../../../../utils/checkEnv';


function SectionCreator(props) {
    const url=checkEnv()
    const base_url = configData.base_url;
    const surveyUrl=checkSurveyEnv()
    const getSurveyDetails = props.getSurveyDetails;
    const changeNewSectionDisplay = props.changeNewSectionDisplay;
    const sectionIndex = props.newSectionIndex;

    const createSection = (e) => {
        e.preventDefault();

        axios.post(surveyUrl + '/smyttenresearch/surveysection/', {
            "title": e.target.elements[0].value,
            "survey_id": props.surveyId,
            "previous_section_id": props.previousSectionId
        })
            .then(() => {
                getSurveyDetails();
                changeNewSectionDisplay(false);
            })
            .catch((err) => {
                getSurveyDetails();
            })
    }
    return (
        <div className='sectionCreator' key={Math.random()}>
            <form className='rn-sectionCreator' onSubmit={createSection}>
                <div className='sc-sectionNumber'>S{sectionIndex}</div>
                <Input autoFocus bordered={false} suffix={<><div style={{ cursor: "pointer" }} onClick={() => document.getElementById('createSectionButton').click()}><CheckOutlined /></div><div style={{ cursor: "pointer" }} onClick={() => { changeNewSectionDisplay(false); getSurveyDetails(); }}><CloseOutlined /></div></>} className='newSectionInput' type={'text'} placeholder='Type here..' required />
                <input id='createSectionButton' type='submit' hidden />
            </form>
        </div>
    )
}

export default SectionCreator