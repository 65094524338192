import React, { useEffect, useState } from 'react'
import "./CompetitorAnalysisTable.css"
import axios from 'axios'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faArrowUp,
    faArrowDown
} from "@fortawesome/free-solid-svg-icons";
import { useSearchParams } from 'react-router-dom'
import { Skeleton } from 'antd';
import CompetitiveAnalysisTopWords from '../CompetitveAnalysisTopWords/CompetitiveAnalysisTopWords';
import checkEnv from '../../../utils/checkEnv';
export default function CompetitorAnalysisTable(props) {
    const [nextState, setNextState] = useState(false)
    const [competitorTableData, setCompetitorTableData] = useState([])
    const [searchParams, setSearchParams] = useSearchParams()
    const [state,setState]=useState(false)
    let token = localStorage.getItem("token")
    let startDate = localStorage.getItem('my_start_date')
    let endDate = localStorage.getItem('my_end_date')
    let url=checkEnv()

    useEffect(() => {
        setCompetitorTableData([])
        axios.get(`${url}/brandsdashboardproxy/v1/brand/${searchParams.get('brand')}/pickupratecitywise/?startDate=${startDate}&endDate=${endDate}&categoryIds=${searchParams.get('categoryIds')}&stateName=${props.selectedState}`, {
            headers: {
                'authorization': `${token}`
            }
        })
            .then(res => {
                setCompetitorTableData(res.data)
                console.log(res.data)
                if(res.data.length>0){
                    setState(true)
                }
            })
            .catch(err => {
                console.log(err)
            })
    }, [searchParams, props.selectedState])

    useEffect(() => {
        if (props.nextState === true && competitorTableData.length !== 0) {
            setNextState(true)
        } else {
            setNextState(false)
        }
    }, [props.nextState && competitorTableData.length])
    console.log(competitorTableData)

    return (
        <>
            <div className='competitiveAnalysisTableSection'>
                <CompetitiveAnalysisTopWords nextState={nextState} state={state} length={competitorTableData.length} selectedState={props.selectedState} />
            </div>
            <div className='myflex-column'>
                <div className='maps-header-text margin-bottom-16'>Competetive Ranking </div>
                {
                competitorTableData.length !== 0 ?
                        <div className='competitiveAnalysisTableSection1'>

                            <table>
                                <thead>
                                    <tr>
                                        <th>
                                            S.NO
                                        </th>
                                        <th>
                                            <div style={{ paddingLeft: "1rem", textAlign: "left" }}>
                                                City Name
                                            </div>
                                        </th>
                                        <th>
                                            Trial Intent Rank
                                        </th>
                                        {/* <th>
                                            Change
                                        </th> */}
                                        <th>
                                            Product Feedbacks Rank
                                        </th>
                                        {/* <th>
                                            Change
                                        </th> */}
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        competitorTableData.map((d, index) => {
                                            return <tr>
                                                <td>
                                                    {index + 1}
                                                </td>
                                                <td>
                                                    <div style={{ paddingLeft: "2rem", textAlign: "left" }}>
                                                        {d.region_name}
                                                    </div>
                                                </td>
                                                <td>
                                                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }} >
                                                        {d.pickup_rate}
                                                        {
                                                            d.pickup_rate_change
                                                                ?
                                                                < div className={d.pickup_rate_change > 0 ? 'ca-rankBox-status ca-rankBox-status-positive' : d.pickup_rate_change < 0 ? 'ca-rankBox-status ca-rankBox-status-negative' : null} style={{ width: "54px", height: "20px" }}>
                                                                    {(-100 < d.pickup_rate_change && d.pickup_rate_change < 100) && Math.sign(d.pickup_rate_change) !== 0 ? d.pickup_rate_change : '--'}
                                                                    {(-100 < d.pickup_rate_change && d.pickup_rate_change < 100) && Math.sign(d.pickup_rate_change) === 1 ? <FontAwesomeIcon className='competitiveUpArrow' style={{ color: `green` }} icon={faArrowUp} /> : ''}
                                                                    {(-100 < d.pickup_rate_change && d.pickup_rate_change < 100) && Math.sign(d.pickup_rate_change) === -1 ? <FontAwesomeIcon className='competitveDownArrow' style={{ color: `red` }} icon={faArrowDown} /> : ''}
                                                                </div>
                                                                :
                                                                <div style={{ width: "54px", height: "20px" }} />
                                                        }
                                                    </div>
                                                </td>
                                                {/* <td>
                                                    {(-100 < d.pickup_rate_change && d.pickup_rate_change < 100) && Math.sign(d.pickup_rate_change) !== 0 ? d.pickup_rate_change : '--'}
                                                    {(-100 < d.pickup_rate_change && d.pickup_rate_change < 100) && Math.sign(d.pickup_rate_change) === 1 ? <FontAwesomeIcon className='competitiveUpArrow' style={{ color: `green` }} icon={faArrowUp} /> : ''}
                                                    {(-100 < d.pickup_rate_change && d.pickup_rate_change < 100) && Math.sign(d.pickup_rate_change) === -1 ? <FontAwesomeIcon className='competitveDownArrow' style={{ color: `red` }} icon={faArrowDown} /> : ''}
                                                    {(-100 < d.pickup_rate_change && d.pickup_rate_change < 100) && Math.sign(d.pickup_rate_change) === 0 ? '--' : ''}
                                                </td> */}
                                                <td>
                                                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }} >
                                                        {d.nps}
                                                        {
                                                            d.nps_rate_change
                                                                ?
                                                                <div className={d.nps_rate_change > 0 ? 'ca-rankBox-status ca-rankBox-status-positive' : d.nps_rate_change < 0 ? 'ca-rankBox-status ca-rankBox-status-negative' : null} style={{ width: "54px", height: "20px" }}>
                                                                    {(-100 < d.nps_rate_change && d.nps_rate_change < 100) && Math.sign(d.nps_rate_change) !== 0 ? d.nps_rate_change : '--'}
                                                                    {(-100 < d.nps_rate_change && d.nps_rate_change < 100) && Math.sign(d.nps_rate_change) === 1 ? <FontAwesomeIcon className='competitiveUpArrow' style={{ color: `green` }} icon={faArrowUp} /> : ''}
                                                                    {(-100 < d.nps_rate_change && d.nps_rate_change < 100) && Math.sign(d.nps_rate_change) === -1 ? <FontAwesomeIcon className='competitveDownArrow' style={{ color: `red` }} icon={faArrowDown} /> : ''}
                                                                </div>
                                                                :
                                                                <div style={{ width: "54px", height: "20px" }} />
                                                        }

                                                    </div>
                                                </td>
                                                {/* <td>
                                                    {(-100 < d.nps_rate_change && d.nps_rate_change < 100) && Math.sign(d.nps_rate_change) !== 0 ? d.nps_rate_change : '--'}
                                                    {(-100 < d.nps_rate_change && d.nps_rate_change < 100) && Math.sign(d.nps_rate_change) === 1 ? <FontAwesomeIcon className='competitiveUpArrow' style={{ color: `green` }} icon={faArrowUp} /> : ''}
                                                    {(-100 < d.nps_rate_change && d.nps_rate_change < 100) && Math.sign(d.nps_rate_change) === -1 ? <FontAwesomeIcon className='competitveDownArrow' style={{ color: `red` }} icon={faArrowDown} /> : ''}
                                                </td> */}
                                            </tr>
                                        })
                                    }
                                </tbody>
                            </table>
                        </div>
                        :
                        <div className="competitiveGraphSkeleton">
                            <Skeleton
                                active
                                title
                                paragraph={{
                                    rows: 7,
                                }} />
                        </div>
                }
            </div>

        </>
    )
}
