import React, { useState, useEffect } from 'react'
import "./PreviewSubmit.css"
import axios from 'axios'
import { useParams } from 'react-router'
import { useSearchParams } from 'react-router-dom'
import PreviewQuestionBox from './PreviewQuestionBox'
import { Modal, Skeleton } from 'antd'
import ResearchPageSubmitted from '../../../../ResearchSubmittedPage/ResearchPageSubmitted'
import TestUserImport from '../TestUserImport'
import { checkSurveyEnv } from '../../../../../utils/checkEnv'

export default function PreviewSubmit() {
    let colors = ['#4d51c9', '#B061FF', '#C1666B']
    const { surveyId } = useParams()
    const [searchParams, setSearchParams] = useSearchParams()
    const [surveyDetails, setSurveyDetails] = useState();
    const [modalOpen, setModalOpen] = useState(false);
    const surveyUrl=checkSurveyEnv()

    const getSurveyDetails = () => {
        axios.get(`${surveyUrl}/smyttenresearch/survey/${surveyId}`)
            .then((res) => {
                setSurveyDetails(res.data);
            })
            .catch((err) => {
                console.log(err.message);
            })
    }
    useEffect(() => {
        getSurveyDetails()
    }, [searchParams])

    return (
        <>
            {
                surveyDetails
                    ?
                    <div className='previewSubmitSection'>
                        <div className='previewSubmitSectionHeader'>
                            <div className='previewSubmitHeaderFirstSection'>
                                <div className='previewSubmitFirstSectionHeader'>
                                    {surveyDetails.title ? surveyDetails.title : ''}
                                </div>
                                <p>{surveyDetails.sub_title ? surveyDetails.sub_title : ''}</p>
                            </div>
                            <div className='previewSubmitHeaderSecondSection'>
                                <div className='previewSubmitTime'>
                                    <p className='timeTaken'>
                                        03.00
                                    </p>
                                    <p className='timeHeader'>
                                        Time
                                    </p>
                                </div>
                                <div className='previewSubmitSections'>
                                    <p className='totalSections'>
                                        {surveyDetails.number_of_sections ? surveyDetails.number_of_sections : ""}
                                    </p>
                                    <p className='sectionHeader'>
                                        Sections
                                    </p>
                                </div>
                                <div className='previewSubmitQuestions'>
                                    <p className='totalNumberQuestions'>
                                        {surveyDetails.number_of_questions ? surveyDetails.number_of_questions : ''}
                                    </p>
                                    <p className='totalNumberHeader'>
                                        Questions
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className='previewSubmitMiddleSectionBody'>
                            {
                                surveyDetails.sections.map((section, index) => {
                                    return (
                                        <div className='previewSubmitSectionsData'>
                                            <div className='previewSubmitSectionMiddle'>
                                                <div className='previewSubmitMiddleSectionTitle'>
                                                    <div className='previewSubmitSectionTitleHeader' style={{ backgroundColor: `${colors[index % colors.length]}` }}>
                                                        {section.title}
                                                    </div>
                                                    <div className='previewSubmitSectionTitleTotalQuestions'>
                                                        <p className='sectionTotalQuestions'>
                                                            {section.questions.length}
                                                        </p>
                                                        <p className='totalNumberHeader'>
                                                            Questions
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='section' id={section.id} key={section.id} >
                                                {
                                                    (section.questions.length > 0)
                                                        ?
                                                        <div className='section-body'>
                                                            {
                                                                section.questions.map((question, questionIndex) => {
                                                                    return <PreviewQuestionBox question={question} questionIndex={questionIndex} />
                                                                })
                                                            }
                                                        </div>
                                                        :
                                                        ""
                                                }
                                            </div>
                                        </div>
                                    )
                                })
                            }

                        </div>

                        {/* <div className='previewSubmitDoneSection'>
                                        <button className='previewDoneButton' onClick={() => setModalOpen(true)}>
                                            Done
                                        </button>

                                    </div> */}
                        <div style={{
                            display: "flex",
                            justifyContent: "flex-end"
                        }}>
                            <TestUserImport />&nbsp;&nbsp; <ResearchPageSubmitted />
                        </div>
                    </div>
                    :
                    <div className="brandsVsCategorySkeleton"> <Skeleton
                        active
                        title
                        paragraph={{
                            rows: 8,
                        }} />
                    </div>
            }
        </>
    )
}
