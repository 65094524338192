import React from 'react';
import BasketMiniCard from './BasketMiniCard';


export default function BasketCard({data}) {

    
    // const convertData=(arr)=>{
    //     let str=''
    //     arr.forEach(element => {
    //         str+=`,${element}`
    //     });
    //     return str.slice(1)
    // }
  return (
    <>
        <div className='basket-card-container'>
            <div className='basket-card-header'>
                {data.categoryName&& <h2 className='header-text'>{data.categoryName}</h2>}
            </div>
            <div className='basket-card-body'>
                {data.subCategories.slice(0,3).map((subc,index)=>{
                    return <BasketMiniCard data={subc} key={index} index={index}/>
                })}
            </div>
        </div>  
    </>
  )
}
