import { useEffect, useState } from "react";
import { Chart as ChartJs, Tooltip, Title, ArcElement, Legend } from "chart.js";
import { Doughnut, Pie } from "react-chartjs-2";
import "./ConsumerPieChart.css";
import axios from "axios";
import UsersAddingCart from "./UsersDontProceed/UsersAddingCart";
import DataLoader from "../images/dataload_gif.gif"
import { useSearchParams } from "react-router-dom";
import {Skeleton} from "antd"
import checkEnv from "../../utils/checkEnv";
ChartJs.register(Tooltip, Title, ArcElement, Legend);

function ConsumerPieChart(props) {
  const [searchParams, setSearchParams] = useSearchParams()
  const [nextState,setNextState] = useState(false)
  let token = localStorage.getItem("token")
  let brandId = props.brandId
  let startDate = localStorage.getItem("start_date");
  let endDate = localStorage.getItem("end_date");
  let url=checkEnv()
  const [graphData, setGraphData] = useState([])
  let categoryId = localStorage.getItem('allcategoryId') ? (localStorage.getItem('allcategoryId').split(',')) : []
  let productId = localStorage.getItem('allProductId') ? localStorage.getItem('allProductId').split(',') : []

  const getProductTrendUsers = async () => {
    setGraphData([])
    const response = await axios.get(`${url}/brandsdashboardproxy/v1/brand/${searchParams.get('brand')}/producttrendsusers/?startDate=${startDate}&endDate=${endDate}&categoryIds=${searchParams.get('categoryIds')}&productIds=${searchParams.get('productIds')}`, {
      headers: {
        'authorization': `${token}`
      }
    })
      .catch((err) => {
        console.log(err);
      });
    setGraphData(response.data);
  }
  useEffect(() => {
    getProductTrendUsers();
  }, [searchParams]);

  const [graph, setGraph] = useState({
    labels: [],
    data: [],
  });

  useEffect(() => {
    const labels = [];
    const data = [];
    graphData.map((v) => {
      labels.push(v.name);
      data.push(v.count);
    });
    setGraph({
      labels: labels,
      data: data,
    });
  }, [graphData]);

  const data = {
    labels: graph.labels,
    datasets: [
      {
        label: "# of Votes",
        data: graph.data,
        backgroundColor: [
          "rgb(0, 48, 135)",
          "rgb(208, 235, 249)",
          "rgb(19, 115, 93)",
          "rgb(31, 120, 180)",
          "rgb(136, 187, 145)",
          "rgb(255, 207, 97)",
          "rgb(139, 47, 138)",
          "rgb(247, 110, 32)",
          "rgb(255, 115, 97)",
          "rgb(190, 37, 37)",
        ],
        borderWidth: "",
      },
    ],
  };

  useEffect(() => {
    if (props.nextState === true && graphData.length !== 0) {
      setNextState(true)
    } else {
      setNextState(false)
    }
  }, [props.nextState, graphData])


  const createGrid=()=>{
    console.log(graphData)
    let arr=[]
    graphData.forEach((datas,index)=>{
       
      if(index<10){
        arr.push(
          <div className="pieDetails">
            <div className="name">
              <span>{index + 1}.</span>
              <p>{datas.name}</p>
            </div>
            <div className="colorBoxValues">
              <span className={"colorBox" + index}></span>
              <h6>{(datas.count).toFixed(1)}%</h6>
            </div>
          </div>
        )
      }
      
    })
    return arr;
  }

  return (
    <div>
      <div className="pie">
        <div className="pieChartHeading">
          Trends Users who Tried your product are Interested in
        </div>
        {
          props.nextState === true && graphData.length !== 0 ?
            <div className="pieChartDetails">
              <div className="ConsumerPieChart" >
                <Pie
                  data={data}
                  options={{
                    plugins: {
                      legend: {
                        display: false,
                      },
                      datalabels: {
                        display: false,
                      },
                    },
                  }}
                />
              </div>
              {/* <div className="pieData">
                <div>

                  {graphData.map((datas, index) => {
                    if (index <= 4) {
                      return (
                        <div className="pieDetails" key={index}>
                          <div className="name">
                            <span>{index + 1}</span>
                            <p>{datas.name}</p>
                          </div>
                          <div className="colorBoxValues">
                            <span className={"colorBox" + index}></span>
                            <h6>{(datas.count).toFixed(2)}%</h6>
                          </div>
                        </div>
                      );
                    }

                  })}
                </div>
                <div>
                  {graphData.map((datas, index) => {
                    if (index > 4 && index <= 9) {
                      return (
                        <div className="pieDetails" key={index}>
                          <div className="name">
                            <span>{index + 1}</span>
                            <p>{datas.name}</p>
                          </div>
                          <div className="colorBoxValues">
                            <span className={"colorBox" + index}></span>
                            <h6>{(datas.count).toFixed(2)}%</h6>
                          </div>
                        </div>
                      );
                    }

                  })}
                </div>
              </div> */}
              <div className="pieData">
                {createGrid()}
              </div>
            </div>
            : <div className="brandsVsCategorySkeleton"> <Skeleton
            active
            title
            paragraph={{
              rows: 8,
            }} />
            </div>
        }
      </div>
      <UsersAddingCart brandId={props.brandId} allChecked={props.allChecked}
        allProductsChecked={props.allProductsChecked}
        singleCheck={props.singleCheck} singleProductCheck={props.singleProductCheck} nextState = {nextState}/>
    </div>
  );
}

export default ConsumerPieChart;
