import "./App.css";
import Header from "./components/Header";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import ConsumerHeader from "./components/consumerProfile/ConsumerHeader";
import RatingsAndReviewHeader from "./components/RatingsAndReview/RatingsAndReviewHeader";
import ProductHeader from "./components/product/ProductHeader";
import StateEventHeader from "./components/StateEvent/StateEventHeader";
import ProductLevelHeader from "./components/ProductlevelComponents/ProductLevelHeader";
import UserSignIn from "./components/UserSignIn/UserSignIn";
// import MobilePageView from "./components/MobilePageView/MobilePageView";
import ResearchWrapper from "./components/ResearchNew/ResearchWrapper";
import SurveyStepper from "./components/ResearchNew/SurveyStepper/wrapper.js";
import ResearchDashboard from "./components/ResearchNew/ResearchDashboard/wrapper.js";
import CompetitiveAnalysisHeader from "./components/CompetitiveAnalysis/CompetitiveAnalysisHeader";
import ResultAnalysisHead from "./components/ResearchNewResultAnalysis/ResultAnalysisHead";
import ForgotPassword from "./components/UserSignIn/ForgotPassword/ForgotPassword";
import CreateNewHeader from "./components/ResearchNew/new_survey/create_new/CreateNewHeader";
// import ConditionalLogicWrapper from "./components/ConditionalLogic/ConditionalLogicWrapper";

function App() {
  console.log(process.env);
  return (
    <div className="App">
      <BrowserRouter>
        {/* <div className="mobileView">
          <MobilePageView />
        </div> */}
        <div className="main">
          <Routes>
            <Route path="/" element={<UserSignIn />} />
            <Route path="/brandsdashboard" element={<UserSignIn />} />
            <Route
              path="/brandsdashboard/forgotPassword"
              element={<ForgotPassword />}
            />
            <Route
              path="/brandsdashboard/forgotPassword/confirmation"
              element={<ForgotPassword />}
            />
            <Route
              path="/brandsdashboard/performance/:stateDetails"
              element={<StateEventHeader />}
            />
            <Route path="/brandsdashboard/performance" element={<Header />} />
            <Route
              path="/brandsdashboard/consumer"
              element={<ConsumerHeader />}
            />
            <Route
              path="/brandsdashboard/ratings"
              element={<RatingsAndReviewHeader />}
            />
            <Route
              path="/brandsdashboard/product"
              element={<ProductHeader />}
            />
            <Route
              path="/brandsdashboard/ratings/:productDetails"
              element={<ProductLevelHeader />}
            />
            <Route
              path="/brandsdashboard/research"
              element={<ResearchWrapper />}
            />
            <Route
              path="/brandsdashboard/research/dashboard"
              element={<ResearchDashboard />}
            />
            <Route
              path="/brandsdashboard/research/surveys"
              element={<SurveyStepper />}
            />
            <Route
              path="/brandsdashboard/research/surveys/:surveyId/"
              element={<SurveyStepper />}
            />
            {/* <Route path="/brandsdashboard/research/surveys/:surveyId/:stepFromParams" element={<SurveyStepper />} /> */}
            {/* <Route path="/brandsdashboard/research/surveys/:stepFromParams" element={<SurveyStepper />} /> */}
            {/* <Route path="/brandsdashboard/research/surveys/:surveyId/logic/:questionId" element={<ConditionalLogicWrapper />} /> */}
            <Route
              path="/brandsdashboard/competitoranalysis"
              element={<CompetitiveAnalysisHeader />}
            />
            <Route
              path="/brandsdashboard/research/resultAnalysis/:surveyId/"
              element={<ResultAnalysisHead />}
            />


            <Route 
            path='/brandsdashboard/research/instantiatesurvey'
            element={<CreateNewHeader/>}
            />
          </Routes>
        </div>
      </BrowserRouter>
    </div>
  );
}

export default App;
