import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import researchUserWelcome from "../images/research_user_welcome.svg";
import researchWelcomeHead from "../images/researchWelcome_head.svg";
import researchWelcomeFooter from "../images/researchWelcome_footer.svg";

import "./style.css";
import SurveyStepper from "./SurveyStepper";
import GridLoader from "react-spinners/GridLoader";
import moment from "moment";
import checkEnv, { checkSurveyEnv } from "../../utils/checkEnv";
import SurveyHomepage from "./new_survey/SurveyHomepage";
import CreateNew from "./new_survey/create_new/CreateNew";
import CreateModal from "./ResearchDashboard/createmodal/CreateModal";

function ResearchNew() {
  const [searchParams, setSearchParams] = useSearchParams();
  const [modalShow, setModalShow] = useState(false);
  const [surveyList, setSurveyList] = useState();
  const navigate = useNavigate();
  let surveyUrl=checkSurveyEnv()
  const selectedBrand = localStorage.getItem("brand_id");
  let startDate = localStorage.getItem("start_date")
    ? localStorage.getItem("start_date")
    : localStorage.setItem(
        "start_date",
        moment().subtract(6, "days").format("YYYY-MM-DD")
      );
  let endDate = localStorage.getItem("end_date")
    ? localStorage.getItem("end_date")
    : localStorage.setItem("end_date", moment().format("YYYY-MM-DD"));
  let url = checkEnv();
  
  const getSurveyList = () => {
    axios
      .get(
        `${surveyUrl}/smyttenresearch/survey/?brand_id=${searchParams.get(
          "brand"
        )}`,
        {}
      )
      .then((res) => {
        console.log("I got resp")
        setSurveyList(res.data);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    console.log("Hi i am here");
    getSurveyList();
  }, []);

  useEffect(() => {
    getSurveyList();
  }, [searchParams]);



  // return (
  //   <div className="researchDashboardBody-new-loader">
  //       {/* <GridLoader color={"#003087"} /> */}
  //       <button onClick={()=>setModalShow(true)}>launch</button>
  //       <CreateModal  show={modalShow} onHide={()=>setModalShow(false)}/>
  //   </div>
  // )


  if (surveyList) {
    if (surveyList.length > 0)
      navigate({
        pathname: "/brandsdashboard/research/dashboard",
        search: `?brand=${localStorage.getItem("brand_id")}&categoryIds=${
          localStorage.getItem("allcategoryId")
            ? localStorage.getItem("allcategoryId").split(",")
            : []
        }&productIds=${
          localStorage.getItem("allProductId")
            ? localStorage.getItem("allProductId").split(",")
            : []
        }&date=${startDate}to${endDate}`,
      });
    else
      return (
        <div className="researchDashboardBody-new">
          {/* <img src={researchWelcomeHead} />
                    <div className='researchWelcome'>
                        <img src={researchUserWelcome} />
                    </div>
                    <div style={{ marginBottom: "2rem" }}>
                        <img src={researchWelcomeFooter} />
                    </div>
                    <button className='setupFirstSurvey' onClick={() => {
                        // navigate('/brandsdashboard/research/surveys');
                        navigate({
                            pathname: '/brandsdashboard/research/surveys',
                            search: `?brand=${localStorage.getItem("brand_id")}&categoryIds=${localStorage.getItem('allcategoryId') ? (localStorage.getItem('allcategoryId').split(',')) : []}&productIds=${localStorage.getItem('allProductId') ? localStorage.getItem('allProductId').split(',') : []}&date=${startDate}to${endDate}`
                        })
                    }}>
                        SETUP YOUR SURVEY
                    </button> */}

          <SurveyHomepage />
        </div>
      );
  } else
    return (
      <div className="researchDashboardBody-new-loader">
        <GridLoader color={"#003087"} />
        {/* <button onClick={()=>setModalShow(true)}>launch</button>
        <CreateModal show={modalShow} onHide={()=>setModalShow(false)}/> */}
      </div>
    );

  // if (!setupFirstSurvey) {

  //     if (allSurveys.length < 0)

  //         return (
  //             <div className='researchDashboardBody'>
  //                 Display List Of Surveys
  //                 {console.log(allSurveys)}
  //             </div>
  //         )
  //     else
  //         return (
  //             <div className='researchDashboardBody-new'>

  //                 <img src={researchWelcomeHead} />
  //                 <div className='researchWelcome'>
  //                     <img src={researchUserWelcome} />
  //                 </div>
  //                 <div style={{ marginBottom: "2rem" }}>
  //                     <img src={researchWelcomeFooter} />
  //                 </div>
  //                 <button className='setupFirstSurvey' onClick={() => {
  //                     changeSetupFirstSurveyState(true);
  //                 }}>
  //                     SETUP YOUR SURVEY
  //                 </button>
  //             </div>
  //         )
  // }
  // else {
  //     return <SurveyStepper />
  // }
}

export default ResearchNew;
