import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useState } from 'react'
import "./StandardTemplate.css";

import Spinner from 'react-bootstrap/Spinner';

import { faCheck } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import { checkSurveyEnv } from '../../../../../utils/checkEnv';


// import { faXmark } from '@fortawesome/free-solid-svg-icons';
// import { useNavigate } from 'react-router';
// import moment from "moment"

export default function StandardTemplate({templateId}) {
    const [data,setData]=useState({})
    const surveyUrl=checkSurveyEnv()
    const [keyarr,setKeyArr]=useState([])

    useEffect(()=>{
        axios.get(`${surveyUrl}/smyttenresearch/surveytemplate/${templateId}`)
        .then(res=>{
            
            setData(res.data)
            setKeyArr(Object.keys(res.data))
        })
        .catch(err=>{
            console.log(err)
        })
    },[templateId,surveyUrl])
    
   
    if(keyarr.length===0){
        return(
            <div className="standard-template-container">
              
                
                <Spinner animation="grow" variant="primary" />
                <Spinner animation="grow" variant="secondary" />
                <Spinner animation="grow" variant="success" />
                <Spinner animation="grow" variant="danger" />
                <Spinner animation="grow" variant="warning" />
                <Spinner animation="grow" variant="info" />
                <Spinner animation="grow" variant="dark" />
            </div>
        )
    }
    
  return (
    
     <div className='standard-template-container'>
        {data.title&&<div className='standard-template-header'>
            <p>{data.title}</p>
        </div>}
        {data.detailed_description && <div className='standard-template-description'>
            <p>{data.detailed_description}</p>
        </div>}
        {data.bullet_points&&<div className='lists'>
            {data.bullet_points.map((point,i)=>{
                return <div key={templateId+i} className='list'>
                    <FontAwesomeIcon style={{color:"green",scale:"1.5"}} icon={faCheck}></FontAwesomeIcon>
                    <p>{point}</p>
                </div>
            })}
            
        </div>}

    </div>
    
  )
}
