import React, { useEffect, useState } from 'react';
import './style.css';
import configData from '../../../../../config/config.json'
import axios from 'axios';
import { useParams } from 'react-router';
import addSectionIcon from '../../../../images/addSectionIconNew.svg';
import SectionQuestionnaire from '../SectionQuestionnaire';
import SectionCreator from '../SectionCreator';
import Loader from 'react-spinners/BarLoader';
import SectionBox from '../SectionBox';
import { PropertySafetyFilled } from '@ant-design/icons';
import { checkSurveyEnv } from '../../../../../utils/checkEnv';

function SurveyQuestionnaireSetup({changeStep}) {
    const base_url = configData.base_url;
    const surveyUrl=checkSurveyEnv()
    const { surveyId } = useParams();
    const [surveyDetails, setSurveyDetails] = useState();
    const [sectionIndex, setSectionIndex] = useState(0);
    const [questionList, setQuestionList] = useState([]);
    const [displayNewSection, setDisplayNewSection] = useState(false);
    let newSectionName;

    const getSurveyDetails = () => {
        axios.get(surveyUrl + `/smyttenresearch/survey/${surveyId}`)
            .then((res) => {
                setSurveyDetails(res.data);
                if (res.data.sections.length == 0)
                    changeNewSectionDisplay(true);
                else
                    setSectionIndex(res.data.sections.length - 1);
            })
            .catch((err) => {
                console.log(err);
            })
    }

    useEffect(() => {
        getSurveyDetails();
    }, [])



    const renderSectionQuestionnaire = () => {
        if (sectionIndex >= 0)
            return (
                <SectionQuestionnaire sectionIndex={sectionIndex} key={Math.random()} changeStep={changeStep} />
            )
    }

    const changeSectionIndex = (index) => {
        setSectionIndex(index);
    }

    const createSection = (e) => {
        e.preventDefault();

        axios.post(surveyUrl + '/smyttenresearch/surveysection/', {
            "title": newSectionName,
            "survey_id": surveyDetails.id,
            "previous_section_id": null,
        })
            .then(() => {
                getSurveyDetails();
            })
            .catch((err) => {
                getSurveyDetails();
            })
    }

    const generatePreviousSectionId = (sectionIndex) => {
        let previousSectionId;
        if (sectionIndex == 0)
            previousSectionId = null;
        else
            previousSectionId = surveyDetails.sections[sectionIndex - 1].id;
        return previousSectionId;
    }

    const changeNewSectionDisplay = (flag) => {
        setDisplayNewSection(flag);
    }

    if (surveyDetails)
        return (
            <div className='sc-main'>
                <div className='sc-sectionsTab'>
                    <div className='sc-addNewSection'>Sections<div onClick={() => { changeNewSectionDisplay(true); setSectionIndex(-1); }} style={{ cursor: "pointer" }}><img src={addSectionIcon} /></div></div>
                    {
                        (surveyDetails && surveyDetails.sections.length > 0)
                            ?
                            surveyDetails.sections.map((section, index) => {
                                return (
                                    <>
                                        <SectionBox sectionObject={section} index={index} sectionIndex={sectionIndex} changeSectionIndex={changeSectionIndex} getSurveyDetails={getSurveyDetails} />

                                    </>
                                )
                            })
                            :
                            null
                    }
                    {displayNewSection
                        ?
                        <SectionCreator newSectionIndex={((surveyDetails.sections.length) + 1)} changeNewSectionDisplay={changeNewSectionDisplay} previousSectionId={surveyDetails.sections.length == 0 ? generatePreviousSectionId(0) : generatePreviousSectionId(surveyDetails.sections.length)} getSurveyDetails={getSurveyDetails} surveyId={surveyDetails.id} />
                        :
                        null
                    }
                </div>
                <div className='sc-questionsTab'>
                    {renderSectionQuestionnaire()}
                </div>
            </div >
        )
    else
        return <Loader />
}

export default SurveyQuestionnaireSetup