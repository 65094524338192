import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import UserProfileArrow from "../images/userProfileArrow.svg"
import {
  faBars,
  faCircle,
  faCircleInfo,
} from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import ConsumerDetails from "./ConsumerDetails";
import Dashboard from "../Dashboard";
import { useNavigate } from "react-router"
import { useSearchParams } from "react-router-dom";
import checkEnv, { checkSurveyEnv } from "../../utils/checkEnv";
import Logo from "../images/Logo.png"
import logoCoin from "../images/Final_01_1.png"
import ConvertTemplatized1 from "../modal/ConvertTemplatized1"
let currentId;
const ConsumerHeader = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  let startDate = searchParams.get('date').split('to')
  let brand = localStorage.getItem("brand_id");
  let token = localStorage.getItem("token");
  let userName = localStorage.getItem('userName')
  const [learnMore, setLearnMore] = useState(false);

  let url=checkEnv()
  let surveyUrl=checkSurveyEnv()
  const [brands, setBrands] = useState([]);
  const [userState, setUserState] = useState(false)
  const [creditBalance,setCreditBalance] = useState('')
  const navigate = useNavigate()
  let search =searchParams.get('brand') !== 'null' ? window.location.search : '';
  const getBrands = () => {
    axios.get(`${url}/brandsdashboardproxy/v1/brand/`, {
      headers: {
        'authorization': `${token}`
      }
    })
      .then(res => {
        setBrands(res.data.map((dat) => dat))
      })
      .catch((err) => {
        console.log(search)
        getnewToken(window.location.pathname, search)
      })
  };
  useEffect(()=>{
    axios.get(`${surveyUrl}/smyttenresearch/brandcredits/${searchParams.get('brand')}`,{

    })
    .then(res=>{
     
      setCreditBalance(res.data.credits)
    })
  },[searchParams])
  const getnewToken = (path, search) => {
    axios.get(`${url}/brandsdashboardproxy/v1/auth/token`, {
      headers: {
        'authorization': `${token}`
      }
    })
      .then(res => {
        localStorage.setItem('token', res.data.token)
      })
      .catch((err) => {
        localStorage.clear()
        localStorage.setItem('previousUrl', path)
        localStorage.setItem('search', search)
        localStorage.setItem('start_date',startDate[0])
        localStorage.setItem('end_date',startDate[1])
        navigate("/brandsdashboard")
      })
  }
  useEffect(() => {
    getBrands();
  }, []);
  const [open, setOpen] = useState(false);
  const clickHandler = () => {
    setOpen(!open);
  };


  const changeHandler = (e) => {
    currentId = e.target.value;
    localStorage.setItem("brand_id", currentId);
    navigate({
      pathname: `/brandsdashboard/consumer`,
      search: `?brand=${e.target.value}&categoryIds=${[]}&productIds=${[]}&date=${searchParams.get('date')}`
    })
  };
  const MakeItem = function (X) {
    return (
      <option key={X.brandId} value={X.brandId}>
        {X.brandName}
      </option>
    );
  };

  if (open === true && document.querySelector('.section') !== null) {
    localStorage.setItem("dashBoard_bars", true)
    document.querySelector('.section').style.display = 'none'
    document.querySelector('.catData').style.marginLeft = '0px'
    document.querySelector('.boxes').style.marginLeft = '0px'
  } else if (open === false && document.querySelector('.section') && document.querySelector('.catData') && document.querySelector('.boxes') && window.innerWidth > 1200) {
    localStorage.setItem("dashBoard_bars", false)
    document.querySelector('.section').style.display = 'block'
    document.querySelector('.catData').style.marginLeft = '200px'
    document.querySelector('.boxes').style.marginLeft = '200px'
  } else if (open === false && document.querySelector('.section') && document.querySelector('.catData') && document.querySelector('.boxes') && window.innerWidth <= 1200) {
    localStorage.setItem("dashBoard_bars", false)
    document.querySelector('.section').style.display = 'block'
    document.querySelector('.catData').style.marginLeft = '50px'
    document.querySelector('.boxes').style.marginLeft = '50px'
  }
  window.addEventListener('resize', function (event) {
    if (document.querySelector('.section') && document.querySelector('.catData') && document.querySelector('.boxes') && window.innerWidth > 1200) {
      document.querySelector('.section').style.display = 'block'
      document.querySelector('.catData').style.marginLeft = '200px'
      document.querySelector('.boxes').style.marginLeft = '200px'
    } else if (document.querySelector('.section') && document.querySelector('.catData') && document.querySelector('.boxes') && window.innerWidth <= 1200) {
      document.querySelector('.section').style.display = 'block'
      document.querySelector('.catData').style.marginLeft = '50px'
      document.querySelector('.boxes').style.marginLeft = '50px'
    }
  });

  const userprofileClickHandler = () => {
    setUserState(!userState)
  }

  const logoutClickHandler = () => {
    navigate("/brandsdashboard")
    localStorage.clear()
  }

  const loginPopUpHideClickHandler = () => {
    if (userState === true) {
      setUserState(false)
    }
  }

  
  return (
    <div onClick={loginPopUpHideClickHandler}>
      <div className="header">
      <div className="header-left">
          {/* <FontAwesomeIcon
            className="hamberger"
            icon={faBars}
            onClick={clickHandler}
          /> */}
          <div className="header-left-left">
            <img className="logo-image" src={Logo} alt="logo"/>
          </div>
          <div className="header-left-right">
            <div>
                <img className="logo-image1" src={logoCoin} alt="coin"/>
            </div>
            <div className="remaining-balance-container">
              <span className="remaining-text">Remaining Balance:</span>
              {creditBalance?<span className="remaining-balance">{creditBalance} Credits</span>:<span className="remaining-balance">0 Credits</span>}
            </div>
          </div>
          <div className="learn-more">
            <FontAwesomeIcon icon={faCircleInfo} className="learn-more-icon" />
            <span className="learn-more-text" onClick={()=>{setLearnMore(true)}}>Learn More</span>
          </div>
        </div>
        <div className="headerOne">
          <div className="headerCenter">
            <select
              className="selectBrand"
              value={searchParams.get('brand')}
              onChange={(e) => {
                changeHandler(e);
              }}
            >
              {brands.map(MakeItem)}
            </select>
          </div>
          <div>
            <div className="user" onClick={userprofileClickHandler}>
              {/* <FontAwesomeIcon className="userProfile" icon={faCircle} /> */}
              <div className="userLetterProfile">{userName ? userName.slice(0, 1) : ''}</div>
              <img
                src={UserProfileArrow}
                alt="user"
              />
            </div>
            {
              userState ? <div className="userLogoutProfile">
                <div>Change Password</div>
                <div onClick={logoutClickHandler}>Logout</div>
              </div> : ""
            }
          </div>
        </div>
      </div>
      <Dashboard />
      <div className="data">
        <ConsumerDetails brandId={brand} />
      </div>
      <ConvertTemplatized1 show={learnMore} onHide={()=>setLearnMore(false)}/>

    </div>
  );
};

export default ConsumerHeader;
