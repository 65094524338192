import React, { useState, useEffect } from 'react';
import "./StateTop10Areas.css";
import axios from 'axios';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowUpShortWide, faArrowDownShortWide, faBullseye } from "@fortawesome/free-solid-svg-icons"
import Dropdown from 'react-bootstrap/Dropdown'
import DropdownToggle from "react-bootstrap/esm/DropdownToggle";
import DropdownMenu from "react-bootstrap/esm/DropdownMenu";
import { useSearchParams } from 'react-router-dom';
import DataLoader from "../../images/noDataFull.svg"
import UserWordsForState from '../UserWordsForState/UserWordsForState';
import { Skeleton } from "antd"
import rdSortUp from '../../images/rdSortUp.svg';
import rdSortDown from '../../images/rdSortDown.svg';
import checkEnv from '../../../utils/checkEnv';
function nFormatter(num) {
  if (num >= 1000000000) {
    return (num / 1000000000).toFixed(1).replace(/\.0$/, "") + "G";
  }
  if (num >= 1000000) {
    return (num / 1000000).toFixed(1).replace(/\.0$/, "") + "M";
  }
  if (num >= 1000) {
    return (num / 1000).toFixed(1).replace(/\.0$/, "") + "K";
  }
  return num;
}

export default function   StateTop10Areas(props) {
  const [noDataLoad, setNoDataLoad] = useState(false)
  const [nextState, setNextState] = useState(false)
  const [searchParams, setSearchParams] = useSearchParams()
  const brandId = props.brandId;
  let token = localStorage.getItem("token")
  const [cities, setCities] = useState([]);
  let stateName = localStorage.getItem('state_name')
  let startDate = localStorage.getItem("start_date");
  let endDate = localStorage.getItem("end_date");
  let url=checkEnv()
  let categoryId = localStorage.getItem('allcategoryId') ? (localStorage.getItem('allcategoryId').split(',')) : []
  let productId = localStorage.getItem('allProductId') ? localStorage.getItem('allProductId').split(',') : []
  const [sortState, setSortState] = useState({
    region: false,
    user_sampled: false,
    products_sampled: false,
    nps_rating: false,
  })
  const getStateCities = async () => {
    setCities([])
    const response = await axios.get(`${url}/brandsdashboardproxy/v1/brand/${searchParams.get('brand')}/topregionsforstate/?startDate=${startDate}&endDate=${endDate}&categoryIds=${searchParams.get('categoryIds')}&productIds=${searchParams.get('productIds')}&stateName=${searchParams.get(`stateName`)}`, {
      headers: {
        'authorization': `${token}`
      }
    })
      .catch((err) => {
        console.log(err);
      });
    setCities(response.data.slice(0, 10));
    response.data.length === 0 ? setNoDataLoad(true) : setNoDataLoad(false)
  };
  useEffect(() => {
    getStateCities();
  }, [searchParams]);
  const ascendingStateHandler = () => {
    cities.sort((a, b) => {
      if (a.regionName > b.regionName) {
        return 1
      } else if (b.regionName > a.regionName) {
        return -1
      } else {
        return 0
      }
    });

  }

  const descendingStateHandler = () => {
    cities.sort((a, b) => {
      if (a.regionName > b.regionName) {
        return -1
      } else if (b.regionName > a.regionName) {
        return 1
      } else {
        return 0
      }
    });

  }
  const ascendingUsersSampledHandler = () => {
    cities.sort((a, b) => {
      return a.totalSampledUsers - b.totalSampledUsers;
    });

  }
  const descendigUsersSampledHandler = () => {
    cities.sort((a, b) => {
      return b.totalSampledUsers - a.totalSampledUsers;
    });

  }
  const ascendingProductsSampledHandler = () => {
    cities.sort((a, b) => {
      return a.totalProductsSampled - b.totalProductsSampled;
    });

  }
  const descendingProductsSampledHandler = () => {
    cities.sort((a, b) => {
      return b.totalProductsSampled - a.totalProductsSampled;
    });

  }
  const ascendingNpsHandler = () => {
    cities.sort((a, b) => {
      return a.npsRating - b.npsRating;
    });

  }
  const descendingNpsHandler = () => {
    cities.sort((a, b) => {
      return b.npsRating - a.npsRating;
    });

  }
  const ascendingAddtoCartHandler=()=>{
    cities.sort((a,b)=>{
      return a.add_to_cart-b.add_to_cart
    })
  }
  const descendingAddtoCartHandler=()=>{
    cities.sort((a,b)=>{
      return b.add_to_cart-a.add_to_cart
    })
  }


  useEffect(() => {
    if (props.nextState === true && cities.length !== 0) {
      setNextState(true)
    } else {
      setNextState(false)
    }
  }, [cities, props.nextState])
// console.log("cities",cities)

  const sortSurveyList = (param, order) => {
    let sortStateCopy = { ...sortState };
    sortStateCopy[param] = !sortStateCopy[param];
    setSortState({ ...sortStateCopy });

    if (param == "region") {
      if (order == "ascending") {
        ascendingStateHandler()
      } else {
        descendingStateHandler()
      }
    }
    if (param == 'user_sampled') {
      if (order == "ascending") {
        ascendingUsersSampledHandler()
      } else {
        descendigUsersSampledHandler()
      }
    }
    if (param == 'products_sampled') {
      if (order == "ascending") {
        ascendingProductsSampledHandler()
      } else {
        descendingProductsSampledHandler()
      }
    }
    if (param == 'nps_rating') {
      if (order == "ascending") {
        ascendingNpsHandler()
      } else {
        descendingNpsHandler()
      }
    }
    if(param =="add_to_cart"){
      if (order == "ascending") {
        ascendingAddtoCartHandler()
      } else {
        descendingAddtoCartHandler()
      }
    }

  }
  return (
    <div>
      <div className='topAreas'>
        <div className='topAreasHeader'>
          <div>
            Top 10 Areas
          </div>
          {/* <Dropdown>
            <DropdownToggle className='areaSort'>
              <FontAwesomeIcon icon={faArrowUpShortWide} /> Sort By
            </DropdownToggle>
            <DropdownMenu className="sortRangeSection">
              <div className="sortLabel"><FontAwesomeIcon className="font" onClick={ascendingStateHandler} icon={faArrowDownShortWide} /><FontAwesomeIcon className="font" onClick={descendingStateHandler} icon={faArrowUpShortWide} /> Region</div>
              <div className="sortLabel"><FontAwesomeIcon className="font" onClick={ascendingUsersSampledHandler} icon={faArrowDownShortWide} /><FontAwesomeIcon className="font" onClick={descendigUsersSampledHandler} icon={faArrowUpShortWide} /> Total users Sampled</div>
              <div className="sortLabel"><FontAwesomeIcon className="font" onClick={ascendingProductsSampledHandler} icon={faArrowDownShortWide} /><FontAwesomeIcon className="font" onClick={descendingProductsSampledHandler} icon={faArrowUpShortWide} /> Total Products Sampled</div>
              <div className="sortLabel"><FontAwesomeIcon className="font" onClick={ascendingNpsHandler} icon={faArrowDownShortWide} /><FontAwesomeIcon className="font" onClick={descendingNpsHandler} icon={faArrowUpShortWide} /> NPS Rating</div>
            </DropdownMenu>
          </Dropdown> */}
        </div>
        {
          cities.length !== 0 ?
            <table >
              <thead>
                <tr >
                  <th >
                    S.No
                  </th>
                  <th onClick={() => document.getElementById('sort-region').click()}>
                    Region
                    {
                      sortState.region
                        ?
                        <img id='sort-region' src={rdSortUp} onClick={() => sortSurveyList('region', 'ascending')} />
                        :
                        <img id='sort-region' src={rdSortDown} onClick={() => sortSurveyList('region', 'descending')} />
                    }
                  </th>
                  <th onClick={() => document.getElementById('sort-user_sampled').click()}>
                    Total users Sampled
                    {
                      sortState.user_sampled
                        ?
                        <img id='sort-user_sampled' src={rdSortUp} onClick={() => sortSurveyList('user_sampled', 'ascending')} />
                        :
                        <img id='sort-user_sampled' src={rdSortDown} onClick={() => sortSurveyList('user_sampled', 'descending')} />
                    }
                  </th>
                  <th onClick={() => document.getElementById('sort-products_sampled').click()}>
                    Total Products Sampled
                    {
                      sortState.products_sampled
                        ?
                        <img id='sort-products_sampled' src={rdSortUp} onClick={() => sortSurveyList('products_sampled', 'ascending')} />
                        :
                        <img id='sort-products_sampled' src={rdSortDown} onClick={() => sortSurveyList('products_sampled', 'descending')} />
                    }
                  </th>
                  {/* <th onClick={() => document.getElementById('add_to_cart').click()}>
                    Add to Cart
                    {
                      sortState.products_sampled
                        ?
                        <img id='sort-products_sampled' src={rdSortUp} onClick={() => sortSurveyList('add_to_cart', 'ascending')} />
                        :
                        <img id='sort-products_sampled' src={rdSortDown} onClick={() => sortSurveyList('add_to_cart', 'descending')} />
                    }
                  </th> */}
                  {/* <th onClick={() => document.getElementById('sort-nps_rating').click()}>
                    NPS Rating
                    {
                      sortState.nps_rating
                        ?
                        <img id='sort-nps_rating' src={rdSortUp} onClick={() => sortSurveyList('nps_rating', 'ascending')} />
                        :
                        <img id='sort-nps_rating' src={rdSortDown} onClick={() => sortSurveyList('nps_rating', 'descending')} />
                    }
                  </th> */}
                </tr>
              </thead>
              <tbody>
                {cities.map((d, index) => {
                  // console.log(d.npsRating)
                  if (index <= 9) {
                    let right = 95 - (d.npsRating) * 10
                    // right=95-(-1.3)*10  
                    // console.log(d.npsRating)
                    return <tr key={index}>
                      <td>
                        {index + 1}
                      </td>
                      <td>
                        {d.regionName}
                      </td>
                      <td>
                        {nFormatter(d.totalSampledUsers)}
                      </td>
                      <td>
                        {nFormatter(d.totalProductsSampled)}
                      </td>
                      {/* <td>
                        {nFormatter(d.add_to_cart)}
                      </td> */}
                      {/* <td >
                        <div className='stateNpsRating'>
                          {d.npsRating ? Math.round(d.npsRating) : "0.00"}
                          
                        </div>
                        <div className="topAreasMainColor">
                          <div className="linearGraph">

                          </div>
                          <div className="npsRatingMarker" style={{ right: `${-100 + right}px` }}>

                          </div>
                        </div>
                      </td> */}
                    </tr>
                  }
                })}
              </tbody>
            </table>
            : <div className="brandsVsCategorySkeleton">
              {
                noDataLoad !== true ?
                  <Skeleton
                    active
                    title
                    paragraph={{
                      rows: 8,
                    }} />
                  :
                  <img className="singleNoDataImage" src={DataLoader} alt='image data empty' />
              }
            </div>}
      </div>
      <UserWordsForState brandId={props.brandId} allChecked={props.allChecked}
        allProductsChecked={props.allProductsChecked}
        singleCheck={props.singleCheck} singleProductCheck={props.singleProductCheck} nextState={nextState} />
    </div>
  )
}
