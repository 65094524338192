import React, { useState, useEffect } from "react";
import axios from "axios";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-daterangepicker/daterangepicker.css";
import moment from "moment";
import RatingMetricBoxes from "./RatingMetricBoxes/RatingMetricBoxes";
import Dropdown from 'react-bootstrap/Dropdown'
import DropdownToggle from "react-bootstrap/esm/DropdownToggle";
import DropdownMenu from "react-bootstrap/esm/DropdownMenu";
import DateRangeExport from "../DateRange";
import { useSearchParams, useNavigate } from "react-router-dom";
import checkEnv from "../../utils/checkEnv";
import rdSortUp from "../images/rdSortUp.svg";
import rdSortDown from "../images/rdSortDown.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarDays } from "@fortawesome/free-regular-svg-icons";

const RatingsDetails = (props) => {
  const [searchParams, setSearchParams] = useSearchParams()
  const navigate = useNavigate()
  let token = localStorage.getItem("token")
  const [categoryList, setCategoryList] = useState([]);
  const [productListWithIdName, setProductListWithIdName] = useState([])
  const [categoryIdList, setCategoryIdList] = useState([])
  const [productIdList, setProductIdList] = useState([])
  const [productList, setProductList] = useState([]);
  const [checkedCategoryState, setCheckedCategoryState] = useState();
  const [checkedState, setCheckedState] = useState();
  const [categorySearch, setCategorySearch] = useState('')
  const [productSearch, setProductSearch] = useState('')
  const [sortState,setSortState]=useState({
    category:false,
    product:false,
    date:false
  })
  let url=checkEnv()
  let forward = ">";
  if(searchParams.get("date")){
    localStorage.setItem("start_date",searchParams.get("date").split("to")[0])
    localStorage.setItem("end_date",searchParams.get("date").split("to")[1])
  }
  let startDate = localStorage.getItem("start_date");

  let endDate = localStorage.getItem("end_date");

  const getCategories = async () => {
    const response = await axios.get(`${url}/brandsdashboardproxy/v1/brand/${searchParams.get(`brand`)}/category/`, {
      headers: {
        'authorization': `${token}`
      }
    })
      .catch((err) => {
        // console.log(err);
      });
    setCategoryList(response.data.map((dat) => dat));
    setCategoryIdList(response.data.map(d => d.categoryId));
    setCheckedCategoryState(new Array(response.data.length).fill(false))
  };
  const getProducts = async () => {
    const response = await axios.get(`${url}/brandsdashboardproxy/v1/brand/${searchParams.get(`brand`)}/product/?startDate=${startDate}&endDate=${endDate}&categoryIds=${searchParams.get(`categoryIds`)}`, {
      headers: {
        'authorization': `${token}`
      }
    })
      .catch((err) => {
        // console.log(err);
      });
    setProductList(response.data.map((dat) => dat.name));
    setProductListWithIdName(response.data.map(dat => dat))
    setCheckedState(new Array(response.data.length).fill(false))
    setProductIdList(response.data.map(dat => dat.sku))
  };
  useEffect(() => {
    getCategories();
  }, [searchParams.get('brand')])

  useEffect(() => {
    getProducts()
  }, [searchParams.get('categoryIds')])

  const handleOnChange1 = (e) => {
    if (e.target.checked === false) {
      let checkedAllCategoryState = new Array(categoryList.length).fill(false)
      setCheckedCategoryState(checkedAllCategoryState)
      navigate({
        pathname: `/brandsdashboard/ratings`,
        search: `?brand=${searchParams.get('brand')}&categoryIds=${[]}&productIds=${[]}&date=${searchParams.get('date')}`
      })
    }
    if (e.target.checked === true) {
      let checkedAllCategoryState = new Array(categoryList.length).fill(true)
      setCheckedCategoryState(checkedAllCategoryState)
      navigate({
        pathname: `/brandsdashboard/ratings`,
        search: `?brand=${searchParams.get('brand')}&categoryIds=${e.target.value}&productIds=${[]}&date=${searchParams.get('date')}`
      })
    }
    if (e.target.checked === undefined) {
      if (e.target.firstElementChild.checked === true) {
        let checkedAllCategories = new Array(categoryList.length).fill(false)
        setCheckedCategoryState(checkedAllCategories)
        navigate({
          pathname: `/brandsdashboard/ratings`,
          search: `?brand=${searchParams.get('brand')}&categoryIds=${[]}&productIds=${[]}&date=${searchParams.get('date')}`
        })
      }
      if (e.target.firstElementChild.checked === false) {
        let checkedAllCategories = new Array(categoryList.length).fill(true)
        setCheckedCategoryState(checkedAllCategories)
        navigate({
          pathname: `/brandsdashboard/ratings`,
          search: `?brand=${searchParams.get('brand')}&categoryIds=${e.target.firstElementChild.value}&productIds=${searchParams.get('productIds')}&date=${searchParams.get('date')}`
        })
      }
      return e.target.firstElementChild.checked = !e.target.firstElementChild.checked
    }

  };


  const handleOnChange = (e) => {
    if (e.target.checked === false) {
      let checkedAllProducts = new Array(productList.length).fill(false)
      setCheckedState(checkedAllProducts);
      navigate({
        pathname: `/brandsdashboard/ratings`,
        search: `?brand=${searchParams.get('brand')}&categoryIds=${searchParams.get('categoryIds')}&productIds=${[]}&date=${searchParams.get('date')}`
      })
    } else {
      let checkedAllProducts = new Array(productList.length).fill(true)
      setCheckedState(checkedAllProducts);
      navigate({
        pathname: `/brandsdashboard/ratings`,
        search: `?brand=${searchParams.get('brand')}&categoryIds=${searchParams.get('categoryIds')}&productIds=${e.target.value}&date=${searchParams.get('date')}`
      })
    }

    if (e.target.checked === undefined) {
      if (e.target.firstElementChild.checked === true) {
        let checkedAllCategories = new Array(productListWithIdName.length).fill(false)
        setCheckedState(checkedAllCategories)
        navigate({
          pathname: `/brandsdashboard/ratings`,
          search: `?brand=${searchParams.get('brand')}&categoryIds=${searchParams.get('categoryIds')}&productIds=${[]}&date=${searchParams.get('date')}`
        })
      }
      if (e.target.firstElementChild.checked === false) {
        let checkedAllCategories = new Array(productListWithIdName.length).fill(true)
        setCheckedState(checkedAllCategories)
        navigate({
          pathname: `/brandsdashboard/ratings`,
          search: `?brand=${searchParams.get('brand')}&categoryIds=${searchParams.get('categoryIds')}&productIds=${e.target.firstElementChild.value}&date=${searchParams.get('date')}`
        })
      }
      return e.target.firstElementChild.checked = !e.target.firstElementChild.checked
    }
  };
  const handleChange = (position) => {
    let productIdStates = []
    const updatedCheckedState = checkedState.map((item, index) =>
      index === position ? !item : item
    );
    setCheckedState(updatedCheckedState);
    updatedCheckedState.map((d, index) => {
      if (d === true) {
        productIdStates.push(productIdList[index])
      }
    })
    navigate({
      pathname: `/brandsdashboard/ratings`,
      search: `?brand=${searchParams.get('brand')}&categoryIds=${searchParams.get('categoryIds')}&productIds=${productIdStates}&date=${searchParams.get('date')}`
    })
  };
  const handleCategoryChange = (position) => {
    let categoryIdStates = []
    const updatedCheckedState = checkedCategoryState.map((item, index) =>
      index === position ? !item : item
    );
    setCheckedCategoryState(updatedCheckedState)
    updatedCheckedState.map((d, index) => {
      if (d === true) {
        categoryIdStates.push(categoryIdList[index])
      }
    })
    navigate({
      pathname: `/brandsdashboard/ratings`,
      search: `?brand=${searchParams.get('brand')}&categoryIds=${categoryIdStates}&productIds=${[]}&date=${searchParams.get('date')}`
    })
  };
  let selectedCategory=searchParams.get("categoryIds")!==""?searchParams.get("categoryIds").split(","):[]

  let selectedProducts=searchParams.get("productIds")!==""?searchParams.get("productIds").split(","):[]
  const changeImg=(st)=>{
    let copy={...sortState}
    // copy[st]=!copy[st]
    setSortState(copy)
  }
  const getCategory=(num)=>{
    // console.log(num,categoryList)
    let selectedname=""
    categoryList.forEach(e=>{
      if(e.categoryId===parseInt(num)){
          
          selectedname=e.categoryName
      }
    })
    return selectedname
  }
  const getProduct=(sku)=>{
    let selectedname=""
    productListWithIdName.forEach(e=>{
      if(e.sku===sku){
          selectedname=e.name
      }
    })
    return selectedname
  }

  const trimText=(str,strLen)=>{
    if(str.length>strLen){
      return str.slice(0,25)+`...`
    }
    return str
  }


  return (
    <div className="mainCategoryPage">
      <div className="catData" >
        <div className="home" >
          Home {forward}
          <p>Ratings and Reviews</p>
        </div>
        <div className="sample" >
          <div>

            <div className="samplingHeading">

              Sentiment Analysis

            </div>
          </div>
          <div ></div>
          <div className="category">
            <div className="first" >
              <Dropdown>
                <Dropdown.Toggle className="catSection1">
                <div className="myflex" onClick={()=>changeImg("category")}>
                    {selectedCategory.length===1?
                        <div>{trimText(getCategory(selectedCategory[0]),25)}</div>
                        :<div>{selectedCategory.length!==categoryList.length && selectedCategory.length!==0 ?`${selectedCategory.length} categories selecetd`:"All Categories Selected"}</div>
                    }                   
                     <div>
                      {sortState.category?<img src={rdSortUp} alt="up"></img>:<img src={rdSortDown} alt="down"></img>}
                    </div>
                  </div>
                </Dropdown.Toggle>
                <Dropdown.Menu className="sortStateSectionHideMenu">
                  <div className="mainCategory1" id="mainCategory1">
                    {/* <input
                      className="search"
                      type="text"
                      placeholder="Search"
                      id="mainCategory1"
                      value={categorySearch}
                      onChange={(e) => setCategorySearch(e.target.value)}
                    ></input> */}
                    <div className="cList" id="mainCategory1" onClick={handleOnChange1}>
                      <input
                        id="allCategoryChecked"
                        type="checkbox"
                        checked={searchParams.get('categoryIds') ? searchParams.get('categoryIds').split(',').length === categoryIdList.length : false}
                        // onChange={handleOnChange1}
                        value={categoryIdList}
                      ></input>
                      <p id="mainCategory1">All</p>
                    </div>
                    <div className="totalList1">
                      {categoryList.length > 0 ? categoryList.map((d, index) => {
                        return (
                          <div className="categoryList" id={index} key={index} onClick={() => handleCategoryChange(index)}>
                            <input
                              id={`singleCategory${index}`}
                              type="checkbox"
                              checked={searchParams.get('categoryIds') ? searchParams.get('categoryIds').split(',').includes(`${d.categoryId}`) : false}
                              // onChange={() => handleCategoryChange(index)}
                              value={d.categoryId}
                            ></input>
                            <p>{d.categoryName}</p>
                          </div>
                        );
                      }) : ""}
                    </div>
                  </div>
                </Dropdown.Menu>
              </Dropdown>
            </div>
            <div className="second">
              <Dropdown>
                <DropdownToggle className="catSection2">
                <div className="myflex" onClick={()=>changeImg("product")}>
                {selectedProducts.length===1?
                    <div>{trimText(getProduct(selectedProducts[0]),25)}</div>
                      :<div>{selectedProducts.length!==productIdList.length && selectedProducts.length!==0?`${selectedProducts.length} products selecetd`:"All Products Selected"}</div>}                    <div>
                      {sortState.product?<img src={rdSortUp} alt="up"></img>:<img src={rdSortDown} alt="down"></img>}
                    </div>
                  </div>

                </DropdownToggle>
                <DropdownMenu>
                  <div className="mainCategory2">
                    {/* <input
                      className="search"
                      type="text"
                      placeholder="Search"
                      value={productSearch}
                      onChange={(e) => setProductSearch(e.target.value)}
                    ></input> */}
                    <div className="pList" onClick={handleOnChange}>
                      <input
                        id="allProductsChecked"
                        type="checkbox"
                        checked={searchParams.get('productIds') ? searchParams.get('productIds').split(',').length === productIdList.length : false}
                        // onChange={handleOnChange}
                        value={productIdList}
                      ></input>
                      <p>All</p>
                    </div>
                    <div className="totalList">
                      {productListWithIdName.length > 0 ? productListWithIdName.map((d, index) => {
                        return (
                          <div className="productList" id={"product" + index} key={index} onClick={() => handleChange(index)}>
                            <input
                              type="checkbox"
                              checked={searchParams.get('productIds') ? searchParams.get('productIds').split(',').includes(`${d.sku}`) : false}
                              // onChange={() => handleChange(index)}
                              value={d.sku}
                            ></input>
                            <p>{d.name}</p>
                          </div>
                        )
                      }) : ""}
                    </div>
                  </div>
                </DropdownMenu>
              </Dropdown>
            </div>
            <div className="DateRange">
              <Dropdown>
                <DropdownToggle id="date">
                  <div className="myflex">
                      <div style={{marginRight:"10px"}}>
                        {moment(startDate).format("LL")} to  {moment(endDate).format("LL")}
                      </div>
                      <div>
                        <FontAwesomeIcon className="calenderIcon" icon={faCalendarDays}></FontAwesomeIcon>
                      </div>
                    </div>
                </DropdownToggle>
                <DropdownMenu className="newDate">
                  <DateRangeExport />
                </DropdownMenu>
              </Dropdown>
            </div>
          </div>
        </div>
      </div>
      <RatingMetricBoxes brandId={props.brandId}
      />
    </div>
  );
};

export default RatingsDetails;
