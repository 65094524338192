import React, { useState, useEffect } from "react";
import { Chart } from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import DataLoader from "../images/dataload_gif.gif"
import stateBackgroundBlurImage from "../images/planLock.png"
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import axios from "axios";
import { Bar } from "react-chartjs-2";
import { useSearchParams } from "react-router-dom";
import {Skeleton} from "antd"
import checkEnv from "../../utils/checkEnv";

ChartJS.register(
  ChartDataLabels,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

function nFormatter(num) {
  if (num >= 1000000000) {
    return (num / 1000000000).toFixed(1).replace(/\.0$/, "") + "G";
  }
  if (num >= 1000000) {
    return (num / 1000000).toFixed(1).replace(/\.0$/, "") + "M";
  }
  if (num >= 1000) {
    return (num / 1000).toFixed(1).replace(/\.0$/, "") + "K";
  }
  return num;
}
const ConsumerUsersInterested = (props) => {
  const [searchParams,setSearchParams] = useSearchParams()
  let token = localStorage.getItem("token")
  let brandId = props.brandId
  let startDate = localStorage.getItem("start_date");
  let endDate = localStorage.getItem("end_date");
  let url=checkEnv()
  const [userData, setUserData] = useState([])
  const [xLabels, setXLabaels] = useState([])
  const [hoverLabels, setHoverLabels] = useState([])
  const datas = {
    labels: xLabels.map((text) => text + `...`).slice(0, 6),
    datasets: [
      {
        label: "Users",
        data: userData.map((text) => text.value).slice(0, 6),
        backgroundColor: "rgb(0, 48, 135)",
        barThickness: 30,
      },
    ],
  };
  let categoryId = localStorage.getItem('allcategoryId') ? (localStorage.getItem('allcategoryId').split(',')) : []
  let productId = localStorage.getItem('allProductId') ? localStorage.getItem('allProductId').split(',') : []
  const getUsersTriedInterestedGraph = async () => {
    setUserData([])
    const response = await axios.get(`${url}/brandsdashboardproxy/v1/brand/${searchParams.get('brand')}/triedalsointerested/?startDate=${startDate}&endDate=${endDate}&categoryIds=${searchParams.get('categoryIds')}&productIds=${searchParams.get('productIds')}`, {
      headers: {
        'authorization': `${token}`
      }
    })
      .catch((err) => {
        console.log(err);
      });
    setUserData(response.data);
    setXLabaels(response.data.map(d => (d.sub_categories).slice(0, 9)))
    setHoverLabels(response.data.map(d => d.sub_categories))
  };
  useEffect(() => {
    getUsersTriedInterestedGraph();
  }, [searchParams]);


  return (
    <>
      <div className="barChartUsersTried">
        <div className="usersTried">
          <b>Users who Tried your product are also Interested in</b>
        </div>
        {
          props.nextState === true && userData.length !== 0 ?
            <Bar
              data={datas}
              height={200}
              options={{
                layout: {
                  padding: {
                    top: 10,
                  }
                },
                plugins: {
                  tooltip: {
                    callbacks: {
                      title: function (context) {
                        let index = context[0].dataIndex
                        return `Sub category : ${hoverLabels[index]}`
                      }
                    }
                  },
                  title: {
                    display: true,
                    text: "Top 6 Sub-Categories",
                    position: "bottom",
                  },
                  legend: {
                    display: false,
                    position: "bottom",
                  },
                  datalabels: {
                    formatter: function (value, context) {
                      const datapoints = context.chart.data.datasets[0].data;
                      function totalSum(total, datapoint) {
                        return total + datapoint;
                      }
                      const totalValue = datapoints.reduce(totalSum, 0);
                      const percentageValue = ((value / totalValue) * 100).toFixed(1);

                      return `${percentageValue}%`;
                    },
                    anchor: "end",
                    align: "top",
                    color: "#003087",
                    padding:{
                      bottom:-10
                    },
                  },
                },
                scales: {
                  y: {
                    ticks: {
                      callback: function (val, index) {
                        return nFormatter(val);
                      },
                    },
                  },
                },
              }}
            />
            :<div className="brandsVsCategorySkeleton"> <Skeleton
            active
            title
            paragraph={{
              rows: 8,
            }} />
            </div>
        }
      </div>

    </>
  );
};

export default ConsumerUsersInterested;