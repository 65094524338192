import React from 'react';
import ReactWordcloud from 'react-wordcloud';
export default function CompetitveAnalysisWordCloud(props) {

  const options = {
    colors: ["rgb(1, 162, 76)", "rgb(1, 33, 101)","rgb(85, 208, 0)", "rgb(1, 33, 101)","rgb(4, 133, 195)","rgb(1, 162, 76)"],
    enableTooltip: false,
    deterministic: true,
    fontFamily: "impact",
    fontSizes: [20, 50],
    fontStyle: "normal",
    fontWeight: "normal",
    padding: 5,
    rotations: 0,
    rotationAngles: [0,290,],
    scale: "sqrt",
    spiral: "archimedean",
    // transitionDuration: 10
  };
  const size = [600, 150]
  console.log(props.words)
  return (
    <div className="WordCloudSection">
      <ReactWordcloud
        options={options} words={props.words}
      //  size={size}
      />
    </div>
  )
}