import React from 'react'

export default function BasketMiniCard({index,data}) {

  
    const trimStr=(str,strlen)=>{
        if(strlen<str.length){
            return `${str.slice(0,strlen)}...`
        }else{
            return str
        }
    }

    const renderSub=()=>{
        let arr=[]
        data.ingredients.forEach((topSub,ind)=>{
            if(arr.length<3){
                if(topSub.length>=1){
                    arr.push(<div key={ind} className='basket-categeryChips'><span>{trimStr(topSub,18)}</span></div>) 
                }
            }
            
        })
        return arr
    }
  return (
    <div className='basket-card-inner-container'>
                <div className='basket-inner-card-header'>
                    <div className='basket-inner-card-header-left-side'>
                        <div className='round'>{index+1}</div>
                        <div className='basket-inner-card-header-text'>{data.name}</div>
                    </div> 
                </div>
                <div className='basket-inner-card-body'>
                    {data.ingredients && renderSub()}
                </div>
    </div>
  )
}
