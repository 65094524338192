import React, { useState, useEffect } from 'react'
import ChartDataLabels from "chartjs-plugin-datalabels";
import {
    Chart,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import MultiSelect from "../../images/MultiSelect.svg"
import SingleSelect from '../../images/SingleSelect.svg';
import MatrixSelect from "../../images/MatrixSelect.svg";
import RangeSelect from "../../images/RangeSelection.svg";
import OrderSelect from "../../images/OrderSelection.svg"
import TextInput from "../../images/TextInput.svg";
import RangeSelectGraph from "../../images/rangeSelectionGraph.svg"

export default function ResultAnalysisOrder(props) {

    const [orderData, setorderData] = useState(props.data.result);
    const progressColors = ['#D3F4DF', '#DEF6D2', '#DDF7FF', '#FEFFDD', '#FFEAD6', '#FFDBD6']
    const [questionObject, setQuestionObject] = useState(props.data);

    const questionTypeLabels = {
        'SINGLE_SELECT': 'Single Selection',
        'MULTI_SELECT': 'Multiple Selection',
        'MATRIX_SELECT': 'Matrix Selection',
        'RANGE_SELECTION': 'Range Selection',
        'ORDER_SELECTION': 'Order Selection',
        'TEXT_INPUT': 'Text Input',
    }

    const questionType = () => {
        switch (questionObject.question_type) {
            case 'SINGLE_SELECT':
                return <img src={SingleSelect} alt='singleSelect' />
            case 'MULTI_SELECT':
                return <img src={MultiSelect} alt='multiSelect' />
            case 'MATRIX_SELECT':
                return <img src={MatrixSelect} alt='matrixSelect' />
            case 'RANGE_SELECTION':
                return <img src={RangeSelect} alt='rangeSelect' />
            case 'ORDER_SELECTION':
                return <img src={OrderSelect} alt='orderSelect' />
            case 'TEXT_INPUT':
                return <img src={TextInput} alt='textInput' />
            default:
                return <></>
        }
    }
    // const data1 = [{
    //     label: 'quality',
    //     ranks: [{
    //         label: 'Rank 1',
    //         value: 20
    //     },
    //     {
    //         label: 'Rank 2',
    //         value: 20
    //     }
    //     ],

    // },
    // {
    //     label: 'quantity',
    //     ranks: [{
    //         label: 'Rank 1',
    //         value: 20
    //     },
    //     {
    //         label: 'Rank 2',
    //         value: 15
    //     }
    //     ],

    // }, 
    // ]
    const splitFunction = (input) => {
        return input ? input.match(/\b[\w']+(?:[^\w\n]+[\w']+){0,2}\b/g) : "";
    }

    const options = {
        indexAxis: 'y',
        plugins: {
            // tooltip: {
            //     callbacks: {
            //         title: function (context) {
            //             let index = context[0].dataIndex
            //             return hoverLabels[index]
            //         },
            //         label: function (context) {
            //             return `${context.dataset.label} ${context.raw.toFixed(2)}%`
            //         }
            //     }
            // },
            title: {
                display: false,
                text: 'Ratings Of The Users Who Tried Your Product',
                font: {
                    weight: "bold",
                    size: 18,
                },
                align: "start",
                padding: {
                    top: 10,
                    bottom: 50,
                },
            },
            legend: {
                display: false,
            },
            datalabels: {
                // display:false,
                formatter: function (value, context) {
                    if (value > 10) {
                        return `${context.dataset.label}-${value.toFixed(2)}%`;
                    } else {
                        return ''
                    }
                },
                anchor: 'center',
                align: "center",
                color: "#0F1628"
            }
        },
        responsive: true,
        scales: {
            x: {
                max: 100,
                stacked: true,
                ticks: {
                    min: 0,
                    stepSize: 20
                },
                grid: {
                    // display: false,
                    // drawTicks: false,
                    drawOnChartArea: false,
                    // drawBorder: false
                  },
            },
            y: {
                // beginAtZero: true,
                stacked: true,
                grid: {
                    drawTicks: false,
                },
                ticks: {
                    autoSkip:false
                },
                grid: {
                    // display: false,
                    // drawTicks: false,
                    drawOnChartArea: false,
                    // drawBorder: false
                  },
                afterFit: function (scaleInstance) {
                    scaleInstance.width = 130 // sets the width to 100px
                }
            },
        },
    };

    const colorChange = (percentage) => {
        // console.log(percentage,'percentage')
        if (percentage === 0) {
            return 'white'
        } else if (percentage >= 75 && percentage <= 100) {
            return progressColors[0]
        } else if (percentage >= 60 && percentage < 75) {
            return progressColors[1]
        } else if (percentage >= 45 && percentage < 60) {
            return progressColors[2]
        } else if (percentage >= 30 && percentage < 45) {
            return progressColors[3]
        } else if (percentage > 0 && percentage < 30) {
            return progressColors[4]
        }
    }

    // console.log(.data.datasets)
    const data = {
        labels: orderData.map(text => splitFunction(text.value)),
        datasets: [],
    };

    // orderData.map(d=>{
    //     d.ranks.map(d2=>{
    //         return data.datasets.push({
    //             label:orderData.map((d, i) => d.`Rank-3`,
    //             data: [20],
    //             backgroundColor: 'rgb(0, 188, 138)',
    //             barThickness: 45,
    //         })
    //     })
    // })

    let first = []
    // console.log(d.ranks.map((d1, ine) => d.ranks[ine].value), 'with different index')
    // data.datasets.push({
    //     label: `Rank ${i + 1}`,
    //     data: d.ranks.map((d1, ine) => d.ranks[ine].value),
    //     backgroundColor: i % 2 == 0 ? 'rgb(0, 188, 138)' : 'rgb(255, 207, 97)',
    //     barThickness: 45,
    // })

    // orderData.map((d, i) => {
    //     var second = []
    //     // second.push(d.ranks.value)
    //     d.ranks.map((data,index)=>{
    //         if (i === index){
    //             second.push(data.value)
    //         }
    //     })
    //     first.push(second)
    // })
    orderData.map((d, index) => {
        let a = []
        for (let i = 0; i < orderData.length; i++) {
            for (let j = 0; j < orderData[i].ranks.length; j++) {
                // console.log(orderData[i].ranks)
                if (index === j) {
                    a.push(orderData[i].ranks[j].value)
                }
            }
        }
        first.push(a)
    })

    if (first.length === orderData.length) {
        orderData.map((d, i) => {
            // console.log(first[i])
            data.datasets.push({
                label: `Rank ${i + 1}`,
                data: first[i],
                backgroundColor: first[i].map(d => colorChange(d)),
                barThickness: 30,
            })
        })
    }



    return (
        <div className='resultAnalysisOrderSection'>
            <div className='resultTopSection'>
                <div className='resultSingleSelectHeaderSection'>
                    <div className='resultIndex'>
                        {(props.index + 1 < 10) ? '0' + (props.index + 1) : props.index + 1}&nbsp;&nbsp;&nbsp;
                    </div>
                    <div className='resultSingleSelectHeaderFirstSection'>
                        <div className='resultQuestion'>
                            <div className='resultQuestionText'>
                                {questionObject.question_text}&nbsp;&nbsp;&nbsp;
                            </div>
                            <div className='cond-questionText-inner'>
                                <span className='questionType'>{questionType()}{questionTypeLabels[questionObject.question_type]}</span>
                            </div>
                        </div>

                    </div>
                </div>
                <div className='resultSingleSelectHeaderSecondSection'>
                    <div className='resultAnswered'>
                        <span>800</span> &nbsp;Answered
                    </div>&nbsp;&nbsp;&nbsp;&nbsp;
                    <div className='resultSkipped'>
                        <span>200</span>&nbsp; Skipped
                    </div>
                </div>
            </div>
            <Bar
                height={orderData.length*15}
                options={options}
                data={data} />
        </div>
    )
}
